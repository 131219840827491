import { AuthContext } from "../../Context"
import { AuthContextApi } from '../../Context/api'
import { useContext, useEffect, useState } from 'react'
import { CheckCircleIcon, ChevronDoubleUpIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Tabela/TabelaPontosUsuario'
import ModalShowTable from '../../Components/Modal/ShowTableUsuarioPonto'
import ModalPontoEdit from "../../Components/Modal/PontoEdit"
import SpinnerOverlay from "../../Components/SpinnerOverlay"
import Navbar from '../../Components/Navbar/navbar'
import { toast } from "react-toastify"
import moment from "moment"

export default function Perfil() {
    const { states, setters } = useContext(AuthContext)
    const { changeEmail, changePassword, baterPonto, dataUsuarioDiasDeTrabalho, dataPontosUsuario, encerrarDiaEmAberto, checkLogin, editMyPonto } = useContext(AuthContextApi)

    const [email, setEmail] = useState(states.email)
    const [senha, setSenha] = useState('')
    const [novaSenha, setNovaSenha] = useState('')
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('')

    const [hasNovaSenha, setHasNovaSenha] = useState(false)
    const [errorSenha, setErrorSenha] = useState(false)
    const [errorNovaSenha, setErrorNovaSenha] = useState(false)

    const [cumprimento, setCumprimento] = useState('')

    const [arrowButton, setArrowButton] = useState(true)
    const [titleModal, setTitleModal] = useState('')
    const [now, setNow] = useState(new Date())



    const load = async () => {
        await checkLogin()
        if (localStorage.getItem('funcionario') == 'true') {
            await dataUsuarioDiasDeTrabalho()
        }
    }

    const confereSenha = () => {
        try {
            if (errorSenha) {
                if (senha == '') {
                    setErrorSenha(true)
                }
                else {
                    setErrorSenha(false)
                }
            }
        }
        catch (err) {

        }
    }

    const confereNovaSenha = () => {
        if (novaSenha == confirmarNovaSenha) {
            setErrorNovaSenha(false)
        }
        else {
            setErrorNovaSenha(true)
        }
    }

    const enviarFormulario = async (e) => {
        try {
            e.preventDefault()
            if (email != states.email) {
                await mudarEmail()
            }

            if (senha != '' || novaSenha != '') {
                await mudarSenha()
            }
        }
        catch (err) {

        }
    }

    const mudarEmail = async () => {
        try {
            if (senha == '' && novaSenha == '') {
                setErrorSenha(true)
            }
            else {
                const objEmail = { email: email, password: senha }
                await changeEmail(objEmail)
            }
        }
        catch (err) {

        }
    }

    const mudarSenha = async () => {
        try {
            if (senha == '') {
                setErrorSenha(true)
            }
            else {
                if (errorNovaSenha == false) {
                    const objPassword = { password: senha, newPass: novaSenha }
                    const result = await changePassword(objPassword)
                    if (result.status) {
                        setSenha('')
                        setNovaSenha('')
                        setConfirmarNovaSenha('')
                        setHasNovaSenha('')
                        setErrorNovaSenha('')
                    }
                }
                else {

                }
            }
        }
        catch (err) {

        }
    }

    const openForm = async () => {
        if (arrowButton == true) {
            setArrowButton(false)
        } else {
            setArrowButton(true)
        }
    }

    const baterPontoOnClick = async () => {
        const retorno = await baterPonto()
        if (retorno) {
            toast.success('Ponto registrado com sucesso!')
        }
    }

    const encerrarDiaOnClick = async () => {
        const retorno = await encerrarDiaEmAberto()
        if (retorno) {
            toast.success('Dia encerrado com suceessos!')

        }
    }


    const next = async () => {
        if (states.dataUsuarioDiasTrabalho.length === 0) {
            return
        }
        else {
            if (!states.limitSearchUsuarioDiasTrabalho) {
                setters.setOffSetUsuarioDiasTrabalho(states.offsetUsuarioDiasTrabalho + states.limitUsuarioDiasTrabalho)
                await dataUsuarioDiasDeTrabalho()
            } else {
                return;
            }
        };
    }

    const previous = async () => {
        if (states.dataUsuarioDiasTrabalho.length === 0 && states.offsetUsuarioDiasTrabalho <= 0) {
            states.offsetUsuarioDiasTrabalho < 0 && setters.setOffSetUsuarioDiasTrabalho(0)
            return
        } else {
            states.offsetUsuarioDiasTrabalho > 0 && setters.setOffSetUsuarioDiasTrabalho(states.offsetUsuarioDiasTrabalho - states.limitUsuarioDiasTrabalho)
            states.limitSearchUsuarioDiasTrabalho && setters.setLimitSearchUsuarioDiasTrabalho(false)
            await dataUsuarioDiasDeTrabalho()
        }
    }

    const openModalPontos = async (day) => {
        setters.setDataPontosUsuario([])
        setters.setOffSetPontosUsuario(0)
        setters.setLimitSearchPontosUsuario(false)
        setters.setIdDiaTrabalho(day.id)
        setTitleModal(`Pontos do dia: ${moment(day.date).format("DD/MM/YYYY")} - Usuário: ${day.email}`)
        setters.setModalPonto(true)
        await dataPontosUsuario(day.id)
    }

    const openModalEditPontos = async (ponto) => {
        setters.setPonto(ponto)
        setters.setModalEditPontos(true)
    }

    const actionEditPontos = async (ponto, id) => {
        const resp = await editMyPonto(ponto, id)
        if (resp) {
            setters.setModalEditPontos(false)
            setters.setPonto({})
        }
    }

    const headers = ['Descrição', 'Data', 'Horário de Entrada', 'Horário de Saída', 'Horário Total (Dia)', 'Pontos']
    const headersPontos = ['Solicitar troca', 'Data', 'Tipo do ponto', 'Horário de Entrada', 'Horário de Saída', 'Horário Total']

    const loadPontos = async () => {
        if (states.dataPontosUsuario.length > 0 && states.idDiaTrabalho != '') {
            await dataPontosUsuario(states.idDiaTrabalho)
        }

    }


    const nextModal = async () => {
        if (states.dataPontosUsuario.length === 0) {
            return;
        }
        else {
            if (!states.limitSearchPontosUsuario) {
                setters.setOffSetPontosUsuario(states.offsetPontosUsuario + states.limitPontosUsuario)
            } else {
                return;
            }
        };
    }

    const previousModal = async () => {
        if (states.dataPontosUsuario.length === 0 && states.offsetPontosUsuario <= 0) {
            states.offsetPontosUsuario < 0 && setters.setOffSetPontosUsuario(0)
            return
        } else {
            states.offsetPontosUsuario > 0 && setters.setOffSetPontosUsuario(states.offsetPontosUsuario - states.limitPontosUsuario)
            states.limitSearchUsuarioDiasTrabalho && setters.limitSearchPontosUsuario(false)
        }
    }


    useEffect(() => {
        load()
        setNow(new Date())
        setters.setModalPonto(false)
        setters.setIdDiaTrabalho('')
        setters.setDataPontosUsuario([])
        setters.setLimitSearchPontosUsuario(false)
        setters.setPermission(localStorage.getItem('permission'))
        const dataHora = new Date()
        const hora = dataHora.getHours()
        if (hora >= 1 && hora <= 11) {
            setCumprimento('Bom dia')
        }
        else if (hora >= 12 && hora <= 17) {
            setCumprimento('Boa tarde')
        }
        else if (hora >= 18 && hora <= 23) {
            setCumprimento('Boa noite')
        }
        else {
            setCumprimento('Olá')
        }
        return () => {
            setters.setDataUsuarioDiasTrabalho([])
            setters.setOffSetUsuarioDiasTrabalho(0)
            setters.setLimitSearchUsuarioDiasTrabalho(false)
        }

    }, []);


    useEffect(() => {
        loadPontos()
    }, [states.offsetPontosUsuario])


    useEffect(() => {
        if (novaSenha == '') {
            setConfirmarNovaSenha('')
            setHasNovaSenha(false)
        }
        else {
            setHasNovaSenha(true)
        }
    }, [novaSenha]);

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="py-2 flow-root mb-4">
                                <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                            <div className='p-5'>
                                                <div className="space-y-12 sm:space-y-16">
                                                    <div>
                                                        <div className="rounded-md flex justify-between items-center bg-white">
                                                            <div className="flex flex-1 justify-between items-center">
                                                                <div className="flex flex-col">
                                                                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                                                        {cumprimento}, {localStorage.getItem('email')}
                                                                    </h1>
                                                                    <div className="flex items-center">
                                                                        <CheckCircleIcon
                                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Permissão:
                                                                        {states.permission === '0' ? 'Administrador' :
                                                                            states.permission === '1' ? 'Financeiro' :
                                                                                states.permission === '2' ? 'Administrativo' :
                                                                                    states.permission === '3' ? 'Operacional' :
                                                                                        states.permission === '4' ? 'Técnico' :
                                                                                            states.permission === '5' ? 'Comunicação' : 'Usuário'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex p-2">
                                                                {
                                                                    localStorage.getItem('funcionario') == 'true' &&
                                                                    <div className='flex-1 flex bg-white justify-end items-center mr-8'>
                                                                        <button onClick={() => baterPontoOnClick()} className="flex items-center justify-center h-10 w-10 rounded-md borderfocus-visible:outline-offset-0 px-14 py-6 bg-blue-600 text-sm font-semibold text-white shadow-sm hover:bg-blue-500">
                                                                            Bater Ponto
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    localStorage.getItem('funcionario') == 'true' && (localStorage.getItem('diaAberto') == 'true' || (now.getHours() > 13 || now.getHours() > 16 || (now.getHours() === 16 && now.getMinutes() > 30))) &&
                                                                    <div className='flex-1 flex bg-white justify-end items-center'>
                                                                        <button onClick={() => encerrarDiaOnClick()} className="flex items-center justify-center h-10 w-10 rounded-md borderfocus-visible:outline-offset-0 px-14 py-6 bg-blue-600 text-sm font-semibold text-white shadow-sm hover:bg-blue-500">
                                                                            {
                                                                                localStorage.getItem('diaAberto') == 'true' ? 'Regularizar dia' : 'Encerrar Dia'
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="py-2 mt-4 flow-root">
                                <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                            <form className='p-5' onSubmit={e => enviarFormulario(e)}>
                                                <div className="space-y-12 sm:space-y-16">
                                                    <div>
                                                        <div className="rounded-md flex justify-between items-center bg-white">
                                                            <div className="flex flex-1 justify-between items-center">
                                                                <div className="flex flex-col">
                                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do usuário</h2>
                                                                    <div className="flex items-center">
                                                                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                                                            Aqui você pode alterar as informações do seu perfil.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='flex-1 flex bg-white justify-end items-center'>
                                                                <button
                                                                    className="flex items-center text-gray-600 hover:text-gray-800"
                                                                    onClick={openForm}
                                                                >
                                                                    <ChevronDoubleUpIcon
                                                                        className={`${arrowButton ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {!arrowButton &&
                                                            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                                                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                        Email
                                                                    </label>
                                                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            id="email"
                                                                            name="email"
                                                                            type="email"
                                                                            autoComplete="email"
                                                                            value={email}
                                                                            onChange={e => setEmail(e.target.value)}
                                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                        Senha atual
                                                                    </label>
                                                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            id="password"
                                                                            name="password"
                                                                            type="password"
                                                                            autoComplete="password"
                                                                            placeholder='Digite a senha atual ..'
                                                                            value={senha}
                                                                            onChange={e => setSenha(e.target.value)}
                                                                            onBlur={() => confereSenha()}
                                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorSenha === true && (
                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                É necessário preencher a senha para efetuar edições.
                                                                            </p>
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                        Nova senha
                                                                    </label>
                                                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            id="newPassword"
                                                                            name="newPassword"
                                                                            type="password"
                                                                            autoComplete="newPassword"
                                                                            placeholder='Digite a nova senha ..'
                                                                            value={novaSenha}
                                                                            onChange={e => setNovaSenha(e.target.value)}
                                                                            onBlur={() => confereNovaSenha()}
                                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorNovaSenha === true && (
                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                A Senha e a Nova senha não conferem.
                                                                            </p>
                                                                        )
                                                                    }
                                                                </div>

                                                                {hasNovaSenha &&
                                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                            Confirme a nova senha
                                                                        </label>
                                                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                id="newPassword"
                                                                                name="newPassword"
                                                                                type="password"
                                                                                autoComplete="newPassword"
                                                                                placeholder='Digite a confirmação ..'
                                                                                value={confirmarNovaSenha}
                                                                                onChange={e => setConfirmarNovaSenha(e.target.value)}
                                                                                onBlur={() => confereNovaSenha()}
                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                            />
                                                                        </div>
                                                                        {
                                                                            errorNovaSenha === true && (
                                                                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                    A Senha e a Nova senha não conferem.
                                                                                </p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>}

                                                    </div>
                                                </div>

                                                {!arrowButton &&
                                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                                        <button
                                                            type="submit"
                                                            className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                        >
                                                            Salvar
                                                        </button>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {
                                localStorage.getItem('funcionario') == 'true' &&
                                <Table title='Registros dos dias de serviço' description='Registro dos dias de trabalho' next={next} previous={previous} states={states} setters={setters} pontos={openModalPontos}
                                    headers={headers} infoTable={{ offset: states.offsetUsuarioDiasTrabalho, limit: states.limitUsuarioDiasTrabalho }} dataTable={states.dataUsuarioDiasTrabalho} />
                            }
                            <ModalShowTable states={states} setters={setters} title={titleModal} data={states.dataPontosUsuario}
                                headers={headersPontos} descriptio={titleModal} dataFunction={dataPontosUsuario}
                                next={nextModal} previous={previousModal} editPonto={openModalEditPontos}

                            />
                            <ModalPontoEdit states={states} setters={setters} action={actionEditPontos} />

                        </div>
                    </div>
                </div>

                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}