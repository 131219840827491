import { Link } from "react-router-dom";
import { UserGroupIcon, FingerPrintIcon, PhotoIcon } from '@heroicons/react/24/outline'
import { useContext, useEffect } from "react";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";


const AdminIndex = () => {

    const { checkLogin } = useContext(AuthContextApi)
    const { setters } = useContext(AuthContext)

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                setters.setSigned(false)
                return;
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [])



    return (
        <>
        <div className="flex flex-1 flex-col pb-16 bg-white">
            <Navbar />
            <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                <div className="p-4">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                            <h1 className="text-3xl text-center font-bold">Painel Usuários</h1>
                            <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as áreas de usuários do sistema</p>
                        </div>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/feriasIndex" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <PhotoIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl font-bold text-center">Férias</h2>
                                    <p className="text-center text-center">Clique nesse cartão para acessar o painel de férias</p>
                                </Link>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/historicousuarios" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <UserGroupIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl font-bold text-center">Histórico dos Usuários</h2>
                                    <p className="text-center text-center">Clique nesse cartão para acessar os registros dos usuários</p>
                                </Link>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/pontoEletronicoIndex" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <FingerPrintIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl font-bold">Ponto Eletrônico</h2>
                                    <p className="text-center">Clique nesse cartão para acessar o painel de ponto eletrônico</p>
                                </Link>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/usuarios" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <UserGroupIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl font-bold">Usuários</h2>
                                    <p className="text-center">Clique nesse cartão para acessar os usuários.</p>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="pt-8">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>

        </>
    )

}

export default AdminIndex;
