import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment'


export default function ModalShow({ states, setters, title, data, closeModal }) {

    const [open, setOpen] = useState(true)
    const [obj, setObj] = useState({})

    const close = () => {
        closeModal()
    }

    const valueMonetary = (value) => {
        const divid = value / 100
        return divid.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    const formatPrice = (data) => {
        const valorEmReais = (data / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return valorEmReais;

    }

    useEffect(() => {
        if (states.modalShowTecnico === false) return
        setObj(data.objInsert)
    }, [states.modalShowTecnico])

    return (
        <Transition.Root show={states.modalShowTecnico} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-0"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-blue-100 px-4 py-5 sm:px-6  items-center justify-center">
                                <h2 className="text-lg font-medium text-gray-900 text-center">{title}</h2>
                            </div>
                            <div className="px-4 py-5 sm:p-6">
                                <dl className="space-y-2">

                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">E-mail do aprovador:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.emailResponse}</dd>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">E-mail do requerente:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.emailRequest}</dd>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Tipo:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                                            {
                                                data.type === 1 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Fatura
                                                    </span>
                                                ) : (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                                                        Assinatura
                                                    </span>
                                                )
                                            }
                                        </dd>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Data de criação:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{moment(data.createdAt).format('DD/MM/YYYY')}</dd>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Objeto:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                                            {
                                                Object.keys(obj).map((key, index) => {
                                                    if(key === 'iat') return 
                                                    return (
                                                        <div key={index}>
                                                            <div className="flex items-start justify-start">

                                                                {key} : {obj[key]}

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </dd>
                                    </div>


                                </dl>
                            </div>
                            <div className="bg-blue-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

