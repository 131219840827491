
import NavBar from "../../../Components/Navbar/navbar";
import NavBarPanel from "../../../Components/navMenu/juridico";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import ModalConfirm from '../../../Components/Modal/Confirm'
import Footer from "../../../Components/Footer/footer";
import Table from '../../../Components/Tabela/TabelaUsersJuridico'
import ModalUsuarios from '../../../Components/Modal/UsuariosJuri'


const AdvogadosJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const { dataSystemUsers, checkToken, dataUsers, loadUsers, createRegister, getUserRegiserEmail, editRegister, blockUnlockRegister } = useContext(AuthContextJuri);
    const [search, setSearch] = useState('')


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }




    const loadData = async () => {
        await dataSystemUsers()
        await dataUsers()
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES


    const openModal = async () => {
        await loadUsers()
        setters.setIsEditJuri(false)
        setters.setModalJuri(true)
    }

    const openModalEdit = async (data) => {
        await loadUsers()
        setters.setIsEditJuri(true)
        setters.setJuriUser(data)
        setters.setModalJuri(true)
    }

    const actionModal = async (data) => {
        if (states.isEditJuri) {
            await editRegister(data)
        } else {
            await createRegister(data)
        }
        loadData()
    }

    const getEmailUser = async () => {
        if (search == '') {
            loadData()
        } else {
            await getUserRegiserEmail(search)
        }
    }



    const headers = ['Status', 'Permissão', 'Email', 'Criador', 'Data de criação', 'Data de atualização', '']
    const [titleConfirm, setTitleConfirm] = useState('')
    const [descriptionConfirm, setDescriptionConfirm] = useState('')

    const lockUnlock = async (data) => {
        setters.setIsEditJuri(true)
        if (data.status == 0) {
            setTitleConfirm('Deseja bloquear o usuário?')
            setDescriptionConfirm('Ao bloquear o usuário ele não terá mais acesso ao sistema jurídico.')
        }
        if (data.status == 1) {
            setTitleConfirm('Deseja desbloquear o usuário?')
            setDescriptionConfirm('Ao desbloquear o usuário ele terá acesso ao sistema.')
        }
        setters.setJuriUser(data)
        setters.setConfirmModal(true)
    }

    const actionModalConfirm = async () => {
        await blockUnlockRegister(states.JuriUser.id)
        loadData()
    }


    const next = async () => {
        if (states.limitSearchJuriUser && states.offsetJuriUser > 0) {
            return;
        } else {
            setters.setOffsetJuriUser(states.offsetJuriUser + states.limitJuriUser)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriUser && states.offsetJuriUser <= 0) {
            states.offsetJuriUser < 0 && setters.setOffsetJuriUser(0)
            return;
        } else {
            setters.setOffsetJuriUser(states.offsetJuriUser - states.limitJuriUser)
        }
    };

    useEffect(() => {
        if (states.dataJuriUser.length == 0) loadData()
        checkTokenLoad()

        return () => {
            setters.setDataJuriUser([])
            setters.setOffsetJuriUser(0)
            setters.setLimitSearchJuriUser(false)
        }
    }, [])


    useEffect(() => {
        if (states.offsetJuriUser >= 0 && states.dataJuriUser.length > 0) {
            loadData()
        }
    }, [states.offsetJuriUser])



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Usuários</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os usuários cadastrados no jurídico.
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {states.dataSystemJuriUser?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }

                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }

                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>



                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por usuário</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setSearch(e.target.value)}
                                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getEmailUser()
                                            }
                                        }}
                                    />

                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getEmailUser}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <Table add={openModal} role={lockUnlock} edit={openModalEdit}  title='Usuários' description='Registro dos usuários do sistema jurídico' next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriUser, limit: states.limitJuriUser }} dataTable={states.dataJuriUser} />

                        </div>

                        <ModalConfirm states={states} action={actionModalConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
                        <ModalUsuarios action={actionModal} states={states} setters={setters}  />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default AdvogadosJuridico;