import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import Footer from '../../../Components/Footer/footer'
import ModalConfirm from '../../../Components/Modal/Confirm'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import { toast } from 'react-toastify'
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useRef, useState, useContext, useEffect } from 'react'
import { PencilIcon, ChevronDoubleDownIcon, ArrowUpOnSquareIcon, MagnifyingGlassIcon, XCircleIcon, TrashIcon } from '@heroicons/react/20/solid'


export default function Terceirizado() {

  const { states, setters } = useContext(AuthContext)
  const { getTerceirizado, addComentarioTerceirizado, addArquivoTerceirizado,
    deleteComentarioTerceirizado, deleteArquivoTerceirizado, getFaturasTerceirizado, editarTerceirizado } = useContext(AuthContextApi)

  const [showFile, setShowFile] = useState(false)
  const [index, setIndex] = useState(0)
  const [title, setTitle] = useState('')
  const [titleModalDelete, setTitleModalDelete] = useState('')
  const [descriptionModalDelete, setDescriptionModalDelete] = useState('')

  const [arrowButton, setArrowButton] = useState(true)

  const [isEditing, setIsEditing] = useState(false)
  const [img, setImg] = useState('')
  const inputArquivoRef = useRef(null);

  const [novoComentario, setNovoComentario] = useState('');
  const [autor, setAutor] = useState('');
  const [exibirComentarios, setExibirComentarios] = useState(true);
  const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
  const [idArquivo, setIdArquivo] = useState(null);
  const [isComment, setIsComment] = useState(false);

  const [fileType, setFileType] = useState('');
  const [binaryData, setBinaryData] = useState(null);

  const { id } = useParams()

  useEffect(() => {
    load()
    setArrowButton(true)
  }, [])

  const load = async () => {
    await getTerceirizado(id)
  }

  const formatarTelefone = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digitos = `${input}`.replace(/\D/g, '');

    // Verificar se o número tem 8 ou 9 dígitos
    if (digitos.length === 8) { // Telefone fixo
      return digitos.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else if (digitos.length === 9) { // Telefone móvel
      return digitos.replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3');
    } else {
      // Não é um número válido
      return 'Número inválido';
    }
  };

  const formatarDocumento = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digitos = input.replace(/\D/g, '');

    // Verificar a quantidade de dígitos e formatar
    if (digitos.length === 11) {
        // Formatar como CPF
        return digitos.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (digitos.length === 14) {
        // Formatar como CNPJ
        return digitos.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
        // Não é um CPF nem um CNPJ válido
        return 'Número inválido';
    }
};

  const formatarCEP = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digits = input.replace(/\D/g, '');

    // Aplicar o padrão XXXXX-XXX usando grupos de captura
    const formatted = digits.replace(/(\d{5})(\d{3})/, '$1-$2');

    return formatted;
  };

  const adicionarComentario = async () => {
    if (novoComentario.trim() !== '' && autor.trim() !== '') {
      const objSend = {
        outsourced_id: id,
        description: novoComentario,
        title: autor
      }
      const response = await addComentarioTerceirizado(objSend)
      if (response == true) {
        load()
        setExibirComentarios(false);
        setNovoComentario('');
        setAutor('');
      } else {
        setNovoComentario('');
        setAutor('');
      }
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setArquivoSelecionado(file);
      } else {
        toast.error('Extensão de arquivo não permitida. Apenas imagens são permitidas.');
      }
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {

      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setArquivoSelecionado(file);
      } else {
        toast.error('Extensão de arquivo não permitida. Apenas imagens são permitidas.');
      }
    }
  };

  const handleFileUpload = async () => {
    if (arquivoSelecionado) {
      if (title == '') {
        toast.error('Preencha o titulo do arquivo');
      } else {
        if (title.length > 70) {
          toast.error('Titulo deve ter no máximo 70 caracteres');
        } else {
          const objRequest = {
            file: arquivoSelecionado,
            title: title,
            outsourced_id: id
          };
          const response = await addArquivoTerceirizado(objRequest)
          if (response == true) {
            setArquivoSelecionado(null);
            setTitle(null)
            load()
          }
        }
      }
    }
  };

  const handleInputArquivoClick = () => {
    if (inputArquivoRef.current) {
      inputArquivoRef.current.click();
    }
  };

  const viewBinary = (fileName, binaryData, index) => {
    setIndex(index)
    setShowFile(true);
    const supportedExtensions = {
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      txt: 'text/plain',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
    };

    const fileExtension = fileName.split('.').pop().toLowerCase();
    const mimeType = supportedExtensions[fileExtension] || 'application/octet-stream';

    const blob = new Blob([binaryData], { type: mimeType });
    // const buffer = encodeBase64(decodeURI(encodeURIComponent(binaryData)));
    // var src = "data:image/png;base64," + buffer;
    const uri = URL.createObjectURL(blob);
    setImg(uri)
    setFileType(mimeType);
    setBinaryData(binaryData);
    setShowFile(true);
  };

  const closeViewer = () => {
    setShowFile(false);
    setFileType('');
    setIndex(null);
  };

  const actionDelete = async () => {
    setters.setConfirmModal(false)
    setTitleModalDelete('')
    setDescriptionModalDelete('')
    if (isComment) {
      const response = await deleteComentarioTerceirizado(idArquivo)
      if (response == true) {
        setIdArquivo(null)
        load()
      }
    }
    else {
      const response = await deleteArquivoTerceirizado(idArquivo)
      if (response == true) {
        setIdArquivo(null)
        load()
      }
    }
  }

  const openModalDelete = (data) => {
    setIsComment(false)
    setTitleModalDelete('Deseja excluir o arquivo?')
    setDescriptionModalDelete('Ao excluir o arquivo, ele não poderá ser recuperado.')
    setIdArquivo(data.id)
    setters.setConfirmModal(true)
  }

  const openModalDeleteComment = (data) => {
    setIsComment(true)
    setTitleModalDelete('Deseja excluir o comentário?')
    setDescriptionModalDelete('Ao excluir o comentário, ele não poderá ser recuperado.')
    setIdArquivo(data.id)
    setters.setConfirmModal(true)
  }

  const editTerceirizado = async () => {
    const response = await editarTerceirizado(states.terceirizados.id, states.terceirizados)
    if (response == true) {
      setIsEditing(false)
      load()
      toast.success('Terceirizado editado com sucesso')
    }
    else {
      toast.error('Erro ao editar terceirizado')
    }
  }

  const getInvoices = async () => {
    if (arrowButton == true) {
      setArrowButton(!arrowButton)
      const response = await getFaturasTerceirizado(id)
      if (response == false) {
        setArrowButton(true)
        setters.setDataTerceirizadosFatura([])
      }
    } else {
      setters.setDataTerceirizadosFatura([])
      setArrowButton(!arrowButton)
    }
  }

  const loadMoreInvoice = async () => {
    //Apenas setta o offset para o valor atual + 10
    await getFaturasTerceirizado(id)
  }

  const copyLinkSite = (e) => {
    var content = document.getElementById(e)
    navigator.clipboard.writeText(content.value)
      .then(() => {
        toast.success('Link copiado com sucesso!')
      })
  }

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              {
                Object.keys(states.terceirizados).length > 0 && (
                  <div className="mb-8">
                    <div className="flex justify-between">
                      <div>
                        <Link to="/adminIndex/terceirizados" className="text-sm font-semibold leading-6 text-blue-500 hover:text-blue-600">Voltar</Link>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Terceirizado</h1>
                      </div>
                      <button onClick={() => setIsEditing(!isEditing)} className="bg-blue-500 hover:bg-blue-600 w-12 h-10 rounded-md text-white ">
                        <PencilIcon className="w-6 h-6 mx-auto" />
                      </button>
                    </div>

                    <div className="border p-4 rounded-md mt-4">
                      {
                        isEditing ? (
                          <p className="text-md flex flex-col sm:w-[28%] w-[100%] ">
                            Informações sobre o Terceirizado:
                            <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.name}
                              onChange={e => setters.setTerceirizados({ ...states.terceirizados, name: e.target.value })}
                            />
                          </p>
                        ) : (
                          <p className="">
                            Informações sobre o Terceirizado: <p className="text-base font-semibold leading-6 text-gray-900 mt-2">{states.terceirizados.name}</p>
                          </p>
                        )
                      }
                      <hr className="mt-4" />
                      <p className="mt-2">Contato</p>
                      <div className="grid sm:grid-cols-3 sm:grid-cols-1  gap-4">

                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                              Email: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.email}
                                onChange={e => setters.setTerceirizados({ ...states.terceirizados, email: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Email: {states.terceirizados.email}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                              Prefixo Telefone: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.prefix_phone}
                                onChange={e => setters.setTerceirizados({ ...states.terceirizados, prefix_phone: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              {/* {formatarTelefone(states.terceirizados.phone)} */}
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Prefixo Telefone: {states.terceirizados.prefix_phone}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                              Telefone: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.phone}
                                onChange={e => setters.setTerceirizados({ ...states.terceirizados, phone: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              {/* {formatarTelefone(states.terceirizados.phone)} */}
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Telefone: {formatarTelefone(states.terceirizados.phone)}</p>
                            </p>
                          )
                        }
                      </div>
                      <hr className="mt-4" />
                      <p className="mt-2">Dados gerais</p>
                      <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 justify-center items-center">
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              CPF/CNPJ: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.cpf_cnpj}
                                onChange={e => setters.setTerceirizados({ ...states.terceirizados, cpf_cnpj: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">CPF/CNPJ: {formatarDocumento(states.terceirizados.cpf_cnpj)}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Funcionário:
                              <select
                                type="text"
                                className="text-base leading-6 mt-2 rounded-[8px]"
                                value={states.terceirizados.employee}
                                onChange={e => setters.setTerceirizados({ ...states.terceirizados, sexo: e.target.value })}
                              >
                                <option>Selecione ..</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                              </select>
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Funcionário: {' '}
                                {states.terceirizados.employee == '1' ? 'Sim' : 'Não'}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Empresa:
                              <select
                                type="text"
                                className="text-base leading-6 mt-2 rounded-[8px]"
                                value={states.terceirizados.isCompany}
                                onChange={e => setters.setTerceirizados({ ...states.terceirizados, retired: e.target.value })}
                              >
                                <option>Selecione ..</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                              </select>
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Empresa: {' '} {states.terceirizados.isCompany == '1' ? 'Sim' : 'Não'}</p>
                            </p>
                          )
                        }

                      </div>


                      {/* Área de Comentários */}
                      <div className="mt-4">

                        <hr className="mt-4" />
                        <p className="mt-2">Endereço de contato</p>
                        <div className="grid sm:grid-cols-3 sm:grid-cols-1  gap-4">

                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                CEP: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.zip_code}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, cep: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                {/* {formatarCEP(states.terceirizados.zip_code)} */}
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">CEP: {states.terceirizados.zip_code}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Endereço: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.street}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, street: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Endereço: {states.terceirizados.street}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Número: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.locationNumber}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, locationNumber: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Número: {states.terceirizados.locationNumber}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Complemento: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.complement}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, complement: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Complemento: {states.terceirizados.complement}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Bairro: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.district}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, district: e.target.value })}
                                />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Bairro: {states.terceirizados.district}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Cidade: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.city}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, city: e.target.value })}
                                />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Cidade: {states.terceirizados.city}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Estado: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.state}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, state: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Estado: {states.terceirizados.state}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                País: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.terceirizados.country}
                                  onChange={e => setters.setTerceirizados({ ...states.terceirizados, country: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">País: {states.terceirizados.country}</p>
                              </p>
                            )
                          }

                        </div>

                        {
                          isEditing && (

                            <button
                              className="mt-4 bg-blue-500 text-white px-10 py-2 rounded hover:bg-blue-600"
                              onClick={() => editTerceirizado()}
                            >
                              Editar terceirizado
                            </button>
                          )
                        }
                      </div>

                    </div>


                    <div className='border p-4 rounded-md mt-6'>
                      <div className="flex w-[100%] justify-between items-center">
                        <p>Faturas do Terceirizado:  {states.terceirizados.name}</p>
                        <button
                          className="flex items-center text-gray-600 hover:text-gray-800"
                          onClick={getInvoices}
                        >
                          <ChevronDoubleDownIcon
                            className={`${!arrowButton ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                          />
                        </button>
                      </div>

                      {
                        !arrowButton && states.dataTerceirizadosFatura.data != [] && (
                          <>
                            <hr className='mt-4' />
                            <div className='flex justify-between'>
                              <div className="flex flex-col w-full mt-4 mb-4">
                                <div className='flex justify-between'>
                                  <h1 className="text-2xl font-bold mb-4">Faturas</h1>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                  {states.dataTerceirizadosFatura.map((invoice, index) => (
                                    <div key={index} className="border p-4 rounded-md">
                                      <h5 className="text-sm font-semibold mb-2">{invoice.description}</h5>
                                      <p><strong>Valor:</strong>  {(invoice.price / 100).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                      })}</p>
                                      <p><strong className='mr-2'>Status:</strong>

                                        {
                                          (invoice.status == 'paid' || invoice.status == 'externally_paid') && (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                              Pago
                                            </span>
                                          )
                                        }
                                        {
                                          invoice.status == 'pending' && (

                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                              Pendente
                                            </span>

                                          )
                                        }
                                        {
                                          invoice.status == "expired" && (

                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-yellow-800">
                                              Expirado
                                            </span>

                                          )
                                        }
                                        {
                                          invoice.status == 'canceled' && (

                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                              Cancelado
                                            </span>

                                          )
                                        }
                                        {
                                          invoice.status == "refunded" && (


                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-400 text-white">
                                              Estornado
                                            </span>


                                          )
                                        }

                                      </p>
                                      <p><strong>Descrição:</strong> {invoice.description}</p>
                                      <p><strong>Desconto:</strong> {invoice.discount} %</p>
                                      <input id={invoice.id} type='hidden' className='hidden' value={invoice.path} />

                                      <div className='flex'>
                                        <p><strong className='mb-2'>Link:</strong></p>
                                        {
                                          invoice.path == '/' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                              Pagamento Local
                                            </span>
                                          ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
                                              <button className="px-2 text-gray-400 border bg-gray-50 border-gray-200 hover:bg-gray-200 rounded-md" onClick={() => copyLinkSite(invoice.id)}>
                                                {/* <DocumentDuplicateIcon className="h-7 w-7" /> */}
                                                Copiar
                                              </button>
                                            </span>
                                          )
                                        }
                                      </div>
                                      <p><strong>Multa:</strong>  {(invoice.penalty / 100).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                      })}</p>
                                      <p><strong>Expiração:</strong>   {moment(invoice.experies_at).format('DD/MM/YYYY')}</p>
                                      <p><strong>Criação:</strong>   {moment(invoice.created_at).format('DD/MM/YYYY')}</p>
                                      <p><strong>Atualização:</strong>   {moment(invoice.updated_at).format('DD/MM/YYYY')}</p>
                                    </div>
                                  ))}
                                </div>

                                <div className='flex items-center justify-center'>
                                  {
                                    !states.limitSearchTerceirizados &&
                                    <button className="mt-4 text-blue-600 hover:text-blue-400 font-bold py-2 px-4 rounded" onClick={loadMoreInvoice} >Carregar mais faturas</button>
                                  }
                                </div>

                              </div>
                            </div>
                          </>
                        )
                      }

                      {
                        !arrowButton && states.dataTerceirizadosFatura.data == [] &&
                        <div className="flex justify-center items-center">
                          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid mt-20 mb-20"></div>
                        </div>
                      }
                    </div>

                    {/* Área de Comentários */}
                    <div className="border p-4 rounded-md mt-6">
                      {/* Exibir Comentários */}
                      <div className="">
                        <div className="flex w-[100%] justify-between">
                          <p>Comentários</p>
                          <button
                            className="flex items-center text-gray-600 hover:text-gray-800"
                            onClick={() => setExibirComentarios(!exibirComentarios)}
                          >
                            <ChevronDoubleDownIcon
                              className={`${!exibirComentarios ? 'transform rotate-180' : ''
                                } w-5 h-5 text-gray-500`}

                            />
                          </button>
                        </div>
                        {exibirComentarios && (
                          <div className="mt-4 space-y-2">
                            {states.terceirizados.comments.length > 0 ? states.terceirizados.comments.map((comentario, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="border p-4 rounded-md bg-gray-50 flex flex-row justify-between"
                                  >
                                    <div>
                                      <p className="font-semibold">{comentario.title}</p>
                                      <p>{comentario.description}</p>
                                    </div>
                                    <div>
                                      <p className="font-semibold">Criador:</p>
                                      <p>{comentario.emailCreator}</p>
                                    </div>
                                    {
                                      ((localStorage.getItem('email') === comentario.emailCreator || localStorage.getItem('permission') == 0 || localStorage.getItem('permission') == 1 || localStorage.getItem('permission') == 2)) && (
                                        <button className="mt-1 border border-gray-200 w-10 h-10 flex items-center justify-center rounded-md hover:bg-gray-100" onClick={() => openModalDeleteComment(comentario)}>
                                          <TrashIcon className="w-5 h-5 text-red-500" />
                                        </button>
                                      )
                                    }

                                  </div>
                                </>
                              )

                            }) : (
                              <p className="text-sm text-gray-600">Nenhum comentário ainda</p>
                            )
                            }
                          </div>
                        )}
                      </div>

                      <p className="text-lg font-semibold mb-2 mt-4">Adicionar Comentário</p>
                      <input
                        className="w-full mb-2 border rounded-md border-gray-200"
                        type="text"
                        placeholder="Digite título do seu comentário"
                        value={autor}
                        onChange={(e) => setAutor(e.target.value)}
                      />
                      <textarea
                        className="w-full mb-2 border rounded-md border-gray-200 mt-2"
                        rows="4"
                        placeholder="Digite seu comentário"
                        value={novoComentario}
                        onChange={(e) => setNovoComentario(e.target.value)}
                      />
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={adicionarComentario}
                      >
                        Adicionar Comentário
                      </button>
                    </div>

                    {/* Área de Anexar Arquivos */}
                    <div className="mt-6">
                      <div className="border border-gray-200 rounded-md p-4">
                        {
                          arquivoSelecionado && (
                            <input
                              className="w-full px-3 py-2 mb-2 border rounded"
                              type="text"
                              placeholder="Digite titulo para o arquivo"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          )
                        }
                        <div
                          className={`mt-2 mb-2 border-2 border-dashed ${arquivoSelecionado ? 'bg-gray-50' : ''
                            } p-4 rounded-md cursor-pointer`}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={handleFileDrop}
                          onClick={handleInputArquivoClick}
                        >
                          {!arquivoSelecionado && (
                            <div className="flex items-center justify-center space-x-2">
                              <ArrowUpOnSquareIcon className="w-5 h-5 text-gray-500" />
                              <p className="text-gray-500">
                                Arraste e solte ou clique para anexar um arquivo (jpg, jpeg, png)
                              </p>
                            </div>
                          )}
                          {arquivoSelecionado && (
                            <>
                              <div className="flex items-center justify-between">
                                <p>{arquivoSelecionado.name}</p>
                                <button
                                  className="mt-1 border border-gray-200 w-10 h-10 flex items-center justify-center rounded-md hover:bg-gray-100"
                                  onClick={() => setArquivoSelecionado(null)}
                                >
                                  <TrashIcon className="w-5 h-5 text-red-500" />
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf,.docx"
                          ref={inputArquivoRef}
                          className="hidden"
                          onChange={handleFileChange}
                        />
                        {arquivoSelecionado && (
                          <button
                            className="bg-blue-500 text-white px-4 py-2 mt-2 rounded hover:bg-blue-600"
                            onClick={handleFileUpload}
                          >
                            Anexar Arquivo
                          </button>
                        )}
                      </div>
                      {states.terceirizados.files.length > 0 && (
                        <div className="mt-2 space-y-2">
                          <p className="font-semibold">Arquivos Anexados:</p>
                          {states.terceirizados.files.length > 0 ? states.terceirizados.files.map((arquivo, inde) => {
                            return (
                              <>
                                <div className="pb-4 pt-2 border-b">
                                  <div className="flex">
                                    <button className="w-10 h-10 flex items-center justify-center rounded-md border border-gray-200" onClick={() => viewBinary(arquivo.fileName, arquivo.datafile, inde)}>
                                      <MagnifyingGlassIcon className="w-5 h-5 text-black" />
                                    </button>
                                    <button className="ml-4 w-10 h-10 flex items-center justify-center rounded-md border border-gray-200" onClick={() => openModalDelete(arquivo)}>
                                      <TrashIcon className="w-5 h-5 text-red-500" />
                                    </button>
                                    {showFile && index == inde && (
                                      <button onClick={closeViewer} className="ml-4 w-10 h-10 flex items-center justify-center  rounded-md border border-gray-200">
                                        <XCircleIcon className="w-5 h-5 text-black" />
                                      </button>)
                                    }
                                  </div>
                                  <div key={inde} className="border p-2 rounded-md bg-gray-50 mt-2">
                                    <p>{arquivo.title} - {arquivo.fileName}</p>
                                  </div>
                                </div>

                                {showFile && index == inde && (
                                  <div className='p-8 border border-gray-200'>
                                    {fileType === 'application/pdf' && (
                                      <embed src={`data:application/pdf;base64,${binaryData}`} type="application/pdf" width="100%" height="500px" />
                                    )}
                                    {(fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/gif') && (
                                      <img src={`data:image/png;base64,${binaryData}`} alt="Imagem" />
                                    )}
                                  </div>
                                )}

                              </>
                            )
                          }) : (
                            <p className="text-sm text-gray-600">Nenhum arquivo anexado</p>
                          )
                          }
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
              <ModalConfirm states={states} action={actionDelete} setters={setters} title={titleModalDelete} description={descriptionModalDelete} />
              <Footer />
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
