import { AuthContext } from "../../../Context"
import { Link, useParams } from 'react-router-dom'
import { AuthContextApi } from "../../../Context/api"
import { useState, useContext, useEffect } from 'react'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import TableFaturaPagamento from '../../../Components/Tabela/TabelaFinanceiroPagamentoCustos'

export default function CustosFatura() {
  const { id } = useParams()

  const { states, setters, } = useContext(AuthContext)
  const { dataCustosFaturas } = useContext(AuthContextApi)

  const [cardsArray, setCardsArray] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)

  const loadData = async () => {
    await dataCustosFaturas(id)
  }

  const formatToMoney = (value) => {
    const intToFloat = parseInt(value) / 100
    return intToFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  useEffect(() => {
    if (states.dataCustosFatura.length > 0) return;
    if (states.setOffsetCustosFatura > 0) {
      setters.setOffsetCustosFatura(0)
      setters.setLimitSearchCustosFatura(false)
    } 
    return () => {
      setters.setOffsetCustosFatura(0)
      setters.setLimitSearchCustosFatura(false)
      setFirstLoad(false)
      setters.setDataCustosFatura([])
    }
  }, [])

  useEffect(() => {
    if (firstLoad) return;
    loadData()
  }, [states.offsetCustosFatura])

  useEffect(() => {
    if (!states.dataCustosFatura.expenses) {
      !firstLoad && setFirstLoad(true)
      loadData()
      return;
    }
    const filterById = states.dataCustosFatura.expenses;
    setCardsArray(filterById)
    setDataTable(states.dataCustosFatura.invoices)
  }, [states.dataCustosFatura])

  const next = async () => {
    if (states.limitSearchCustosFatura) {
      return
    } else {
      setters.setOffsetCustosFatura(states.offsetCustosFatura + states.limitCustosFatura)
    }
  };

  const previous = async () => {
    if (dataTable.length === 0 && states.offsetCustosFatura <= 0) {
      return
    } else {
      states.offsetCustosFatura > 0 && setters.setOffsetCustosFatura(states.offsetCustosFatura - states.limitCustosFatura)
      states.offsetCustosFatura < 0 && setters.setOffsetCustosFatura(0)
    }
  };

  const headers = ['Valor', 'Descrição', 'Desconto', 'Status', 'Link da Fatura', 'Multa', 'Criador', 'Data de Expiração', 'Data de Criação', 'Data de Atualização', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />

        <SpinnerOverlay />

        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
            
                <Link to="/financeIndex/custos" className='text-blue-500 hover:text-blue-700'>
                  Retornar
                </Link>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {cardsArray.map((item) => (
                  <div key={item.name} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                              <div className='flex flex-row'>
                                <div className="text-sm font-medium text-gray-900">{item.type}</div>
                              </div>
                            </dt>
                            <dd>
                            <dt className="truncate text-sm font-medium text-gray-500">Valor utilizado:</dt>
                              {/* <div className="text-lg font-medium text-gray-900">{formatToMoney(item.usedValue)}</div> */}
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                <p className="text-3xl font-semibold tracking-tight text-gray-900">{formatToMoney(item.usedValue)}</p>
                              </dd>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
                </div>

                <TableFaturaPagamento title={`Faturas de custos`} next={next} previous={previous}
                      description={
                      cardsArray.length > 0 ? `Tipo do custo: ${cardsArray[0].type}` : 'Nenhuma fatura vinculada a custos'
                    } setters={setters} states={states} headers={headers} infoTable={{ offset: states.offsetCustosFatura, limit: states.limitCustosFatura }} dataTable={dataTable} />
                  
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
