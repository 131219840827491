import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/ferias'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'
import Modal from '../../../../Components/Modal/Ferias/PainelFerias'
import Table from '../../../../Components/Tabela/TabelasFerias/TabelaPainelFerias'

import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'

export default function PainelFerias() {
    const { dataPainelFerias, deletePainelFerias } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    useEffect(() => {   
        return () => {
            setters.setOffSetPainelFerias(0)
            setters.setLimitSearchPainelFerias(false)
            setters.setDataPainelFerias([])
        }
    }, [])

    useEffect(() => {
        listarAsync()
    }, [states.offsetPainelFerias])

    const listarAsync = async () => {
        await dataPainelFerias()
    }

    const add = async () => {
        setters.setModalPainelFerias(true);
    }

    const deleted = async (id) => {
        await deletePainelFerias(id)
    }

    const next = async () => {
        if (states.dataPainelFerias.length === 0) {
            return
        } else {
            const data = states.dataQuery
            setters.setOffSetPainelFerias(states.offsetPainelFerias + states.limitPainelFerias)
            const sumOff = states.offsetPainelFerias + states.limitPainelFerias
            const limit = states.limitPainelFerias + sumOff
            const newData = data.slice(sumOff, limit)
            setters.setDataPainelFerias(newData)
        }
    };

    const previous = async () => {
        if (states.dataPainelFerias.length === 0 && states.offsetPainelFerias <= 0) {
            return
        } else {
            const data = states.dataQuery
            states.offsetPainelFerias > 0 && setters.setOffSetPainelFerias(states.offsetPainelFerias - states.limitPainelFerias)
            states.offsetPainelFerias < 0 && setters.setOffSetPainelFerias(0)
            const sumOff = states.offsetPainelFerias > 0 ? states.offsetPainelFerias - states.limitPainelFerias : 0;
            const limit = sumOff + 10 > states.limitPainelFerias ? sumOff + 10 : states.limitPainelFerias;
            const newData = data.slice(sumOff, limit)
            setters.setDataPainelFerias(newData)
        }
    };

    const headers = ['Email do Funcionário', 'De Férias',  'Férias Mandatórias', 'Data Início', 'Data Fim', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />
                            
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de Férias</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os funcionários de férias
                            </p>

                            <Table title='Painel Geral de Férias' description='Registro do cadastro de férias de cada funcionário' 
                                   next={next} previous={previous} states={states} headers={headers} add={add} deleted={deleted}
                                   infoTable={{ offset: states.offsetPainelFerias, limit: states.limitPainelFerias }} dataTable={states.dataPainelFerias} />
                        </div>
                    </div>
                </div>

                <Modal states={states} setters={setters}/>

                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
