import { toast } from 'react-toastify'
import { AuthContext } from '../../../../Context/index'
import { useState, useContext, useEffect } from 'react'
import { AuthContextApi } from '../../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import Navbar from '../../../../Components/Navbar/navbar'
import NavbarMenu from '../../../../Components/navMenu/acoes'
import ModalConfirm from '../../../../Components/Modal/Confirm'
import ModalShow from '../../../../Components/Modal/ShowAprovados'
import Table from '../../../../Components/Tabela/TabelaAssinaturasSolicitacoes'
import Modal from '../../../../Components/Modal/Acoes/Assinaturas/modalAssinaturas'

export default function AsssinaturasPendentes() {

  const { states, setters } = useContext(AuthContext)
  const { dataRequestAssinatura,  dataSystemRequestAssinatura, searchQueryRequestAssinatura, responseRequestAssinatura } = useContext(AuthContextApi)
  const [assinaturaSelecionada, setAssinaturaSelecionada] = useState({})

  const callData = async () => {
    await dataRequestAssinatura()
  }

  useEffect(() => {
    load()
      return () => {
        setters.setDataRequestAssinatura([])
        setters.setQuerySearchRequestAssinatura('')
        setters.setOffsetRequestAssinatura(0)
        setters.setLimitSearchRequestAssinatura(false)
        setters.setModalShowTecnico(false)
        setters.setSystemRequestAssinatura([])
        setters.setDataQuery([])
      }
  }, []);

  useEffect(() => {
    if(states.dataQuery.length > 0  || states.offsetRequestAssinatura == 0)return
    callData()
  }, [states.offsetRequestAssinatura])

  const load = async () => {
    const promises = [
      dataSystemRequestAssinatura(),
      dataRequestAssinatura()
    ];
  
    await Promise.all(promises);
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataRequestAssinatura.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetRequestAssinatura(states.offsetRequestAssinatura + states.limitRequestAssinatura)
        const sumOff = states.offsetRequestAssinatura + states.limitRequestAssinatura
        const limit = states.limitRequestAssinatura + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataRequestAssinatura(newData)
      }
    } else {
      if (states.limitSearchRequestAssinatura) {
        return
      } else {
        setters.setOffsetRequestAssinatura(states.offsetRequestAssinatura + states.limitRequestAssinatura)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataRequestAssinatura.length === 0 && states.offsetRequestAssinatura <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetRequestAssinatura > 0 && setters.setOffsetRequestAssinatura(states.offsetRequestAssinatura - states.limitRequestAssinatura)
        states.offsetRequestAssinatura < 0 && setters.setOffsetRequestAssinatura(0)
        const sumOff = states.offsetRequestAssinatura > 0 ? states.offsetRequestAssinatura - states.limitRequestAssinatura : 0;
        const limit = sumOff + 10 > states.limitRequestAssinatura ? sumOff + 10 : states.limitRequestAssinatura;
        const newData = data.slice(sumOff, limit)
        setters.setDataRequestAssinatura(newData)
      }
    } else {
      if (states.offsetRequestAssinatura <= 0) {
        states.offsetRequestAssinatura < 0 && setters.setOffsetRequestAssinatura(0)
        return
      } else {
        if (states.limitSearchRequestAssinatura) setters.setLimitSearchRequestAssinatura(false)
        setters.setOffsetRequestAssinatura(states.offsetRequestAssinatura - states.limitRequestAssinatura)
      }
    }
  };

  const closeModalShow = () => {
    setters.setRequestAssinatura({});
    setters.setModalShowTecnico(false);
  }
  
  const openModalAprove = (data) => {
    setters.setIsEdit(false)
    setters.setRequestAssinatura({
      ...data,
      auth_id: data.id,
      status: 1
    })
    setters.setConfirmModal(true);

  }
  
  const openModalRefuse = (data) => {
    setters.setIsEdit(true)
    setters.setRequestAssinatura({
      ...data,
      auth_id: data.id,
      status: 2
    })
    setters.setConfirmModal(true);

  }

  const abrirModal = (data) => {
    setAssinaturaSelecionada(data)
    setters.setModalIsOpen(true);
  };

  const dataSearch = async () => {
    if (states.querySearchRequestAssinatura !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchRequestAssinatura)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryRequestAssinatura()
      }

    }
  }

  const actionConfirm = async () => {
    // 
    setters.setConfirmModal(false)
    await responseRequestAssinatura()
  }

  const titleConfirm = states.isEdit ? 'Recusar' : 'Aprovar'
  const descriptionConfirm = states.isEdit ? 'Tem certeza que deseja recusar a solicitação?' : 'Tem certeza que deseja aprovar a solicitação?'

  const headers = ['Status', 'Email do requerente','Nome do Associado','Data de criação', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
          <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
            <div className="p-4">
              <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Assinaturas Pendentes</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre as solicitações de cancelamento de assinatura.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemRequestAssinatura.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}

              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por solicitações</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchRequestAssinatura(e.target.value)} onKeyDown={(e) => e.key == 'Enter' && dataSearch()}
                    value={states.querySearchRequestAssinatura} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}
                      
                    >
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              {/* Outros componentes de gráfico de pizza aqui */}
              <Table  title='Solicitações' aprove={openModalAprove} refuse={openModalRefuse} description='Registro das solicitações de cancelamento de assinatura' next={next} previous={previous} states={states} show={abrirModal} headers={headers} infoTable={{ offset: states.offsetRequestAssinatura, limit: states.limitRequestAssinatura }} dataTable={states.dataRequestAssinatura} />
            </div>
          </div>
        </div>
        <Modal states={states} setters={setters} assinatura={assinaturaSelecionada}/>
        <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.RequestAssinatura} title='Visualização ampla' />
        <ModalConfirm states={states} action={actionConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
      </div>
    </>
  )
}
