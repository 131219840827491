
import NavBar from "../../../Components/Navbar/navbar";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import moment from "moment";
import { ArrowUturnLeftIcon, InformationCircleIcon, MagnifyingGlassCircleIcon, InboxIcon, BellAlertIcon, UserGroupIcon, FolderIcon, DocumentTextIcon, ChevronDoubleUpIcon, PencilIcon, UserIcon } from '@heroicons/react/24/outline'

import { Link } from "react-router-dom";
import { Pie, Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
import Footer from "../../../Components/Footer/footer";
import ModalAutorizacao from '../../../Components/Modal/AuthorizationJuri'
import ModalAutorizacaoAssociado from '../../../Components/Modal/AuthorizationJuriAssociate'
Chart.register(...registerables);




const ProcessoPainel = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        getProcessosById,
        dataSystemHomeProcess,
        updateAuthorization,
        dataAssociadoCpf,
        associateAuth
    } = useContext(AuthContextJuri);
    const { id } = useParams()


    const [arrowButton, setArrowButton] = useState(false)
    const [arrowButtonAuth, setArrowButtonAuth] = useState(true)
    const [leftCards, setLeftCards] = useState([])
    const [rightCards, setRightCards] = useState([])



    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
        const resp = await getProcessosById(id)
        if (resp == false) {
            hist('/juridicoIndex/processos')
        } else {
            setters.setModalJuri(false)
            setters.setModalAssociadoProcesso(false)
            await dataSystemHomeProcess(id)
        }
    }


    useEffect(() => {
        checkTokenLoad()
    }, [])


    const returnPage = () => {
        hist('/juridicoIndex/processos')
    }



    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const actionModal = async (data, id) => {
        const resp = await updateAuthorization(data, id)
        if (resp == true) {
            await getProcessosById(states.JuriLegalProcess?.id)
        }
        setters.setModalJuri(false)
    }

    const actionModalAssociate = async (data) => {
        const resp = await associateAuth(data)
        if (resp == true) {
            await getProcessosById(states.JuriLegalProcess?.id)
        }
    }





    const data1 = {

        labels: ['total', 'deferido', 'indeferido'],
        datasets: [
            {
                data: states.dataSystemJuriProcess?.graphic?.motions.map((item) => item.value),
                backgroundColor: [
                    '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
                    '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
                    '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
                ],
                hoverBackgroundColor: [
                    '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
                    '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
                    '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
                ],
                borderColor: '#ffffff',
                borderWidth: 2,
                hoverBorderColor: '#ffffff',
            },
        ]
    };


    const dataFlowCash = {//states.dataSystemJuriProcess?.graphic?.associate
        labels: states.dataSystemJuriProcess?.graphic?.associate?.map((item) => moment(item.date).format('MM/YYYY')),
        datasets: [
            {
                label: 'Entradas',
                data: states.dataSystemJuriProcess?.graphic?.associate?.map((item) => item.value),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            }
        ],
    };



    const options = {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            position: 'bottom',
            labels: {
                fontColor: 'rgba(255, 255, 255, 0.8)',
                fontSize: 12,
                padding: 20,
            },
        },
    };

    useEffect(() => {
        setLeftCards(states.dataSystemJuriProcess?.statistic?.processDetail?.slice(0, 3))
        setRightCards(states.dataSystemJuriProcess?.statistic?.processDetail?.slice(3, 6))

    }, [states.dataSystemJuriProcess])


    const dowloadFile = async (base64String, filename) => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64String}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const openEditAuth = (data) => {
        setters.setJuriProcessoAuth(data)
        setters.setModalJuri(true)
    }

    // //console.log(states.JuriLegalProcess?.auth.id)

    const openModalAddAssociate = () => {
        setters.setModalAssociadoProcesso(true)
        setters.setJuriAssociadoProcesso({})
    }

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2 items-center'>
                    <div className="flex flex-row w-full items-between justify-between">
                        <button onClick={() => returnPage()} className="flex flex-row ml-3  text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                            <ArrowUturnLeftIcon className="h-5 w-5" />
                        </button>
                        <button onClick={() => hist(`/juridicoIndex/processo/${id}/exibicao`)} className="flex flex-row mr-3  text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                            <MagnifyingGlassCircleIcon className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="flex flex-col w-full items-center justify-center p-2 pb-8 ">
                        <div className="grid grid-cols-6  gap-2 w-full">
                            <div className="sm:col-span-1  col-span-6  flex flex-col w-full justify-between  ">
                                {leftCards?.map((card, index) => {
                                    return (
                                        <div key={card.title} className=" w-full border p-8 rounded-[10px] shadow hover:shadow-lg">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    {
                                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                    }

                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        {
                                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                        }

                                                        <dd>
                                                            {
                                                                card.value > 0 && index === -1 ?
                                                                    (<div className="text-sm font-medium text-red-500">
                                                                        {card.value}
                                                                    </div>) : (
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {card.value}
                                                                        </div>)
                                                            }
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="sm:col-span-4  col-span-6  flex flex-col  flex flex-row w-full items-center  border rounded-[10px] shadow hover:shadow-lg">
                                <div className="flex flex-row w-full items-center justify-center">
                                    <div className="flex flex-col w-full items-center justify-center p-1">
                                        <p className="text-[15px] font-sans">Data de abertura: {moment(states.JuriLegalProcess?.filingDate).format('DD/MM/YYYY')}</p>

                                    </div>
                                    <div className="flex flex-col w-full items-center justify-center p-1">
                                        <p className="text font-sans">Data próxima audiência: {moment(states.JuriLegalProcess?.nextFilingDate).isValid() ? moment(states.JuriLegalProcess?.nextFilingDate).format('DD/MM/YYYY') : 'Não há audiência'}</p>

                                    </div>


                                </div>
                                <hr className="w-full" />
                                <div className="flex flex-row w-full items-center justify-between mt-2 p-5 ">
                                    <p className="text-[15px] font-sans" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Número: {states.JuriLegalProcess?.number}</p>
                                    <p className="text-[15px] font-sans" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Titulo: {states.JuriLegalProcess?.caseTitle}</p>
                                    <p className="text-[15px] font-sans" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Juíz: {states.JuriLegalProcess?.judge}</p>
                                    <p className="text-[15px] font-sans" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Tribunal: {states.JuriLegalProcess?.court_name}</p>
                                </div>
                                <div className=" flex-row w-full items-center justify-center  p-4" style={{ overflowWrap: 'break-word' }}>
                                    <p className="text-[15px] font-sans">
                                        Descrição do processo:
                                    </p>
                                    <p className="text-[15px] font-sans">
                                        {truncateText(states.JuriLegalProcess.caseDescription, 500)}
                                    </p>
                                    {
                                        // states.JuriLegalProcess.caseDescription.length > 500 ? ( Fazer aqui o botao  para ler o processo todo
                                    }
                                </div>
                                <hr className="w-full" />
                                <div className=" flex flex-row w-full items-center justify-between mt-2 p-5 " style={{ overflowWrap: 'break-word' }}>
                                    <p className="text-[15px] font-sans" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                        Escritório : {states.JuriLegalProcess?.lawFirm_name}
                                    </p>
                                    <p className="text-[15px] font-sans" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                        Advogado : {states.JuriLegalProcess?.lawyer_name}
                                    </p>
                                    {
                                        // states.JuriLegalProcess.caseDescription.length > 500 ? ( Fazer aqui o botao  para ler o processo todo
                                    }
                                </div>
                                <div className=" flex flex-row w-full items-center justify-between mt-2 p-5 " style={{ overflowWrap: 'break-word' }}>
                                    <p className="text-[15px] font-sans">
                                        Reú : {states.JuriLegalProcess?.prosecutor}
                                    </p>
                                    <p className="text-[15px] font-sans">
                                        Acusado : {states.JuriLegalProcess?.defendant}
                                    </p>
                                    {
                                        // states.JuriLegalProcess.caseDescription.length > 500 ? ( Fazer aqui o botao  para ler o processo todo
                                    }
                                </div>

                            </div>
                            <div className="sm:col-span-1 col-span-6 flex flex-col  flex flex-row w-full  justify-between  ">
                                {rightCards?.map((card, index) => {
                                    return (
                                        <div key={card.title} className=" w-full border p-8 rounded-[10px] shadow hover:shadow-lg ">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    {
                                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                    }
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        {
                                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                        }
                                                        <dd>
                                                            {
                                                                card.value > 0 && index === -1 ?
                                                                    (<div className="text-sm font-medium text-red-500">
                                                                        {card.value}
                                                                    </div>) : (
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {card.value}
                                                                        </div>)
                                                            }
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="flex flex-col w-full items-center justify-center p-2 pb-8 ">
                            {/* PAINEL DE AUTORIZAÇÃO */}
                            <div className="border mt-5 p-4 rouded-[10px] hover:shadow w-full rounded-[10px]">
                                <div className="rounded-md flex justify-between items-center bg-white " onClick={() => setArrowButtonAuth(!arrowButtonAuth)}>
                                    <div className="flex flex-1 justify-between items-center">
                                        <div className="flex flex-col">
                                            <h2 className="text-base font-semibold leading-7 text-gray-900" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Autorização vinculada, processo: {states.JuriLegalProcess?.caseTitle}</h2>
                                            <div className="flex items-center">
                                                <p className="max-w-2xl text-sm leading-6 text-gray-600" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                    Tenha acesso a autorização vinculada ao processo {states.JuriLegalProcess?.caseTitle}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex bg-white justify-end items-center'>
                                        <button
                                            className="flex items-center text-gray-600 hover:text-gray-800"
                                            onClick={() => setArrowButtonAuth(!arrowButtonAuth)}
                                        >
                                            <ChevronDoubleUpIcon
                                                className={`${arrowButtonAuth ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                                            />
                                        </button>
                                    </div>
                                </div>

                                {/* {(permission == 'ADMIN' || permission == 'LAWYER') } */}
                                {!arrowButtonAuth &&
                                    <>
                                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-5 border rounded-[10px]">
                                            {/* INFORMAÇAO DO PROCESSO */}
                                            <div className="flex w-full flex-col items-center justify-center p-4">
                                                <div className="flex flex-row w-full items-start justify-start p-1">
                                                    <span>Titulo da autorização: </span> <p className="text-[15px] font-sans ml-5" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{truncateText(states.JuriLegalProcess?.auth?.title, 50)}</p>
                                                </div>
                                                <div className="flex flex-row w-full items-start justify-start p-1">
                                                    <span>Descrição do processo: </span> <p className="text-[15px] font-sans ml-5" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{truncateText(states.JuriLegalProcess?.auth?.description, 50)}</p>
                                                </div>
                                            </div>
                                            <div className="flex w-full flex-col items-center justify-center p-4">
                                                <div className="flex flex-row w-full items-start justify-start p-1">
                                                    <span>E-mail de recebimento: </span> <p className="text-[15px] font-sans ml-5" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{truncateText(states.JuriLegalProcess?.auth?.email_recive, 80)}</p>
                                                </div>
                                                <div className="flex flex-row w-full items-start justify-start p-1">
                                                    <span>Arquivo: </span> <p className="text-[15px] font-sans ml-5" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{truncateText((states.JuriLegalProcess?.auth?.fileName?.split('_')[1]), 80)}</p>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') ?
                                                <div className="flex w-full items-center justify-center p-4">
                                                    <div className="flex w-full items-center justify-center p-4">
                                                        <div className="flex flex-row w-full items-start justify-start p-1">
                                                            <button onClick={() => dowloadFile(states.JuriLegalProcess?.auth?.file, states.JuriLegalProcess?.auth?.fileName)} className="flex flex-row w-full items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                                <DocumentTextIcon className="h-5 w-5" />
                                                                <p className="ml-2">Dowload</p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full items-center justify-center p-4">
                                                        <div className="flex w-full items-center justify-center p-4">
                                                            <div className="flex flex-row w-full items-start justify-start p-1">
                                                                <button onClick={() => openModalAddAssociate(states.JuriLegalProcess?.auth?.file, states.JuriLegalProcess?.auth?.fileName)} className="flex flex-row w-full items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                                    <UserIcon className="h-5 w-5" />
                                                                    <p className="ml-2">Adicionar Associado</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full items-center justify-center p-4">
                                                        <div className="flex flex-row w-full items-start justify-start p-1">
                                                            <button onClick={() => openEditAuth(states.JuriLegalProcess?.auth)} className="flex flex-row w-full items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                                <PencilIcon className="h-5 w-5" />
                                                                <p className="ml-2">Editar</p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <>
                                                    <div className="flex w-full items-center justify-center p-4">
                                                        <div className="flex w-full items-center justify-center p-4">
                                                            <div className="flex flex-row w-full items-start justify-start p-1">
                                                                <button onClick={() => dowloadFile(states.JuriLegalProcess?.auth?.file, states.JuriLegalProcess?.auth?.fileName)} className="flex flex-row w-full items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                                    <DocumentTextIcon className="h-5 w-5" />
                                                                    <p className="ml-2">Dowload</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        Cookies.get('roleJuri') == 'ADMIN' &&
                                                        <div className="flex w-full items-center justify-center p-4">
                                                            <div className="flex w-full items-center justify-center p-4">
                                                                <div className="flex flex-row w-full items-start justify-start p-1">
                                                                    <button onClick={() => openModalAddAssociate()} className="flex flex-row w-full items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                                                        <UserIcon className="h-5 w-5" />
                                                                        <p className="ml-2">Adicionar Associado</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                        }


                                    </>
                                }
                            </div>

                            {/* PAINEL DE CONTROLE */}
                            <div className="border mt-5 p-4 rouded-[10px] hover:shadow w-full rounded-[10px]">
                                <div className="rounded-md flex justify-between items-center bg-white ">
                                    <div className="flex flex-1 justify-between items-center">
                                        <div className="flex flex-col">
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Paineis de controle, processo: {states.JuriLegalProcess?.caseTitle}</h2>
                                            <div className="flex items-center">
                                                <p className="max-w-2xl text-sm leading-6 text-gray-600">
                                                    Aqui você encontrará os principais acesso do processo {states.JuriLegalProcess?.caseTitle}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex bg-white justify-end items-center'>
                                        <button
                                            className="flex items-center text-gray-600 hover:text-gray-800"
                                            onClick={() => setArrowButton(!arrowButton)}
                                        >
                                            <ChevronDoubleUpIcon
                                                className={`${arrowButton ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                                            />
                                        </button>
                                    </div>
                                </div>

                                {/* {(permission == 'ADMIN' || permission == 'LAWYER') } */}
                                {!arrowButton &&
                                    <>
                                        {
                                            (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') ?
                                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-5 border">
                                                    {/*  */}
                                                    <div className="flex items-center justify-center flex-col">
                                                        <Link to={`/juridicoIndex/processo/${id}/associados`} className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                            <UserGroupIcon className="h-[120px] w-[350px] text-blue-600" />
                                                        </Link>
                                                        <p className="text-center mt-2">Associados Vinculados</p>
                                                    </div>
                                                    <div className="flex items-center justify-center flex-col">
                                                        <Link to={`/juridicoIndex/processo/${id}/atualizacoes`} className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                            <BellAlertIcon className="h-[120px] w-[350px] text-blue-600" />
                                                        </Link>
                                                        <p className="text-center mt-2">Atualizações</p>
                                                    </div>

                                                    <div className="flex items-center justify-center flex-col">
                                                        <Link to={`/juridicoIndex/processo/${id}/deferimentos`} className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                            <FolderIcon className="h-[120px] w-[350px] text-blue-600" />
                                                        </Link>
                                                        <p className="text-center mt-2">Deferimentos</p>
                                                    </div>
                                                    <div className="flex items-center justify-center flex-col">
                                                        <Link to={`/juridicoIndex/processo/${id}/secoes`} className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                            <InboxIcon className="h-[120px] w-[350px] text-blue-600" />
                                                        </Link>
                                                        <p className="text-center mt-2">Seções</p>
                                                    </div>
                                                </div>
                                                : <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 mt-5 border">
                                                    {/*  */}
                                                    <div className="flex items-center justify-center flex-col">
                                                        <Link to={`/juridicoIndex/processo/${id}/associados`} className="w-[20%] h-[40%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-full overflow-hidden bg-white hover:shadow-md">
                                                            <UserGroupIcon className="h-[120px] w-[350px] text-blue-600" />
                                                        </Link>
                                                        <p className="text-center mt-2">Associados Vinculados</p>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                            <div className='lg:flex w-full'>
                                <div className="lg:mr-2 flex lg:w-full flex-col items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                                    <h2 className="text-xl font-bold">Adesões ao processo por ano</h2>
                                    <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                                    <div style={{ height: '90%', width: '100%' }}>
                                        <Line data={dataFlowCash} options={options} />
                                    </div>
                                </div>

                                <div className="lg:ml-2 flex flex-col lg:w-full items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                                    <h2 className="text-xl font-bold">Pedidos de deferimentos</h2>
                                    <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                                    <div style={{ height: '90%', width: '100%' }}>
                                        {
                                            states.dataSystemJuriProcess?.graphic?.motions.reduce((a, b) => a + b.value, 0) == 0 ? (<p className="text-xl font-bold text-center mt-16">Não há deferimentos</p>) : (<Pie data={data1} options={options} />)
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ModalAutorizacao states={states} setters={setters} action={actionModal} />
                    <ModalAutorizacaoAssociado states={states} setters={setters} action={actionModalAssociate} associate={dataAssociadoCpf} />



                </div>
                <Footer />
            </div >
        </>
    )
}



export default ProcessoPainel;