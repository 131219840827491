import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import './index.css'
export default function ModalShow({ states, setters, title, data, closeModal }) {
    const [open, setOpen] = useState(true)

    const close = () => {
        closeModal()
    }

    const formatCPF = (cpf) => {
        if (!cpf) {
            return '';
        }

        const cleanedCPF = cpf.replace(/\D/g, '');

        if (cleanedCPF.length !== 11) {
            return cpf; // Retorna o valor original se não for possível formatar
        }

        const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
        const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');

        return formattedCPF;
    }

    function formatarCNPJ(cnpj) {
        // Remove caracteres não numéricos
        cnpj = cnpj.replace(/[^\d]/g, '');

        // Formatação do CNPJ (99.999.999/9999-99)
        return cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
            '$1.$2.$3/$4-$5'
        );
    }


    return (
        <Transition.Root show={states.modalShowTecnico} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-0"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-[80%]">
                            <div className="bg-blue-100 px-4 py-5 sm:px-6  items-center justify-center">
                                <h2 className="text-lg font-medium text-gray-900 text-center">{title}</h2>
                            </div>
                            <div className="px-4 py-5 sm:p-4">

                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">CPF/CNPJ</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{data.isCompany === 1 ? formatarCNPJ(data.cpf_cnpj) : formatCPF(data.cpf_cnpj)}</dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">E-mail</dt>
                                        <dd className="mt-1 text-sm text-gray-900 break-words">
                                            <div style={{ wordBreak: 'break-all' }}>{data.email}</div>
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Data de criação</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {moment(data.created_at).format('DD/MM/YYYY')}
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Nome</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div style={{ wordBreak: 'break-all' }}>{data.name}</div>
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Telefone</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div style={{ wordBreak: 'break-all' }}>({data.prefix_phone}){data.phone}</div>
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Tipo</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {
                                                data.employee == 1 ? (
                                                    // <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        "Empregado"
                                                    // </span>
                                                ) : data.employee == 0 && data.isCompany == 1 ? (
                                                    // <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        "Empresa"
                                                    // </span>
                                                ) : (
                                                    // <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                        "Pessoa física"
                                                    // </span>
                                                )
                                            }
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Estado</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{data.district}</dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Cidade</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{data.city}</dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Bairro</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{data.district}</dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Rua</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div style={{ wordBreak: 'break-all' }}>{data.street}</div>
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Complemento</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div style={{ wordBreak: 'break-all' }}>{data.complement}</div>
                                        </dd>
                                    </div>
                                    <div className="flex flex-col items-start justify-center sm:col-span-1 p-2 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Número</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div style={{ wordBreak: 'break-all' }}>{data.locationNumber}</div>
                                        </dd>
                                    </div>


                                  
                                    {/* Adicione mais blocos de código semelhantes para exibir outras informações */}
                                </dl>

                            </div>
                            <div className="bg-blue-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

