import { AuthContext } from "../../Context"
import { AuthContextApi } from "../../Context/api"
import { useState, useContext, useEffect } from "react"

import Footer from "../../Components/Footer/footer"

const Login = () => {

    const { states, setters } = useContext(AuthContext)
    const { forgotPassword } = useContext(AuthContextApi)


    const [inputEmail,setInputEmail] = useState(true)

    const action = async (e) => {
        e.preventDefault()
        await forgotPassword(states.email)
    }

    useEffect(() => {
        // localstorage.clear()
    }, [])

    const leaveInput = async () => {
        if (states.email === '') {
            setInputEmail(false)
        } else {
            setInputEmail(true)
        }
    }


    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div className="justify-center">
                                <img
                                    className="h-[5em] w-auto"
                                    src="https://financeiro.sinprev.org.br/logo-sinprev-resp.png"
                                    alt="Sinprev logo"
                                />
                                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Redefinição de senha</h2>

                            </div>

                            <div className="mt-8">
                                <div>
                                    <div className="relative mt-6">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center text-sm">
                                            <span className="bg-white px-2 text-gray-500">Digite seu e-mail para recuperar sua senha</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <form action="#" method="POST" className="space-y-6" onSubmit={action}>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                E-mail
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    onChange={e => setters.setEmail(e.target.value)}
                                                    onBlur={leaveInput}
                                                    required
                                                    className={inputEmail === true
                                                        ? "block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gr" +
                                                        "ay-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue" +
                                                        "-500 sm:text-sm sm:leading-6"
                                                        : "block w-full rounded-md border-1 py-1.5 border border-red-500 shadow-sm ring-1 r" +
                                                        "ing-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
                                                        "focus:ring-blue-500 sm:text-sm sm:leading-6"} />
                                            </div>
                                            {
                                                inputEmail === false &&
                                                <p class="mt-2 text-pink-600 text-sm">
                                                    {/* Forneça um endereço de e-mail válido. */}
                                                    É obrigatório o preenchimento de e-mail.
                                                </p>
                                            }

                                        </div>

                                        <div>
                                            <button
                                            disabled={inputEmail === false ? true : false}
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-blue-600">
                                                {
                                                    states.isLoading === true ? (
                                                        <p>Carregando...</p>
                                                    ): (
                                                        <p>Recuperar senha</p>
                                                    )
                                                }
                                            </button>
                                        </div>

                                        <Footer />

                                    </form>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                            alt="" />
                    </div>


                </div>

            </div>


        </>
    )
}

export default Login