import { toast } from 'react-toastify';
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'


export default function ModalCustos(props) {

  useEffect(() => {
    if (props.states.modalIsOpen == false) {
      props.setters.setCustos({})
      limparStatesModalPlano()
    }
  }, [props.states.modalIsOpen]);

  const [preco, setPreco] = useState('')
  const [viewPreco, setViewPreco] = useState('')
  const [idCustos, setIdCustos] = useState('')

  const [descricao, setDescricao] = useState('')

  const [errorPreco, setErrorPreco] = useState(false)
  const [errorQuantidade, setErrorQuantidade] = useState(false)
  const [errorDescricao, setErrorDescricao] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const handleChange = (inputValue, origin) => {
    try {
      if (inputValue.length > 0) {
        const value = inputValue.replace(/[^0-9]/g, '')
        const valueMoney = value / 100
        if (origin == 'preco') {
          const arrPrice = value.split('')
          if (arrPrice.length > 2) {

            arrPrice.splice(-2, 0, ',')
            const valueString = arrPrice.join('')
            setViewPreco(valueString)
          } else {
            setViewPreco(value)
          }
          // // 
          setPreco(value)
        }
      }
      else {
        if (origin == 'preco') {
          setPreco('')
        }
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'preco') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPreco(true)
      }
      else {
        setErrorPreco(false)
      }
    }
    else if (tipo === 'descricao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorQuantidade(true)
      }
      else {
        setErrorQuantidade(false)
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()

    const validator = errorPreco === false && errorDescricao === false ? true : false
    if (!validator) return

    if (props.states.isEdit == true) {
      const body = {
        ...props.states.Custos,
        budget: preco,
        type: descricao,
      }
      props.action(body)
    } else {
      const body = {
        budget: preco,
        type: descricao,

      }
      props.action(body)
    }




  }

  const limparStatesModalPlano = () => {
    setPreco('')
    setDescricao('')
    setIdCustos('')
    setViewPreco('')
    setErrorPreco(false)
    setErrorDescricao(false)
  }

  useEffect(() => {
    if (Object.keys(props.states.Custos).length > 0) {
      setIdCustos(props.states.Custos.id)
      setPreco(props.states.Custos.budget)
      setViewPreco(props.states.Custos.budget)
      setDescricao(props.states.Custos.type)
    }
  }, [props.states.Custos])


  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Custos</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do custos
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1">

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Orçamento anual
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">R$</span>
                              <input
                                type="text"
                                name="preco"
                                id="preco"
                                autoComplete="preco"
                                placeholder={props.states.waitTimeout === false ? '0,00' : 'Carregando...'}
                                disabled={disabled}
                                value={viewPreco}
                                onBlur={() => leaveInput(preco, 'preco')}
                                onChange={e => handleChange(e.target.value, 'preco')}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorPreco === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do orçamento.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Descrição do custo
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="text"
                                name="quantidade"
                                id="quantidade"
                                autoComplete="quantidade"
                                placeholder={props.states.waitTimeout === false ? 'Digite o tipo do custo' : 'Carregando...'}
                                disabled={disabled}
                                value={descricao}
                                onBlur={() => leaveInput(descricao, 'descricao')}
                                onChange={e => setDescricao(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorQuantidade === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da descrição do custos
                            </p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {
                        props.states.isEdit == true ? 'Editar' : 'Salvar'
                      }
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
