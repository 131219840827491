

import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import './index.css'





const TableLogs = ({ states, headers, dataTable, title, description, negociacao }) => {

    // 

    const [dataTableState, setDataTableState] = useState([])
    const [offSet, setOffSet] = useState(0)
    const limitTable = 10

    const nextPage = () => {
        if (dataTableState.length === 0 || dataTable.length <= limitTable ) {
            return
        } else {
            const data = dataTable
            const sumOff = offSet + limitTable
            const limit = limitTable + sumOff
            const newData = data.slice(sumOff, limit)
            setOffSet(limitTable + offSet)
            setDataTableState(newData)
        }
    }

    const previousPage = () => {
        if (offSet === 0) {
            return
        } else {
            const data =dataTable
            offSet > 0 && setOffSet(offSet - limitTable)
            offSet < 0 && setOffSet(0)
            const sumOff = offSet > 0 ? offSet - limitTable : 0;
            const limit = sumOff + 10 > limitTable ? sumOff + 10 : states.limitAssociadoss;
            const newData = data.slice(sumOff, limit)
            setDataTableState(newData)
        }

    }

    useEffect(() => {
        setDataTableState(dataTable.slice(0, limitTable))
    }, [])

    const formatCPF = (cpf) => {
        if (!cpf) {
            return '';
        }

        const cleanedCPF = cpf.replace(/\D/g, '');

        if (cleanedCPF.length !== 11) {
            return cpf; // Retorna o valor original se não for possível formatar
        }

        const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
        const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');

        return formattedCPF;
    }

    function money(numero) {
        var numeroFormatado = numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return numeroFormatado;
      }





      const trGen = () => {
        if (states.isLoading) {


            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTable.length > 0 && Object.keys(dataTable[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {


            return (
                dataTable.length > 0 ? (
                    dataTable.map((logs) => {
                        return (
                            <tr key={logs.id} className="bg-gray-50 hover:bg-gray-200">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {formatCPF(logs.cpf)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.emailAssociate}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.description
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    R$ {money(logs.value/100)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.status === 'paid' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Pago
                                            </span>
                                        ) : logs.status === 'pending' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                Pendente
                                            </span>
                                        ) : logs.status === 'expired' ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                Expirado
                                            </span>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {logs.status}
                                            </span>
                                        )
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.expires_ate).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.createdAt).format('DD/MM/YYYY')}
                                </td>
                            </tr>
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500" colSpan="5">
                            Sem registro de dados
                        </td>
                    </tr>
                )
            )

        }
    }



    return (
        <div className="py-2 mt-6 overflow-x-auto">
            <div className="inline-block min-w-full  rounded-md border border-gray-200">

                <div>
                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 justify-between items-center">
                            <div className="flex flex-col">
                                <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                <p className="text-gray-500">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="min-w-full divide-y divide-gray-300">


                    <thead className="bg-gray-50">
                        <tr>
                            {
                                headers.map((headers, index) => (
                                    <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                        {headers}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            trGen()
                        }
                    </tbody>
                </table>
                <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                    <div className="flex flex-1 justify-between sm:justify-start">
                        <button
                            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 bg-gray-100 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                            onClick={() => previousPage()}
                        >
                            Anterior
                        </button>
                        <button
                            href="#"
                            className="relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 bg-gray-100 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                            onClick={() => nextPage()}
                        >
                            Próximo
                        </button>


                    </div>
                </nav>
            </div>
        </div>







    )



}


export default TableLogs;