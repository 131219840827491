import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, TrashIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
// import { XMarkIcon, TrashIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import moment from 'moment'

export default function ModalProduct({ states, setters, conciliate }) {

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPageSaidas, setCurrentPageSaidas] = useState(1);
  const [currentPageEntradas, setCurrentPageEntradas] = useState(1);
  
  const [filtroEntradas, setFiltroEntradas] = useState('valor');
  const [termoBuscaEntradas, setTermoBuscaEntradas] = useState('');
  const [filtroEntradasAtivo, setFiltroEntradasEntradasAtivo] = useState(false);

  const [filtroSaidas, setFiltroSaidas] = useState('valor');
  const [termoBuscaSaidas, setTermoBuscaSaidas] = useState('');
  const [filtroSaidasAtivo, setFiltroSaidasSaidasAtivo] = useState(false);

  const [dataConciliacaoSaidasFiltro, setDataConciliacaoSaidasFiltro] = useState([]);
  const [dataConciliacaoEntradasFiltro, setDataConciliacaoEntradasFiltro] = useState([]);


  const close = () => {
    setters.setModalConc(false)
  }

  const sliceData = (origem) => {
    if(origem == 'entradas')
    {
      const startIndex = (currentPageEntradas - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return states.dataConciliacaoEntradas.slice(startIndex, endIndex);
    }
    else if(origem == 'saidas')
    {
      const startIndex = (currentPageSaidas - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return states.dataConciliacaoSaidas.slice(startIndex, endIndex);
    }
  }

  const sliceDataFiltro = (origem) => {
    if(origem == 'entradas')
    {
      const startIndex = (currentPageEntradas - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return dataConciliacaoEntradasFiltro.slice(startIndex, endIndex);
    }
    else if(origem == 'saidas')
    {
      const startIndex = (currentPageSaidas - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return dataConciliacaoSaidasFiltro.slice(startIndex, endIndex);
    }
  }

  const controlarTamanhoPagina = (event) => {
    const value = parseInt(event.target.value, 10);
    
    if (!isNaN(value) && value >= 1 && value <= 200) {
      setCurrentPageSaidas(1)
      setCurrentPageEntradas(1)
      setItemsPerPage(value);
    }
  }

  const converterStringParaNumero = (valorString) => {
    // Remove caracteres não numéricos, como vírgulas
    const valorNumericoString = valorString.replace(/[^0-9]/g, '');
  
    // Converte a string resultante para um número
    const valorNumerico = parseInt(valorNumericoString, 10);
  
    return valorNumerico;
  }



  const handleFiltroChangeEntradas = (e) => {
    setFiltroEntradas(e.target.value);
    setTermoBuscaEntradas("");
  };

  const handleTermoBuscaChangeEntradas = (e) => {
    setTermoBuscaEntradas(e.target.value);
  };

  const handleBuscarEntradasClick = () => {
    const resultadoBusca = states.dataConciliacaoEntradas.filter((entrada) => {
      if (filtroEntradas === "valor") {
        return entrada.value.toString().includes(converterStringParaNumero(termoBuscaEntradas));
      } else if (filtroEntradas === "descricao") {
        return entrada.description.toString().includes(termoBuscaEntradas);
      } else if (filtroEntradas === "data") {
        return entrada.entry_date.toString().split('T')[0].includes(termoBuscaEntradas);
      }
      return true; // Se nenhum filtro correspondente for encontrado, retorna todos os resultados
    });

    if(resultadoBusca.length === 0)
    {
      setFiltroEntradasEntradasAtivo(false)
    }
    else
    {
      setFiltroEntradasEntradasAtivo(true)
    }

    // Use resultadoBusca conforme necessário
    setDataConciliacaoEntradasFiltro(resultadoBusca);
  };

  const handleLimparEntradasClick = () => {
    setFiltroEntradasEntradasAtivo(false)
    setFiltroEntradas('valor'); // Resetar o filtro para o valor padrão ou outro valor desejado
    setTermoBuscaEntradas(''); // Limpar o termo de busca
    setCurrentPageEntradas(1);
  };



  const handleFiltroChangeSaidas = (e) => {
    setFiltroSaidas(e.target.value);
    setTermoBuscaSaidas("");
  };

  const handleTermoBuscaChangeSaidas = (e) => {
    setTermoBuscaSaidas(e.target.value);
  };

  const handleBuscarSaidasClick = () => {
    const resultadoBusca = states.dataConciliacaoSaidas.filter((entrada) => {
      if (filtroSaidas === "valor") {
        return entrada.value.toString().includes(converterStringParaNumero(termoBuscaSaidas));
      } else if (filtroSaidas === "descricao") {
        return entrada.description.toString().includes(termoBuscaSaidas);
      } else if (filtroSaidas === "data") {
        return entrada.entry_date.toString().split('T')[0].includes(termoBuscaSaidas);
      }
      return true; // Se nenhum filtro correspondente for encontrado, retorna todos os resultados
    });

    if(resultadoBusca.length === 0)
    {
      setFiltroSaidasSaidasAtivo(false)
    }
    else
    {
      setFiltroSaidasSaidasAtivo(true)
    }

    // Use resultadoBusca conforme necessário
    setDataConciliacaoSaidasFiltro(resultadoBusca);
  };

  const handleLimparSaidasClick = () => {
    setFiltroSaidasSaidasAtivo(false)
    setFiltroSaidas('valor'); // Resetar o filtro para o valor padrão ou outro valor desejado
    setTermoBuscaSaidas(''); // Limpar o termo de busca
    setCurrentPageSaidas(1);
  };

  return (
    <Transition.Root show={states.modalConc} as={Fragment} >
      <Dialog as="div" className="relative z-10" onClose={() => setters.setModalConc(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >

              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[80%] z-10 transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">

                <div className="absolute right-0 top-0  pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={close}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className='flex-col border-b border-gray-900/10 mb-4'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Conciliação</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-4">
                    Informações da conciliação
                  </p>

                  <div className="flex justify-center items-center space-x-8 mb-4">
                      <div>
                        <button type="button" className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 py-2 px-1 rounded-md text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                          onClick={close}>
                          Cancelar
                        </button>
                      </div>
                      <div>
                        <p className="text-[12px] font-semibold text-center">
                          Mostrando
                        </p>
                        <input
                          type="number"
                          value={itemsPerPage}
                          onChange={controlarTamanhoPagina}
                          className="border border-gray-200 p-2 py-1 text-sm font-semibold leading-5 text-gray-900 rounded-md text-center"
                          min="0"
                          max="200"
                        />
                        </div>
                      <div>
                        <button type="button" className="parag border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md py-2 px-1 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                          onClick={conciliate}>
                          Conciliar
                        </button>
                      </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-center pb-4">
                    <div className="flex justify-center max-w-4xl  gap-x-8 mt-2 grid grid-cols-1 lg:grid-cols-6">

                      <div className="sm:col-span-3">
                        {states.dataConciliacaoEntradas.length !== 0 &&
                          <>
                            <label className="block text-sm font-medium leading-6 text-gray-900 lg:mt-0 mt-4">
                              Entradas
                            </label>

                            <div className="flex justify-between items-center mt-2 mb-2">
                              <select
                                value={filtroEntradas}
                                onChange={handleFiltroChangeEntradas}
                                className={`rounded-md mr-2 p-1 ${filtroEntradasAtivo ? 'border-2 border-green-500' : 'border border-gray-300'}`}
                              >
                                <option value="valor">Valor</option>
                                <option value="descricao">Descrição</option>
                                <option value="data">Data</option>
                              </select>
                              <input
                                type={filtroEntradas === "data" ? "date" : "text"}
                                value={termoBuscaEntradas}
                                onChange={handleTermoBuscaChangeEntradas}
                                placeholder='Buscar'
                                className="rounded-md mr-2 p-1 border border-gray-300"
                              />
                              <div className='space-x-1 flex items-center justify-center'>
                                <button
                                  onClick={handleLimparEntradasClick}
                                  title='Limpar'
                                  // className="p-1 bg-gray-200 text-gray-900"
                                  className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 p-2 rounded-md text-sm font-semibold focus-visible:outline-gray-100"
                                >
                                  <TrashIcon className='h-4 w-4'/>
                                </button>
                                <button
                                  onClick={handleBuscarEntradasClick}
                                  disabled={termoBuscaEntradas.trim() === ""}
                                  title='Buscar'
                                  // className="p-1 bg-blue-500 text-white"
                                  className="border text-sm font-semibold leading-5 text-white bg-blue-500 p-2 rounded-md text-sm font-semibold focus-visible:outline-blue-100"
                                >
                                  <MagnifyingGlassIcon className='h-4 w-4'/>
                                </button>
                              </div>
                            </div>

                            <div className='flex flex-1 flex-col'>
                              <div>
                                <div className="py-2 mt-2 flow-root">
                                  <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                      <div className="border border-gray-200">
                                        <table className="min-w-full divide-y divide-gray-300">
                                          <thead className="bg-gray-50">
                                            <tr>
                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Valor
                                              </th>
                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Descrição
                                              </th>
                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Data Criação
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="divide-y divide-gray-200 bg-white">
                                            {filtroEntradasAtivo ? 
                                              <>
                                                {sliceDataFiltro('entradas').map((fatura) => (
                                                <tr key={fatura.id} className="hover:bg-gray-50">
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    R$ {parseFloat(fatura.value / 100).toFixed(2).toString().replace('.', ',')}
                                                  </td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 parag">{fatura.description}</td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(fatura.entry_date).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                ))} 
                                              </>
                                              : 
                                              <>
                                              {sliceData('entradas')
                                              .map((fatura) => (
                                                <tr key={fatura.id} className="hover:bg-gray-50">
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    R$ {parseFloat(fatura.value / 100).toFixed(2).toString().replace('.', ',')}
                                                  </td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 parag">{fatura.description}</td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(fatura.entry_date).format('DD/MM/YYYY')}</td>
                                                </tr>
                                              ))}
                                              </>
                                            }
                                          </tbody>
                                        </table>
                                        <div className="flex justify-between items-center mt-4 p-2">
                                          <div>
                                            <button
                                              onClick={() => setCurrentPageEntradas(currentPageEntradas - 1)}
                                              disabled={currentPageEntradas === 1}
                                              className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            >
                                              Anterior
                                            </button>
                                          </div>
                                          {filtroEntradasAtivo ? <p className="text-[12px] font-semibold text-gray-500 pr-2 pl-2">
                                              Mostrando {currentPageEntradas === 1 ? 1 : (currentPageEntradas - 1) * itemsPerPage + 1} -{' '}
                                              {currentPageEntradas * itemsPerPage > dataConciliacaoEntradasFiltro.length
                                                ? dataConciliacaoEntradasFiltro.length
                                                : currentPageEntradas * itemsPerPage}{' '}
                                              de {dataConciliacaoEntradasFiltro.length} valores
                                            </p>
                                            : 
                                            <p className="text-[12px] font-semibold text-gray-500 pr-2 pl-2">
                                              Mostrando {currentPageEntradas === 1 ? 1 : (currentPageEntradas - 1) * itemsPerPage + 1} -{' '}
                                              {currentPageEntradas * itemsPerPage > states.dataConciliacaoEntradas.length
                                                ? states.dataConciliacaoEntradas.length
                                                : currentPageEntradas * itemsPerPage}{' '}
                                              de {states.dataConciliacaoEntradas.length} valores
                                            </p>
                                          }
                                          
                                          <div>
                                            <button
                                              onClick={() => setCurrentPageEntradas(currentPageEntradas + 1)}
                                              disabled={currentPageEntradas * itemsPerPage >= states.dataConciliacaoEntradas.length}
                                              className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            >
                                              Próxima
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </div>

                      <div className="sm:col-span-3 lg:mt-0 lg:border-none mt-4 border-t border-gray-200">
                        {states.dataConciliacaoSaidas.length !== 0 &&
                          <>
                            <label className="block text-sm font-medium leading-6 text-gray-900 lg:mt-0 mt-4">
                              Saídas
                            </label>

                            <div className="flex justify-between items-center mt-2 mb-2">
                              <select
                                value={filtroSaidas}
                                onChange={handleFiltroChangeSaidas}
                                className={`rounded-md mr-2 p-1 ${filtroSaidasAtivo ? 'border-2 border-green-500' : 'border border-gray-300'}`}
                              >
                                <option value="valor">Valor</option>
                                <option value="descricao">Descrição</option>
                                <option value="data">Data</option>
                              </select>
                              <input
                                type={filtroSaidas === "data" ? "date" : "text"}
                                value={termoBuscaSaidas}
                                onChange={handleTermoBuscaChangeSaidas}
                                placeholder='Buscar'
                                className="rounded-md mr-2 p-1 border border-gray-300"
                              />
                              
                              <div className='space-x-1 flex justify-center items-center'>
                                <button
                                  onClick={handleLimparSaidasClick}
                                  // className="p-1 bg-gray-200 text-gray-900"
                                  className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 p-2 rounded-md text-sm font-semibold focus-visible:outline-gray-100"
                                >
                                  <TrashIcon className='h-4 w-4'/>
                                </button>
                                <button
                                  onClick={handleBuscarSaidasClick}
                                  disabled={termoBuscaSaidas.trim() === ""}
                                  // className="p-1 bg-blue-500 text-white"
                                  className="border text-sm font-semibold leading-5 text-white bg-blue-500 p-2 rounded-md text-sm font-semibold focus-visible:outline-blue-100"
                                >
                                  <MagnifyingGlassIcon className='h-4 w-4'/>
                                </button>
                              </div>
                            </div>

                            <div className='flex flex-1 flex-col'>
                              <div>
                                <div className="py-2 mt-2 flow-root">
                                  <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                      <div className="border border-gray-200">
                                        <table className="min-w-full divide-y divide-gray-300">
                                          <thead className="bg-gray-50">
                                            <tr>
                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Valor
                                              </th>
                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Descrição
                                              </th>
                                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Data Criação
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody className="divide-y divide-gray-200 bg-white">
                                            {filtroSaidasAtivo ? 
                                              <>
                                                {sliceDataFiltro('saidas').map((fatura) => (
                                                <tr key={fatura.id} className="hover:bg-gray-50">
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    R$ {parseFloat(fatura.value / 100).toFixed(2).toString().replace('.', ',')}
                                                  </td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 parag">{fatura.description}</td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(fatura.entry_date).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                ))} 
                                              </>
                                              : 
                                              <>
                                              {sliceData('saidas').map((fatura) => (
                                                <tr key={fatura.id} className="hover:bg-gray-50">
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    R$ {parseFloat(fatura.value / 100).toFixed(2).toString().replace('.', ',')}
                                                  </td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 parag">{fatura.description}</td>
                                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(fatura.entry_date).format('DD/MM/YYYY')}</td>
                                                </tr>
                                              ))}
                                              </>
                                            }
                                          </tbody>

                                        </table>

                                        <div className="flex justify-between items-center mt-4 p-2">
                                          <div>
                                            <button
                                              onClick={() => setCurrentPageSaidas(currentPageSaidas - 1)}
                                              disabled={currentPageSaidas === 1}
                                              className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            >
                                              Anterior
                                            </button>
                                          </div>
                                          {filtroSaidasAtivo ? <p className="text-[12px] font-semibold text-gray-500 pr-2 pl-2">
                                              Mostrando {currentPageSaidas === 1 ? 1 : (currentPageSaidas - 1) * itemsPerPage + 1} -{' '}
                                              {currentPageSaidas * itemsPerPage > dataConciliacaoSaidasFiltro.length
                                                ? dataConciliacaoSaidasFiltro.length
                                                : currentPageSaidas * itemsPerPage}{' '}
                                              de {dataConciliacaoSaidasFiltro.length} valores
                                            </p>
                                            : 
                                            <p className="text-[12px] font-semibold text-gray-500 pr-2 pl-2">
                                              Mostrando {currentPageSaidas === 1 ? 1 : (currentPageSaidas - 1) * itemsPerPage + 1} -{' '}
                                              {currentPageSaidas * itemsPerPage > states.dataConciliacaoSaidas.length
                                                ? states.dataConciliacaoSaidas.length
                                                : currentPageSaidas * itemsPerPage}{' '}
                                              de {states.dataConciliacaoSaidas.length} valores
                                            </p>
                                          }
                                          <div>
                                            <button
                                              onClick={() => setCurrentPageSaidas(currentPageSaidas + 1)}
                                              disabled={currentPageSaidas * itemsPerPage >= states.dataConciliacaoSaidas.length}
                                              className="border text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            >
                                              Próxima
                                            </button>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </div>

                    </div>
                  </div>
                </div>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
