import { Link } from 'react-router-dom'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from "../../../Context/api"
import { useState, useContext, useEffect } from 'react'
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import ModalCustos from '../../../Components/Modal/Custos/modalCustos'

export default function Custos() {
  const { states, setters, } = useContext(AuthContext)
  const { postExpenses, dataCustos, putExpenses, deleteExpenses, createReportFinancialExpenses, createReportFinancialExpensesExtract } = useContext(AuthContextApi)

  const [isExtract, setIsExtract] = useState(false)

  const [limitCard, setLimitCard] = useState(3)
  const [cardsArray, setCardsArray] = useState([])
  const [intervalMonth, setIntervalMonth] = useState(0)

  const formatToMoney = (value) => {
    let val = value
    if (value < 0) val = value * (-1)
    const intToFloat = parseInt(val) / 100
    return intToFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  const add = () => {
    setters.setIsEdit(false)
    setters.setCustos({})
    setters.setModalIsOpen(true)
  }

  const actionModal = async (body) => {
    if (states.isEdit) {
      setters.setModalIsOpen(false)
      await putExpenses(body)
    } else {
      setters.setModalIsOpen(false)
      await postExpenses(body)
    }
  }

  const loadData = async () => {
    await dataCustos()
  }

  useEffect(() => {
    if (states.dataCustos.length != 0) return;
    loadData()

    return () => {
      setters.setDataCustos([])
      setters.setOffsetCustos(0)
    }

  }, [])

  useEffect(() => {
    loadData()
  }, [states.offsetCustos])

  const loadMore = () => {
    setters.setOffsetCustos(states.offsetCustos + states.limitCustos)
  }

  const modalEdit = (item) => {
    setters.setIsEdit(true)
    setters.setCustos(item)
    setters.setModalIsOpen(true)
  }

  const openDeleteModal = (item) => {
    setters.setCustos(item)
    setters.setConfirmModal(true)
  }

  const deleteCustos = async () => {
    setters.setConfirmModal(false)
    await deleteExpenses()
  }

  useEffect(() => {
    setCardsArray(states.dataCustos.slice(0, limitCard))
  }, [limitCard])

  useEffect(() => {
    setCardsArray(states.dataCustos.slice(0, limitCard))
  }, [])

  const gerarRelatorio = async () => {
    setIsExtract(false)
    await setters.setTitleReport('Relatório de Custos')
    await setters.setDescriptionReport(`Relatório de custos do dia ${moment(states.initialDataCustos).format('DD/MM/YYYY')} até ${moment(states.finalDataCustos).format('DD/MM/YYYY')}`)
  }

  const gerarRelatorioExtrato = async () => {
    setIsExtract(true)
    await setters.setTitleReport('Relatório de extrato custos')
    await setters.setDescriptionReport(`Relatório de custos do dia ${moment(states.initialDataCustos).format('DD/MM/YYYY')} até ${moment(states.finalDataCustos).format('DD/MM/YYYY')}`)
  }

  const repost = async () => {
    isExtract ? await createReportFinancialExpensesExtract() : await createReportFinancialExpenses()
  }

  useEffect(() => {
    if (states.titleReport !== '' && states.descriptionReport !== '') {
      repost()
    }
  }, [states.titleReport, states.descriptionReport])

  useEffect(() => {
    const intervalMonth = moment(states.finalDataCustos).diff(moment(states.initialDataCustos), 'months')
    setIntervalMonth(intervalMonth)
  }, [states.initialDataCustos, states.finalDataCustos])

  const genOption = () => {
    const array = []
    for (let i = 1; i <= states.dataCustos.length; i++) {
      array.push(i)
    }
    return array.map((item) => (
      <option value={item}>{item}</option>
    ))
  }

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4" >
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Custos</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Informações sobre os custos.
                  </p>
                </div>
              </div>

              {/* <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <div className='flex'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Intervalo:  {moment(states.initialDataCustos).format("DD/MM")} - {moment(states.finalDataCustos).format('DD/MM')}</h3>
                  <select id="my-select" className="border ml-3 border-gray-300 px-2 py-0 rounded focus:outline-none focus:border-blue-500"
                    onChange={e => setLimitCard(e.target.value)} value={limitCard}
                  >
                    {
                      genOption()
                    }
                  </select>
                </div>
              </div> */}

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {cardsArray.map((item, index) => (
                  <div key={item.name} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                              <div className='flex flex-row'>
                                <div className="text-sm font-medium text-gray-900">{item.type}</div>
                              </div>
                            </dt>
                            <dd>
                              <div className="text-lg font-medium text-gray-900">{formatToMoney(item.usedValue)}</div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Intervalo da apresentação</h1>
                <div className='flex md:flex-row flex-col items-center justify-center mt-2'>
                  <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                    <label htmlFor="start-date" className="mr-2">Data de início:</label>
                    <input id="start-date" type="date" onChange={e => setters.setInitialDataCustos(e.target.value)} value={states.initialDataCustos} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="end-date" className="mr-2">Data de término:</label>
                    <input id="end-date" type="date" onChange={e => setters.setFinalDataCustos(e.target.value)} value={states.finalDataCustos} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => loadData()}>
                      Aplicar
                    </button>
                    <button className="ml-2 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={() => gerarRelatorio()}>
                      Gerar Relatório
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>

                <button className="mt-6 ml-2 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={() => gerarRelatorioExtrato()}>
                  Gerar Relatório de Extrato
                </button>
              </div>

              <div className="mt-4 overflow-x-auto">
                <div className="inline-block min-w-full rounded-md border border-gray-200">
                  <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                    <div className="flex flex-1 justify-between items-center">
                      <div className="flex flex-col">
                        <h1 className="text-xl font-bold text-gray-700">Custos</h1>
                        <p className="text-gray-500">Registro dos custos do SINPREV</p>
                      </div>
                    </div>
                    <div className='flex-1 flex bg-white justify-end items-center'>
                      <button onClick={() => add()} className=" flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border 
                                        hover:bg-gray-200 focus-visible:outline-offset-0">
                        <PlusIcon className="h-12 w-12" />
                      </button>
                    </div>
                  </div>
                  <table className="min-w-full">
                    {states.dataCustos.map((item) => (
                      <dl className="mb-4 mr-4 ml-4 grid grid-cols-1 overflow-hidden rounded-md bg-white hover:shadow-md border border-gray-200 md:grid-cols-1">
                        <div key={item.type} className="p-4">
                          <dt className="text-base font-normal text-gray-900">{item.type}</dt>
                          <dd className="block items-baseline justify-between md:block lg:flex p-4">

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Orçamento Total</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {formatToMoney(item.budget)}
                                {
                                  parseInt(item.usedValue) > parseInt(item.budget) ? (

                                    <span className="text-sm font-medium text-red-500">Utilizado: {formatToMoney(item.usedValue)}</span>
                                  ) : (
                                    <span className="text-sm font-medium text-green-500">Utilizado: {formatToMoney(item.usedValue)}</span>
                                  )
                                }
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Orçamento Utilizado</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {formatToMoney(item.usedValue)}
                                {
                                  parseInt(item.usedValue) > parseInt(item.budget) ? (

                                    <span className="text-sm font-medium text-red-500">Média mensal: {formatToMoney(item.usedValue / intervalMonth)}</span>
                                  ) : (
                                    <span className="text-sm font-medium text-green-500">Média mensal: {formatToMoney(item.usedValue / intervalMonth)}</span>
                                  )
                                }
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Orçamento disponível</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {formatToMoney(item.budget - item.usedValue)}
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Ações</p>
                              <div className="flex items-baseline text-xl font-semibold text-blue-600">
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600'>
                                  <Link to={`/financeIndex/custos/faturas/${item.id}`}>
                                    <MagnifyingGlassIcon className='h-7 w-7' />
                                  </Link>
                                </button>
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600' onClick={() => modalEdit(item)}>
                                  <AdjustmentsHorizontalIcon className='h-7 w-7' />
                                </button>
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600' onClick={() => openDeleteModal(item)}>
                                  <TrashIcon className='h-7 w-7' />
                                </button>

                              </div>
                            </div>
                          </dd>
                        </div>
                      </dl>
                    ))}
                    {
                      !states.limitSearchCustos && (
                        <div className='flex justify-center pt-4 pb-8'>
                          <button className='flex' onClick={loadMore}>
                            <p className='text-blue-600 hover:text-blue-400'>Carregar mais</p>
                          </button>
                        </div>
                      )
                    }
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <ModalCustos states={states} setters={setters} action={actionModal} />
        <ModalConfirm states={states} setters={setters} action={deleteCustos} title='Deseja mesmo excluir esse custo?'
          description='Ao excluir esse custo, você não poderá mais recuperá-lo.'
        />

      </div>
    </>
  )
}
