
import moment from 'moment'
import { ArrowLeftIcon, ArrowRightIcon, MagnifyingGlassCircleIcon, PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import './index.css'
import { jsonConfig } from '../../../Config'
import Cookies from 'js-cookie'


const TableAdvogadoProcessos = ({ states, headers, dataTable, title, description, add, next, previous, edit, show, del }) => {


    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const trGen = () => {
        if (states.isLoading) {


            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTable.length > 0 && Object.keys(dataTable[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {


            return (
                dataTable.length > 0 ? (
                    dataTable.map((logs) => {
                        return (
                            <tr key={logs.id} className="bg-gray-50 hover:bg-gray-200">
                                <input id={logs.id} type='hidden' className='hidden' value={jsonConfig.urlAplication + '/vizualizacao/processo/' + logs.url} />
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {truncateText(logs.name, 100)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.type === 1 ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Ação Coletiva
                                            </span>
                                        ) :  logs.type === 2 ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                Ação Individual
                                            </span>
                                        ) :  (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                                                Ação 
                                            </span>
                                        )

                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.isRestricted === 1 ? (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Ação com restrição de fundo
                                            </span>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                Ação sem restrição de fundo
                                            </span>
                                        )

                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {
                                        logs.email
                                    }
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900 flex  ">
                                    <button
                                        onClick={() => show(logs)}
                                        className="flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0 ml-2"
                                    >
                                        <MagnifyingGlassCircleIcon className="h-6 w-6" />
                                    </button>
                                    {
                                        (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') &&
                                        <>
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => edit(logs)}>
                                                <PencilIcon className="h-7 w-7" />
                                            </button>
                                        </>
                                    }
                                    {
                                        Cookies.get('roleJuri') === 'LAWYER' &&
                                        <>
                                            <button className="h-10 w-10 text-gray-400 hover:text-gray-600" onClick={() => del(logs)}
                                            >
                                                <TrashIcon className="h-7 w-7" />
                                            </button>
                                        </>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500" colSpan="5">
                            Sem ações registradas
                        </td>
                    </tr>
                )
            )

        }
    }

    const scrollContainerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const scrollTable = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // A quantidade de pixels para rolar
            const currentScroll = scrollContainerRef.current.scrollLeft;
            scrollContainerRef.current.scrollLeft = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
        }
    };
    useEffect(() => {
        const checkOverflow = () => {
            const container = scrollContainerRef.current;
            if (container) {
                const isOverflowing = container.scrollWidth > container.clientWidth;
                setShowScrollButtons(isOverflowing);
            }
        };

        // Verifica o overflow quando o componente é montado
        checkOverflow();

        // Adiciona um ouvinte para verificar o overflow quando a janela é redimensionada
        window.addEventListener('resize', checkOverflow);

        // Remove o ouvinte quando o componente é desmontado
        return () => window.removeEventListener('resize', checkOverflow);
    }, [dataTable]);

    return (
        <div className="mt-4">
            {showScrollButtons && (
                <div className="flex justify-between">
                    <button
                        onClick={() => scrollTable('left')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowLeftIcon className="h-7 w-7 text-blue-500" />
                    </button>
                    <button
                        onClick={() => scrollTable('right')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowRightIcon className="h-7 w-7 text-blue-500" />
                    </button>
                </div>
            )}
            <div className="mt-4 overflow-x-auto" ref={scrollContainerRef}>
                <div className="inline-block min-w-full  rounded-md border border-gray-200">

                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 items-center">
                            {
                               (Cookies.get('roleJuri') === 'LAWYER' || Cookies.get('roleJuri') === 'AUXLAWYER') &&
                                <button
                                    onClick={() => add()}
                                    className="flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0"
                                >
                                    <PlusIcon className="h-12 w-12" />
                                </button>
                            }
                            <div className="flex flex-col ml-4">
                                <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                <p className="text-gray-500">{description}</p>
                            </div>
                        </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">


                        <thead className="bg-gray-50">
                            <tr>
                                {
                                    headers.map((headers, index) => (
                                        <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                            {headers}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                trGen()
                            }
                        </tbody>
                    </table>
                    <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                        <div className="flex flex-1 justify-between sm:justify-start">
                            <button
                                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 bg-gray-100 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => previous()}
                            >
                                Anterior
                            </button>
                            <button
                                href="#"
                                className="relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 bg-gray-100 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => next()}
                            >
                                Próximo
                            </button>


                        </div>
                    </nav>
                </div>
            </div>
        </div>







    )



}


export default TableAdvogadoProcessos;