
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import { useContext, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, PlusIcon, XMarkIcon, PencilIcon, ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import { jsonConfig } from "../../../Config";
import { useParams } from "react-router-dom";
import moment from 'moment'

const ProcessosJuridico = () => {

    const { sendRequestMail } = useContext(AuthContextApi)
    const { url } = useParams()
    const [cpf, setCpf] = useState('')
    const [email, setEmail] = useState('')

    const [errorCpf, setErrorCpf] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [error, setError] = useState(false)


    const sendEmail = async () => {
        if (!cpf || cpf.length < 14) {
            setErrorCpf(true)
            return
        }
        setErrorCpf(false)

        if (!email || !email.includes('@') || !email.includes('.')) {
            setErrorEmail(true)
            return
        }
        setErrorEmail(false)
        setLoading(true)
        const response = await sendRequestMail({ cpf, email, process_id: url })
        setLoading(false)
        if (response == true) {
            setCompleted(true)
            setError(false)
        } else {
            setCompleted(true)
            setError(true)
        }
    }

    const novaTentativa = () => {
        setCompleted(false)
        setError(false)
        setCpf('')
        setEmail('')
    }


    useEffect(() => {
        setCompleted(false)
        setLoading(false)
        setError(false)
        setCpf('')
        setEmail('')

        return () => {
            setCompleted(false)
            setLoading(false)
            setError(false)
            setCpf('')
            setEmail('')
        }
    }, [])


    return (
        <>
            <div className="flex flex-1 flex-col  bg-white">
                {/* Header */}
                <header class="flex justify-center w-full bg-gradient-to-r from-blue-700 via-blue-400 to-blue-700 fixed " >
                    <div class="mx-auto max-w-7xl overflow-hidden p-5">
                        <img class="block h-16 w-auto" src={`${jsonConfig.urlAplication}/logo-sinprev-resp.png`} alt="Sinprev" />
                    </div>
                </header>
            </div>

            <div class="flex flex-col items-start justify-center  ml-4 border shadow p-5 mr-12 mt-32 rounded-[10px]">
                <p class="text-xl font-semibold text-gray-800">Seja bem-vindo(a), para acessar os documentos, siga as instruções abaixo</p>
            </div>
            <div class="flex flex-col w-full items-center justify-center">
                <div class="flex flex-col items-start justify-center mt-16 border rounded-[10px] w-[95%] p-4 shadow ">
                    <p class="text-xl font-semibold text-gray-800">Preencha abaixo com seu cpf e um e-mail para recebimento </p>
                    {
                        loading && <SpinnerOverlay />
                    }
                    {
                        !loading && !completed && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center w-full">
                                    <div class="flex flex-col items-start justify-center w-full">
                                        <label for="cpf" class="text-md font-semibold text-gray-800">CPF</label>
                                        <input type="text" id="cpf" name="cpf" class="w-full border rounded-[10px] p-2" placeholder="Digite seu CPF" value={cpf} onChange={(e) => {
                                            const formatCpf = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                                            if (formatCpf.length > 14) return;
                                            setCpf(formatCpf)
                                        }} />
                                        {
                                            errorCpf && <p class="text-red-500 text-xs mt-2 ml-2">CPF inválido</p>
                                        }
                                    </div>
                                    <div class="flex flex-col items-start justify-center w-full mt-4">
                                        <label for="email" class="text-md font-semibold text-gray-800">E-mail</label>
                                        <input type="text" id="email" name="email" class="w-full border rounded-[10px] p-2" placeholder="Digite seu e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {
                                            errorEmail && <p class="text-red-500 text-xs mt-2 ml-2">E-mail inválido</p>
                                        }
                                    </div>
                                    <div class="flex flex-col items-start justify-center w-[50%] mt-4">
                                        <button class="bg-blue-700 text-white font-semibold p-2 rounded-[10px] w-full hover:bg-blue-600" onClick={sendEmail}>Enviar</button>
                                    </div>
                                </div>

                            </div>
                        )
                    }
                    {
                        !loading && !error && completed && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                    <div class="flex flex-col items-center justify-center w-full">
                                        <p>Solicitação de acesso aos documentos realizada com sucesso, verifique seu e-mail!</p>
                                    </div>
                                </div>

                            </div>
                        )
                    }
                    {
                        !loading && error && completed && (
                            <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                <div class="flex flex-col items-center justify-center mt-8 border rounded-[10px] sm:w-[55%] p-8 shadow hover:shadow-md ">
                                    <div class="flex flex-col items-center justify-center w-full">
                                        <p>Ops! Algo deu errado, tente novamente mais tarde</p>
                                    </div>
                                    <button class="bg-blue-700 text-white font-semibold p-2 rounded-[10px] w-full hover:bg-blue-600 mt-4" onClick={novaTentativa}>Nova solicitação</button>
                                </div>

                            </div>
                        )
                    }
                </div>

            </div>


            <footer class="flex justify-center w-full bg-gradient-to-r from-blue-700 via-blue-400 to-blue-700 mt-[3.9em]">
                <div class="mx-auto max-w-7xl overflow-hidden">
                    <p class="text-center text-xs leading-5 text-black py-8">&copy; {moment().format('YYYY')} SINPREV. Todos os direitos reservados</p>
                </div>
            </footer>
        </>
    )
}



export default ProcessosJuridico;