import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/Show'
import ModalConfirm from '../../../Components/Modal/Confirm'
import TableLogs from '../../../Components/Tabela/TabelaRelatorio'
import NavbarPanel from '../../../Components/navMenu/administrativo'


export default function RegistroCron() {
  const { states, setters } = useContext(AuthContext)
  const { dataSystemReport, dataRelatorio } = useContext(AuthContextApi)

  const callLogsData = async () => {
    const promises = [
      dataSystemReport(),
      dataRelatorio()
    ];
  
    await Promise.all(promises);
  }

  useEffect(() => {
    if (states.offsetRelatorio == 0) return
    if (states.offsetRelatorio < 0) setters.setOffsetRelatorio(0)
    if (states.dataQuery.length > 0) return
    callLogsData()
  }, [states.offsetRelatorio])

  useEffect(() => {
    setters.setOffsetRelatorio(0)
    callLogsData()
    return () => {
      setters.setDataRelatorio([])
      setters.setOffsetRelatorio(0)
      setters.setLimitSearchRelatorio(false)
      setters.setModalShowTecnico(false)
      setters.setDataSystemRelatorio([])
    }
  }, [])

  const openModalInfo = (data) => {
    setters.setRelatorio(data)
    setters.setModalShowTecnico(true)
  }

  const actionDelete = async () => {
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataRelatorio.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetRelatorio(states.offsetRelatorio + states.limitRelatorio)
        const sumOff = states.offsetRelatorio + states.limitRelatorio
        const limit = states.limitRelatorio + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataRelatorio(newData)
      }
    } else {
      if (states.limitSearchRelatorio) {
        return
      } else {
        setters.setOffsetRelatorio(states.offsetRelatorio + states.limitRelatorio)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataRelatorio.length === 0 && states.offsetRelatorio <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetRelatorio > 0 && setters.setOffsetRelatorio(states.offsetRelatorio - states.limitRelatorio)
        states.offsetRelatorio < 0 && setters.setOffsetRelatorio(0)
        const sumOff = states.offsetRelatorio > 0 ? states.offsetRelatorio - states.limitRelatorio : 0;
        const limit = sumOff + 10 > states.limitRelatorio ? sumOff + 10 : states.limitRelatorio;
        const newData = data.slice(sumOff, limit)
        setters.setDataRelatorio(newData)
      }
    } else {
      if (states.offsetRelatorio <= 0) {
        states.offsetRelatorio < 0 && setters.setOffsetRelatorio(0)
        return
      } else {
        if (states.limitSearchRelatorio) setters.setLimitSearchRelatorio(false)
        setters.setOffsetRelatorio(states.offsetRelatorio - states.limitRelatorio)
      }
    }
  };

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setRelatorio({})
  }

  const headers = ['Título', 'Descrição', 'Tipo', 'Data de Criação', 'Data de Atualização', '']
  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
          <Navbar />
          <SpinnerOverlay />
           <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
              <div className="p-4">
                <div className="px-4 sm:px-6 lg:px-8">
                  <NavbarPanel />
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Relatórios</h1>
                    <p className="mt-2 text-sm text-gray-700">
                      Informações sobre os relatórios do sistema.
                    </p>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                      {/* Card */}
                      {states.dataSystemRelatorio.map((card, index) => {
                        return (
                          <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                            <div className="p-5">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                  <dl>
                                    <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                    <dd>
                                      <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                                    </dd>
                                  </dl>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                    </div>
                    <TableLogs title='Relatórios' description='Registro dos relatórios registrados no sistema' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetRelatorio, limit: states.limitRelatorio }} dataTable={states.dataRelatorio} />
                </div>
            </div>
          </div>
          <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
      </div>
      <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.Relatorio} title='Dados de log' />
      <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do registro da Relatorio.' />
    </>
  )
}
