import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import moment from 'moment'


export default function ModalShowAcao(props) {
    useEffect(() => {
        if (props.states.modalProjetos === false) {
            props.setters.setProjetos({})
        }
    }, [props.states.modalProjetos]);





    return (
        <Transition.Root show={props.states.modalProjetos} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalProjetos(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalProjetos(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Projeto</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações do projeto - {props.states.Projetos.title}
                                    </p>
                                </div>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-4 mt-4">

                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] '>
                                            <p className="text-sm text-gray-500">Número de série:</p>
                                            <p className="text-sm text-gray-900">{props.states.Projetos.serialNumber}</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Origem do projeto:</p>
                                            <p className="text-sm text-gray-900 ml-2">  {
                                                props.states.Projetos.origin === 'dev' ? 'Equipe de desenvolvimento' : 'Equipe interna'
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-4">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                            <p className="text-sm text-gray-500">Descrição:</p>
                                            <p className="text-sm text-gray-900 ml-2">{props.states.Projetos.description}</p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-4">

                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Notas:</p>
                                            <p className="text-sm text-gray-900 ml-2">{props.states.Projetos?.notes?.replace('.', '\n')}</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-1">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Etapas:</p>
                                            <p className="text-sm text-gray-900 ml-2">  {
                                                props.states.Projetos.steps
                                            }</p>
                                        </div>

                                    </div>

                                    <div className="col-span-1 md:col-span-1">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Entrega:</p>
                                            <p className="text-sm text-gray-900 ml-2">  {
                                                props.states.Projetos.percent_completed
                                            }%</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-1">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Status:</p>
                                            <p className="text-sm text-gray-900 ml-2"> {
                                                props.states.Projetos.status === 0 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                        Solicitado
                                                    </span>
                                                ) : props.states.Projetos.status === 1 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                        Aprovado
                                                    </span>
                                                ) : props.states.Projetos.status === 2 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                        Em desenvolvimento
                                                    </span>
                                                ) : props.states.Projetos.status === 3 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                        Em teste
                                                    </span>
                                                ) : props.states.Projetos.status === 4 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Em produção
                                                    </span>
                                                ) : props.states.Projetos.status === 5 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        Cancelado
                                                    </span>
                                                ) : props.states.Projetos.status === 6 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        Reprovado
                                                    </span>
                                                ) : props.states.Projetos.status === 7 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                        Finalizado aguardando aprovação
                                                    </span>
                                                ) : props.states.Projetos.status === 8 ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Finalizado
                                                    </span>
                                                ) : (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        Sem status
                                                    </span>
                                                )
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-1">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Horas totais:</p>
                                            <p className="text-sm text-gray-900 ml-2">   {
                                                props.states.Projetos.total_hours !== null ? props.states.Projetos.total_hours : '0'
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Necessário autorização:</p>
                                            <p className="text-sm text-gray-900 ml-2">    {
                                                props.states.Projetos.requireAuth == true ? (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Sim
                                                    </span>
                                                ) : (
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        Não
                                                    </span>
                                                )
                                            }</p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Data de entrega:</p>
                                            <p className="text-sm text-gray-900 ml-2">    {
                                                moment(props.states.Projetos.delivery_date).format('DD/MM/YYYY')
                                            }</p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Data de início:</p>
                                            <p className="text-sm text-gray-900 ml-2">    {
                                                props.states.Projetos.initial_date !== null ? moment(props.states.Projetos.initial_date).format('DD/MM/YYYY HH:mm:ss') : 'Projeto não iniciado'
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Data de entrega:</p>
                                            <p className="text-sm text-gray-900 ml-2">   {
                                                props.states.Projetos.final_date !== null ? moment(props.states.Projetos.final_date).format('DD/MM/YYYY HH:mm:ss') : 'Projeto não iniciado'
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Autor:
                                                {
                                                    props.states.Projetos.user_author_email
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Criador:
                                                {
                                                    props.states.Projetos.user_created_email
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-4">
                                        <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                            <p className="text-sm text-gray-500">Responsável pela autorização:
                                                {
                                                    props.states.Projetos.require_auth == 1 ? props.states.Projetos.user_authorized_email : 'Não requer autorização'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
