import { AuthContext } from "../../../Context"
import { AuthContextApi } from "../../../Context/api"
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import Table from '../../../Components/Tabela/TabelaConciliacao'
import ModalInformacao from '../../../Components/Modal/ShowFuncefAssinatura'
import ModalConciliacao from '../../../Components/Modal/Conciliacao/modalConciliacao'

export default function Conciliacao() {
  const { states, setters } = useContext(AuthContext)
  const { dataSystemConciliacao, dataConciliate, dataExtratoUncEntry, dataExtratoUncOut, conciliacaoPart, unConciliate } = useContext(AuthContextApi)

  const [offSetSaidas, setOffSetSaidas] = useState(0)

  const loadDataConc = async () => {
    const promises = [
      dataSystemConciliacao(),
      dataExtratoUncEntry(),
      dataExtratoUncOut(),
      dataConciliate()
    ];

    await Promise.all(promises);
  }

  useEffect(() => {
    setOffSetSaidas(0)
    loadDataConc()
    return () => {
      setters.setDataConciliacao([])
      setters.setOffsetConciliacao(0)
      setters.setLimitSearchConciliacao(false)
      setters.setModalShowTecnico(false)
      setters.setDataSystemConciliacao([])
    }
  }, []);

  useEffect(() => {
    if (states.offsetConciliacao == 0) return
    loadDataConc()
  }, [states.offsetConciliacao])

  useEffect(() => {
    dataExtratoUncOut(offSetSaidas)
  }, [offSetSaidas])

  const next = async () => {
    if (states.limitSearchConciliacao) {
      return
    } else {
      setters.setOffsetConciliacao(states.offsetConciliacao + states.limitConciliacao)
    }
  }

  const previous = async () => {
    if (states.offsetConciliacao <= 0) {
      states.offsetConciliacao < 0 && setters.setOffsetConciliacao(0)
      return
    } else {
      if (states.limitSearchConciliacao) setters.setLimitSearchConciliacao(false)
      setters.setOffsetConciliacao(states.offsetConciliacao - states.limitConciliacao)
    }
  }

  const openModalConciliacaoInput = async () => {
    // //console.log(states.dataConciliacaoSaidas)
    // //console.log(states.dataConciliacaoEntradas)
    setters.setModalConc(true);
  }

  const conciliate = async () => {
    await conciliacaoPart()
  }

  useEffect(() => {
    if (states.sendCon) {
      setters.setSendConc(false)
      conciliate()
    }
  }, [states.sendConc])

  const openModalInfo = (data) => {
    setters.setConciliacao(data)
    setters.setModalShowTecnico(true)
  }

  const openModalConfirm = (data) => {
    setters.setConciliacao(data)
    setters.setConfirmModal(true)
  }

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setConciliacao({})
  }

  const action = async () => {
    setters.setConfirmModal(false)
    await unConciliate()
  }

  const headers = ['Email do usuário', 'Retornável', 'Data de criação', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">

                  <h1 className="text-base font-semibold leading-6 text-gray-900">Conciliação</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Informações sobre as contas não conciliadas.
                  </p>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                    {/* Card */}
                    {states.dataSystemConciliacao.length > 0 && states.dataSystemConciliacao.map((card, index) => (
                      <div key={card.title} className={card.value > 0 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>

                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              {
                                card.value > 0 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                              }

                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                {
                                  card.value > 0 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                }

                                <dd>
                                  {
                                    card.value > 0 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                  }
                                  {
                                    card.value > 0 && (
                                      <dt className="truncate text-sm font-medium text-gray-500">
                                        <div className='flex flex-row'>
                                          <div className="text-sm font-medium text-red-500 hover:text-red-700"><button onClick={openModalConciliacaoInput}>Verificar agora</button></div>
                                        </div>
                                      </dt>
                                    )
                                  }
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))}
                  </div>

                  <Table aprove={openModalConfirm} title='Conciliações' description='Registro das conciliações realizadas' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetConciliacao, limit: states.limitConciliacao }} dataTable={states.dataConciliacao} />

                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalConciliacao states={states} setters={setters} conciliate={conciliate} />
        <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.FuncefAssinatura} title='Visualização ampla' />
        <ModalConfirm action={action} states={states} setters={setters} closeModal={closeModal} data={states.FuncefAssinatura} title='Confirmação de aprovação' description='Confirme a aprovação do associado na funcef como assinatura' />

      </div>
    </>
  )
}
