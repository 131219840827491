import { toast } from 'react-toastify'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'


import Navbar from '../../../Components/Navbar/navbar'
import Table from '../../../Components/Tabela/TabelaPlanos'
import ModalShow from '../../../Components/Modal/ShowPlanos'
import ModalConfirm from '../../../Components/Modal/Confirm'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Modal from '../../../Components/Modal/Planos/modalPlanos'
import NavbarMenu from '../../../Components/navMenu/administrativo'


export default function Planos() {

  const { states, setters } = useContext(AuthContext)
  const { dataPlans, dataSystemPlans, searchQueryPlans, createPlan, updatePlan, deletePlans } = useContext(AuthContextApi)
  const [idSelecionado, setIdSelecionado] = useState(0)

  const callData = async () => {
    await dataPlans()
  }

  useEffect(() => {
    load()
    return () => {
      setters.setDataPlans([])
      setters.setQuerySearchPlans('')
      setters.setOffsetPlans(0)
      setters.setLimitSearchPlans(false)
      setters.setModalShowTecnico(false)
      setters.setDataQuery([])
      setters.setSystemPlans([])
    }
  }, []);

  useEffect(() => {
    if (states.dataQuery.length > 0) return
    callData()
  }, [states.offsetPlans])

  const load = async () => {
    const promises = [
      dataSystemPlans(),
      dataPlans()
    ];

    await Promise.all(promises);
  }

  const abrirModal = (id) => {
    setters.setIsEdit(true)
    setIdSelecionado(id)
    setters.setPlans({})
    setters.setModalIsOpen(true);
  };


  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataPlans.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetPlans(states.offsetPlans + states.limitPlans)
        const sumOff = states.offsetPlans + states.limitPlans
        const limit = states.limitPlans + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataPlans(newData)
      }
    } else {
      if (states.limitSearchPlans) {
        return
      } else {
        setters.setOffsetPlans(states.offsetPlans + states.limitPlans)
      }
    }

  };



  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataPlans.length === 0 && states.offsetPlans <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetPlans > 0 && setters.setOffsetPlans(states.offsetPlans - states.limitPlans)
        states.offsetPlans < 0 && setters.setOffsetPlans(0)
        const sumOff = states.offsetPlans > 0 ? states.offsetPlans - states.limitPlans : 0;
        const limit = sumOff + 10 > states.limitPlans ? sumOff + 10 : states.limitPlans;
        const newData = data.slice(sumOff, limit)
        setters.setDataPlans(newData)
      }
    } else {
      if (states.offsetPlans <= 0) {
        states.offsetPlans < 0 && setters.setOffsetPlans(0)
        return
      } else {
        if (states.limitSearchPlans) setters.setLimitSearchPlans(false)
        setters.setOffsetPlans(states.offsetPlans - states.limitPlans)
      }
    }
  };


  const openModalInfo = (associado) => {
    const { ...newJson } = associado;
    const updatedJson = { ...newJson };
    setters.setPlans(updatedJson);
    setters.setModalShowTecnico(true);

  }



  const closeModalShow = () => {
    setters.setPlans({});
    setters.setModalShowTecnico(false);
  }

  const add = () => {
    setters.setIsEdit(false)
    setters.setModalIsOpen(true);
  }


  const createPlanData = async () => {
    states.isEdit === false ? await createPlan() : await updatePlan()
  }


  const dataSearch = async () => {
    if (states.querySearchPlans !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchAssociados)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryPlans()
      }

    }
  }


  const actionConfirm = async () => {
    //Logica aqui apra confirmacao
    setters.setConfirmModal(false)
    await deletePlans()

  }

  const openDeleteModal = (associado) => {
    setters.setPlans(associado)
    setters.setConfirmModal(true)
  }

  const showTables = async (data) => {
    // setDataTable(data)
    setters.setModalTable(true)

  }

  const titleConfirm = 'Deleção de plano.'
  const descriptionConfirm = 'Ao realizar essa ação, o plano será excluído do sistema, deseja confirmar essa ação?'

  const headers = ['Título', 'Descrição', 'Tolerância', 'Frequência', 'Preço', 'Tipo', 'Data de criação', 'Data de atualização', '']


  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />

              <h1 className="text-base font-semibold leading-6 text-gray-900">Planos</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre os planos cadastrados.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemPlans.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                              {
                                card.value > 0 && (index === 3 || index === 4) && (
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row '>
                                      <div className="text-sm font-medium text-red-500 hover:text-red-200"><button onClick={() => showTables(card.data)}>Filtrar dados</button></div>
                                    </div>
                                  </dt>

                                )
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}

              </div>
              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por plano</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchPlans(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={states.querySearchPlans} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {/* Outros componentes de gráfico de pizza aqui */}
              <Table add={add} edit={abrirModal} dell={openDeleteModal} title='Planos' description='Registro dos planos do SINPREV' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetPlans, limit: states.limitPlans }} dataTable={states.dataPlans} />
            </div>
          </div>
        </div>
        <Modal states={states} setters={setters} id={idSelecionado} create={createPlanData} />
        <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.Plans} title='Visualização ampla' />
        <ModalConfirm states={states} action={actionConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
      </div>
    </>
  )
}
