import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { AuthContextApi } from '../../../Context/api';

export default function ModalAssinaturas(props) {

  const { buscarAssinaturaPorId } = useContext(AuthContextApi)

  const [metodo, setMetodo] = useState('')
  const [idAssociado, setIdAssociado] = useState('')
  const [idPlano, setIdPlano] = useState('')
  const [gateway, setGateway] = useState('')

  const [errorMetodo, setErrorMetodo] = useState(false)
  const [errorIdAssociado, setErrorIdAssociado] = useState(false)
  const [errorIdPlano, setErrorIdPlano] = useState(false)
  const [errorGateway, setErrorGateway] = useState(false)

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (props.states.modalIsOpen) {
      if (props.states.isEdit) {
        if (props.id != undefined) {
          buscarAssinatura(props.id)
        }
        if (localStorage.getItem('permission') === 3) {
          setDisabled(true)
        }
      }
    } else {
      setMetodo('')
      setIdAssociado('')
      setIdPlano('')
      setGateway('')
      setErrorMetodo(false)
      setErrorIdAssociado(false)
      setErrorIdPlano(false)
      setErrorGateway(false)

    }
  }, [props.states.modalIsOpen])

  const buscarAssinatura = async (id) => {
    try {
      // associadoFatura
      const data = await buscarAssinaturaPorId(id)
      setMetodo(data.data.method === 'bank_slip' ? 'Boleto' : 'Cartão')
      setIdPlano(data.data.plan_id)
      setGateway(data.data.dayCharge)
      props.setters.setAssociadoFatura(data.data)
      props.setters.setCodeRegister(data.data.cpf)
      if (data.data.idIuguSignature === 0 || data.data.idIuguSignature === '') {
        props.setters.setAssinatura({
          ...data.data,
          iugu: '0'
        })
      }
      if (data.data.idIuguSignature != 0 && data.data.idIuguSignature != '') {
        props.setters.setAssinatura({
          ...data.data,
          iugu: '1'
        })
      }
    }
    catch (err) {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'metodo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorMetodo(true)
      }
      else {
        if (valor === 'Selecione ..') {
          setErrorMetodo(true)
        }
        else {
          setErrorMetodo(false)
        }
      }
    }
    else if (tipo === 'idAssociado') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorIdAssociado(true)
      }
      else {
        if (valor === 'Selecione ..') {
          setErrorIdAssociado(true)
        }
        else {
          setErrorIdAssociado(false)
        }
      }
    }
    else if (tipo === 'idPlano') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorIdPlano(true)
      }
      else {
        if (valor === 'Selecione ..') {
          setErrorIdPlano(true)
        }
        else {
          setErrorIdPlano(false)
        }
      }
    }
    else if (tipo === 'gateway') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorGateway(true)
      }
      else {
        if (valor === 'Selecione ..') {
          setErrorGateway(true)
        }
        else {
          setErrorGateway(false)
        }
      }
    }


    if (props.states.isEdit) {
      props.setters.setAssinatura({
        ...props.states.Assinatura,
        status: props.states.Assinatura.status === 1 ? true : false,
        method: metodo === 'Boleto' ? "bank_slip" : "credit_card",
        associate_id: Object.keys(props.states.associadoFatura).length > 0 ? props.states.associadoFatura.id : props.states.Assinatura.associate_id,
        plan_id: idPlano,
        dayCharge: gateway
      })

    } else {
      props.setters.setAssinatura({
        method: metodo === 'Boleto' ? "bank_slip" : "credit_card",
        associate_id: idAssociado,
        plan_id: idPlano,
        dayCharge: gateway
      })
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    if (props.states.Assinatura.associate_id === '') return toast.error('Selecione um associado')
    props.submit()
  }

  const close = () => {
    props.setters.setAssociadoFatura({})
    props.setters.setAssinatura({})
    setMetodo('')
    setIdAssociado('')
    setIdPlano('')
    setGateway('')
    setErrorMetodo(false)
    setErrorIdAssociado(false)
    setErrorIdPlano(false)
    setErrorGateway(false)
    props.setters.setModalIsOpen(false)
  }

  useEffect(() => {
    // .length > 0)
    Object.keys(props.states.associadoFatura).length > 0 ? props.setters.setAssinatura({ ...props.states.Assinatura, associate_id: props.states.associadoFatura.id }) : setIdAssociado('')
  }, [props.states.associadoFatura])

  useEffect(() => {
    if (Object.keys(props.states.associadoFatura).length > 0) return
    props.setters.setAssinatura({ ...props.states.Assinatura, associate_id: idAssociado })

  }, [idAssociado])


  const formatCpf = (cpf) => {
    if (cpf != undefined) {
      cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona ponto após os primeiros 3 dígitos
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona ponto após os próximos 3 dígitos
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona traço antes dos últimos 2 dígitos
    }
    return cpf;
  }

  const clearSelect = () => {
    props.setters.setAssociadoFatura({})
    props.setters.setAssinatura({})
    props.setters.setCodeRegister('')
  }

  const formatMoney = (n) => {
    return parseFloat(n / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  const changeRegisterCode = (e) => {
    const value = e.replace(/\D/g, '')
    props.setters.setCodeRegister(value)
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Assinatura</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações da assinatura
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a assinatura.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Método
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <select
                                id="metodo"
                                name="metodo"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setMetodo(e.target.value)}
                                onBlur={() => leaveInput(metodo, 'metodo')}
                                value={
                                  metodo
                                }
                              >
                                <option>Selecione ..</option>
                                <option value='Cartão'>Cartão de crédito</option>
                                <option value='Boleto'>Boleto</option>
                              </select>
                            </div>
                          </div>
                          {
                            errorMetodo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do método.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Dia da cobrança
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                id="gateway"
                                name="gateway"
                                type="text"
                                disabled={props.states.isEdit ? true : false}
                                maxLength={2}
                                className={props.states.isEdit ? "pl-2 block w-full min-w-0 flex-1 bg-gray-200 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" : "pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"}
                                onChange={e => {
                                  if (isNaN(e.target.value)) return
                                  setGateway(e.target.value)
                                }}
                                onBlur={() => leaveInput(gateway, 'gateway')}
                                value={
                                  gateway
                                }
                              />
                            </div>
                          </div>
                          {
                            errorGateway === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do dia da cobrança.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-6">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Plano
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                              <select
                                id="plano"
                                name="plano"
                                className="pl-2  w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setIdPlano(e.target.value)}
                                onBlur={() => leaveInput(idPlano, 'idPlano')}
                                value={
                                  idPlano
                                }
                              >
                                <option>Selecione ..</option>
                                {
                                  props.states.dataPlans.map(plan => (
                                    <option value={plan.id}>
                                      {plan.title} - {
                                        plan.isFuncef === 1 ? 'Plano Funcef' : plan.idIuguPlan != 0 ? 'Plano gateway' : plan.idIuguPlan === 0 ? 'Plano interno' : null
                                      } - {formatMoney(plan.price)}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          {
                            errorIdPlano === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do plano.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-6">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Associado
                          </label>
                          <div className="mt-2 flex rounded-md shadow-sm">
                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                              <input
                                type="text"
                                name="associado"
                                id="associado"
                                className="pl-2 block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                placeholder="Digite o CPF do Associado ..."
                                value={props.states.codeRegister}
                                disabled={disabled}
                                onChange={e => changeRegisterCode(e.target.value)}
                              />
                            </div>
                            <button
                              onClick={() => props.getAssociate()}
                              disabled={disabled}
                              type="button"
                              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              <MagnifyingGlassIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              Buscar
                            </button>
                          </div>
                          {
                            errorIdAssociado === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do associado.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-6 mt-2">
                          {Object.keys(props.states.associadoFatura).length > 0 && (
                            <>
                              <div className="border border-gray-200 rounded-md flex items-center justify-between">
                                <div className="p-4">
                                  Email: {props.states.associadoFatura.email || props.states.associadoFatura.emailAssociate} <br />
                                  CPF: {formatCpf(props.states.associadoFatura.cpf)} <br />
                                  Nome: {props.states.associadoFatura.name || props.states.associadoFatura.name}
                                </div>
                                {
                                  !disabled &&
                                  <div className="p-4 mr-4 hover:bg-gray-100 rounded-md">
                                    <XMarkIcon class="h-8 w-8 text-gray-400" aria-hidden="true" onClick={clearSelect} />
                                  </div>
                                }
                              </div>
                              {errorIdAssociado === true && (
                                <p className="mt-2 ml-2 text-pink-600 text-sm">
                                  É obrigatório o preenchimento do associado.
                                </p>
                              )}
                            </>
                          )}
                        </div>



                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {
                        props.states.isEdit ? 'Editar' : 'Salvar'
                      }
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
