import { toast } from 'react-toastify'
import { AuthContext } from '../../../../Context/index'
import { useContext, useEffect } from 'react'
import { AuthContextApi } from '../../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import SpinnerOverlay from '../../../../Components/SpinnerOverlay'

import Navbar from '../../../../Components/Navbar/navbar'
import NavbarMenu from '../../../../Components/navMenu/acoes'
import Table from '../../../../Components/Tabela/TabelaAprovados'
import ModalShow from '../../../../Components/Modal/ShowAprovados'


export default function Aprovacoes() {

  const { states, setters } = useContext(AuthContext)
  const { dataAprove, dataSystemAprove, searchQueryAprove } = useContext(AuthContextApi)

  const callData = async () => {
    await dataAprove()
  }

  useEffect(() => {
    load()

    return () => {
      setters.setDataAprove([])
      setters.setQuerySearchAprove('')
      setters.setOffsetAprove(0)
      setters.setLimitSearchAprove(false)
      setters.setModalShowTecnico(false)
      setters.setSystemAprove([])
      setters.setDataQuery([])
    }
  }, []);

  useEffect(() => {
    if (states.dataQuery.length > 0) return
    callData()
  }, [states.offsetAprove])

  const load = async () => {
    const promises = [
      dataSystemAprove(),
      dataAprove()
    ];

    await Promise.all(promises);
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAprove.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetAprove(states.offsetAprove + states.limitAprove)
        const sumOff = states.offsetAprove + states.limitAprove
        const limit = states.limitAprove + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataAprove(newData)
      }
    } else {
      if (states.limitSearchAprove) {
        return
      } else {
        setters.setOffsetAprove(states.offsetAprove + states.limitAprove)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAprove.length === 0 && states.offsetAprove <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetAprove > 0 && setters.setOffsetAprove(states.offsetAprove - states.limitAprove)
        states.offsetAprove < 0 && setters.setOffsetAprove(0)
        const sumOff = states.offsetAprove > 0 ? states.offsetAprove - states.limitAprove : 0;
        const limit = sumOff + 10 > states.limitAprove ? sumOff + 10 : states.limitAprove;
        const newData = data.slice(sumOff, limit)
        setters.setDataAprove(newData)
      }
    } else {
      if (states.offsetAprove <= 0) {
        states.offsetAprove < 0 && setters.setOffsetAprove(0)
        return
      } else {
        if (states.limitSearchAprove) setters.setLimitSearchAprove(false)
        setters.setOffsetAprove(states.offsetAprove - states.limitAprove)
      }
    }
  };

  const openModalInfo = (associado) => {
    const { ...newJson } = associado;
    const updatedJson = { ...newJson };
    setters.setAprove(updatedJson);
    setters.setModalShowTecnico(true);

  }

  const closeModalShow = () => {
    setters.setAprove({});
    setters.setModalShowTecnico(false);
  }

  const dataSearch = async () => {
    if (states.querySearchAprove !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchAprove)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryAprove()
      }

    }
  }

  const showTables = async (data) => {
    setters.setModalTable(true)
  }

  const headers = ['Email do aprovador', 'Email do requerente', 'Tipo', 'Data de criação', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Aprovações</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre as ações aprovadas no sistema.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemAprove.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                              {
                                card.value > 0 && (index === 3 || index === 4) && (
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row '>
                                      <div className="text-sm font-medium text-red-500 hover:text-red-200"><button onClick={() => showTables(card.data)}>Filtrar dados</button></div>
                                    </div>
                                  </dt>

                                )
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}

              </div>
              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por aprovação</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchAprove(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={states.querySearchAprove} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500 w-[35%]" />

                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}
                      
                    >
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>

                </div>
              </div>

              <Table title='Aprovações' description='Registro das ações aprovadas no SINPREV' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetAprove, limit: states.limitAprove }} dataTable={states.dataAprove} />
            </div>
            <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.Aprove} title='Visualização ampla' />
          </div>
        </div>
      </div>
    </>
  )
}
