import Navbar from '../../../../Components/Navbar/navbar'
import ModalConfirm from '../../../../Components/Modal/Confirm'
import Modal from '../../../../Components/Modal/AssinaturaFuncef'
import NavbarPanel from '../../../../Components/navMenu/financeiroFolha'
import ModalInformacao from '../../../../Components/Modal/ShowFuncefAssinatura'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFuncefAssinatura'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'

import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { InformationCircleIcon } from '@heroicons/react/24/outline'


Chart.register(...registerables);

export default function RemessaAssinaturas() {

    const { states, setters, } = useContext(AuthContext)
    const { searchQueryAssinaturaFuncef, dataFuncefAssinatura, buscarAssociadoCpf, aproveSigature, dataSystemSignatureFuncef, dataPlans, returnSigatureFuncef, createFuncefAssinatura, deleteAssinaturaFuncef } = useContext(AuthContextApi)

    const [idDelete, setIdDelete] = useState(0)
    const [isDelete, setIsDelete] = useState('')

    const loadData = async () => {
        const promises = [
            dataSystemSignatureFuncef(),
            dataFuncefAssinatura(),
            dataPlans()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        loadData()
        setters.setOffsetFuncefAssinatura(0)
        setters.setQuerySearchFuncefAssinatura('')
        return () => {
            setters.setDataFuncefAssinatura([])
            setters.setSystemAssinaturaFuncef([])
            setters.setQuerySearchFuncefAssinatura('')
            setters.setOffsetFuncefAssinatura(0)
            setters.setLimitSearchFuncefAssinatura(false)
            setters.setModalShowTecnico(false)
            setters.setDataQuery([])
        }
    }, [])

    const callLogsData = async () => {
        await dataFuncefAssinatura()
    }

    useEffect(() => {
        if (states.dataQuery.length > 0) return
        callLogsData()
    }, [states.offsetFuncefAssinatura])



    const openModalInfo = (data) => {
        setters.setFuncefAssinatura(data)
        setters.setModalShowTecnico(true)
    }

    const aprove = (data) => {
        setIsDelete(false)
        setIdDelete(0)
        setters.setFuncefAssinatura(data)
        setters.setConfirmModal(true)
    }

    const openModalConfirm = (id) => {
        setIsDelete(true)
        setIdDelete(id)
        setters.setFuncefAssinatura('')
        setters.setConfirmModal(true)
    }

    const next = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataFuncefAssinatura.length === 0) {
                return
            } else {
                const data = states.dataQuery
                setters.setOffsetFuncefAssinatura(states.offsetFuncefAssinatura + states.limitFuncefAssinatura)
                const sumOff = states.offsetFuncefAssinatura + states.limitFuncefAssinatura
                const limit = states.limitFuncefAssinatura + sumOff
                const newData = data.slice(sumOff, limit)
                setters.setDataFuncefAssinatura(newData)
            }
        } else {
            if (states.limitSearchFuncefAssinatura) {
                return
            } else {
                setters.setOffsetFuncefAssinatura(states.offsetFuncefAssinatura + states.limitFuncefAssinatura)
            }
        }

    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataFuncefAssinatura.length === 0 && states.offsetFuncefAssinatura <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetFuncefAssinatura > 0 && setters.setOffsetFuncefAssinatura(states.offsetFuncefAssinatura - states.limitFuncefAssinatura)
                states.offsetFuncefAssinatura < 0 && setters.setOffsetFuncefAssinatura(0)
                const sumOff = states.offsetFuncefAssinatura > 0 ? states.offsetFuncefAssinatura - states.limitFuncefAssinatura : 0;
                const limit = sumOff + 10 > states.limitFuncefAssinatura ? sumOff + 10 : states.limitFuncefAssinatura;
                const newData = data.slice(sumOff, limit)
                setters.setDataFuncefAssinatura(newData)
            }
        } else {
            if (states.offsetFuncefAssinatura <= 0) {
                states.offsetFuncefAssinatura < 0 && setters.setOffsetFuncefAssinatura(0)
                return
            } else {
                if (states.limitSearchFuncefAssinatura) setters.setLimitSearchFuncefAssinatura(false)
                setters.setOffsetFuncefAssinatura(states.offsetFuncefAssinatura - states.limitFuncefAssinatura)
            }
        }
    };

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setFuncefAssinatura({})
    }

    const deleteSignature = async () => {
        await deleteAssinaturaFuncef(idDelete)
        setIdDelete(0)
        setters.setConfirmModal(false)
    }

    const headers = ['CPF', 'Email do associado', 'Status', 'Data de criação', '', '']

    const action = async () => {
        await aproveSigature()
        setters.setConfirmModal(false)
    }

    const submit = async (data) => {
        setters.setModalIsOpen(false)
        states.isEdit ? await returnSigatureFuncef() : await createFuncefAssinatura()
    }

    const returnAssinatura = (data) => {
        setters.setIsEdit(true)
        setters.setModalIsOpen(true)
        setters.setFuncefAssinatura(data)
        setters.setAssociadoFatura(data)
    }

    const addSignature = () => {
        setters.setIsEdit(false)
        setters.setModalIsOpen(true)
        setters.setFuncefAssinatura({})
        setters.setAssociadoFatura({})
    }

    const dataSearch = async () => {
        if (states.querySearchFuncefAssinatura !== '') {
            const regex = /[-\/]/;
            const isData = regex.test(states.querySearchFuncefAssinatura)
            if (isData) {
                toast.error('Não é possível pesquisar por data')
            } else {
                await searchQueryAssinaturaFuncef()
            }
        }
    }

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Remessas de assinaturas da FUNCEF</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as remessas de assinaturas da FUNCEF.
                                    </p>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                        {/* Card */}
                                        {states.systemAssinaturaFuncef.length > 0 && states.systemAssinaturaFuncef.map((card, index) => (
                                            <div key={card.title} className={card.value > 0 && index === 7 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                                <div className="p-5">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                card.value > 0 && index === 7 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                            }

                                                        </div>
                                                        <div className="ml-5 w-0 flex-1">
                                                            <dl>
                                                                <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>

                                                                <dd>
                                                                    <dt className="truncate text-sm font-medium text-gray-500">{card.value}</dt>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}


                                    </div>
                                </div>
                            </div>

                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por assinatura</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchFuncefAssinatura(e.target.value)}
                                        value={states.querySearchFuncefAssinatura} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" 
                                        onKeyDown={e => e.key === 'Enter' && dataSearch()}
                                        />

                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()} onKeyDown={e => e.key === 'Enter' && dataSearch()}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <TableFinanceiro add={addSignature} returnAss={returnAssinatura} aprove={aprove} title='Remessas das assinaturas da FUNCEF' description='Registro das remessas de assinaturas da FUNCEF' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetFuncefAssinatura, limit: states.limitFuncefAssinatura }} dataTable={states.dataFuncefAssinatura} />

                        </div>
                    </div>
                </div>

                <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.FuncefAssinatura} title='Visualização ampla' />
                {/* OLD */}

                {isDelete ?
                    <ModalConfirm action={deleteSignature} data={idDelete} title='Confirmação de deleção' description='Confirme a deleção da assinatura' states={states} setters={setters} closeModal={closeModal} />
                    :
                    <ModalConfirm action={action} data={states.FuncefAssinatura} title='Confirmação de aprovação' description='Confirme a aprovação do associado na funcef como assinatura' states={states} setters={setters} closeModal={closeModal} />
                }

                <Modal submit={submit} getAssociate={buscarAssociadoCpf} states={states} setters={setters} />

            </div>
        </>
    )
}