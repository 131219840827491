import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/Hooks'
import NavbarPanel from '../../../Components/navMenu/tecnico'
import TabelaHooks from '../../../Components/Tabela/TabelaHooks'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export default function RegistroLogs() {
    const { states, setters } = useContext(AuthContext)
    const { dataHooks,  dataSystemHooks } = useContext(AuthContextApi)

    let dataArrSys = []

    const callLogsData = async () => {
        const promises = [
            dataSystemHooks(),
            dataHooks()
            ];
        
        await Promise.all(promises);
    }

    useEffect(() => {
        callLogsData()
    }, [states.offsetHooks])

    useEffect(() => {
        setters.setOffsetHooks(0)
        return () => {
            setters.setLimitSearchHooks(false)
            setters.setHooksData([])
        }
    }, [])

    useEffect(() => {
        dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))
    }, [states.dataSystemHooks])

    const openModalInfo = (data) => {
        setters.setHooks(data)
        setters.setModalHooksIsOpen(true)
    }

    const next = async () => {
        if (states.limitSearchHooks) {
            return
        } else {
            setters.setOffsetHooks(states.offsetHooks + states.limitLog)
        }
    }

    const previous = async () => {
        if (states.offsetHooks <= 0) {
            states.offsetHooks < 0 && setters.setOffsetHooks(0)
            return
        } else {
            if (states.limitSearchHooks) setters.setLimitSearchHooks(false)
            setters.setOffsetHooks(states.offsetHooks - states.limitLog)
        }
    }

    const closeModal = () => {
        setters.setModalHooksIsOpen(false)
        setters.setHooks({})
    }

    const headers = ['Status', 'Tipo', 'Processamento', 'Data de criação', 'Data de atualização', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <SpinnerOverlay/>

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Registro dos Webhooks</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as requisições feitas em tempo real do gateway de pagamento para o sistema.
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                {states.dataSystemHooks.map((card, index) => {
                                    return (
                                        <div key={card.value} className="verflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {  index === 0 ?
                                                                           <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                        :  index === 1 ?   <InformationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                                                        :                  <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> 
                                                        }
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                                            <dd>
                                                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <TabelaHooks title='WEBHOOKS' description='Registro das comunicações em tempo real' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetHooks, limit: states.limitHooks }} dataTable={states.HooksData} />

                        </div>
                    </div>
                    <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.Hooks} title='Dados do Hook' />
                 </div>
            </div>
        </>
    )
}
