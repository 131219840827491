import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Arquivo from '../../UploadComunicacaoArquivo'
import Imagem from '../../UploadComunicacaoImagem'

export default function ModalProcessos(props) {



    const [id, setId] = useState('')
    const [form, setForm] = useState({
        title: '',
        description: '',
        link: '',
        image: null,
        file: null
    })
    const [errorForm, setErrorForm] = useState({
        title: false,
        description: false,
        link: false,
        image: false,
        file: false
    })



    const [dataSelect, setDataSelect] = useState([])
    const [dataSelectLawyer, setDataSelectLawyer] = useState([])





    const onSubmitForm = async (e) => {
        e.preventDefault()
        //Caso tenha os arquivos marcados, deve verificar assim como o link 

        let error = false;
        for (const item of Object.keys(form)) {
            if (form[item] == '' || form[item] == ' ' || form[item] == undefined) {
                if (item == 'file' && checkFile == false) continue;
                if (item == 'link' && checkLink == false) continue;
                if (item == 'image' && checkImage == false) continue;
                error = true
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (error) {
            error = false
            return;
        }

        let obj;
        const isPublic = form.isPublic == 'true' ? 1 : 0
        if ((form.file !== null && Object.keys(form.file).length === 0) || (form.image !== null && Object.keys(form.image).length === 0)) {
            obj = new FormData();
            if(form.file !== null) obj.append('file', form.file)
            if(form.image !== null) obj.append('image', form.image)
            obj.append('title', form.title);
            obj.append('description', form.description);
            obj.append('link', form.link);
            if (props.states.isEdit == true) {
                if(form.file === null && props.states.NoticiasComunicacao.file !== null){
                    obj.append('deleteFile', true)
                }
                if(form.image === null && props.states.NoticiasComunicacao.image !== null){
                    obj.append('deleteImage', true)
                }
            }
        } else {
            obj = {
                title: form.title,
                description: form.description,
                link: form.link
            }
            if (props.states.isEdit == true) {
                if(form.file === null && props.states.NoticiasComunicacao.file !== null){
                    obj.deleteFile = true
                }
                if(form.image === null && props.states.NoticiasComunicacao.image !== null){
                    obj.deleteImage = true
                }
            }

        }   
        if(props.states.isEdit == true){
            const send = {form: obj, id: id}
            props.action(send)
            return
        }
        props.action(obj)
        // //console.log(obj)
    }



    useEffect(() => {
        if (props.states.modalNoticiasComunicacao == false) {
            Object.keys(form).map(item => {
                if (item == 'file' || item == 'image') {
                    setForm(e => {
                        return { ...e, [item]: null }
                    })
                }
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            setCheckFile(false)
            setCheckLink(false)
            setCheckImage(false)
            setId('')
            props.setters.setIsEdit(false)
            props.setters.setNoticiasComunicacao({})
        } else {
            if (props.states.isEdit == true) {
                setId(props.states.NoticiasComunicacao.id)
                setForm(() => {
                    return {
                        title: props.states.NoticiasComunicacao.title,
                        description: props.states.NoticiasComunicacao.description,
                        link: props.states.NoticiasComunicacao.link === 'null' ? '' : props.states.NoticiasComunicacao.link,
                        image: {name: props.states.NoticiasComunicacao.fileImage},
                        file: {name: props.states.NoticiasComunicacao.fileFile}
                    }
                })
                if (props.states.NoticiasComunicacao.image !== null) {
                    setCheckImage(true)
                }
                if (props.states.NoticiasComunicacao.file !== null) {
                    setCheckFile(true)
                }
                if (props.states.NoticiasComunicacao.link !== 'null') {
                    setCheckLink(true)
                }
            }

        }
        //Limpeza do modal ao fechar
    }, [props.states.modalNoticiasComunicacao]);




    const [checkFile, setCheckFile] = useState(false)
    const [checkLink, setCheckLink] = useState(false)
    const [checkImage, setCheckImage] = useState(false)

    const saveFile = (file) => {
        setForm( current => ({ ...current, file: file }))
    }

    const saveImage = (img) => {
        setForm( current => ({ ...current, image: img }))
    }

    // useEffect(() => {
    //     if (checkFile == false) {
    //         setForm(() => {
    //             return { ...form, file: null }
    //         })
    //     }
    // }, [checkFile])

    /* aqui deve fazer um useEffect para limpar os arquivos, caso mude a opção */

    return (
        <Transition.Root show={props.states.modalNoticiasComunicacao} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalNoticiasComunicacao(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalNoticiasComunicacao(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            {props.states.isEdit === false ? 'Cadastrar' : 'Editar'} Notícia
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            Preencha os campos abaixo para cadastrar uma nova notícia na área do associado.
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Título da Natoícia
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o título da seção'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, title: e.target.value }
                                                                })}
                                                                value={form?.title}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.title === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o do título da notícia.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 4 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Descrição da notícia
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, description: e.target.value }
                                                                })}
                                                                value={form?.description}
                                                                placeholder='Digite a descrição do seção'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    {errorForm.description === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da descrição do notícia.
                                                    </p>
                                                    }
                                                </div>
                                            </div>


                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Para inserir um link, clique ao lado
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    id="comments"
                                                                    aria-describedby="comments-description"
                                                                    name="comments"
                                                                    value={checkLink}
                                                                    checked={checkLink === true ? true : false}
                                                                    onChange={e => {
                                                                        setCheckLink(e.target.checked)
                                                                        if (e.target.checked === false) {
                                                                            setForm(() => {
                                                                                return { ...form, link: '' }
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="checkbox"
                                                                    className="focus:ring-blue-600 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    checkLink === true && (
                                                        <>
                                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                                <div className="sm:col-span-12">
                                                                    <input type='text' placeholder='Digite o link da notícia' className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                        onChange={e => setForm(() => {
                                                                            return { ...form, link: e.target.value }
                                                                        }
                                                                        )}
                                                                    />
                                                                    {
                                                                        errorForm.link === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                            É obrigatório o preenchimento do link da notícia.
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            {/* Pergnuta sobre inserção de arquivo */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Para inserir uma foto, clique ao lado
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    id="comments"
                                                                    aria-describedby="comments-description"
                                                                    name="comments"
                                                                    value={checkImage}
                                                                    checked={checkImage === true ? true : false}
                                                                    onChange={e => {
                                                                        setCheckImage(e.target.checked)
                                                                        if (e.target.checked === false) {
                                                                            setForm(() => {
                                                                                return { ...form, image: null }
                                                                            })
                                                                            setErrorForm(() => {
                                                                                return { ...errorForm, image: false }
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="checkbox"
                                                                    className="focus:ring-blue-600 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pergnuta sobre inserção de arquivo */}
                                            {
                                                checkImage === true && (
                                                    <>
                                                        <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                            <div className="sm:col-span-12">
                                                                <Imagem file={saveImage} />
                                                                <div className='flex  w-full '>
                                                                    {
                                                                        form.image && (
                                                                            <>
                                                                                <button type='button' className="mt-1 bg-blue-600 text-white rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                                    onClick={() => {
                                                                                        setForm(() => {
                                                                                            return { ...form, image: null }
                                                                                        })
                                                                                        setErrorForm(() => {
                                                                                            return { ...errorForm, image: false }
                                                                                        })
                                                                                    }
                                                                                    }
                                                                                >
                                                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                                                </button>

                                                                                <p className="mt-2 ml-2 text-green-600 text-sm">
                                                                                    Arquivo inserido com sucesso! {form.image.name || form.fileName}
                                                                                </p>
                                                                            </>
                                                                        )}
                                                                    {
                                                                        errorForm.image === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                            É obrigatório o preenchimento da imagem da notícia.
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className={
                                                checkImage === true ? "grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8 mt-24 sm:mt-6" : "grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8 mt-6"
                                            }>
                                                <div className="sm:col-span-12 flex w-full">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Para inserir um arquivo, clique ao lado
                                                    </label>
                                                    <div className="mt-1 ml-4">
                                                        {/* Checkbox */}
                                                        <div className="relative flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    id="comments"
                                                                    aria-describedby="comments-description"
                                                                    name="comments"
                                                                    value={checkFile}
                                                                    checked={checkFile === true ? true : false}
                                                                    onChange={e => {
                                                                        setCheckFile(e.target.checked)
                                                                        if (e.target.checked === false) {
                                                                            setForm(() => {
                                                                                return { ...form, file: null }
                                                                            })
                                                                            setErrorForm(() => {
                                                                                return { ...errorForm, file: false }
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="checkbox"
                                                                    className="focus:ring-blue-600 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pergnuta sobre inserção de arquivo */}
                                            {
                                                checkFile === true && (
                                                    <>
                                                        <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                            <div className="sm:col-span-12">
                                                                <Arquivo fileArquivo={saveFile} />
                                                                <div className='flex  w-full '>
                                                                    {
                                                                        form.file && (
                                                                            <>
                                                                                <button type='button' className="mt-1 bg-blue-600 text-white rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                                    onClick={() => {
                                                                                        setForm(() => {
                                                                                            return { ...form, file: null }
                                                                                        })
                                                                                        setErrorForm(() => {
                                                                                            return { ...errorForm, file: false }
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                                            </button>

                                                                    <p className="mt-2 ml-2 text-green-600 text-sm">
                                                                        Arquivo inserido com sucesso! {form.file.name || form.fileName}
                                                                    </p>
                                                                </>
                                                                        )}
                                                                {
                                                                    errorForm.file === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                        É obrigatório o preenchimento do arquivo da notícia.
                                                                    </p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                        </>
                                        )
                                            }
                                    </div>
                                </div>

                                <div className="mt-12 flex items-center justify-end gap-x-6">
                                    <button
                                        type="button"
                                        className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                        onClick={() => props.setters.setModalNoticiasComunicacao(false)}>
                                        Cancelar
                                    </button>
                                    <button
                                        // hidden ={disabled}
                                        type="submit"
                                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                        Enviar
                                    </button>
                                </div>
                            </form>

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
        </Transition.Root >
    )
}
