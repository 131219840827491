import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ModalFiltro = ({ states, setters, acao }) => {
    const [selectedFilters, setSelectedFilters] = useState({
        nome: false,
        email: false,
        cpf: false,
        cidadeNascimento: false,
        estadoNascimento: false,
        emissorRG: false,
        nomePai: false,
        nomeMãe: false,
        fundo: false,
        companhia: false,
        códigoCompanhia: false,
        nomePlanoSaúde: false,
        cidade: false,
        estado: false,
        bairro: false,
        rua: false,
        cep: false,
        complemento: false,
        dataFiliação: false,
        dataEmissãoRG: false,
        dataAniversário: false,
        dataCriação: false,
        aposentado: false,
        sexo: false,
        filiado: false,
    });
    const [dataFilter, setDataFilter] = useState([]);


    //Apos conseguir a troca do objeto, faca a validacao e envie para o backend
    const changeKey = (key, value) => {
        switch (key) {
            case 'nome':
                setDataFilter(prev => ({ ...prev, name: value }));
                break;
            case 'email':
                setDataFilter(prev => ({ ...prev, email: value }));
                break;
            case 'cidadeNascimento':
                setDataFilter(prev => ({ ...prev, hometown: value }));
                break;
            case 'estadoNascimento':
                setDataFilter(prev => ({ ...prev, homeState: value }));
                break;
            case 'emissorRG':
                setDataFilter(prev => ({ ...prev, emissorEntityRg: value }));
                break;
            case 'nomePai':
                setDataFilter(prev => ({ ...prev, fathername: value }));
                break;
            case 'nomeMae':
                setDataFilter(prev => ({ ...prev, mothername: value }));
                break;
            case 'fundo':
                setDataFilter(prev => ({ ...prev, fundo: value }));
                break;
            case 'companhia':
                setDataFilter(prev => ({ ...prev, company: value }));
                break;
            case 'codigoCompanhia':
                setDataFilter(prev => ({ ...prev, companyCode: value }));
                break;
            case 'nomePlanoSaude':
                setDataFilter(prev => ({ ...prev, nameHealthPlan: value }));
                break;
            case 'cidade':
                setDataFilter(prev => ({ ...prev, city: value }));
                break;
            case 'estado':
                setDataFilter(prev => ({ ...prev, state: value }));
                break;
            case 'bairro':
                setDataFilter(prev => ({ ...prev, neighborhood: value }));
                break;
            case 'rua':
                setDataFilter(prev => ({ ...prev, address: value }));
                break;
            case 'cep':
                setDataFilter(prev => ({ ...prev, cep: value }));
                break;
            case 'complemento':
                setDataFilter(prev => ({ ...prev, complement: value }));
                break;
            case 'dataFiliacao':
                setDataFilter(prev => ({ ...prev, dateFiliate: value }));
                break;
            case 'dataEmissaoRG':
                setDataFilter(prev => ({ ...prev, dateRg: value }));
                break;
            case 'dataAniversario':
                setDataFilter(prev => ({ ...prev, birthDate: value }));
                break;
            case 'dataCriacao':
                setDataFilter(prev => ({ ...prev, createdAt: value }));
                break;
            case 'aposentado':
                setDataFilter(prev => ({ ...prev, retired: value }));
                break;
            case 'sexo':
                setDataFilter(prev => ({ ...prev, sexo: value }));
                break;
            case 'filiado':
                setDataFilter(prev => ({ ...prev, filiate: value }));
                break;
            case 'cpf':
                setDataFilter(prev => ({ ...prev, cpf: value }));
                break;
            default:
        }
    }

    const action = () => {
        setters.setModalFiltro(false);
        acao(dataFilter);
        Object.keys(selectedFilters).map((key) => {
            setSelectedFilters(prev => ({ ...prev, [key]: false }));
        });
    }

    const close = () => {
        setters.setModalFiltro(false);
        Object.keys(selectedFilters).map((key) => {
            setSelectedFilters(prev => ({ ...prev, [key]: false }));
        });
    }

    return (
        <Transition.Root show={states.modalFiltro} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setters.setModalFiltro}>
                <div className="flex min-h-screen items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <Dialog.Panel className="w-full max-w-6xl p-4 rounded-lg bg-white shadow-xl border-2">
                            <div className='flex flex-col items-center p-5'>
                                <h1 className="text-2xl font-bold">Filtros</h1>
                                <p className="ml-2 text-gray-500">Selecione os filtros desejados e realize sua busca!</p>
                            </div>
                            <div className='flex flex-col border p-6 rounded-[15px] shadow-sm hover:shadow-md'>
                                <div className="grid sm:grid-cols-4 sm:gap-4  grid-cols-2 gap-4">
                                    {Object.entries(selectedFilters).map(([key, value]) => (
                                        <div key={key} className="p-2 border rounded bg-gray-100">
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={value}
                                                    onChange={() => setSelectedFilters(prev => ({ ...prev, [key]: !value }))}
                                                />
                                                <span className="ml-2 capitalize">{key.replace(/([A-Z])/g, ' $1').toLowerCase()}</span>
                                            </label>
                                            {value && key !== 'filiado' && key !== 'fundo' && (
                                                <input
                                                    type={
                                                        ['dataFiliação', 'dataEmissãoRG', 'dataAniversário', 'dataCriação'].includes(key)
                                                            ? 'date'
                                                            : 'text'
                                                    }
                                                    onChange={(e) => changeKey(key, e.target.value)}
                                                    placeholder={`Digite ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                                                    className="mt-2 p-2 w-full border rounded"
                                                />
                                            )}
                                            {value && key === 'filiado' && (
                                                <select
                                                    onChange={(e) => changeKey(key, e.target.value)}
                                                    className="mt-2 p-2 w-full border rounded"
                                                >
                                                    <option value="">Selecione</option>
                                                    <option value="1">Sim</option>
                                                    <option value="0">Não</option>
                                                </select>
                                            )}
                                            {value && key === 'fundo' && (
                                                <select
                                                    onChange={(e) => changeKey(key, e.target.value)}
                                                    className="mt-2 p-2 w-full border rounded"
                                                >
                                                    <option value={null}>Selecione</option>
                                                    {
                                                        states.dataFundo.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-4 flex justify-end">
                                    <button
                                        className={Object.values(selectedFilters).every((value) => !value) ? "ml-2 bg-gray-400 text-white px-4 py-2 rounded" : "ml-2 bg-blue-600 text-white px-4 py-2 rounded"}
                                        onClick={() => action()}
                                        disabled={Object.values(selectedFilters).every((value) => !value)}
                                    >
                                        Aplicar Filtros
                                    </button>
                                    <button
                                        className=" ml-2 bg-red-600 text-white px-4 py-2 rounded"
                                        onClick={() => close()}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default ModalFiltro;
