import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/comunicacao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon, PencilIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import Footer from '../../../Components/Footer/footer'
import ModalFaqCompunicacao from '../../../Components/Modal/FaqComunicacao'
import ModalConfirm from '../../../Components/Modal/Confirm/index'

export default function RegistroLogs() {
    const { states, setters } = useContext(AuthContext)
    const { putFaq, postFaq, dataFaqs, deleteFaq } = useContext(AuthContextApi)

    let dataArrSys = []

    const callLogsData = async () => {
        const promises = [
            dataFaqs()
        ];

        await Promise.all(promises);
    }



    useEffect(() => {
        if (states.dataFaqsComunicacao.length === 0) {
            callLogsData()
        }
        return () => {
            setters.setTotalFaqsComunicacao(0)
            setters.setDataFaqsComunicacao([])
            setters.setIsEdit(false)
        }
    }, [])


    const openModalAdd = () => {
        setters.setIsEdit(false)
        setters.setModalFaqComunicacao(true)
    }


    const openModalEdit = (data) => {
        setters.setIsEdit(true)
        setters.setFaqsComunicacao(data)
        setters.setModalFaqComunicacao(true)
    }


    const actionModal = async (data) => {
        if (states.isEdit) {
            await putFaq(data)
        } else {
            await postFaq(data)
        }
        setters.setModalFaqComunicacao(false)
        setters.setIsEdit(false)
    }

    const truncat = (str, length) => {
        return str.length > length ? str.substring(0, length - 3) + "..." : str;
    }

    const openModalDelete = (data) => {
        setters.setFaqsComunicacao(data)
        setters.setConfirmModal(true)
    }

    const actionModalDelete = async () => {
        await deleteFaq(states.FaqsComunicacao.id)
        setters.setFaqsComunicacao({})
        setters.setConfirmModal(false)
    }

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <SpinnerOverlay />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Registro de perguntas e respostas</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as perguntas e respostas cadastradas no sistema
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4">
                                {/* Card */}
                                <div className="verflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total de Faqs</dt>
                                                    <dd>
                                                        <div className="text-lg font-medium text-gray-900">{states.totalFaqsComunicacao}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/** Quadro de faqs */}
                            <button onClick={openModalAdd} className="flex items-center justify-center mt-4 p-2 bg-blue-600 text-white rounded-md hover:shadow-md hover:bg-blue-700">
                                <PlusIcon className="h-5 w-5" />
                            </button>

                            {
                                states.dataFaqsComunicacao.length > 0 ? (
                                    <div className="flex flex-col mt-4">
                                        {
                                            states.dataFaqsComunicacao?.map((faq, index) => {
                                                return (
                                                    <div key={faq.id} className="flex flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md m-2">
                                                        <div className="p-3">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    <InformationCircleIcon className="h-10 w-10 text-blue-600" />
                                                                </div>
                                                                <div className="ml-5 w-0 flex-1">
                                                                    <dl>
                                                                        <dt className="truncate text-sm font-medium text-gray-500">{faq.question}</dt>
                                                                        <dd>
                                                                            <div className="text-lg font-medium text-gray-900">{truncat(faq.answer, 250)}</div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-end">
                                                                <button onClick={() => openModalEdit(faq)} className="flex items-center justify-center p-2 bg-blue-600 text-white rounded-md hover:shadow-md">
                                                                    <PencilIcon className="h-5 w-5" />
                                                                </button>
                                                                <button onClick={() => openModalDelete(faq)} className="flex ml-3 items-center justify-center p-2 bg-red-600 text-white rounded-md hover:shadow-md">
                                                                    <TrashIcon className="h-5 w-5" />
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : (
                                    <div className="flex flex-col mt-4">
                                        <div className="flex flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <InformationCircleIcon className="h-10 w-10 text-blue-600" />
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">Nenhum registro encontrado</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                        <ModalFaqCompunicacao states={states} setters={setters} action={actionModal} />
                        <ModalConfirm
                        action={actionModalDelete}
                        states={states} setters={setters}  title="Deseja realmente excluir?" description={`Você deseja realmente excluir o registro de faq: ${states.FaqsComunicacao.question}`} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
