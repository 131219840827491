import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import ModalInformacao from '../../../Components/Modal/ShowEntradaSaida'
import TableFinanceiro from '../../../Components/Tabela/TabelaFinanceiroEntradaSaida'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import { Line } from 'react-chartjs-2';
import { AuthContext } from "../../../Context"
import { Chart, registerables } from 'chart.js'
import { AuthContextApi } from "../../../Context/api"
import { useContext, useEffect } from 'react'
import { BuildingLibraryIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'

Chart.register(...registerables);

export default function Balance() {
  const { states, setters, } = useContext(AuthContext)
  const { getDataGraphicInput, dataEntradas, dataSystemFinancial } = useContext(AuthContextApi)

  const loadData = async () => {
    const promises = [
      dataSystemFinancial(),
      getDataGraphicInput()
    ];
  
    await Promise.all(promises);
  }

  useEffect(() => {
    loadData()
    return () => {
      setters.setDataGraphicEntradas([])
      setters.setOffsetEntradas(0)
      setters.setDataEntradas([])
    }
  }, [])

  const data = {
    labels: states.dataGraphicEntradas.map(elements => elements.date),
    datasets: [
      {
        label: 'Valor de entrada',
        data: states.dataGraphicEntradas.map(elements => elements.entryConc),
        backgroundColor: 'rgba(63, 81, 181, 0.5)',
        borderColor: 'rgba(63, 81, 181, 1)',
        pointBackgroundColor: 'rgba(63, 81, 181, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => {
            return `$${value.toLocaleString('en-US')}`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y || 0;
            return `${label}: $${value.toLocaleString('en-US')}`;
          },
        },
      },
    },
  };

  const callLogsData = async () => {
    await dataEntradas()
  }

  useEffect(() => {
    callLogsData()
  }, [states.offsetEntradas])

  useEffect(() => {
    setters.setOffsetEntradas(0)
    setters.setLimitEntradas(10)
  }, [])

  const openModalInfo = (data) => {
    setters.setEntradas(data)
    setters.setModalShowTecnico(true)
  }

  const openModalConfirm = (data) => {
    setters.setEntradas(data)
    setters.setConfirmModal(true)
  }

  const next = async () => {
    if (states.limitSearchEntradas) {
      return
    } else {
      setters.setOffsetEntradas(states.offsetEntradas + states.limitEntradas)
    }
  }

  const previous = async () => {
    if (states.offsetEntradas <= 0) {
      states.offsetEntradas < 0 && setters.setOffsetEntradas(0)
      return
    } else {
      if (states.limitSearchEntradas) setters.setLimitSearchEntradas(false)
      states.offsetEntradas > 0 && setters.setOffsetEntradas(states.offsetEntradas - states.limitEntradas)
      states.offsetEntradas < 0 && setters.setOffsetEntradas(0)
    }
  }

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setEntradas({})
  }

  const headers = ['Valor', 'Descrição', 'Data de Entrada', 'Data de Criação', 'Data de Atualização', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />

        <SpinnerOverlay />

        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4" >
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Entradas</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Informações sobre as entradas financeiras.
                  </p>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                    {states.dataSystemAccount.length > 0 && states.dataSystemAccount.map((card, index) => {
                      if (index !== 1) return;
                      return (
                        <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                          <div className="p-5">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                {
                                  index === 0 && (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                                }
                                {
                                  index === 1 && (<ArrowDownIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                                }
                                {
                                  index === 2 && (<ArrowUpIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                                }
                              </div>
                              <div className="ml-5 w-0 flex-1">
                                <dl>
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row'>
                                      <div className="text-sm font-medium text-gray-900">{card.title}</div>
                                      <div className='ml-5 flex'>
                                        {
                                          card.percent < 0 ? (<><ArrowDownIcon className='h-3 w-3 text-red-400' /> <p className='text-red-500'>{card.percent}%</p></>) : (<><ArrowUpIcon className='h-3 w-3 text-green-400' /> <p className='text-green-500'>{card.percent}</p></>)
                                        }
                                      </div>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="text-lg font-medium text-gray-900">{card.total}</div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>

                        </div>
                      )
                    })}
                  </div>

                  <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                    <h1 className='text-xl font-semibold'>Intervalo do gráfico</h1>
                    <div className='flex md:flex-row flex-col items-center justify-center mt-2'>
                      <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                        <label htmlFor="start-date" className="mr-2">Data de início:</label>
                        <input id="start-date" type="date" onChange={e => setters.setInitialDateEntradas(e.target.value)} value={states.initialDateEntradas} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                      </div>
                      <div className="flex items-center">
                        <label htmlFor="end-date" className="mr-2">Data de término:</label>
                        <input id="end-date" type="date" onChange={e => setters.setFinalDateEntradas(e.target.value)} value={states.finalDateEntradas} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                      </div>
                      <div className="flex items-center md:ml-5 p-2">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => loadData()}>
                          Aplicar
                        </button>
                        {
                          states.isLoading && (
                            <div className="flex items-center justify-center ml-8">
                              <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 mt-4">
                    <div class="flex flex-col items-center p-5 bg-white rounded-md w-full h-full border border-1 border-gray-200">
                      <h2 class="text-xl font-bold">Evolução das entradas financeiras</h2>
                      <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                      <div style={{ height: '100%', width: '100%' }}>
                        <Line data={data} options={options} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <TableFinanceiro title='Extrato financeiro' description='Registro das entradas financeiras' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetEntradas, limit: states.limitEntradas }} dataTable={states.dataEntradas} />
              <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.Entradas} title='Visualização ampla' />

            </div>
          </div>
        </div>

      </div>
    </>
  )
}
