import { Line } from 'react-chartjs-2';
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { BuildingLibraryIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import moment from 'moment'
import Navbar from '../../../../Components/Navbar/navbar'
import NavbarPanel from '../../../../Components/navMenu/Importacao'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroImportacao'
import ModalCsv from '../../../../Components/Modal/ImportarCSV'
import ModalConfirm from '../../../../Components/Modal/Confirm'

const Importacoes = () => {
    const { states, setters, } = useContext(AuthContext)
    const { dataSystemImport, dataImport, createImport, deleteImport } = useContext(AuthContextApi)



    const loadData = async () => {
        const promises = [
            dataSystemImport(),
            dataImport()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        loadData()

        return () => {
            setters.setDataImport([])
            setters.setOffsetImport(0)
            setters.setLimitSearchImport(false)
            setters.setModalImport(false)
        }
    }, [])



    const loadDataImport = async () => {
        await dataImport();

    }
    const next = async () => {
        if (states.dataImport.length === 0) {
            return
        } else {
            setters.setOffsetImport(states.offsetImport + states.limitImport)
            loadDataImport()
        }

    };

    const previous = async () => {
        if (states.dataImport.length === 0 && states.offsetImport <= 0) {
            return
        } else {
            states.offsetImport > 0 && setters.setOffsetImport(states.offsetImport - states.limitImport)
            states.offsetImport < 0 && setters.setOffsetImport(0)
            loadDataImport()
        }

    };




    const add = () => {
        setters.setModalImport(true)
    }



    const formatToMoney = (value) => {
        const valueFormat = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        return valueFormat;
    }

    const actionModalImport = async (file) => {
        setters.setModalImport(false)
        await createImport(file)

    }

    const openModalConfirm = (data) => {
        setters.setImport(data)
        setters.setConfirmModal(true)
    }

    const action = async () => {
        await deleteImport(states.Import.id)
        setters.setImport({})
        setters.setConfirmModal(false)
    }

    const headers = ['Status', 'Total', 'Total de débitos', 'Total de créditos', 'Data de criação', 'Data de atualizações', 'Usuário', 'Ação']
    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Faturas de Cobrança</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as faturas de cobrança.
                                    </p>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                        {/* Card */}
                                        {states.dataSystemImport?.map((card, index) => {
                                            if (index == 0 || index == 1) {
                                                if ((localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2')) {
                                                    return (
                                                        <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                            <div className="p-5">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0">
                                                                        {
                                                                            (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                        }
                                                                    </div>
                                                                    <div className="ml-5 w-0 flex-1">
                                                                        <dl>
                                                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                                                <div className='flex flex-row'>
                                                                                    <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                                </div>
                                                                            </dt>
                                                                            <dd>
                                                                                {
                                                                                    index === 0 && (<div className="text-lg font-medium text-green-500">{formatToMoney(card.value / 100)}</div>)
                                                                                }
                                                                                {
                                                                                    index === 1 && (<div className="text-lg font-medium text-red-500">{formatToMoney(card.value / 100)}</div>)
                                                                                }
                                                                                {
                                                                                    index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                                }
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <div className="p-5">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    {
                                                                        (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                    }
                                                                </div>
                                                                <div className="ml-5 w-0 flex-1">
                                                                    <dl>
                                                                        <dt className="truncate text-sm font-medium text-gray-500">
                                                                            <div className='flex flex-row'>
                                                                                <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                            </div>
                                                                        </dt>
                                                                        <dd>
                                                                            {
                                                                                index === 0 && (<div className="text-lg font-medium text-green-500">{card.value}</div>)
                                                                            }
                                                                            {
                                                                                index === 1 && (<div className="text-lg font-medium text-red-500">{card.value}</div>)
                                                                            }
                                                                            {
                                                                                index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                            }
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }


                                        }

                                        )}
                                    </div>

                                </div>
                            </div>


                            <TableFinanceiro add={add} title='Importações realizadas' deleteImport={openModalConfirm}
                                description='Registro das importações financeiras' next={next} previous={previous} setters={setters} states={states} headers={headers} infoTable={{ offset: states.offsetImport, limit: states.limitImport }} dataTable={states.dataImport} />

                            <ModalConfirm states={states} setters={setters} action={action}
                                title={`Deseja realmente excluir a importação ?`}
                                description='Após a exclusão não será possível recuperar os dados'


                            />
                            <ModalCsv states={states} setters={setters} action={actionModalImport} />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export default Importacoes;