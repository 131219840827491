
import { useState, useEffect } from "react"
import UploadJuridico from "../../UploadJuridicoAutorizacao"

const AdicaoAutorizacao = ({ states, setters, adicao, isEdit, section, edit, authorization }) => {

    const [file, setFile] = useState(false)
    const [motion, setMotion] = useState({
        titulo: '',
        descricao: '',
        email: '',
        file: null
    })
    const [error, setError] = useState({
        titulo: false,
        descricao: false,
        email: false,
        file: false
    })

    const saveFile = (file) => {
        setMotion({ ...motion, file: file })
    }

    const saveMotion = () => {
        let errorProcess = false;
        for (const item of Object.keys(motion)) {
            if ((motion[item] === '' || motion[item] === ' ' || motion[item] === undefined)) {
                setError(e => {
                    return { ...e, [item]: true }
                })
                errorProcess = true
            } else {
                setError(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (errorProcess) return;
        adicao({
            title: motion.titulo,
            description: motion.descricao,
            email: motion.email,
            file: motion.file
        })
    }



    useEffect(() => {
        if (isEdit) {
            setMotion({
                titulo: authorization.title,
                descricao: authorization.description,
                email: authorization.email,
                file: authorization.file
            })
        } else {
            setMotion({ titulo: '', descricao: '', email: '', file: null })
        }
        setError({ titulo: false, descricao: false, email: false, file: false })
    }, [])

    return (
        <div className="flex w-full flex-col  p-3">
            {/* Adicao de arquivo e text */}
            <div className="flex flex-col w-full">
                <p className="text-sm font-bold text-gray-500">Adicionar autorização</p>
            </div>

            <div className="flex flex-col w-full mt-4 rounded-[10px] border p-3">
                <p className="text-sm font-bold text-gray-500">Adicionae um título para a autorização</p>
                <input
                    className="w-full border rounded-[10px] p-2 mt-1"
                    type="text"
                    placeholder="Título da autorização"
                    value={motion.titulo}
                    onChange={(e) => setMotion({ ...motion, titulo: e.target.value })} />
                {
                    error.titulo && <p className="text-sm font-bold text-red-500">Adicione um titulo para a autorização</p>
                }
                <p className="text-sm font-bold text-gray-500 mt-3">Adicionae um e-mail para a autorização</p>
                <input
                    className="w-full border rounded-[10px] p-2 mt-1"
                    type="text"
                    placeholder="E-mail para o recebimento da autorização"
                    value={motion.email}
                    onChange={(e) => setMotion({ ...motion, email: e.target.value })} />
                {
                    error.email && <p className="text-sm font-bold text-red-500">Adicione um e-mail para a autorização</p>
                }
                <p className="text-sm font-bold text-gray-500 mt-3">Adicionae uma descrição para a autorização</p>
                <textarea
                    className="w-full border rounded-[10px] p-2 mt-1"
                    type="text"
                    placeholder="Texto da seção"
                    value={motion.descricao}
                    onChange={(e) => setMotion({ ...motion, descricao: e.target.value })} />
                {
                    error.descricao && <p className="text-sm font-bold text-red-500">Adicione um descrição para a autorização</p>
                }

                <span className="mt-5" />
                <div className="flex flex-col w-full mt-1 items-center p-3">
                    {
                        motion.file &&
                        <div className="flex w-full flex-col items-end">
                            <button
                                className=" bg-blue-500 text-white rounded-full p-2 text-xs mb-5"
                                onClick={() => {
                                    setFile(false)
                                    setMotion({ ...motion, file: null })
                                }} >X</button>
                        </div>
                    }
                    <UploadJuridico file={saveFile} setFile={setters.setFile} />
                    {
                        motion.file && <p className="text-sm font-bold text-gray-500 mt-2">{motion.file.name}</p>
                    }
                </div>
                {
                    error.file && <p className="text-sm font-bold text-red-500">Adicione um arquivo para a autorização</p>
                }
                <div className="flex w-full mt-3">
                    <button
                        className="w-full bg-blue-500 text-white rounded-[10px] p-2"
                        onClick={() => saveMotion()}

                    >
                        {
                            isEdit ? 'Editar' : 'Adicionar'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}


export default AdicaoAutorizacao