import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

export default function ModalShow({ states, setters, title, data, closeModal }) {
    const [open, setOpen] = useState(true)

    const close = () => {
        closeModal()
    }

    return (
        <Transition.Root show={states.modalShowTecnico} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-0"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-blue-100 px-4 py-5 sm:px-6  items-center justify-center">
                                <h2 className="text-lg font-medium text-gray-900 text-center">{title}</h2>
                            </div>
                            <div className="px-4 py-5 sm:p-6">
                                <dl className="space-y-2">
                                    {
                                        Object.keys(data).map((key, index) => 
                                        {
                                            // 
                                            if (key !== 'id' && key !== 'block' && key !== 'role' && key !== 'emailAuth' && key !== 'isDeleted' && key !== 'created_at' && key !== 'updated_at' && key !== 'createdAt' && key !== 'updatedAt' && key !== 'status' && key !== 'isDenger') {
                                                return (
                                                    <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                        <dt className="text-sm font-medium text-gray-500">{key}:</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data[key]}</dd>
                                                    </div>
                                                )
                                            }
                                            if ((key == 'created_at' || key == 'updated_at' || key == 'createdAt' || key == 'updatedAt') && (moment(data[key]).format('DD/MM/YYYY') !== 'Invalid date')) {

                                                const title = key == 'created_at' || key == 'createdAt' ? 'Data de criação' : 'Data de atualização'

                                                return (
                                                    <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                        <dt className="text-sm font-medium text-gray-500">{title}:</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{moment(data[key]).format('DD/MM/YYYY')}</dd>
                                                    </div>
                                                )
                                            }
                                            if ((key == 'created_at' || key == 'updated_at' || key == 'createdAt' || key == 'updatedAt') && (moment(data[key]).format('DD/MM/YYYY') == 'Invalid date')) {
                                                return (
                                                    <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                        <dt className="text-sm font-medium text-gray-500">{key}:</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>Sem atualizações</dd>
                                                    </div>
                                                )
                                            }
                                            if (key == 'status') {
                                                return (
                                                    <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                        <dt className="text-sm font-medium text-gray-500">{key}:</dt>
                                                        <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word', color: data[key] == 1 ? '#0076ff' : '#ff0000' }}>{data[key] == 1 ? 'Sucesso' : 'Erro'}</dd>
                                                    </div>
                                                )
                                            }
                                            if (key == 'block') {
                                                return (
                                                    <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                        <dt className="text-sm font-medium text-gray-500">Situação:</dt>
                                                        <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word', color: data[key] == 1 ? '#0076ff' : '#ff0000' }}>{data[key] == 0 ? 'Bloqueado' : 'Liberado'}</dd>
                                                    </div>
                                                )
                                            }
                                            if (key == 'role') {
                                                return (
                                                    <div key={index} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                        <dt className="text-sm font-medium text-gray-500">Permissão:</dt>
                                                        <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word', color: data[key] == 1 ? '#0076ff' : '#ff0000' }}>{
                                                            data[key] == 0 ? 'Administrador' : data[key] == 1 ? 'Financeiro' : data[key] == 2 ? 'Administrativo' : data[key] == 3 ? 'Atendimento' : 'Técnico'
                                                        }</dd>
                                                    </div>
                                                )
                                            }
                                        })
                                    }                              
                                </dl>
                            </div>
                            <div className="bg-blue-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

