import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import Table from '../../../Components/Tabela/TabelaNegociacao'
import ModalShow from '../../../Components/Modal/ShowNegociacao'
import NavbarMenu from '../../../Components/navMenu/administrativo'
import ModalShowTable from '../../../Components/Modal/ShowTableNegociacao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import { toast } from 'react-toastify'
import { Pie, Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

Chart.register(...registerables);

export default function Negociacoes() {

  const { states, setters } = useContext(AuthContext)
  const { dataSystemNegociacao, searchQueryNegociacao, dataNegociacao, getDataGraphicNegociacao } = useContext(AuthContextApi)

  const [pieChart, setPieChart] = useState([])
  const [lineChart, setLineChart] = useState([])
  const [dataChart, setDataChart] = useState([])

  const [dataTable, setDataTable] = useState([])

  const callData = async () => {
    await dataNegociacao()
    // await getDataGraphicNegociacao()
  }

  useEffect(() => {
    load()

    return () => {
      setters.setDataNegociacao([])
      setters.setQuerySearchNegociacao('')
      setters.setOffsetNegociacao(0)
      setters.setLimitSearchNegociacao(false)
      setters.setModalShowTecnico(false)
      setters.setDataQuery([])
      setters.setSystemNegoaciacao([])
    }
  }, []);

  useEffect(() => {
    //Isso aqui atrapalha a paginacao
    callData()
  }, [states.offsetNegociacao])

  const load = async () => {
    const promises = [
      dataNegociacao(),
      dataSystemNegociacao()
    ];

    await Promise.all(promises);
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssinatura.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetAssinatura(states.offsetAssinatura + states.limitAssinatura)
        const sumOff = states.offsetAssinatura + states.limitAssinatura
        const limit = states.limitAssinatura + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataAssinatura(newData)
      }
    } else {
      if (states.limitSearchAssinatura) {
        return
      } else {
        setters.setOffsetAssinatura(states.offsetAssinatura + states.limitAssinatura)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssinatura.length === 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetAssinatura > 0 && setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
        states.offsetAssinatura < 0 && setters.setOffsetAssinatura(0)
        const sumOff = states.offsetAssinatura > 0 ? states.offsetAssinatura - states.limitAssinatura : 0;
        const limit = sumOff + 10 > states.limitAssinatura ? sumOff + 10 : states.limitAssinatura;
        const newData = data.slice(sumOff, limit)
        setters.setDataAssinatura(newData)
      }
    } else {
      if (states.offsetAssinatura <= 0) {
        states.offsetAssinatura < 0 && setters.setOffsetAssinatura(0)
        return
      } else {
        if (states.limitSearchAssinatura) setters.setLimitSearchAssinatura(false)
        setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
      }
    }
  };

  useEffect(() => {
    if (states.dataGraphicNegociacao.length === 0) {
      return
    }
    setPieChart(states.dataGraphicNegociacao.pie)
    setLineChart(states.dataGraphicNegociacao.lineCount)
    setDataChart(states.dataGraphicNegociacao.lineValue)

  }, [states.dataGraphicNegociacao])

  const data1 = {
    labels: pieChart.map(item => item.status),
    datasets: [
      {
        data: pieChart.map(item => item.items),
        backgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        hoverBackgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
        hoverBorderColor: '#ffffff',
      },
    ],
  };

  const dataFlowCash = {
    labels: lineChart.map(elements => elements.date),
    datasets: [
      {
        label: 'Total de negociações',
        data: lineChart.map(elements => elements.total),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Total de negociações pagas',
        data: dataChart.map(elements => elements.total),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255, 255, 255, 0.8)',
        fontSize: 12,
        padding: 20,
      },
    },
  };

  const dataFilter = async () => {
    await dataNegociacao()
  }

  const openModalInfo = (associado) => {
    const { ...newJson } = associado;
    const updatedJson = { ...newJson };
    setters.setAssinatura(updatedJson);
    setters.setModalShowTecnico(true);

  }

  const closeModalShow = () => {
    setters.setAssinatura({});
    setters.setModalShowTecnico(false);
  }

  const dataSearch = async () => {
    if (states.querySearchNegociacao !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchNegociacao)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryNegociacao()
      }

    }
  }

  const showTables = async (data) => {
    setDataTable(data.data)

    setters.setModalTable(true)
  }

  const openModalNegociacao = (data) => {
    setters.setNegociacao(data)
    setters.setModalTable(false)
    setters.setModalNegociacao(true)
  }

  const headers = ['Email', 'CPF', 'Descrição', 'Valor', 'Status', 'Data de expiração', 'Data de criação', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Negociações</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre as negociações.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemNegoaciacao.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === 2 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === 2 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                              {
                                card.value > 0 && (index === 2) && (
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row '>
                                      <div className="text-sm font-medium text-red-500 hover:text-red-200"><button onClick={() => showTables({ data: card.data, debit: true })}>Filtrar dados</button></div>
                                    </div>
                                  </dt>

                                )
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>

              <div className='lg:flex'>
                <div className="lg:mr-2 flex lg:w-[80%] flex-col items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                  <h2 className="text-xl font-bold">Quantidade de negociações x Valor das negociações</h2>
                  <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Line data={dataFlowCash} options={options} />
                  </div>
                </div>

                <div className="lg:ml-2 flex flex-col lg:w-[80%] items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                  <h2 className="text-xl font-bold">Negociações por status</h2>
                  <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Pie data={data1} options={options} />
                  </div>
                </div>
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Intervalo das negociações</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                  <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                    <label htmlFor="start-date" className="mr-2">Data de início:</label>
                    <input id="start-date" type="date" onChange={e => setters.setInitialDateNegociacao(e.target.value)} value={states.initialDateNegociacao} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="end-date" className="mr-2">Data de término:</label>
                    <input id="end-date" type="date" onChange={e => setters.setFinalDateNegociacao(e.target.value)} value={states.finalDateNegociacao} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataFilter()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por negociação</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchNegociacao(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={states.querySearchNegociacao} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-6 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {/* Outros componentes de gráfico de pizza aqui */}
              <Table title='Negociações' description='Registro das negociações do SINPREV' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetNegociacao, limit: states.limitNegociacao }} dataTable={states.dataNegociacao} />
            </div>
          </div>
        </div>
        <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.Assinatura} title='Visualização ampla' />
        <ModalShowTable states={states} setters={setters} signature={true} closeModal={closeModalShow} headers={headers}
          data={dataTable} negociacao={openModalNegociacao} title='Visualiazação de negociação' description='Exibição de negociações' />
      </div>
    </>
  )
}
