import { Line } from 'react-chartjs-2';
import { AuthContext } from "../../../Context"
import { Chart, registerables } from 'chart.js'
import { AuthContextApi } from "../../../Context/api"
import { useContext, useEffect, useState } from 'react'
import { BuildingLibraryIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import ModalInformacao from '../../../Components/Modal/ShowConta'
import TableFinanceiro from '../../../Components/Tabela/TabelaFinanceiroExtrato'

Chart.register(...registerables);

export default function Balance() {

  const { states, setters, } = useContext(AuthContext)
  const { dataGraficAccount, dataExtratoConc, createReportFinancial, dataSystemFinancial, createReportFinancialResult } = useContext(AuthContextApi)

  const [month, setMonth] = useState(false)

  useEffect(() => {
    setters.setOffsetContaExtrato(0)
    loadData()
    return () => {
      setters.setDataGraphicAccount([])
      setters.setInitialDateGraphicAccount(moment().format('YYYY-MM-DD'))
      setters.setFinalDateGraphicAccount(moment().format('YYYY-MM-DD'))
      setters.setInitialDateExtrato(moment().format('YYYY-MM-DD'))
      setters.setFinalDateExtrato(moment().format('YYYY-MM-DD'))
      setters.setDataContaExtrato([])
      setters.setOffsetContaExtrato(0)
      setters.setLimitSearchContaExtrato(false)
      setters.setModalShowTecnico(false)
      setters.setContaExtrato({})
    }
  }, [])

  useEffect(() => {
    if (states.offsetContaExtrato == 0) return
    callLogsData()
  }, [states.offsetContaExtrato])

  const data = {
    labels: states.dataGraphicAccount.map(elements => elements.date),
    datasets: [
      {
        label: 'Valor total da conta',
        data: states.dataGraphicAccount.map(elements => elements.valueAccount),
        backgroundColor: 'rgba(63, 81, 181, 0.5)',
        borderColor: 'rgba(63, 81, 181, 1)',
        pointBackgroundColor: 'rgba(63, 81, 181, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => {
            return `$${value.toLocaleString('en-US')}`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y || 0;
            return `${label}: $${value.toLocaleString('en-US')}`;
          },
        },
      },
    },
  };

  const dataFlowCash = {
    labels: states.dataGraphicAccount.map(elements => elements.date),
    datasets: [
      {
        label: 'Entradas',
        data: states.dataGraphicAccount.map(elements => elements.entryConc),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Saídas',
        data: states.dataGraphicAccount.map(elements => elements.outConc),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const loadData = async () => {
    const promises = [
      dataSystemFinancial(),
      dataExtratoConc()
    ];

    await Promise.all(promises);
  }

  const callLogsData = async () => {
    await dataExtratoConc()
  }

  const openModalInfo = (data) => {
    setters.setContaExtrato(data)
    setters.setModalShowTecnico(true)
  }

  const openModalConfirm = (data) => {
    setters.setContaExtrato(data)
    setters.setConfirmModal(true)
  }

  const next = async () => {
    if (!states.limitSearchContaExtrato) {
      setters.setOffsetContaExtrato(states.offsetContaExtrato + states.limitContaExtrato)
    }
  }

  const previous = async () => {
    if (states.offsetContaExtrato <= 0) {
      setters.setOffsetContaExtrato(0)
    } else {
      if (states.limitSearchContaExtrato) {
        setters.setLimitSearchContaExtrato(false)
      }
      setters.setOffsetContaExtrato(states.offsetContaExtrato - states.limitContaExtrato)
    }
  }

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setContaExtrato({})
  }

  const createReport = async () => {
    setMonth(false)
    await setters.setTitleReport('Relatório de Contas')
    await setters.setDescriptionReport(`Relatório de contas do dia ${moment(states.initialDateExtrato).format('DD/MM/YYYY')} até ${moment(states.finalDateExtrato).format('DD/MM/YYYY')}`)
  }

  const createReportMonth = async () => {
    setMonth(true)
    await setters.setTitleReport('Relatório de resultado mensal')
    await setters.setDescriptionReport(`Relatório de contas do dia ${moment(states.initialDateExtrato).format('DD/MM/YYYY')} até ${moment(states.finalDateExtrato).format('DD/MM/YYYY')}`)
  }

  const repost = async () => {
    month ? await createReportFinancialResult() : await createReportFinancial()
  }

  useEffect(() => {
    if (states.titleReport !== '' && states.descriptionReport !== '') {
      repost()
    }
  }, [states.titleReport, states.descriptionReport])

  const headers = ['Valor', 'Descrição', 'Tipo', 'Data de Entrada', 'Data de Criação', 'Data de Atualização', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <SpinnerOverlay />

                  <h1 className="text-base font-semibold leading-6 text-gray-900">Contas</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Informações sobre as contas.
                  </p>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                    {/* Card */}
                    {states.dataSystemAccount.length > 0 && states.dataSystemAccount.map((card, index) => (
                      <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              {
                                index === 0 && (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                              }
                              {
                                index === 1 && (<ArrowDownIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                              }
                              {
                                index === 2 && (<ArrowUpIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                              }
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  <div className='flex flex-row'>
                                    <div className="text-sm font-medium text-gray-900">{card.title}</div>
                                    <div className='ml-5 flex'>
                                      {
                                        card.percent < 0 ? (<>
                                          <ArrowDownIcon className='h-5 w-3 mr-2 text-red-400' /> <p className='text-red-500'>{card.percent}%</p></>) : (<><ArrowUpIcon className='h-5 w-3 mr-2 text-green-400' /> <p className='text-green-500'>{card.percent}</p></>)
                                      }
                                    </div>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{card.total}</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))}
                  </div>

                  <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                    <h1 className='text-xl font-semibold'>Intervalo do gráfico</h1>
                    <div className='flex md:flex-row flex-col items-center justify-center mt-2'>
                      <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                        <label htmlFor="start-date" className="mr-2">Data de início:</label>
                        <input id="start-date" type="date" onChange={e => setters.setInitialDateGraphicAccount(e.target.value)} value={states.initialDateGraphicAccount} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                      </div>
                      <div className="flex items-center">
                        <label htmlFor="end-date" className="mr-2">Data de término:</label>
                        <input id="end-date" type="date" onChange={e => setters.setFinalDateGraphicAccount(e.target.value)} value={states.finalDateGraphicAccount} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                      </div>
                      <div className="flex items-center md:ml-5 p-2">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => loadData()}>
                          Aplicar
                        </button>
                        {
                          states.isLoading && (
                            <div className="flex items-center justify-center ml-8">
                              <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                    <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                      <h2 class="text-xl font-bold">Evolução do valor da conta</h2>
                      <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                      <div style={{ height: '100%', width: '100%' }}>
                        <Line data={data} options={options} />
                      </div>
                    </div>

                    <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                      <h2 class="text-xl font-bold">Fluxo de caixa</h2>
                      <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                      <div style={{ height: '100%', width: '100%' }}>
                        <Line data={dataFlowCash} options={options} />
                      </div>
                    </div>
                  </div>

                  <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-8">
                    <h1 className='text-xl font-semibold'>Intervalo do extrato</h1>
                    <div className='flex md:flex-row flex-col items-center justify-center mt-2'>
                      <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                        <label htmlFor="start-date" className="mr-2">Data de início:</label>
                        <input id="start-date" type="date" onChange={e => setters.setInitialDateExtrato(e.target.value)} value={states.initialDateExtrato} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                      </div>
                      <div className="flex items-center">
                        <label htmlFor="end-date" className="mr-2">Data de término:</label>
                        <input id="end-date" type="date" onChange={e => setters.setFinalDateExtrato(e.target.value)} value={states.finalDateExtrato} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                      </div>
                      <div className="flex items-center md:ml-5 p-2">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataExtratoConc()}>
                          Aplicar
                        </button>
                        <button className="ml-5 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={() => createReport()}>
                          Gerar relatório
                        </button>
                        {
                          states.isLoading && (
                            <div className="flex items-center justify-center ml-8">
                              <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <button className="mt-6 ml-5 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={() => createReportMonth()}>
                      Gerar relatório de resultado mensal
                    </button>
                  </div>
                </div>
              </div>

              <TableFinanceiro title='Extrato financeiro' description='Registro de entradas e saídas' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetContaExtrato, limit: states.limitContaExtrato }} dataTable={states.dataContaExtrato} />

            </div>
          </div>
        </div>

      </div>
      <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.contaExtrato} title='Visualização ampla' />
    </>
  )
}
