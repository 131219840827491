import Footer from "../../Components/Footer/footer"

import { AuthContext } from "../../Context"
import { AuthContextApi } from "../../Context/api"
import { useState, useContext, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

const Login = () => {

    const hist = useNavigate()

    const {token} = useParams()

    const { states, setters } = useContext(AuthContext)
    const { forgotChangePass } = useContext(AuthContextApi)


    const [inputEmail,
        setInputEmail] = useState(true)

    const [inputPassword,
        setInputPassword] = useState(true)

            
    const action = async (e) => {
        e.preventDefault()
        if(inputEmail === true && inputPassword === true){
            await forgotChangePass()
            hist('/')
        }
    }

    useEffect(() => {
        setters.setToken(token)
    }, [])

    const leaveInput = async () => {
        if (states.password === '') {
            setInputEmail(false)
            setInputPassword(false)
        } else {
            const password = states.password;
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/;
            const isValid = regex.test(password);
            if(isValid === true)
            {
                setInputEmail(true)
                setInputPassword(true)
            }
            else
            {
                setInputEmail(false)
                setInputPassword(false)
            }
        }
    }

    const changePassword = async (e) => {
        if(states.password !== e){
            setInputPassword(false)
        }else{
            setInputPassword(true)
        }
    }


    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div className="justify-center">
                                <img
                                    className="h-[5em] w-auto"
                                    src="https://financeiro.sinprev.org.br/logo-sinprev-resp.png"
                                    alt="Sinprev logo"
                                />
                                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Redefinição de senha</h2>

                            </div>

                            <div className="mt-8">
                                <div>
                                    <div className="relative mt-6">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center text-sm">
                                            <span className="bg-white px-2 text-gray-500">Digite sua nova senha </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <form action="#" method="POST" className="space-y-6" onSubmit={action}>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                Digite sua nova senha
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="password"
                                                    autoComplete="email"
                                                    onChange={e => setters.setPassword(e.target.value)}
                                                    onBlur={leaveInput}
                                                    required
                                                    className={inputEmail === true
                                                        ? "block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gr" +
                                                        "ay-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue" +
                                                        "-500 sm:text-sm sm:leading-6"
                                                        : "block w-full rounded-md border-1 py-1.5 border border-red-500 shadow-sm ring-1 r" +
                                                        "ing-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
                                                        "focus:ring-blue-500 sm:text-sm sm:leading-6"} />
                                            </div>
                                            {
                                                inputEmail === false &&
                                                <p class="mt-2 text-pink-600 text-sm">
                                                    {/* Forneça um endereço de e-mail válido. */}
                                                    É obrigatório o preenchimento de uma senha com ao menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
                                                </p>
                                            }

                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                Confirme sua nova senha
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="password"
                                                    autoComplete="email"
                                                    onChange={e => changePassword(e.target.value)}
                                                    onBlur={leaveInput}
                                                    required
                                                    className={inputEmail === true
                                                        ? "block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gr" +
                                                        "ay-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue" +
                                                        "-500 sm:text-sm sm:leading-6"
                                                        : "block w-full rounded-md border-1 py-1.5 border border-red-500 shadow-sm ring-1 r" +
                                                        "ing-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
                                                        "focus:ring-blue-500 sm:text-sm sm:leading-6"} />
                                            </div>
                                            {
                                                 inputPassword === false &&
                                                 <p class="mt-2 text-pink-600 text-sm">
                                                     É obrigatório o preenchimento da confirmação de senha.
                                                 </p>
                                            }

                                        </div>

                                        <div>
                                            <button
                                            disabled={inputEmail === false ? true : false}
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-blue-600">
                                                {
                                                    states.isLoading === true ? (
                                                        <p>Carregando...</p>
                                                    ) : (
                                                        <p>Trocar Senha</p>
                                                    )
                                                }
                                            </button>
                                        </div>

                                        <Footer />

                                    </form>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                            alt="" />
                    </div>


                </div>

            </div>


        </>
    )
}

export default Login