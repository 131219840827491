
import NavBar from "../../../../Components/Navbar/navbar";
import SpinnerOverlay from "../../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Context";
import { AuthContextJuri } from "../../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import Footer from "../../../../Components/Footer/footer";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ArrowUturnLeftIcon, InformationCircleIcon, ArrowDownIcon } from '@heroicons/react/24/outline'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const ProcessoExibicao = () => {

    const { states, setters } = useContext(AuthContext);
    const { checkToken, getProcessosShowById
    } = useContext(AuthContextJuri);
    const { id } = useParams()

    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }
    const returnPage = () => {
        hist(-1)
    }




    const loadData = async () => {
        const resp = await getProcessosShowById(id)
        if (resp == false) {
            hist(-1)
        }
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES
    useEffect(() => {
        checkTokenLoad()
        loadData()
    }, [])




    const dowloadFile = (base64String, filename) => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64String}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const printDocument = () => {
        setters.setIsLoading(true)
        const input = document.getElementById('canva'); // Certifique-se de que este é o ID do contêiner que você deseja imprimir

        html2canvas(input, {
            scale: 3, // Você pode ajustar isso para aumentar a qualidade da imagem
            useCORS: true,
            logging: true,
            scrollY: -window.scrollY,
            scrollX: 0,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const ratio = pageWidth / canvasWidth;
            const totalHeight = canvasHeight * ratio;

            let heightLeft = totalHeight;
            let position = 0;

            // Adiciona a primeira página
            pdf.addImage(imgData, 'PNG', 0, position, pageWidth, totalHeight);

            // Continua adicionando páginas enquanto houver conteúdo
            while (heightLeft >= 0) {
                position = heightLeft - totalHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, pageWidth, totalHeight);
                heightLeft -= pageHeight;
            }

            pdf.save(`Sinprev${states.JuriLegalProcess?.caseTitle}.pdf`);
            setters.setIsLoading(false)
        });
    };// 


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="flex flex-row w-full items-between justify-between">
                        <button onClick={() => returnPage()} className="flex flex-row ml-3  text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                            <ArrowUturnLeftIcon className="h-5 w-5" />
                        </button>
                        {
                            states.JuriLegalProcess?.isPublic &&
                            <button onClick={printDocument} className="flex flex-row mr-3  text-center items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                <ArrowDownIcon className="h-5 w-5" />
                            </button>
                        }
                    </div>
                    <div className="p-4" id="canva" >
                        <div className="flex flex-col sm:flex-row border rounded-[10px] justify-between p-4 m-2 shadow">
                            <div className="flex flex-row">
                                <h1 className="ml-2 text-lg font-semibold text-gray-500">Processo</h1>
                            </div>
                            <div className="flex flex-row">
                                <h1 className="ml-2 text-lg font-semibold text-gray-500" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{states.JuriLegalProcess?.caseTitle}</h1>
                            </div>
                            <div className="flex flex-row">
                                <h1 className="ml-2 text-lg font-semibold text-gray-500">N:</h1>
                                <h1 className="ml-2 text-lg font-semibold text-gray-500">{states.JuriLegalProcess?.number}</h1>
                            </div>
                        </div>
                        <div className="flex flex-col mt-2  sm:flex-row border rounded-[10px] justify-between p-4 m-2  shadow">
                            <div className="flex flex-row">
                                <h1 className="ml-2 text-lg font-semibold text-gray-500">Descrição</h1>
                            </div>
                            <div className="flex flex-row w-[100%]">
                                <h1 className="ml-2 text-lg font-semibold text-gray-500 w-full" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                    {states.JuriLegalProcess?.caseDescription}
                                </h1>
                            </div>
                            <div className="flex flex-row" />
                        </div>
                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1 ">
                            <div className="flex flex-col w-[100%] sm:w-[33%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                <p className="font-semibold">Nome do Advogado responsável:</p>
                                <p className="font-semibold text-start" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{states.JuriLegalProcess.lawyer_name}</p>
                            </div>
                            <div className="flex flex-col w-[100%] sm:w-[33%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                <p className="font-semibold">E-mail do Advogado responsável:</p>
                                <p className="font-semibold text-start" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{states.JuriLegalProcess.lawyer_email}</p>
                            </div>
                            <div className="flex flex-col w-[100%] sm:w-[33%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                <p className="font-semibold">OAB do Advogado responsável:</p>
                                <p className="font-semibold text-start" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{states.JuriLegalProcess.lawyer_oab}</p>
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row w-full items-between justify-between p-1">
                            <div className="flex flex-col w-[100%] sm:w-[50%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                <p className="font-semibold" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Nome do escritório responsáel:</p>
                                <p className="font-semibold text-center">{states.JuriLegalProcess.lawFirm_name}</p>
                            </div>
                            <div className="flex flex-col w-[100%] sm:w-[50%] items-start border p-5 rounded-[10px] m-2 shadow" >
                                <p className="font-semibold">E-mail do escritório responsáel:</p>
                                <p className="font-semibold text-start" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{states.JuriLegalProcess.lawFirm_email}</p>
                            </div>
                        </div>
                        {/* SECÕES DO PROCESSO */}
                        {
                            states.JuriLegalProcess?.sections?.length > 0 && (
                                <>
                                    <div className="flex flex-row items-center justify-center p-1">
                                        <InformationCircleIcon className="h-6 w-6 text-gray-500" />
                                        <p className="ml-2 text-gray-500">Seções do Processo</p>
                                    </div>
                                    <div className="border rounded-[10px] shadow">
                                        {
                                            states.JuriLegalProcess?.sections?.map((item, index) => {
                                                return (
                                                    <>
                                                        {
                                                            index > 0 && (
                                                                <hr className="border-gray-300" />
                                                            )
                                                        }
                                                        <div className="flex flex-col w-full items-between justify-between p-1  m-2 p-4" key={index}>
                                                            <h1 className="ml-2 text-lg font-semibold text-gray-500" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.titleSection}:</h1>
                                                            <p className="ml-2 text-lg font-semibold text-gray-500 p-4" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.descriptionSection}</p>

                                                        </div>
                                                        {
                                                            item.file != null && (
                                                                <div className="flex flex-col w-full items-between justify-between p-1  m-2 p-4" key={index}>
                                                                    <button onClick={() => dowloadFile(item.file, item.fileName)} className="ml-2 text-sm font-semibold text-blue-500">Baixar Arquivo</button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )

                        }
                        {/* Atualizações DO Deferimentos */}
                        {
                            states.JuriLegalProcess?.motions?.length > 0 && (
                                <>

                                    <div className="flex flex-row items-center justify-center p-1 mt-4">
                                        <InformationCircleIcon className="h-6 w-6 text-gray-500" />
                                        <p className="ml-2 text-gray-500">Deferimentos do Processo</p>
                                    </div>
                                    <div className="border rounded-[10px] shadow">
                                        {
                                            states.JuriLegalProcess?.motions?.map((item, index) => {
                                                return (
                                                    <>
                                                        {
                                                            index > 0 && (
                                                                <hr className="border-gray-300" />
                                                            )
                                                        }
                                                        <div className="flex flex-col w-full items-between justify-between p-1  m-2 p-4" key={index}>
                                                            <h1 className="ml-2 text-lg font-semibold text-gray-500" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.motionType}:</h1>
                                                            <p className="ml-2 text-lg font-semibold text-gray-500 p-4" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.description}</p>
                                                            <p className="ml-2  font-semibold text-gray-500 p-4" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Data: {moment(item.filedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                                            <p className="ml-2  font-semibold text-gray-500 p-4" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Data da decisão: {
                                                                item.outcome ? moment(item.decisionDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'Aguardando decisão'}</p>
                                                            <p className="ml-2  font-semibold text-gray-500 p-4" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Decisão: {
                                                                !item.outcome ? 'Aguardando decisão' : item.outcome == 'defired' ? 'Deferido' : 'Indeferido'
                                                            }</p>



                                                        </div>
                                                        {
                                                            item.file != null && (
                                                                <div className="flex flex-col w-full items-between justify-between p-1  m-2 p-4" key={index}>
                                                                    <button onClick={() => dowloadFile(item.file, item.fileName)} className="ml-2 text-sm font-semibold text-blue-500">Baixar Arquivo</button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )

                        }
                        {/* Atualizações DO PROCESSO */}
                        {
                            states.JuriLegalProcess?.updates?.length > 0 && (
                                <>

                                    <div className="flex flex-row items-center justify-center p-1 mt-5">
                                        <InformationCircleIcon className="h-6 w-6 text-gray-500" />
                                        <p className="ml-2 text-gray-500">Atualizações do Processo</p>
                                    </div>
                                    <div className="border rounded-[10px] shadow">
                                        {
                                            states.JuriLegalProcess?.updates?.map((item, index) => {
                                                return (
                                                    <>
                                                        {
                                                            index > 0 && (
                                                                <hr className="border-gray-300" />
                                                            )
                                                        }
                                                        <div className="flex flex-col w-full items-between justify-between p-1  m-2 p-4" key={index}>
                                                            <h1 className="ml-2 text-lg font-semibold text-gray-500" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.title}:</h1>
                                                            <p className="ml-2 text-lg font-semibold text-gray-500 p-4" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{item.description}</p>

                                                        </div>
                                                        {
                                                            item.file != null && (
                                                                <div className="flex flex-col w-full items-between justify-between p-1  m-2 p-4" key={index}>
                                                                    <button onClick={() => dowloadFile(item.file, item.fileName)} className="ml-2 text-sm font-semibold text-blue-500">Baixar Arquivo</button>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )

                        }

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default ProcessoExibicao;