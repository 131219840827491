import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../Context";
import { AuthContextJuri } from "../../Context/juri";
import { AuthContextApi } from "../../Context/api";
import Cookies from 'js-cookie'
import Footer from "../../Components/Footer/footer";
import Navbar from '../../Components/Navbar/navbar';
import SpinnerOverlay from "../../Components/SpinnerOverlay";
import PanelJuri from "../../Components/PanelJuri";;


const Juridico = () => {

    const { states, setters } = useContext(AuthContext);
    const { sendCode, checkCode, checkToken, dataSystemHome } = useContext(AuthContextJuri);
    const { checkLogin } = useContext(AuthContextApi);



    const [isValid, setIsValid] = useState(false); // sera controlado por cookie ou localStorage
    const [code, setCode] = useState('');
    const [errorCode, setErrorCode] = useState(false);

    useEffect(() => {
        localStorage.getItem('tokenJuri') ? setIsValid(true) : setIsValid(false);
    }, [])


    const checkCodeMail = async (e) => {
        e.preventDefault();
        if (code == '') return setErrorCode(true);
        setErrorCode(false);
        const resp = await checkCode(code);
        if (resp == true) {
            await dataSystemHome()
        }

    }

    const sendMailCode = () => {
        const resp = sendCode();
        if (resp == true) {
            setters.setInsertCode(true)
        } else {
            setters.setInsertCode(false)
        }
    }


    //Verificação se aqui e a validação ou o painel

    useEffect(() => {
        if (states.insertCode == false) {
            setCode('')
        }
    }, [states.insertCode])


    const checkTokenLoad = async () => {
        const response = await checkLogin()
        if (response === true) {
            setters.setSigned(true)
        } else {
            localStorage.removeItem('token')
            setters.setSigned(false)
            return;
        }

        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            const resp = await checkToken()
            if (resp == true) {
                await dataSystemHome()
            }
        } else {
            setIsValid(false)
            setters.setIsSignedJuri(false)
            setCode('')
            setErrorCode(false)
            setters.setInsertCode(false)
        }
    }

    useEffect(() => {
        checkTokenLoad()
    }, [])

    useEffect(() => {
        if (states.isSignedJuri) {
            setIsValid(true)
        } else {
            setIsValid(false)
            setCode('')
            setErrorCode(false)

        }
    }, [states.isSignedJuri])

    // git add . && git commit -m "Painel personalizado" && git push

    return (
        <div className="flex flex-1 flex-col pb-16 bg-white">
            <Navbar />
            <SpinnerOverlay />
            <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                <div className="p-4">
                    <div className="px-4 sm:px-6 lg:px-8">
                        {
                            !isValid && !states.isLoading && (
                                <>
                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-1 mt-8">
                                        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-xl rounded-3xl sm:px-24 xl:py-32">
                                            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                                Bem Vindo(a) ao sistema jurídico do SINPREV
                                            </h2>
                                            <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
                                                {
                                                    states.insertCode ? 'Digite o código enviado para o seu e-mail' : 'Para acessar essa área do sistema, é necessário inserir um código que será enviado para o seu e-mail.'
                                                }
                                            </p>
                                            {
                                                states.insertCode && (
                                                    <form className="mx-auto mt-10 flex flex-col max-w-md gap-x-4 items-center" onSubmit={checkCodeMail}>
                                                        <input
                                                            id="verification-code"
                                                            name="code"
                                                            type="code"
                                                            onChange={(e) => setCode(e.target.value)}
                                                            value={code}
                                                            autoComplete="code"
                                                            className="min-w-0 w-full flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                                            placeholder="Digite o código enviado para seu e-mail"
                                                        />
                                                        {
                                                            errorCode &&
                                                            <span className="text-red-500 text-sm mt-2">Código inválido</span>
                                                        }
                                                        <button
                                                            type="submit"
                                                            className="rounded-md bg-blue-500 px-3.5 w-[80%] mt-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                                        >
                                                            Validar código
                                                        </button>
                                                    </form>
                                                )
                                            }

                                            <div className="flex justify-center mt-4 gap-x-4">
                                                <button
                                                    onClick={sendMailCode}
                                                    type="button"
                                                    className="rounded-md bg-white px-3.5 py-2.5 text-sm h-[50px] w-[300px] font-semibold text-gray-900 shadow-sm hover:bg-gray-300"
                                                >
                                                    {!states.insertCode ? 'Validar e-mail' : 'Reenviar código'}
                                                </button>
                                            </div>
                                            <svg
                                                viewBox="0 0 1024 1024"
                                                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
                                                aria-hidden="true"
                                            >
                                                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                                                <defs>
                                                    <radialGradient
                                                        id="759c1415-0410-454c-8f7c-9a820de03641"
                                                        cx={0}
                                                        cy={0}
                                                        r={1}
                                                        gradientUnits="userSpaceOnUse"
                                                        gradientTransform="translate(512 512) rotate(180) scale(512)"
                                                    >
                                                        <stop stopColor="#0091FF" /> {/* Tom de azul mais claro */}
                                                        <stop offset={1} stopColor="#0074E9" stopOpacity={0} /> {/* Tom de azul mais claro */}
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            isValid && !states.isLoading && (
                                <PanelJuri states={states} setters={setters} isValid={isValid} data={dataSystemHome} role={Cookies.get('roleJuri')} />
                            )
                        }
                    </div>
                    <div className="pt-8">
                        <Footer />
                    </div>
                </div>
            </div>
        </div >
    )

}


export default Juridico;