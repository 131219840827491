import { Link } from "react-router-dom";
import { ArrowUturnLeftIcon, Cog6ToothIcon, DocumentMagnifyingGlassIcon, CalendarDaysIcon, PhotoIcon } from '@heroicons/react/24/outline'

import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";


const FeriasIndex = () => {

    return (
        <>
        <div className="flex flex-1 flex-col pb-16 bg-white">
            <Navbar />
            <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                <div className="p-4">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                            <h1 className="text-3xl text-center font-bold">Painel Férias</h1>
                            <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as áreas de férias do sistema</p>
                        </div>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <ArrowUturnLeftIcon className="h-16 w-16 text-blue-600" />
                                    <h2 className="text-xl font-bold">Retornar</h2>
                                    <p className="text-center">Clique aqui para retornar ao painel anterior</p>
                                </Link>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/feriasIndex/feriasFuncionarios" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <PhotoIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl text-center font-bold">Férias dos Funcionários</h2>
                                    <p className="text-center">Clique nesse cartão para acessar a página dos usuários que estão de férias</p>
                                </Link>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/feriasIndex/painelFerias" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <Cog6ToothIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl font-bold text-center">Painel de Férias</h2>
                                    <p className="text-center text-center">Clique nesse cartão para acessar a página de férias dos usuários</p>
                                </Link>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/usuarioIndex/feriasIndex/painelVendaFerias" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <CalendarDaysIcon className="h-[30%] w-[30%] text-blue-600" />
                                    <h2 className="text-xl font-bold text-center">Painel de Venda das Férias</h2>
                                    <p className="text-center">Clique nesse cartão para acessar a página de venda das férias dos usuários</p>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="pt-8">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>

        </>
    )

}

export default FeriasIndex;
