import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/comunicacao'
import TabelaNoticiasComunicacao from '../../../Components/Tabela/TabelaAssociadosComunicacao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Footer from '../../../Components/Footer/footer'

export default function RegistroLogs() {
    const { states, setters } = useContext(AuthContext)
    const {    dataAssociateComunicacaoLogado,
        dataAssociateComunicacaoCpfLogado } = useContext(AuthContextApi)

    

    const callLogsData = async () => {
        const promises = [
            dataAssociateComunicacaoLogado()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        callLogsData()
    }, [states.offsetAssociateComunicacao])



    useEffect(() => {
        setters.setOffsetNoticiasComunicacao(0)
        if (states.dataAssociateComunicacao.length === 0) {
            callLogsData()
        }
        return () => {
            setters.setLimitSearchNoticiasComunicacao(false)
            setters.setDataNoticiasComunicacao([])
            setters.setTotalNoticiasComunicacao(0)
            setters.setNoticiasComunicacao({})
            setters.setAssociateComunicacao({}) 
             setters.setQuerySearchAssociados('')
        }
    }, [])



    const openModalInfo = (data) => {
        setters.setHooks(data)
        setters.setModalHooksIsOpen(true)
    }

        /*
      const [dataAssociateComunicacao, setDataAssociateComunicacao] = useState([])
    const [AssociateComunicacao, setAssociateComunicacao] = useState({})
    const [offsetAssociateComunicacao, setOffsetAssociateComunicacao] = useState(0)
    const [limitAssociateComunicacao, setLimitAssociateComunicacao] = useState(30)
    const [limitSearchAssociateComunicacao, setLimitSearchAssociateComunicacao] = useState(false)
    const [totalAssociateComunicacao, setTotalAssociateComunicacao] = useState(0)
    
    */
    const next = async () => {
        if (states.limitSearchAssociateComunicacao) {
            return
        } else {
            setters.setOffsetAssociateComunicacao(states.offsetAssociateComunicacao + states.limitAssociateComunicacao)
        }
    }

    const previous = async () => {
        if (states.offsetAssociateComunicacao <= 0) {
            states.offsetAssociateComunicacao < 0 && setters.setOffsetAssociateComunicacao(0)
            return
        } else {
            if (states.limitSearchAssociateComunicacao) setters.setLimitSearchAssociateComunicacao(false)
            setters.setOffsetAssociateComunicacao(states.offsetAssociateComunicacao - states.limitAssociateComunicacao)
        }
    }

 
    const dataSearch = async () => {
        if(states.querySearchAssociados === ''){
            await dataAssociateComunicacaoLogado()
        }else{
            await dataAssociateComunicacaoCpfLogado(states.querySearchAssociados)
            setters.setQuerySearchAssociados('')
        }
    }

    


    const headers = ['Nome','CPF','E-mail','Fundo', 'Data de criação', 'Data de atualização', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <SpinnerOverlay />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Registro dos associados logados</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os associados logados na área do associado
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                <div className="verflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total de associados online</dt>
                                                    <dd>
                                                        <div className="text-lg font-medium text-gray-900">{states.totalAssociateComunicacao}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por associado</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        setters.setQuerySearchAssociados(numericValue);
                                    }} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                                        value={states.querySearchAssociados} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <TabelaNoticiasComunicacao  title='Associados logados' description='Associados logados na área do associado' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetAssociateComunicacao, limit: states.limitAssociateComunicacao }} dataTable={states.dataAssociateComunicacao} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    )
}
