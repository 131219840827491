import moment from 'moment'
import { toast } from 'react-toastify'
import { Dialog, Transition } from '@headlessui/react'
import { AuthContextApi } from '../../../../Context/api'
import { XMarkIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Fragment, useState, useEffect, useContext } from 'react'


export default function ModalPainelFerias(props) {

  const { dataEmployeeLoadOutsourced, createFeriasPainel } = useContext(AuthContextApi)
  const [disabled, setDisabled] = useState(false)

  const [userId, setUserId] = useState('')
  const [errorUserId, setErrorUserId] = useState('')

  const [dataInicio, setDataInicio] = useState('')
  const [errorDataInicio, setErrorDataInicio] = useState(false)

  const [dataFim, setDataFim] = useState('')
  const [errorDataFim, setErrorDataFim] = useState(false)

  const [ckSale, setCkSale] = useState(false)

  const [valor, setValor] = useState('')
  const [viewValor, setViewValor] = useState('')

  const [quantidadeDias, setQuantidadeDias] = useState('')
  const [errorQuantidadeDias, setErrorQuantidadeDias] = useState(false)

  const [errorValor, setErrorValor] = useState(false)

  useEffect(() => {
    if (props.states.modalPainelFerias) 
    {
      carregaFuncionarios()
      // if(props.edit)
      // {
      // setHorarioTotal(props.data.timeTotalDay)
      // setHorarioAlmoco(props.data.lunchHour)
      // setHorarioEntrada(props.data.entry_time)
      // setHorarioSaida(props.data.exit_time)
      // setUserId(props.data.user_id)
      // }
    }
    else
    {
      clearSelect()
    }
  }, [props.states.modalPainelFerias])

  const carregaFuncionarios = async () => {
    await dataEmployeeLoadOutsourced()
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'dataInicio') 
    {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDataInicio(true)
      }
      else 
      {
        setErrorDataInicio(false)
      }
    }
    else  if (tipo === 'dataFim') 
    {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDataFim(true)
      }
      else 
      {
        setErrorDataFim(false)
      }
    }
    else if (tipo === 'userId') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorUserId(true)
      }
      else {
        if(valor == 'Selecione ..')
        {
          setErrorUserId(true)
        }
        else {
          setErrorUserId(false)
        }
      }
    }

    if(ckSale)
    {
      if (tipo === 'valor') {
        if (valor === undefined || valor === null || valor === '') {
          setErrorValor(true)
        }
        else {
          setErrorValor(false)
        }
      }
      else if(tipo === 'quantidadeDias')
      {
        if (valor === undefined || valor === null || valor === '') {
          setErrorQuantidadeDias(true)
        }
        else {
          setErrorQuantidadeDias(false)
        }
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    try{
      const objFerias = ckSale ?
      {
        "vacationStartDate": dataInicio,
        "vacationEndDate": dataFim,
        "outSource_id": userId,
        "sellDays": true,
        "totalDaysSold": quantidadeDias,
        "totalAmountSold": valor,
      }
        :
      {
        "vacationStartDate": dataInicio,
        "vacationEndDate": dataFim,
        "outSource_id": userId,
        "sellDays": false,
      }


      const retorno = await verificarStates(objFerias)
      if(retorno == true)
      {
        const response = await createFeriasPainel(objFerias)
        if(response)
        {
          props.setters.setModalPainelFerias(false)
        }
      }
      else
      {
        toast.error("Verifique os campos do formulário", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const verificarStates = async (objeto) =>
  {
    try{
      let resultado = true
      for await(const key of Object.keys(objeto))    
      {                     
        if(objeto[key] == '' || objeto[key] == ' ')
        {
          if(key == 'vacationStartDate')
          { 
            resultado = false 
            setErrorDataInicio(true)
          }
          else if(key == 'vacationEndDate')
          { 
            resultado = false 
            setErrorDataFim(true)
          }
          else if(key == 'outSource_id')
          { 
            resultado = false 
            setErrorUserId(true)
          }

          if(ckSale)
          {
            if(key == 'totalDaysSold')
            { 
              resultado = false 
              setErrorQuantidadeDias(true)
            }
            else if(key == 'totalAmountSold')
            { 
              resultado = false 
              setErrorValor(true)
            }
          }
         
        }
        else
        {
          if(key == 'vacationStartDate')
          { 
            setErrorDataInicio(false)
          }
          else if(key == 'vacationEndDate')
          { 
            setErrorDataFim(false)
          }
          else if(key == 'outSource_id')
          { 
            setErrorUserId(false)
          }

          if(ckSale)
          {
            if(key == 'totalDaysSold')
            { 
              setErrorQuantidadeDias(false)
            }
            else if(key == 'totalAmountSold')
            {
              setErrorValor(false)
            }
          }
        }
      }
      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  const clearSelect = () => {
    setUserId('')
    setErrorUserId(false)

    setDataInicio('')
    setErrorDataInicio(false)
    setDataFim('')
    setErrorDataFim(false)

    setCkSale(false)

    setValor('')
    setViewValor('')

    setQuantidadeDias('')
    setErrorQuantidadeDias(false)

    setErrorValor(false)
  }

  const handleChange = (inputValue) => {
    try {
      if (inputValue.length > 0) 
      {
        const value = inputValue.replace(/[^0-9]/g, '')
        const arrPrice = value.split('')
        if (arrPrice.length > 2) 
        {
          arrPrice.splice(-2, 0, ',')
          const valueString = arrPrice.join('')
          setViewValor(valueString)
        } 
        else {
          setViewValor(value)
        }
        setValor(value)
      }
      else 
      {
        setValor('')
        setViewValor('')
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <Transition.Root show={props.states.modalPainelFerias} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalPainelFerias(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalPainelFerias(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Configuração do Ponto</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do Ponto
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos as férias do funcionário.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-8 md:col-span-2">
                        <div className="sm:col-span-4">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Data do Início das Férias
                            </label>
                              <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                  type="date"
                                  name="date"
                                  id="date"
                                  value={dataInicio}
                                  onChange={e => setDataInicio(e.target.value)}
                                  onBlur={() => leaveInput(dataInicio, 'dataInicio')}
                                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {
                                errorDataInicio === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    É obrigatório o preenchimento da data de início das férias.
                                </p>
                              }
                        </div>

                        <div className="sm:col-span-4">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Data do Fim das Férias
                            </label>
                              <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                  type="date"
                                  name="date"
                                  id="date"
                                  value={dataFim}
                                  onChange={e => setDataFim(e.target.value)}
                                  onBlur={() => leaveInput(dataFim, 'dataFim')}
                                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {
                                errorDataFim === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    É obrigatório o preenchimento da data do fim das férias.
                                </p>
                              }
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Funcionário
                            </label>
                            <div className="flex rounded-md shadow-sm">
                              <select
                                required
                                id="funcionario"
                                name="funcionario"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setUserId(e.target.value)}
                                onBlur={() => leaveInput(userId, 'userId')}
                                value={
                                  userId
                                }
                              >
                                <option>Selecione ..</option>
                                {
                                  props.states.dataUsers.map((user) => {
                                    return (
                                      <option value={user.id}>{user.email}</option>
                                    )
                                  })
                                }
                              </select>

                            </div>
                            {
                              errorUserId === true &&
                              <p class="mt-2 ml-2 text-pink-600 text-sm">
                                É obrigatório selecionar um funcionário.
                              </p>
                            }
                        </div>

                        <div className="sm:col-span-4">
                            <div className="space-y-5 mt-10">
                              <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="comments"
                                    aria-describedby="comments-description"
                                    name="comments"
                                    type="checkbox"
                                    checked={ckSale}
                                    onChange={() => {setCkSale(!ckSale); setErrorValor(false); setValor(''); setViewValor(''); setQuantidadeDias(''); setErrorQuantidadeDias(false)} }
                                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                  <label htmlFor="comments" className="font-medium text-gray-600">
                                    Vender dias
                                  </label>
                                </div>
                              </div>
                            </div>
                        </div>

                        <div className="sm:col-span-4" hidden={!ckSale}>
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Quantidade de Dias
                            </label>
                              <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                  type="number"
                                  name="number"
                                  id="number"
                                  value={quantidadeDias}
                                  placeholder='Digite a quantidade de dias ..'
                                  onChange={e => setQuantidadeDias(e.target.value)}
                                  onBlur={() => leaveInput(quantidadeDias, 'quantidadeDias')}
                                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {
                                errorQuantidadeDias === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    É obrigatório o preenchimento da quantidade de dias.
                                </p>
                              }
                        </div>

                        <div className="sm:col-span-4" hidden={!ckSale}>
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Valor
                          </label>
                          <div className="mt-2.5">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">R$</span>
                              <input
                                type="text"
                                name="preco"
                                id="preco"
                                autoComplete="preco"
                                placeholder= "0,00"
                                value={viewValor}
                                onBlur={() => leaveInput(valor, 'valor')}
                                onChange={e => handleChange(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorValor === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do valor.
                            </p>
                          }
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalPainelFerias(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}




  

                                
                             