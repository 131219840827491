import './index.css'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { CalendarIcon, XMarkIcon, MagnifyingGlassIcon, CheckCircleIcon, ArrowPathIcon, PlusIcon, DocumentDuplicateIcon, BanknotesIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'

const TableFaturasCobranca = ({ checkFatura, add, setters, states, show, headers, dataTable, next, previous, infoTable, title, description, cancel, reembolso, baixa, changeDate }) => {

    useEffect(() => {
        return () => {
            dataTable = []
        }
    }, [])

    const nextPage = () => {
        next()
    }

    const previousPage = () => {
        previous()
    }

    const copyLinkSite = (e) => {
        var content = document.getElementById(e)
        navigator.clipboard.writeText(content.value)
            .then(() => {
                toast.success('Link copiado com sucesso!')
            })
    }

    const trGen = () => {
        if (states.isLoading) {
            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTable.length > 0 && Object.keys(dataTable[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {

            return (
                dataTable.length > 0 ? (
                    dataTable.map((logs) => {
                        const value = logs.price / 100
                        return (
                            <tr key={logs.id} className={"hover:bg-gray-200"}>
                                <input id={logs.id} type='hidden' className='hidden' value={logs.path} />
                                <td className="pl-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {value.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}
                                </td>
                                <td className="pl-2 py-4 whitespace-nowrap text-sm text-center  text-gray-900 parag">
                                    {logs.description}
                                </td>
                                {/**Tipo de fatura aqui */}
                                {
                                    (logs.idSignature === '0' && logs.description[0] !== '-') ? (
                                        <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900 parag">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                Fatura Avulsa
                                            </span>
                                        </td>
                                    ) : (
                                        <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900 parag">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Fatura de Assinatura
                                            </span>
                                        </td>
                                    )
                                }
                                <td className="px-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900 parag">
                                    {logs.name ? logs.name : 'Sem nome'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.created_at).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {moment(logs.experies_at).format('DD/MM/YYYY')}
                                </td>
                                {
                                    (logs.status == 'paid' || logs.status == 'externally_paid') && (
                                        <td className="pl-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Pago
                                            </span>
                                        </td>
                                    )
                                }
                                {
                                    logs.status == 'pending' && (
                                        <>
                                            {
                                                moment().isAfter(moment(logs.experies_at, 'YYYY-MM-DD')) ? (
                                                    <td className="pl-6  py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                            Expirado
                                                        </span>
                                                    </td>
                                                ) : (
                                                    <td className="pl-6  py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                            Pendente
                                                        </span>
                                                    </td>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    logs.status == "expired" && (
                                        <td className="pl-6  py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-yellow-800">
                                                Expirado
                                            </span>
                                        </td>
                                    )
                                }
                                {
                                    logs.status == 'canceled' && (
                                        <td className="pl-6  py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                Cancelado
                                            </span>
                                        </td>
                                    )
                                }
                                {
                                    logs.status == "refunded" && (

                                        <td className="pl-6  py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-400 text-white">
                                                Estornado
                                            </span>
                                        </td>

                                    )
                                }
                                {
                                    logs.payment == 'credit_card' && (logs.status == 'paid' || logs.status == 'externally_paid') ? (
                                        <td className="pl-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Cartão de Crédito
                                            </span>
                                        </td>
                                    ) : logs.payment == 'bank_slip' && (logs.status == 'paid' || logs.status == 'externally_paid') ? (
                                        <td className="pl-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                Boleto
                                            </span>
                                        </td>
                                    ) : (logs.status == 'paid' || logs.status == 'externally_paid') ? (
                                        <td className="pl-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                Não informado
                                            </span>
                                        </td>

                                    ) : (
                                        <td className="pl-6 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                Nao faturado
                                            </span>
                                        </td>
                                    )
                                }
                                <td className="pl-8 py-4 whitespace-nowrap text-sm text-center  text-gray-900">
                                    {logs.emailUser}
                                </td>
                                <td className="pl-8 pr-6 py-6 whitespace-nowrap text-right flex items-center space-x-2">
                                    {
                                        logs.path != '/' &&
                                        <button className="h-7 w-7 text-gray-400 hover:text-gray-600" onClick={() => copyLinkSite(logs.id)}>
                                            <DocumentDuplicateIcon className="h-7 w-7" />
                                        </button>
                                    }
                                    <button className="h-7 w-7 text-gray-400 hover:text-gray-600" onClick={() => show(logs)}>
                                        <MagnifyingGlassIcon className="h-7 w-7" />
                                    </button>
                                    {
                                        (logs.status == 'pending' && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3' || localStorage.getItem('permission') == '5')) && (

                                            <button className="h-7 w-7 text-gray-400 hover:text-gray-600" onClick={() => cancel(logs)}>
                                                <XMarkIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                    {
                                        (logs.status == 'pending' || logs.status == 'expired') && (logs.path !== '/') && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3' || localStorage.getItem('permission') == '5') && (

                                            <button className="h-7 w-7 text-gray-400 hover:text-gray-600" onClick={() => changeDate(logs)}>
                                                <CalendarIcon className="h-7 w-7" />
                                            </button>
                                        )
                                    }
                                    {

                                        (logs.status == 'pending' || logs.status == 'expired') && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') && (
                                            <>
                                                <button className="h-7 w-7 text-gray-400 hover:text-gray-600" >
                                                    <CheckCircleIcon className="h-7 w-7" onClick={() => baixa(logs)} />
                                                </button>
                                            </>
                                        )
                                    }
                                    {
                                        logs.status == 'paid' && logs.refoundAccept === true && logs.path !== '/' && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2') && (
                                            <button className="h-7 w-7 text-gray-400 hover:text-gray-600" onClick={() => reembolso(logs)}>
                                                <BanknotesIcon className="h-7 w-7" />
                                            </button>

                                        )
                                    }
                                    {
                                        logs.path != '/' &&
                                        <button className="h-7 w-7 text-gray-400 hover:text-gray-600" onClick={() => checkFatura(logs)}>
                                            <ArrowPathIcon className="h-7 w-7" />
                                        </button>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500" colSpan="5">
                            Sem registro de dados
                        </td>
                    </tr>
                )
            )

        }
    }

    const scrollContainerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const scrollTable = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // A quantidade de pixels para rolar
            const currentScroll = scrollContainerRef.current.scrollLeft;
            scrollContainerRef.current.scrollLeft = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
        }
    };
    useEffect(() => {
        const checkOverflow = () => {
            const container = scrollContainerRef.current;
            if (container) {
                const isOverflowing = container.scrollWidth > container.clientWidth;
                setShowScrollButtons(isOverflowing);
            }
        };

        // Verifica o overflow quando o componente é montado
        checkOverflow();

        // Adiciona um ouvinte para verificar o overflow quando a janela é redimensionada
        window.addEventListener('resize', checkOverflow);

        // Remove o ouvinte quando o componente é desmontado
        return () => window.removeEventListener('resize', checkOverflow);
    }, [dataTable]);

    return (
        <div className="mt-4">
            {showScrollButtons && (
                <div className="flex justify-between">
                    <button
                        onClick={() => scrollTable('left')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowLeftIcon className="h-7 w-7 text-blue-500" />
                    </button>
                    <button
                        onClick={() => scrollTable('right')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowRightIcon className="h-7 w-7 text-blue-500" />
                    </button>
                </div>
            )}
            <div className="mt-4 overflow-x-auto" ref={scrollContainerRef}>
                <div className="inline-block min-w-full  rounded-md border border-gray-200">

                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 items-center">
                            {
                                (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3' || localStorage.getItem('permission') == '5') &&
                                <button
                                    onClick={() => add()}
                                    className="flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0"
                                >
                                    <PlusIcon className="h-12 w-12" />
                                </button>
                            }
                            <div className="flex flex-col ml-4">
                                <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                <p className="text-gray-500">{description}</p>
                            </div>
                        </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">


                        <thead className="bg-gray-50">
                            <tr>
                                {
                                    headers.map((headers, index) => (
                                        <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                            {headers}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                trGen()
                            }
                        </tbody>
                    </table>
                    <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                        <div className="flex flex-1 justify-between sm:justify-start">
                            <div className="hidden sm:block mt-2">
                                <p className="text-sm text-gray-700">
                                    Mostrando registros de {infoTable.offset > 0 ? infoTable.offset : 1} a {infoTable.offset + infoTable.limit}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-1 justify-start sm:justify-end">
                            <button
                                className="border border-gray-200 relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => previousPage()}
                            >
                                Anterior
                            </button>
                            <button
                                href="#"
                                className="border border-gray-200 relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => nextPage()}
                            >
                                Próximo
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>


    )

}


export default TableFaturasCobranca;