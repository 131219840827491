import { toast } from 'react-toastify'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'


import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Table from '../../../Components/Tabela/TabelaTerceirizado'
import ModalShow from '../../../Components/Modal/ShowTerceirizado'
import NavbarMenu from '../../../Components/navMenu/administrativo'
import Modal from '../../../Components/Modal/Terceirizados/modalTerceirizados'


export default function Associados() {

  const { states, setters } = useContext(AuthContext)
  const { createTerceirizado, dataTerceirizado, dataSystemTerceirizado, searchQueryTerceirizado,
    updateTerceirizado, deleteTerceirizado, dataUsersLoadOutsourced } = useContext(AuthContextApi)
  const [idSelecionado, setIdSelecionado] = useState(0)

  const callData = async () => {
    await dataTerceirizado()
  }

  useEffect(() => {
    load()
    return () => {
      setters.setDataTerceirizado([])
      setters.setQuerySearchTerceirizado('')
      setters.setOffsetTerceirizado(0)
      setters.setLimitSearchTerceirizado(false)
      setters.setModalShowTecnico(false)
      setters.setSystemTerceirizado([])
      setters.setDataQuery([])
    }
  }, []);

  useEffect(() => {
    if (states.dataQuery.length == 0) {
      callData()
    }

  }, [states.offsetTerceirizado])

  const load = async () => {
    const promises = [
      dataSystemTerceirizado(),
      dataTerceirizado()
    ];

    await Promise.all(promises);
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {

      if (states.dataTerceirizado.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetTerceirizado(states.offsetTerceirizado + states.limitTerceirizado)
        const sumOff = states.offsetTerceirizado + states.limitTerceirizado
        const limit = states.limitTerceirizado + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataTerceirizado(newData)
      }
    } else {
      if (states.limitSearchTerceirizado) {
        return
      } else {
        setters.setOffsetTerceirizado(states.offsetTerceirizado + states.limitTerceirizado)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataTerceirizado.length === 0 && states.offsetTerceirizado <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetTerceirizado > 0 && setters.setOffsetTerceirizado(states.offsetTerceirizado - states.limitTerceirizado)
        states.offsetTerceirizado < 0 && setters.setOffsetTerceirizado(0)
        const sumOff = states.offsetTerceirizado > 0 ? states.offsetTerceirizado - states.limitTerceirizado : 0;
        const limit = sumOff + 10 > states.limitTerceirizado ? sumOff + 10 : states.limitTerceirizado;
        // 
        const newData = data.slice(sumOff, limit)
        setters.setDataTerceirizado(newData)
      }
    } else {
      if (states.offsetTerceirizado <= 0) {
        states.offsetTerceirizado < 0 && setters.setOffsetTerceirizado(0)
        return
      } else {
        if (states.limitSearchTerceirizado) setters.setLimitSearchTerceirizado(false)
        setters.setOffsetTerceirizado(states.offsetTerceirizado - states.limitTerceirizado)
      }
    }
  };

  const openModalInfo = (associado) => {
    const {  ...newJson } = associado;
    const updatedJson = { ...newJson };
    setters.setTerceirizado(updatedJson);
    setters.setModalShowTecnico(true);

  }

  const openModalConfirm = () => {

  }

  const closeModalShow = () => {
    setters.setTerceirizado({});
    setters.setModalShowTecnico(false);
  }

  const add = async () => {
    await dataUsersLoadOutsourced()
    setters.setIsEdit(false)
    setters.setTerceirizado({})
    setters.setModalIsOpen(true);
  }

  const edit = async (id) => {
    await dataUsersLoadOutsourced()
    setters.setIsEdit(true)
    setIdSelecionado(id)
    setters.setTerceirizado({})
    setters.setModalIsOpen(true);
  }

  const createTerceirizadoAction = async () => {
    setters.setModalIsOpen(false);
    states.isEdit ? await updateTerceirizado() : await createTerceirizado()
  }

  const dataSearch = async () => {
    if (states.querySearchTerceirizado !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchTerceirizado)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryTerceirizado()
      }

    }
  }

  const dell = async (data) => {
    setters.setTerceirizado(data)
    setters.setConfirmModal(true)
    //Aqui deve abrir o modal de confirmacão

  }

  const actionConfirm = async () => {
    //Logica aqui apra confirmacao
    setters.setConfirmModal(false)
    await deleteTerceirizado()

  }

  const titleConfirm = 'Exclusão de terceirizado.'
  const descriptionConfirm = 'Ao realizar essa ação, o terceirizado será excluído, confirma exclusão?'
  const headers = ['Nome', 'Telefone', 'Email', 'CPF/CNPJ', 'Tipo', 'Data de criação', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Terceirizados</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre os terceirizados do SINPREV.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemTerceirizado.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}

              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por terceirizado</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchTerceirizado(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={states.querySearchTerceirizado} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    <button className="ml-5 bg-green-500 hover:bg-green-300 text-white font-bold py-1 px-8 rounded" onClick={() => load()}>
                      Atualizar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {/* Outros componentes de gráfico de pizza aqui */}
              <Table add={add} edit={edit} form={dell} title='Terceirizados' description='Registro dos terceirizados do SINPREV' next={next} previous={previous} 
              states={states} setters={setters} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetTerceirizado, limit: states.limitTerceirizado }} dataTable={states.dataTerceirizado} />
            </div>
          </div>
        </div>
        <Modal states={states} setters={setters} action={createTerceirizadoAction} id={idSelecionado} />
        <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.Terceirizado} title='Visualização ampla' />
        <ModalConfirm states={states} action={actionConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
      </div>
    </>
  )
}
