import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Table from '../../Tabela/TabelaShowModalAssinatura'
import './index.css'
export default function ModalShow({ states, setters, title, description, headers, negociacao, get, param }) {
    const [open, setOpen] = useState(true)

    const close = () => {
        setters.setModalTable(false)
        setters.setDebitByFundo([])
    }

    // 
    const next = async () => {
        if (states.limitSearchAssinaturaCard) {
            return
        } else {
            setters.setOffsetAssinaturaCard(states.offsetAssinaturaCard + states.limitAssinaturaCard)
        }
    };
    const previous = async () => {
        if (states.offsetAssinaturaCard <= 0) {
            states.offsetAssinaturaCard < 0 && setters.setOffsetAssinaturaCard(0)
            return
        } else {
            if (states.limitSearchAssinaturaCard) setters.setLimitSearchAssinaturaCard(false)
            setters.setOffsetAssinaturaCard(states.offsetAssinaturaCard + states.limitAssinaturaCard)
        }

    };

    const load = async () => {
        await get(param)
    }

    useEffect(() => {
        if (states.offsetAssinaturaCard >= 0 && states.dataAssinaturaCard.length > 0) {
            load()
        }
    }, [states.offsetAssinaturaCard])


    return (
        <Transition.Root show={states.modalTable} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-0"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-[80%]">
                            <div className="bg-blue-100 px-4 py-5 sm:px-6  items-center justify-center">
                                <h2 className="text-lg font-medium text-gray-900 text-center">{title}</h2>
                            </div>
                            <div className="px-4 py-5 sm:p-4">
                                {
                                    states.dataAssinaturaCard?.length > 0 && (
                                        <Table negociacao={negociacao} states={states} headers={headers} dataTable={states.dataAssinaturaCard} title={title} description={description} signature={true}
                                            next={next} previous={previous}
                                        />
                                    )
                                }


                            </div>
                            <div className="bg-blue-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

