import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/Show'
import ModalConfirm from '../../../Components/Modal/Confirm'
import NavbarPanel from '../../../Components/navMenu/tecnico'
import TableLogs from '../../../Components/Tabela/TabelaTecnicoAlerta'

export default function RegistroCron() {
  const { states, setters } = useContext(AuthContext)
  const { dataCron, deleteCron, dataSystemTecAlert } = useContext(AuthContextApi)

  const callLogsData = async () => {
    const promises = [
      dataSystemTecAlert(),
      dataCron()
    ];

    await Promise.all(promises);
  }

  useEffect(() => {
    callLogsData()
  }, [states.offsetCron])

  useEffect(() => {
    setters.setOffsetCron(0)
    return () => {
      setters.setLimitSearchCron(false)
      setters.setCronData([])
    }
  }, [])

  useEffect(() => {
    dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))
  }, [states.systemTecAlert])

  const openModalInfo = (data) => {
    setters.setCron(data)
    setters.setModalShowTecnico(true)
  }

  const openModalConfirm = (data) => {
    setters.setCron(data)
    setters.setConfirmModal(true)
  }

  const actionDelete = async () => {
    setters.setConfirmModal(false)
    await deleteCron()
  }

  const next = async () => {
    if (states.limitSearchCron) {
      return
    } else {
      setters.setOffsetCron(states.offsetCron + states.limitCron)
    }
  }

  const previous = async () => {
    if (states.offsetCron <= 0) {
      states.offsetCron < 0 && setters.setOffsetCron(0)
      return
    } else {
      if (states.limitSearchCron) setters.setLimitSearchCron(false)
      setters.setOffsetCron(states.offsetCron - states.limitCron)
    }

  }

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setCron({})
  }

  let dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))
  const headers = ['Status', 'Descrição', 'Data de Criação', 'Data de Atualização', '']


  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <SpinnerOverlay />

              <h1 className="text-base font-semibold leading-6 text-gray-900">Registro de CRON</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre os registros de CRON do sistema.
              </p>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {dataArrSys?.cron.map((card, index) => {
                  return (
                    <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>

              <TableLogs title='Crons' description='Registro de crons' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetCron, limit: states.limitCron }} dataTable={states.cronData} />

            </div>
          </div>
          <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
          <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.cron} title='Dados de log' />
          <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do registro da cron.' />
        </div>
      </div>
    </>
  )
}
