import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Navbar from '../../../Components/Navbar/navbar'
import Table from '../../../Components/Tabela/TabelaTransferenciaPagSeguro'
import NavbarMenu from '../../../Components/navMenu/administrativo'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalShow from '../../../Components/Modal/ShowTransferencia'

Chart.register(...registerables);


export default function Transferencias() {

  const { states, setters } = useContext(AuthContext)
  const { getTransferencePagSeguro, dataSystemTransfer, getTransferencePagSeguroQuery, cancelSafePay } = useContext(AuthContextApi)


  const [dataFiltro, setDataFiltro] = useState({})
  const [query, setQuery] = useState('')

  const load = async () => {
    setters.setDataTableFiltroAssociados([])
    let promises = [];

    promises = [
      dataSystemTransfer(),
      getTransferencePagSeguro()
    ];

    await Promise.all(promises);
  }



  const next = async () => {
    if (states.limitSearchTransFerenciaPagSeguro) {
      return
    } else {
      setters.setOffsetTransFerenciaPagSeguro(states.offsetTransFerenciaPagSeguro + states.limitTransFerenciaPagSeguro)
    }
  };

  const previous = async () => {
    if (states.offsetTransFerenciaPagSeguro <= 0) {
      states.offsetTransFerenciaPagSeguro < 0 && setters.setOffsetTransFerenciaPagSeguro(0)
      return
    } else {
      if (states.limitSearchTransFerenciaPagSeguro) setters.setLimitSearchTransFerenciaPagSeguro(false)
      setters.setOffsetTransFerenciaPagSeguro(states.offsetTransFerenciaPagSeguro - states.limitTransFerenciaPagSeguro)
    }
  };

  const openModalShow = data => {
    setters.setTransFerenciaPagSeguro(data)
    setters.setModalTransFerenciaPagSeguro(true)
  }

  const loadNext = async () => {
    if (query === '') {
      await getTransferencePagSeguro()
    } else {
      //console.log('Aspdnas')
      await getTransferencePagSeguroQuery(query)
    }
  }

  const dataSearch = async () => {
    if (query === '') {
      setters.setOffsetTransFerenciaPagSeguro(0)
      setters.setLimitSearchTransFerenciaPagSeguro(false)
    } else {
      setters.setOffsetTransFerenciaPagSeguro(0)
      setters.setLimitSearchTransFerenciaPagSeguro(false)
    }
    loadNext()

  }


  const openModalCancel = async (data) => {
    setters.setTransFerenciaPagSeguro(data)
    setters.setConfirmModal(true)
  }

  const actionCancel = async () => {
    setters.setConfirmModal(false)
    await cancelSafePay(states.TransFerenciaPagSeguro.id)
    setters.setTransFerenciaPagSeguro({})
  }

  //COLOCAR UM BOTAO PARA LIMPAR O FILTRO E AO LIMPAR, VOLTAR PARA A TABELA ORIGINAL


  const headers = ['Status', 'Nome', 'CPF', 'Email', 'Data de início', 'Identificador do pagseguro', 'Data de criação', '']

  useEffect(() => {
    load()
    return () => {
      setQuery('')
      setters.setDataSystemTransFerenciaPagSeguro([])
      setters.setDataTransFerenciaPagSeguro([])
      setters.setTransFerenciaPagSeguro({})
      setters.setOffsetTransFerenciaPagSeguro(0)
      setters.setLimitSearchTransFerenciaPagSeguro(false)

    }
  }, []);

  useEffect(() => {
    if (!states.limitSearchTransFerenciaPagSeguro) {
      loadNext()
    }

  }, [states.offsetTransFerenciaPagSeguro])



  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Pag Seguro</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre as transferencias dos associados do pagseguro
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.dataSystemTransFerenciaPagSeguro.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>

                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por associado</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Busque por nome, e-mail ou cpf' type="text" onChange={e => {
                    setQuery(e.target.value);
                  }} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={query} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {
                states.dataTableFiltroAssociados.length == 0 &&
                <Table title='Transferêmcias' description='Registro das transferencias do Pag Seguro' show={openModalShow} cancel={openModalCancel} next={next} previous={previous} states={states} setters={setters} headers={headers} infoTable={{ offset: states.offsetTransFerenciaPagSeguro, limit: states.limitTransFerenciaPagSeguro }} dataTable={states.dataTransFerenciaPagSeguro} />
              }
            </div>
          </div>

        </div>
      </div>
      <ModalConfirm title={'Deseja cancelar a assinatura do pagseguro'} description={'Confirme que o associado tenha uma assinatura na VINDI'} action={actionCancel} states={states} setters={setters} />
      <ModalShow states={states} setters={setters} />
    </>
  )
}