import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/Show'
import NavbarPanel from '../../../Components/navMenu/alerta'
import ModalConfirm from '../../../Components/Modal/Confirm'
import Table from '../../../Components/Tabela/TabelaTecnicoAlerta'


export default function Alertas() {
    const { states, setters } = useContext(AuthContext)
    const { dataAlert, deleteAlert } = useContext(AuthContextApi)

    const callLogsData = async () => {
        await dataAlert()
    }

    useEffect(() => {
        callLogsData()
    }, [states.offsetAlert])

    useEffect(() => {
        setters.setOffsetAlert(0)

        return () => {
            setters.setAlertData([])
            setters.setOffsetAlert(0)
            setters.setLimitSearchAlert(false)
            setters.setModalShowTecnico(false)
        }
    }, [])

    const openModalInfo = (data) => {
        setters.setAlert(data)
        setters.setModalShowTecnico(true)
    }

    const openModalConfirm = (data) => {
        setters.setAlert(data)
        setters.setConfirmModal(true)
    }

    const actionDelete = async () => {
        setters.setConfirmModal(false)
        await deleteAlert()
    }

    const next = async () => {
        if (states.limitSearchAlert) {
            return
        } else {
            setters.setOffsetAlert(states.offsetAlert + states.limitAlert)
        }
    }

    const previous = async () => {
        if (states.offsetAlert <= 0) {
            states.offsetAlert < 0 && setters.setOffsetAlert(0)
            return
        } else {
            if (states.limitSearchAlert) setters.setLimitSearchAlert(false)
            setters.setOffsetAlert(states.offsetAlert - states.limitAlert)
        }
    }

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setAlert({})
    }

    const dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))

    const headers = ['Classificação', 'Título', 'Descrição', 'Data de Criação', 'Data de Atualização', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Alertas</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os alertas gerais do sistema.
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                {dataArrSys?.alerts.map((card, index) => {
                                    return (
                                        <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                                            <dd>
                                                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <Table title='Alertas' description='Registro dos alertas gerais do sistema' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetAlert, limit: states.limitAlert }} dataTable={states.alertData} />

                        </div>
                    </div>
                    <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
                </div>
                <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.alert} title='Dados do alerta' />
                <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do alerta' />
            </div>
        </>
    )
}
