
import NavBar from "../../../Components/Navbar/navbar";
import NavBarPanel from "../../../Components/navMenu/juridico";
import SpinnerOverlay from "../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../Context";
import { AuthContextJuri } from "../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import ModalProcesso from "../../../Components/Modal/Processo";
import ModalConfirm from "../../../Components/Modal/Confirm";
import Footer from "../../../Components/Footer/footer";
//Tem que mudar 
import Table from '../../../Components/Tabela/TabelaProcessosJuridico'

const UsuariosJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const {
        checkToken,
        dataSystemProcessos,
        dataProcessos,
        getProcessosrQuery,
        dataLawyerForm,
        dataEscritorioForm,
        createLegalProcess,
        updateLegalProcess,
        lockUnlockLegalProcess,
        dataTribunal,
        dataTribunalForm,
        dataAcoesForm,
        deleteLegalProcess
    } = useContext(AuthContextJuri);


    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [search, setSearch] = useState('')
    const [del, setDel] = useState(false)


    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }

    const loadData = async () => {
        setters.setDataJuriEscritorio([])
        setters.setDataJuriLawyer([])
        setters.setOffsetJuriEscritorio(0)
        setters.setOffsetJuriLawyer(0)
        await dataSystemProcessos()
        await dataProcessos()
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES



    const getLawyerSearch = async () => {
        if (search == '') {
            loadData()
        } else {
            await getProcessosrQuery(search)
        }
    }


    //Tem que mudar
    const headers = ['Nome da ação','Titulo do processo', 'Status', 'Situação cadastral', 'Tipo do processo', 'Advogado', 'Corte', 'Juíz', 'Número', 'Sujeito Passivo', 'Sujeito Ativo', 'Data de publicação', 'Data próxima audiência', 'Data de criação', 'Data de atualização', '']

    const next = async () => {
        if (states.limitSearchJuriProcessos && states.offsetJuriProcessos > 0) {
            return;
        } else {
            setters.setOffsetJuriProcessos(states.offsetJuriProcessos + states.limitJuriProcessos)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriProcessos && states.offsetJuriProcessos <= 0) {
            states.offsetJuriProcessos < 0 && setters.setOffsetJuriProcessos(0)
            return;
        } else {
            setters.setOffsetJuriProcessos(states.offsetJuriProcessos - states.limitJuriProcessos)
        }
    };

    const addProcess = async () => {
        setters.setModalJuri(true)
        setters.setIsEditJuri(false)
        setters.setOffsetJuriLawyer(0)
        setters.setOffsetJuriEscritorio(0)
        setters.setOffsetJuriTribunais(0)
        await Promise.all([
            dataEscritorioForm(),
            dataLawyerForm(),
            dataTribunal(),
            dataAcoesForm()
        ])

    }

    const moreLawyer = async () => {
        if (states.limitSearchJuriLawyer && states.offsetJuriLawyer > 0) {
            return;
        } else {
            setters.setOffsetJuriLawyer(states.offsetJuriLawyer + states.limitJuriLawyer)
        }
    }

    const moreEscritorio = async () => {
        if (states.limitSearchJuriEscritorio && states.offsetJuriEscritorio > 0) {
            return;
        } else {
            setters.setOffsetJuriEscritorio(states.offsetJuriEscritorio + states.limitJuriEscritorio)
        }
    }

    const moreAcao = async () => {
        if (states.limitSearchJuriAcoes && states.offsetJuriAcoes > 0) {
            return;
        } else {
            setters.setOffsetJuriAcoes(states.offsetJuriAcoes + states.limitJuriAcoes)
        }
    }

    const moreTribunal = async () => {
        if (states.limitSearchJuriTribunais && states.offsetJuriTribunais > 0) {
            return;
        } else {
            setters.setOffsetJuriTribunais(states.offsetJuriTribunais + states.limitJuriTribunais)
        }
    }


    const loadEscritorio = async () => {
        await dataEscritorioForm()
    }

    const loadAdvogado = async () => {
        await dataLawyerForm()

    }

    const loadTribunal = async () => {
        await dataTribunalForm()
    }

    const loadAcoes = async () => {
        await dataAcoesForm()
    }


    useEffect(() => {
        if (states.dataJuriProcessos.length == 0) loadData()
        setters.setOffsetJuriProcessos(0)
        setters.setModalJuri(false)

        checkTokenLoad()

        return () => {
            setters.setIsEditJuri(false)
            setters.setDataJuriEscritorio([])
            setters.setDataJuriLawyer([])
            setters.setDataJuriTribunais([])
            setters.setDataJuriProcessos([])
            setters.setOffsetJuriProcessos(0)
            setters.setOffsetJuriEscritorio(0)
            setters.setOffsetJuriLawyer(0)
            setters.setOffsetJuriTribunais(0)
            setters.setOffsetJuriAcoes(0)
            setters.setDataJuriAcoes([])
            setters.setLimitSearchJuriProcessos(false)
            setDel(false)
        }
    }, [])


    useEffect(() => {
        if (states.offsetJuriProcessos >= 0 && states.dataJuriProcessos.length > 0) {
            loadData()
        }
    }, [states.offsetJuriProcessos])

    useEffect(() => {
        if (states.offsetJuriEscritorio >= 0 && states.dataJuriEscritorio.length > 0) {
            loadEscritorio()
        }
    }, [states.offsetJuriEscritorio])

    useEffect(() => {
        if (states.offsetJuriLawyer >= 0 && states.dataJuriLawyer.length > 0) {
            loadAdvogado()
        }
    }, [states.offsetJuriLawyer])

    useEffect(() => {
        if (states.offsetJuriTribunais >= 0 && states.dataJuriTribunais.length > 0) {
            loadTribunal()
        }
    }, [states.offsetJuriTribunais])

    useEffect(() => {
        if (states.offsetJuriAcoes >= 0 && states.dataJuriAcoes.length > 0) {
            loadAcoes()
        }
    }, [states.offsetJuriAcoes])


    const actionModal = async (data) => {
        if (states.isEditJuri) {
            const id = await updateLegalProcess(data, data.id)
            if (id) {
                loadData()
            }
            return
        }
        const id = await createLegalProcess(data)
        if (id) {
            hist(`/juridicoIndex/processos/cadastro/${id}`)
        }

    }

    const edit = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriLegalProcess(data)
        setters.setOffsetJuriLawyer(0)
        setters.setOffsetJuriEscritorio(0)
        setters.setOffsetJuriTribunais(0)
        setters.setOffsetJuriAcoes(0)
        await Promise.all([
            dataEscritorioForm(),
            dataLawyerForm(),
            dataTribunal(),
            dataAcoesForm()
        ])
        setters.setModalJuri(true)
    }

    const openlockUnlock = async (data) => {
        setDel(false)
        if (data.status == 1) {
            setTitle('Encerrar processo')
            setDescription('Deseja realmente encerrar o processo?')
        } else {
            setTitle('Reabrir processo')
            setDescription('Deseja realmente reabrir o processo?')
        }
        setters.setConfirmModal(true)
        setters.setJuriLegalProcess(data)
    }

    const actionDelete = async () => {
        if(del === true){
            const resp = await deleteLegalProcess(states.JuriLegalProcess.id)
            if(resp){
                loadData()
            }
            setters.setConfirmModal(false)
            setters.setJuriLegalProcess({})
            return;
        }
        const id = await lockUnlockLegalProcess(states.JuriLegalProcess.id)
        if (id) {
            loadData()
        }
        setters.setConfirmModal(false)
        setters.setJuriLegalProcess({})
    }


    const openDeleteProccess = async (data) => {
        setDel(true)
        setTitle('Excluir processo')
        setDescription('Deseja realmente excluir o processo? Isso irá excluir todos os dados relacionados a ele.')
        setters.setConfirmModal(true)
        setters.setJuriLegalProcess(data)
    }


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Processos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os processos cadastrados.
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {states.dataSystemJuriProcessos?.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }

                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }

                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por processo</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setSearch(e.target.value)}
                                        value={search} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getLawyerSearch()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getLawyerSearch}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table add={addProcess} search={() => console.log()} deleteProcess={openDeleteProccess} edit={edit} status={openlockUnlock} title='Processos cadastrados' description='Registro dos processos cadastrados no jurídico' next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriProcessos, limit: states.limitJuriProcessos }} dataTable={states.dataJuriProcessos} />
                            <ModalProcesso action={actionModal} states={states} setters={setters} moreAdvogado={moreLawyer} moreEscritorio={moreEscritorio} moreTribunal={moreTribunal} moreAcao={moreAcao} />
                            <ModalConfirm states={states} setters={setters} title={title} description={description} action={actionDelete} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}



export default UsuariosJuridico;