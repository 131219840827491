import Navbar from '../../../Components/Navbar/navbar'
import NavPanel from '../../../Components/navMenu/usuario'
import ModalShow from '../../../Components/Modal/ShowHistorico'
import Table from '../../../Components/Tabela/TabelaUsersHistorico'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import ModalCSV from '../../../Components/Modal/ImportarCSV'

import { toast } from 'react-toastify'
import { AuthContext } from '../../../Context'
import { AuthContextApi } from '../../../Context/api'
import { useContext, useEffect, useState } from 'react'


export default function Usuarios() {
    const { dataHistorico, searchQueryHistorico } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    useEffect(() => {
        return () => {
            setters.setOffsetHistorico(0)
            setters.setLimitSearchHistorico(false)
            setters.setQuerySearchHistorico('')
        }
    }, []) 


    useEffect(() => {
        if (states.dataQuery.length == 0){
            listarUsuariosAsync()
        }
    
      }, [states.offsetHistorico])

    const listarUsuariosAsync = async (id) => {
        await dataHistorico()
    }

    const openModalInfo = async (data) => {
        setters.setHistorico(data)
        setters.setModalShowTecnico(true)
    }

    const closeModal = async () => {
        setters.setModalShowTecnico(false)
    }

    const dataSearch = async () => {
        if (states.querySearchHistorico !== '') {
            const regex = /[-\/]/;
            const isData = regex.test(states.querySearchHistorico)
            if (isData) {
                toast.error('Não é possível pesquisar por data')
            } else {
                await searchQueryHistorico()
            }
        }
    }

    const next = async () => {
        if (states.dataQuery.length > 0) {

            if (states.dataHistorico.length === 0) {
                return
            } else {
                const data = states.dataQuery
                setters.setOffsetHistorico(states.offsetHistorico + states.limitHistorico)
                const sumOff = states.offsetHistorico + states.limitHistorico
                const limit = states.limitHistorico + sumOff
                const newData = data.slice(sumOff, limit)
                setters.setDataHistorico(newData)
            }
        } else {
            if (states.limitSearchHistorico) {
                return
            } else {
                setters.setOffsetHistorico(states.offsetHistorico + states.limitHistorico)
            }
        }

    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataHistorico.length === 0 && states.offsetHistorico <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetHistorico > 0 && setters.setOffsetHistorico(states.offsetHistorico - states.limitHistorico)
                states.offsetHistorico < 0 && setters.setOffsetHistorico(0)
                const sumOff = states.offsetHistorico > 0 ? states.offsetHistorico - states.limitHistorico : 0;
                const limit = sumOff + 10 > states.limitHistorico ? sumOff + 10 : states.limitHistorico;
                // 
                const newData = data.slice(sumOff, limit)
                setters.setDataHistorico(newData)
            }
        } else {
            if (states.offsetHistorico <= 0) {
                states.offsetHistorico < 0 && setters.setOffsetHistorico(0)
                return
            } else {
                if (states.limitSearchHistorico) setters.setLimitSearchHistorico(false)
                setters.setOffsetHistorico(states.offsetHistorico - states.limitHistorico)
            }
        }
    };

    const headers = ['Ação', 'Email',  'Data de criação', 'Data de atualização', '']


    const [modalAberto, setModalAberto] = useState(false)

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />
                            
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Histórico dos Usuários</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre o histôrico dos usuários.
                            </p>
                            <button className="mt-2 text-sm text-gray-700" onClick={()=>setModalAberto(true)}>
                                Modal
                            </button>

                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por usuário</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQuerySearchHistorico(e.target.value)}
                                        value={states.querySearchHistorico} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                                <div className="flex items-center md:ml-5 p-2">
                                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                                    Aplicar
                                    </button>
                                    {
                                    states.isLoading && (
                                        <div className="flex items-center justify-center ml-8">
                                        <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                        </div>
                                    )
                                    }
                                </div>
                                </div>
                            </div>

                            <Table  title='Histórico dos usuários' description='Registro dos históricos dos usuários' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetHistorico, limit: states.limitHistorico }} dataTable={states.dataHistorico} />

                        </div>
                    </div>
                </div>
                <ModalCSV states={states} setters={setters} aberto={modalAberto} modalAberto={setModalAberto}/>
                <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.Historico} title='Dados de histórico usuário' />
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
