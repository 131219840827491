import Navbar from "../../../Components/Navbar/navbar";
import Footer from "../../../Components/Footer/footer";

import { Link } from "react-router-dom";
import { ArrowUturnLeftIcon, DocumentArrowDownIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline'

const FaturasIndex = () => {

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                <h1 className="text-3xl text-center font-bold">Painel Faturas</h1>
                                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as faturas do sistema</p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                                    <div className="flex items-center justify-center">
                                        <Link to="/financeIndex" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <ArrowUturnLeftIcon className="h-16 w-16 text-blue-600" />
                                            <h2 className="text-xl font-bold">Retornar</h2>
                                            <p className="text-center">Clique aqui para retornar ao painel anterior</p>
                                        </Link>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <Link to="/financeIndex/faturas/cobranca" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <DocumentArrowUpIcon className="h-16 w-16 text-blue-600" />
                                            <h2 className="text-xl text-center font-bold">Faturas de Cobrança</h2>
                                            <p className="text-center">Clique nesse cartão para acessar a área de faturas de cobrança</p>
                                        </Link>
                                    </div>

                                    {
                                        (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' ) &&
                                        <>

                                            <div className="flex items-center justify-center">
                                                <Link to="/financeIndex/faturas/pagamento" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                    <DocumentArrowDownIcon className="h-16 w-16 text-blue-600" />
                                                    <h2 className="text-xl text-center font-bold">Faturas de Pagamento</h2>
                                                    <p className="text-center">Clique nesse cartão para acessar a área de faturas de pagamento</p>
                                                </Link>
                                            </div>
                                        </>
                                    }

                            </div>
                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default FaturasIndex;
