import Navbar from '../../../Components/Navbar/navbar'
import NavPanel from '../../../Components/navMenu/usuario'
import Table from '../../../Components/Tabela/TabelaUsers'
import ModalShow from '../../../Components/Modal/ShowUsers'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalUsuarios from '../../../Components/Modal/Usuarios/modalUsuarios'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import { toast } from 'react-toastify'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export default function Usuarios() {
    const { dataSystemUsers, dataUsers, blockUnlock, cadastrarUsuario, roleChange, searchQueryUsers } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const [usuarioSelecionado, setUsuarioSelecionado] = useState({})

    useEffect(() => {
        listarUsuariosAsync()

        setters.setIndex(10)
        setters.setOffSet(0)
        return () => {
            setters.setOffsetUsers(0)
            setters.setLimitSearchUsers(false)
            setters.setDataUsers([])
        }
    }, []);

    useEffect(() => {
        if (states.modalUser == false) {
            listarUsuariosAsync()

            setters.setIndex(10)
            setters.setOffSet(0)
        }
    }, [states.modalUser]);

    useEffect(() => {
        if (states.dataQuery.length == 0){
            listarUsuariosAsync()
        }
      }, [states.offsetUsers])

    const listarUsuariosAsync = async (id) => {
        const promises = [
            dataSystemUsers(),
            dataUsers()
            ];
        
        await Promise.all(promises);
    }

    const abrirModal = (usuario) => {
        if (usuario.block == 0) {
            setters.setIsEdit(true)
        } else {
            setters.setIsEdit(false)
        }
        setters.setUsers(usuario)
        setters.setConfirmModal(true)
    };

    const add = async () => {
        setUsuarioSelecionado()
        setters.setModalUser(true);
    }

    const role = async (ass) => {
        setUsuarioSelecionado(ass)
        setters.setModalUser(true);
    }

    const openModalInfo = async (data) => {
        setters.setUsers(data)
        setters.setModalShowTecnico(true)
    }

    const closeModal = async () => {
        setters.setModalShowTecnico(false)
    }

    const sendFormFiliate = async (id) => 
    { 
        
    }

    const actionConfirm = async () => {
        setters.setConfirmModal(false)
        await blockUnlock()
    }

    const actionModal = async (data) => {
        setters.setModalUser(false)
        if (data.id == '') {
            await cadastrarUsuario(data)
        } else {
            await roleChange(data)
        }
    }

    const dataSearch = async () => {
        if (states.queryUsers !== '') {
            const regex = /[-\/]/;
            const isData = regex.test(states.queryUsers)
            if (isData) {
                toast.error('Não é possível pesquisar por data')
            } else {
                await searchQueryUsers()
            }
        }
    }

    const next = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataUsers.length === 0) {
                return
            } else {
                const data = states.dataQuery
                setters.setOffsetUsers(states.offsetUsers + states.limitUsers)
                const sumOff = states.offsetUsers + states.limitUsers
                const limit = states.limitUsers + sumOff
                const newData = data.slice(sumOff, limit)
                setters.setDataUsers(newData)
            }
        } else {
            if (states.limitSearchUsers) {
                return
            } else {
                setters.setOffsetUsers(states.offsetUsers + states.limitUsers)
            }
        }

    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataUsers.length === 0 && states.offsetUsers <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetUsers > 0 && setters.setOffsetUsers(states.offsetUsers - states.limitUsers)
                states.offsetUsers < 0 && setters.setOffsetUsers(0)
                const sumOff = states.offsetUsers > 0 ? states.offsetUsers - states.limitUsers : 0;
                const limit = sumOff + 10 > states.limitUsers ? sumOff + 10 : states.limitUsers;
                // 
                const newData = data.slice(sumOff, limit)
                setters.setDataUsers(newData)
            }
        } else {
            if (states.offsetUsers <= 0) {
                states.offsetUsers < 0 && setters.setOffsetUsers(0)
                return
            } else {
                if (states.limitSearchUsers) setters.setLimitSearchUsers(false)
                setters.setOffsetUsers(states.offsetUsers - states.limitUsers)
            }
        }
    };

    const headers = ['Status', 'Permissão', 'Email', 'Data de criação', 'Data de atualização', '']

    const titleConfirm = states.isEdit ? 'Deseja bloquear o usuário?' : 'Deseja desbloquear o usuário?'
    const descriptionConfirm = states.isEdit ? 'Ao bloquear o usuário, ele não poderá mais acessar o sistema.' : 'Ao desbloquear o usuário, ele poderá acessar o sistema novamente.'

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Usuários</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os usuários.
                            </p>
                            
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {states.dataSystemUsers.map((card, index) => {
                                    return (
                                        <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                                            <dd>
                                                                <div className="text-lg font-medium text-gray-900">{card.value}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por usuário</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQueryUsers(e.target.value)}
                                        value={states.queryUsers} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                                <div className="flex items-center md:ml-5 p-2">
                                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                                    Aplicar
                                    </button>
                                    {
                                    states.isLoading && (
                                        <div className="flex items-center justify-center ml-8">
                                        <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                        </div>
                                    )
                                    }
                                </div>
                                </div>
                            </div>

                            <Table add={add} role={role} edit={abrirModal} form={sendFormFiliate} title='Usuários' description='Registro dos usuários do SINPREV' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetUsers, limit: states.limitUsers }} dataTable={states.dataUsers} />
                        </div>

                        <ModalUsuarios actionModal={actionModal} states={states} setters={setters} usuario={usuarioSelecionado} />
                        <ModalConfirm states={states} action={actionConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
                        <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.Users} title='Dados de usuário' />
                    </div>
                </div>
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
