import { Link } from "react-router-dom"
import { Pie, Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../Context/index"
import { AuthContextApi } from '../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { BuildingLibraryIcon, ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline"

import moment from "moment"
import Navbar from '../../Components/Navbar/navbar'
import Footer from '../../Components/Footer/footer'

// banco de dados, back
import MapaEstados from '../../Components/map'
import EstadoDetalhes from '../../Components/estado'

Chart.register(...registerables);


const Home = () => {
  const estadosData = [
    { id: 1, nome: 'Acre', latitude: -9.97499, longitude: -67.8243, info: 'Informações sobre Acre' },
    { id: 2, nome: 'Alagoas', latitude: -9.66599, longitude: -35.735, info: 'Informações sobre Alagoas' },
    { id: 3, nome: 'Amapá', latitude: 0.0390457, longitude: -51.0666, info: 'Informações sobre Amapá' },
    { id: 4, nome: 'Amazonas', latitude: -3.11903, longitude: -60.0217, info: 'Informações sobre Amazonas' },
    { id: 5, nome: 'Bahia', latitude: -12.9714, longitude: -38.5014, info: 'Informações sobre Bahia' },
    { id: 6, nome: 'Ceará', latitude: -3.71722, longitude: -38.5433, info: 'Informações sobre Ceará' },
    { id: 7, nome: 'Distrito Federal', latitude: -15.7797, longitude: -47.9297, info: 'Informações sobre Distrito Federal' },
    { id: 8, nome: 'Espírito Santo', latitude: -20.3155, longitude: -40.3128, info: 'Informações sobre Espírito Santo' },
    { id: 9, nome: 'Goiás', latitude: -16.6864, longitude: -49.2643, info: 'Informações sobre Goiás' },
    { id: 10, nome: 'Maranhão', latitude: -2.53911, longitude: -44.2829, info: 'Informações sobre Maranhão' },
    { id: 11, nome: 'Mato Grosso', latitude: -15.601, longitude: -56.0974, info: 'Informações sobre Mato Grosso' },
    { id: 12, nome: 'Mato Grosso do Sul', latitude: -20.4697, longitude: -54.6201, info: 'Informações sobre Mato Grosso do Sul' },
    { id: 13, nome: 'Minas Gerais', latitude: -19.9191, longitude: -43.9386, info: 'Informações sobre Minas Gerais' },
    { id: 14, nome: 'Pará', latitude: -1.4554, longitude: -48.4902, info: 'Informações sobre Pará' },
    { id: 15, nome: 'Paraíba', latitude: -7.11949, longitude: -34.8451, info: 'Informações sobre Paraíba' },
    { id: 16, nome: 'Paraná', latitude: -25.4284, longitude: -49.2733, info: 'Informações sobre Paraná' },
    { id: 17, nome: 'Pernambuco', latitude: -8.04756, longitude: -34.877, info: 'Informações sobre Pernambuco' },
    { id: 18, nome: 'Piauí', latitude: -5.092, longitude: -42.8038, info: 'Informações sobre Piauí' },
    { id: 19, nome: 'Rio de Janeiro', latitude: -22.9068, longitude: -43.1729, info: 'Informações sobre Rio de Janeiro' },
    { id: 20, nome: 'Rio Grande do Norte', latitude: -5.79448, longitude: -35.211, info: 'Informações sobre Rio Grande do Norte' },
    { id: 21, nome: 'Rio Grande do Sul', latitude: -30.0346, longitude: -51.2177, info: 'Informações sobre Rio Grande do Sul' },
    { id: 22, nome: 'Rondônia', latitude: -8.76116, longitude: -63.9004, info: 'Informações sobre Rondônia' },
    { id: 23, nome: 'Roraima', latitude: 2.8201, longitude: -60.6719, info: 'Informações sobre Roraima' },
    { id: 24, nome: 'Santa Catarina', latitude: -27.5954, longitude: -48.548, info: 'Informações sobre Santa Catarina' },
    { id: 25, nome: 'São Paulo', latitude: -23.5505, longitude: -46.6333, info: 'Informações sobre São Paulo' },
    { id: 26, nome: 'Sergipe', latitude: -10.9472, longitude: -37.0731, info: 'Informações sobre Sergipe' },
    { id: 27, nome: 'Tocantins', latitude: -10.1835, longitude: -48.3336, info: 'Informações sobre Tocantins' },
  ];
  const [selectedEstado, setSelectedEstado] = useState(null);
  const { states, setters } = useContext(AuthContext)
  const { dataSystem, dataSystemHome, dataSystemFinancial, dataGraficAccount, dataAssociadosGrafico } = useContext(AuthContextApi)
  const [cumprimento, setCumprimento] = useState('')
  const [loadGraphFundo, setLoadGraphFundo] = useState(true)
  const [loadGraphAposentado, setLoadGraphAposentado] = useState(true)

  useEffect(() => {
    load()
  }, []);

  useEffect(() => {
    setters.setEmail(localStorage.getItem('email'))
    setters.setPermission(localStorage.getItem('permission'))
    const dataHora = new Date()
    const hora = dataHora.getHours()
    if (hora >= 1 && hora <= 11) {
      setCumprimento('Bom dia')
    }
    else if (hora >= 12 && hora <= 17) {
      setCumprimento('Boa tarde')
    }
    else if (hora >= 18 && hora <= 23) {
      setCumprimento('Boa noite')
    }
    else {
      setCumprimento('Olá')
    }
  }, []);

  const load = async () => {
    setLoadGraphFundo(true)
    setLoadGraphAposentado(true)
    let promises;
    if (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') {
      promises = [
        dataSystemHome(),
        dataSystem(),
        dataSystemFinancial(),
        dataGraficAccount(),
        dataAssociadosGrafico()
      ];
    } else {
      promises = [
        dataSystemHome(),
        dataSystem(),
        dataAssociadosGrafico()
      ];
    }
    await Promise.all(promises);
  }

  const data = {
    labels: states.dataGraphicAccount.map(elements => elements.date),
    datasets: [
      {
        label: 'Valor total da conta',
        data: states.dataGraphicAccount.map(elements => elements.valueAccount),
        backgroundColor: 'rgba(63, 81, 181, 0.5)',
        borderColor: 'rgba(63, 81, 181, 1)',
        pointBackgroundColor: 'rgba(63, 81, 181, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
      },
    ],
  };

  useEffect(() => {
    if (states.dataGraficAssociadosFundo.length > 0) {
      setLoadGraphFundo(false)
    }

  }, [states.dataGraficAssociadosFundo]);

  useEffect(() => {
    if (states.dataAssociadosAposentado.length > 0) {
      setLoadGraphAposentado(false)
    }

  }, [states.dataAssociadosAposentado]);




  const dataAssoc = {
    labels: states.dataGraficAssociadosFundo.map((item) => item.fundo),
    datasets: [
      {
        data: states.dataGraficAssociadosFundo.map((item) => item.items),
        backgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        hoverBackgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
        hoverBorderColor: '#ffffff',
      },
    ]
  };

  const data1Assoc = {
    labels: states.dataAssociadosAposentado.map(item => item.Aposentado),
    datasets: [
      {
        data: states.dataAssociadosAposentado.map(item => item.items),
        backgroundColor: ['#66BB6A', '#FF5252', '#42A5F5'],
        hoverBackgroundColor: ['#66BB6A', '#FF5252', '#42A5F5'],
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => {
            return `$${value.toLocaleString('en-US')}`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y || 0;
            return `${label}: $${value.toLocaleString('en-US')}`;
          },
        },
      },
    },
  };

  const options2 = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255, 255, 255, 0.8)',
        fontSize: 12,
        padding: 20,
      },
    },
  };

  const dataFlowCash = {
    labels: states.dataGraphicAccount.map(elements => elements.date),
    datasets: [
      {
        label: 'Entradas',
        data: states.dataGraphicAccount.map(elements => elements.entryConc),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Saídas',
        data: states.dataGraphicAccount.map(elements => elements.outConc),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">


              <div className="py-2 flow-root mb-4">
                <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                      <form className='p-5'>
                        <div className="space-y-12 sm:space-y-16">
                          <div>
                            <div className="flex items-center">
                              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                {cumprimento}, {states.email}
                              </h1>
                            </div>
                            <dl className="mt-6 flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap">
                              <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                                <CheckCircleIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                  aria-hidden="true"
                                />
                                Permissão:
                                {
                                  states.permission == '0' ? ' Administrador' :
                                    states.permission == '1' ? ' Financeiro' :
                                      states.permission == '2' ? ' Administrativo' :
                                        states.permission == '3' ? ' Operacional' :
                                          states.permission == '4' ? ' Técnico' :
                                            states.permission == '5' && 'Comunicação'
                                }
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {
                (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                  <>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Administrativo</h1>
                    <p className="mt-2 text-sm text-gray-700">
                      Informações sobre a área Administrativa.
                    </p>
                  </>
                )
              }
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}

                {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') && states.dataSystemHome.statistic?.map((card, index) => {
                  return (
                    <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            {
                              card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                            }

                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              {
                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                              }

                              <dd>
                                {
                                  card.value > 0 && index === -1 ?
                                    (<div className="text-lg font-medium text-red-500">
                                      {card.value}
                                    </div>) : (
                                      <div className="text-lg font-medium text-gray-900">
                                        {card.value}
                                      </div>)
                                }

                                {
                                  card.value > 0 && (index === 2 || index === 1 || index === 2 || index === 5) && (
                                    <dt className="truncate text-sm font-medium text-gray-500">
                                      <div className='flex flex-row '>
                                        <div className="text-sm font-medium text-indigo-500 hover:text-indigo-700">
                                          <Link to={
                                            index === 0 ? '/alertaIndex/alertas' : index === 1 ? '/financeIndex/folhaPagamento/remessa' :
                                              index === 2 ? '/adminIndex/assinaturas' : '/adminIndex/associados'
                                          } >
                                            Verificar
                                          </Link>
                                        </div>
                                      </div>
                                    </dt>

                                  )
                                }
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>

              {(localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                <div className='lg:flex'>
                  <div className="mr-1 flex lg:w-[80%] flex-col items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                    <h2 className="text-xl font-bold">Associados por fundo</h2>
                    <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                    <div style={{ height: '100%', width: '100%' }}>
                      {
                        loadGraphFundo ?
                          <div className="flex items-center justify-center mt-8">
                            <div className="h-12 w-12 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                          </div>
                          :
                          <Pie data={dataAssoc} options={options2} />
                      }

                    </div>
                  </div>

                  <div className="ml-1 flex flex-col lg:w-[80%] lg:ml-3 items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                    <h2 className="text-xl font-bold">Associados aposentados</h2>
                    <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                    <div style={{ height: '100%', width: '100%' }}>
                      {
                        loadGraphAposentado ?
                          <div className="flex items-center justify-center mt-8">
                            <div className="h-12 w-12 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                          </div>
                          :
                          <Pie data={data1Assoc} options={options2} />
                      }
                    </div>
                  </div>
                </div>
              )}


              <div className="flex flex-col justify-center">
                <EstadoDetalhes estado={selectedEstado} close={setSelectedEstado} />
                <MapaEstados dados={estadosData} onEstadoSelect={setSelectedEstado} dataBeckEnd={states.dataSystemHome.graphic} />
              </div>

              {
                (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') && (
                  <>
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-4">Financeiro</h1>
                    <p className="mt-2 text-sm text-gray-700">
                      Informações sobre a área Financeira.
                    </p>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                      {/* Card */}
                      {states.dataSystemAccount.length > 0 && states.dataSystemAccount.map((card, index) => (
                        <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                          <div className="p-5">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                {
                                  index === 0 && (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                                }
                                {
                                  index === 1 && (<ArrowDownIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                                }
                                {
                                  index === 2 && (<ArrowUpIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)

                                }
                              </div>
                              <div className="ml-5 w-0 flex-1">
                                <dl>
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row'>
                                      <div className="text-sm font-medium text-gray-900">{card.title}</div>
                                      <div className='ml-5 flex'>
                                        {
                                          card.percent < 0 ? (<><ArrowDownIcon className='h-3 w-3 text-red-400 mt-1' /> <p className='text-red-500'>{card.percent}%</p></>) : (<><ArrowUpIcon className='h-3 w-3 text-green-400 mt-1' /> <p className='text-green-500'>{card.percent}</p></>)
                                        }
                                      </div>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="text-lg font-medium text-gray-900">{card.total}</div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>

                        </div>
                      ))}
                    </div>


                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                      <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <h2 class="text-xl font-bold">Evolução do valor da conta</h2>
                        <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                        <div style={{ height: '100%', width: '100%' }}>
                          <Line data={data} options={options} />
                        </div>
                      </div>

                      <div class="flex flex-col items-center p-6 mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                        <h2 class="text-xl font-bold">Fluxo de caixa</h2>
                        <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                        <div style={{ height: '100%', width: '100%' }}>
                          <Line data={dataFlowCash} options={options} />
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
            <div className="pt-8">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Home;