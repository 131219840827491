import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import Footer from '../../../Components/Footer/footer'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalConfirmCard from '../../../Components/Modal/ConfirmCard'
import { jsonConfig } from '../../../Config/index'
import { toast } from 'react-toastify'
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useRef, useState, useContext, useEffect } from 'react'
import { PencilIcon, ChevronDoubleDownIcon, ArrowUpOnSquareIcon, MagnifyingGlassIcon, XCircleIcon, TrashIcon, DocumentDuplicateIcon } from '@heroicons/react/20/solid'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'



export default function Associado() {

  const { states, setters, acaoFormularioAssociado } = useContext(AuthContext)
  const { getCommentsFileAssociate, dataAllFundo, postCommentsAssociate, postFileAssociate, deleteFileAssociate, deleteCommentAssociate, dataAssociadoFatura, getCreditCard, deleteCreditCard } = useContext(AuthContextApi)
  const [showFile, setShowFile] = useState(false)
  const [currentBinaryData, setCurrentBinaryData] = useState(null);
  const [index, setIndex] = useState(0)
  const [title, setTitle] = useState('')
  const [docs, setDocs] = useState([])
  const [titleModalDelete, setTitleModalDelete] = useState('')
  const [descriptionModalDelete, setDescriptionModalDelete] = useState('')

  const [arrowButton, setArrowButton] = useState(true)

  const { id } = useParams()

  const load = async () => {
    setters.setIsLoading(true)
    if (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') {
      await Promise.all([getCommentsFileAssociate(id), dataAllFundo()])
    } else {
      await Promise.all([getCommentsFileAssociate(id)])
    }

    //console.log(states.Associadoss)
    setters.setIsLoading(false)
  }

  useEffect(() => {
    setters.setEmail(localStorage.getItem('email'))
    load()
    setters.setLimitSearchAssociadoss(false)
    setters.setOffsetAssociadoss(0)
    setters.setDataAssociadossFatura([])
    setters.setDataAssociadossCartoes([])
    setArrowButton(true)
    return () => {
      setters.setLimitSearchAssociadoss(false)
      setters.setOffsetAssociadoss(0)
      setters.setDataAssociadossFatura([])
      setters.setDataAssociadossCartoes([])
      setters.setDataFundo([])
      setArrowButton(true)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(states.Associadoss).length == 0) {
      load()
    } else {
      return
    }
  }, [states.Associadoss])

  const [isEditing, setIsEditing] = useState(false)
  const [img, setImg] = useState('')
  const inputArquivoRef = useRef(null);

  const formatarTelefone = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digit = input.replace(/\D/g, '');

    // Verificar se o número começa com 55 e, se não, adicionar o +55 no início
    let formattedNumber = digit;
    if (digit.length === 11 && !digit.startsWith('55')) {
      formattedNumber = '55' + digit;
    }

    // Aplicar o padrão +55 (XX) XXXX-XXXX usando grupos de captura
    const formatted = formattedNumber.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');

    return formatted;
  };

  const formatarCPF = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digits = input.replace(/\D/g, '');

    // Aplicar o padrão XXX.XXX.XXX-XX usando grupos de captura
    const formatted = digits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return formatted;
  };

  const formatarCEP = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digits = input.replace(/\D/g, '');

    // Aplicar o padrão XXXXX-XXX usando grupos de captura
    const formatted = digits.replace(/(\d{5})(\d{3})/, '$1-$2');

    return formatted;
  };

  const [comentarios, setComentarios] = useState([]);
  const [novoComentario, setNovoComentario] = useState('');
  const [autor, setAutor] = useState('');
  const [exibirComentarios, setExibirComentarios] = useState(true);
  const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
  const [idArquivo, setIdArquivo] = useState(null);
  const [isComment, setIsComment] = useState(false);

  const [fileType, setFileType] = useState('');
  const [binaryData, setBinaryData] = useState(null);

  const adicionarComentario = async () => {
    if (novoComentario.trim() !== '' && autor.trim() !== '') {
      const novoComentarioObj = {
        autor,
        comentario: novoComentario,
      };
      const objSend = {
        associate_id: id,
        description: novoComentario,
        title: autor
      }
      const response = await postCommentsAssociate(objSend)
      if (response == true) {
        load()
        setExibirComentarios(false);
        setNovoComentario('');
        setAutor('');
      } else {
        setNovoComentario('');
        setAutor('');
      }

    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setArquivoSelecionado(file);
      } else {
        toast.error('Extensão de arquivo não permitida. Apenas imagens são permitidas.');
      }
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {

      const allowedExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setArquivoSelecionado(file);
      } else {
        toast.error('Extensão de arquivo não permitida. Apenas imagens são permitidas.');
      }
    }
  };

  const handleFileUpload = async () => {
    if (arquivoSelecionado) {
      //Aqui e melhor recarregar tudo
      if (title == '') {
        toast.error('Preencha o titulo do arquivo');
      } else {
        if (title.length > 70) {
          toast.error('Titulo deve ter no máximo 70 caracteres');
        } else {
          const objRequest = {
            id: id,
            file: arquivoSelecionado,
            title: title,
            associate_id: id
          };
          const response = await postFileAssociate(objRequest)
          if (response == true) {
            setArquivoSelecionado(null);
            setTitle('')

            load()
          }
        }
      }
    }
  };

  const handleInputArquivoClick = () => {
    if (inputArquivoRef.current) {
      inputArquivoRef.current.click();
    }
  };

  const viewBinary = (fileName, binaryData, index) => {
    setIndex(index)
    setShowFile(true);
    const supportedExtensions = {
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      txt: 'text/plain',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
    };

    const fileExtension = fileName.split('.').pop().toLowerCase();
    const mimeType = supportedExtensions[fileExtension] || 'application/octet-stream';

    const blob = new Blob([binaryData], { type: mimeType });
    // const buffer = encodeBase64(decodeURI(encodeURIComponent(binaryData)));
    // var src = "data:image/png;base64," + buffer;
    const uri = URL.createObjectURL(blob);
    setImg(uri)
    setFileType(mimeType);
    setBinaryData(binaryData);
    setShowFile(true);
  };

  const closeViewer = () => {
    setShowFile(false);
    setFileType('');
    setIndex(null);
    setCurrentBinaryData(null);
  };

  const actionDelete = async () => {
    setters.setConfirmModal(false)
    setTitleModalDelete('')
    setDescriptionModalDelete('')
    if (!isComment) {
      const response = await deleteFileAssociate(idArquivo)
      if (response == true) {
        setIdArquivo(null)
        load()
      }
    } else {
      const response = await deleteCommentAssociate(idArquivo)
      if (response == true) {
        setIdArquivo(null)
        load()
      }
    }

  }

  const openModalDelete = (data) => {
    setIsComment(false)
    setTitleModalDelete('Deseja excluir o arquivo?')
    setDescriptionModalDelete('Ao excluir o arquivo, ele não poderá ser recuperado.')
    setIdArquivo(data.id)
    setters.setConfirmModal(true)

  }

  const openModalDeleteComment = (data) => {
    setIsComment(true)
    setTitleModalDelete('Deseja excluir o comentário?')
    setDescriptionModalDelete('Ao excluir o comentário, ele não poderá ser recuperado.')
    setIdArquivo(data.id)
    setters.setConfirmModal(true)

  }

  const editAssociate = async () => {
    const edit = true;
    const response = await acaoFormularioAssociado(states.Associadoss.id, edit, { ...states.Associadoss, fundo: states.Associadoss.fundo_id })
    if (response.status == true) {
      setIsEditing(false)
      load()
      toast.success('Associado editado com sucesso')
    }
  }

  const getInvoices = async () => {
    if (arrowButton == true) {
      setArrowButton(!arrowButton)
      const response = await dataAssociadoFatura(id)
      if (response == false) {
        setArrowButton(true)
        setters.setDataAssociadossFatura([])
      }
    } else {
      setters.setDataAssociadossFatura([])
      setArrowButton(!arrowButton)
    }
  }

  const loadMoreInvoice = async () => {
    //Apenas setta o offset para o valor atual + 10
    await dataAssociadoFatura(id)
  }

  const copyLinkSite = (e) => {
    var content = document.getElementById(e)
    navigator.clipboard.writeText(content.value)
      .then(() => {
        toast.success('Link copiado com sucesso!')
      })
  }


  const loadMore = () => {
    setters.setOffsetAssociadoss(states.offsetAssociadoss + states.limitAssociadoss)
  }

  useEffect(() => {
    if (states.offsetAssociadoss > 0) {
      loadMoreInvoice()
    }
  }, [states.offsetAssociadoss])

  const [arrowButtonPayment, setArrowButtonPayment] = useState(true)

  const getProfilePayment = async (data) => {
    //getCreditCard
    if (arrowButtonPayment == true) {
      setArrowButtonPayment(!arrowButtonPayment)
      const response = await getCreditCard(id)
      if (response == false) {
        setArrowButtonPayment(true)
        setters.setDataAssociadossCartoes([])
      }
    } else {
      setters.setDataAssociadossCartoes([])
      setArrowButtonPayment(!arrowButtonPayment)
    }

  }

  const openModalDeleteCard = (data) => {
    setters.setCartaoAssociado(data)
    setters.setConfirmModalCartao(true)
  }

  const deleteCard = async () => {
    setters.setConfirmModalCartao(false)
    const body = {
      id: states.CartaoAssociado.id,
      idAssociate: id
    }
    const response = await deleteCreditCard(body)
    if (response == true) {
      toast.success('Cartão excluido com sucesso')
    }
  }

  function formatarNumero(numero) {
    const numStr = numero?.toString();
    return `${numStr?.slice(0, 3)}.${numStr?.slice(3, 8)}.${numStr?.slice(8)}`;
  }

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              {
                Object.keys(states.Associadoss).length > 0 && (
                  <div className="mb-8">
                    <div className="flex justify-between">
                      <div>
                        <Link to="/adminIndex/associados" className="text-sm font-semibold leading-6 text-blue-500 hover:text-blue-600">Voltar</Link>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Associado</h1>
                      </div>
                      {
                        (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3' || localStorage.getItem('permission') == '5') && (
                          <button onClick={() => setIsEditing(!isEditing)} className="bg-blue-500 hover:bg-blue-600 w-12 h-10 rounded-md text-white ">
                            <PencilIcon className="w-6 h-6 mx-auto" />
                          </button>
                        )
                      }
                    </div>

                    <div className="border p-4 rounded-md mt-4">
                      <div className='flex flex-col sm:flex-row jutify-between items-between'>
                        {
                          isEditing ? (
                            <p className="text-md flex flex-col sm:w-[28%] w-[100%] ">
                              Informações sobre o Associado:
                              <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.name}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, name: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="">
                              Informações sobre o Associado: <p className="text-base font-semibold leading-6 text-gray-900 mt-2">{states.Associadoss.name}</p>
                            </p>
                          )
                        }
                        <p className="sm:ml-12 mt-2 sm:mt-0">
                          Matrícula do Associado: <p className="text-base font-semibold leading-6 text-gray-900 mt-2">{formatarNumero(states.Associadoss.registration)}</p>
                        </p>
                      </div>

                      <hr className="mt-4" />
                      <p className="mt-2">Contato</p>
                      <div className="grid sm:grid-cols-2 sm:grid-cols-1  gap-4">

                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                              Email: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.email}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, email: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Email: {states.Associadoss.email}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                              Telefone: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.phone}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, phone: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Telefone: {formatarTelefone(states.Associadoss.phone)}</p>
                            </p>
                          )
                        }
                      </div>
                      <hr className="mt-4" />
                      <p className="mt-2">Dados gerais</p>
                      <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 justify-center items-center">

                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Nome da mãe: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.mothername}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, mothername: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Nome da mãe: {states.Associadoss.mothername}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Nome do pai: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.fathername}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, fathername: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700 ml-3">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Nome do pai: {states.Associadoss.fathername}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Cidade natal: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.hometown}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, hometown: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Cidade natal: {states.Associadoss.hometown}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Estado natal: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.homeState}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, homeState: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Estado natal: {states.Associadoss.homeState}</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Sexo: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.sexo}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, sexo: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Sexo: {
                                states.Associadoss.sexo == 'H' ? 'Homem' : states.Associadoss.sexo == 'Homem' ? 'Homem' : states.Associadoss.sexo == 'M' ? 'Homem'
                                  : states.Associadoss.sexo == 'F' ? 'Mulher' : 'Outro'

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Aposentado: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.retired}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, retired: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Aposentado: {
                                states.Associadoss.retired

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Data de Filiação: <input className="text-base leading-6 mt-2 rounded-[8px]" type="date" value={moment(states.Associadoss.dateFiliate).format('YYYY-MM-DD')}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, dateFiliate: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Data de Filiação: {
                                moment(states.Associadoss.dateFiliate).format('DD/MM/YYYY')

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Companhia: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.company}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, company: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Companhia: {
                                states.Associadoss.company
                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              {/* Fundo: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.fundo}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, fundo: e.target.value })}
                              /> */}
                              Fundo: <select className="text-base leading-6 mt-2 rounded-[8px]" value={states.Associadoss.fundo_id}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, fundo_id: e.target.value, fundo: states.dataFundo.find(item => item.id === e.target.value)?.name })}
                              >
                                <option value={null}>Selecione</option>
                                {
                                  states.dataFundo.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>{item.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Fundo: {
                                states.Associadoss.fundo === null ? 'Não informado' : states.dataFundo.find(item => item.id === states.Associadoss.fundo_id)?.name

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Rg: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.rg}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, rg: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-gray-700">
                              <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Rg: {
                                states.Associadoss.rg

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Data de emissão do Rg: <input className="text-base leading-6 mt-2 rounded-[8px]" type="date" value={moment(states.Associadoss.dateRg).format('YYYY-MM-DD')}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, dateRg: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2  text-gray-700">
                              <p className="text-base  leading-6 text-gray-900 mt-2"><span className="font-semibold">Data de emissão do Rg:</span> {
                                moment(states.Associadoss.dateRg).format('DD/MM/YYYY')

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              CPF: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={formatarCPF(states.Associadoss.cpf)}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, cpf: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2  text-gray-700">
                              <p className="text-base  leading-6 text-gray-900 mt-2"><span className="font-semibold">CPF:</span> {
                                formatarCPF(states.Associadoss.cpf)

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Estado Civil: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.civilState}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, civilState: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2  text-gray-700">
                              <p className="text-base  leading-6 text-gray-900 mt-2"><span className="font-semibold">Estado Civil:</span> {
                                states.Associadoss.civilState

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Data de aniversário: <input className="text-base leading-6 mt-2 rounded-[8px]" type="date" value={moment(states.Associadoss.birthDate).format('YYYY-MM-DD')}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, birthDate: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2  text-gray-700">
                              <p className="text-base  leading-6 text-gray-900 mt-2"><span className="font-semibold">Data de aniversário:</span> {
                                moment(states.Associadoss.birthDate).format('DD/MM/YYYY')

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Escolaridade: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.escolarchip}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, escolarchip: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2  text-gray-700">
                              <p className="text-base  leading-6 text-gray-900 mt-2"><span className="font-semibold">Escolaridade:</span> {
                                states.Associadoss.escolarchip

                              }</p>
                            </p>
                          )
                        }
                        {
                          isEditing ? (
                            <p className="mt-2 text-md flex flex-col w-[100%] ">
                              Matrícula da empresa: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.companyCode}
                                onChange={e => setters.setAssociadoss({ ...states.Associadoss, companyCode: e.target.value })}
                              />
                            </p>
                          ) : (
                            <p className="mt-2  text-gray-700">
                              <p className="text-base  leading-6 text-gray-900 mt-2"><span className="font-semibold">Matrícula da empresa:</span> {
                                states.Associadoss.companyCode

                              }</p>
                            </p>
                          )
                        }

                      </div>
                      <div className="mt-4">

                        <hr className="mt-4" />
                        <p className="mt-2">Endereço de contato</p>
                        <div className="grid sm:grid-cols-3 sm:grid-cols-1  gap-4">

                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Cidade: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.city}
                                  onChange={e => setters.setAssociadoss({ ...states.Associadoss, city: e.target.value })}
                                />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Cidade: {states.Associadoss.city}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Estado: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.state}
                                  onChange={e => setters.setAssociadoss({ ...states.Associadoss, state: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Estado: {states.Associadoss.state}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                CEP: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.cep}
                                  onChange={e => setters.setAssociadoss({ ...states.Associadoss, cep: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">CEP: {formatarCEP(states.Associadoss.cep)}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Endereço: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.address}
                                  onChange={e => setters.setAssociadoss({ ...states.Associadoss, address: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Endereço: {states.Associadoss.address}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Bairro: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.neighborhood}
                                  onChange={e => setters.setAssociadoss({ ...states.Associadoss, neighborhood: e.target.value })}
                                />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Bairro: {states.Associadoss.neighborhood}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Número: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.houseNumber} onChange={e => setters.setAssociadoss({ ...states.Associadoss, houseNumber: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Número: {states.Associadoss.houseNumber}</p>
                              </p>
                            )
                          }
                          {
                            isEditing ? (
                              <p className="mt-2 text-md flex flex-col w-[90%] sm:w-[100%] ">
                                Complemento: <input className="text-base leading-6 mt-2 rounded-[8px]" type="text" value={states.Associadoss.complement} onChange={e => setters.setAssociadoss({ ...states.Associadoss, complement: e.target.value })} />
                              </p>
                            ) : (
                              <p className="mt-2 text-sm text-gray-700">
                                <p className="text-base font-semibold leading-6 text-gray-900 mt-2">Complemento: {states.Associadoss.complement}</p>
                              </p>
                            )
                          }
                        </div>

                        {
                          isEditing && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3' || localStorage.getItem('permission') == '5') && (

                            <button
                              className="mt-4 bg-blue-500 text-white px-10 py-2 rounded hover:bg-blue-600"
                              onClick={() => editAssociate()}
                            >
                              Editar associado
                            </button>
                          )
                        }
                      </div>

                    </div>

                    {
                      (localStorage.getItem('permission') == 0 || localStorage.getItem('permission') == 1 || localStorage.getItem('permission') == 2 || localStorage.getItem('permission') == 3) &&
                      <div className='border p-4 rounded-md mt-6'>
                        {/* Local de busca de faturas  */}

                        <div className="flex w-[100%] justify-between items-center">
                          <p>Cartões de crédito adicionados: </p> {/* pelo associado {states.Associadoss.name} */}
                          <div className="flex">
                            <input id={id} type='hidden' className='hidden' value={`${jsonConfig.urlAplication}/cartaoAssociate/${id}`} />
                            <span>
                              Link para adicionar novo cartão
                            </span>
                            <button
                              className="flex items-center text-gray-600 hover:text-gray-800 mr-2 ml-1 hover:bg-gray-200 rounded-md"
                              onClick={() => copyLinkSite(id)}
                            >
                              <DocumentDuplicateIcon className="w-5 h-5 text-gray-500" />
                            </button>
                            <button
                              className="flex items-center text-gray-600 hover:text-gray-800"
                              onClick={getProfilePayment}
                            >
                              <ChevronDoubleDownIcon
                                className={`${arrowButtonPayment ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                              />
                            </button>
                          </div>
                        </div>


                        {
                          !arrowButtonPayment && states.dataAssociadossCartoes.length != 0 && (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                            <>
                              <hr className='mt-4' />
                              <div className='flex justify-between mt-4'>
                                {/* Espaco que sera destinado a exibicao das faturas do associado. 
                            Descricao -> data ede expiracao - penalidade, preco status, reembolsado , Local ou gateway desconto
                          */}
                                <div className="flex flex-col w-full">
                                  <div className='flex justify-between'>
                                    <h1 className="text-2xl font-bold mb-4">Cartões de crédito</h1>
                                  </div>

                                  <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                                    {states.dataAssociadossCartoes.map((card, index) => (
                                      <div key={index} className="border p-4 rounded-md ">
                                        <p><strong>Bandeira:</strong> {card.brand}</p>
                                        <p><strong>4 últimos números:</strong> {card.finalNumber}</p>
                                        <p><strong>Nome:</strong> {card.name}</p>
                                        <p><strong>Status:</strong> {
                                          card.status == 'active' ? (
                                            <span className="text-green-500">Ativo</span>
                                          ) : card.status == 'deleted' ? (
                                            <span className="text-red-500">Deletado</span>
                                          ) : (
                                            <span className="text-yellow-500">Inativo</span>
                                          )
                                        }</p>
                                        <button
                                          className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                          onClick={() => openModalDeleteCard(card)}
                                        >
                                          Deletar cartão
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }

                        {
                          !arrowButtonPayment && states.dataAssociadossCartoes.length == 0 && <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid mt-20 mb-20"></div>
                          </div>
                        }
                      </div>
                    }
                    {
                      (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                        <div className='border p-4 rounded-md mt-6'>
                          <div className="flex w-[100%] justify-between items-center">
                            <p>Faturas do Associado:  {states.Associadoss.name}</p>
                            <button
                              className="flex items-center text-gray-600 hover:text-gray-800"
                              onClick={getInvoices}
                            >
                              <ChevronDoubleDownIcon
                                className={`${arrowButton ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                              />
                            </button>
                          </div>

                          {
                            !arrowButton && states.dataAssociadossFatura.data != undefined && (
                              <>
                                <hr className='mt-4' />
                                <div className='flex justify-between'>
                                  {/* Espaco que sera destinado a exibicao das faturas do associado. 
                            Descricao -> data ede expiracao - penalidade, preco status, reembolsado , Local ou gateway desconto
                          */}
                                  <div className="flex flex-col w-full mt-4 mb-4">
                                    <div className='flex justify-between'>
                                      <h1 className="text-2xl font-bold mb-4">Faturas</h1>

                                      {
                                        (localStorage.getItem('permission') == 0 || localStorage.getItem('permission') == 1 || localStorage.getItem('permission') == 2) ?

                                          (
                                            <>
                                              <div className='flex'>
                                                <p className='mr-2'><strong>Valor total:</strong> </p>
                                                {(states.dataAssociadossFatura.total / 100).toLocaleString('pt-BR', {
                                                  style: 'currency',
                                                  currency: 'BRL'
                                                })}
                                              </div>

                                            </>

                                          ) : (<></>)
                                      }
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                      {states.dataAssociadossFatura.data.map((invoice, index) => (
                                        <div key={index} className="border p-4 rounded-md">
                                          <h5 className="text-sm font-semibold mb-2">{invoice.description}</h5>
                                          <p><strong>Valor:</strong>  {(invoice.price / 100).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                          })}</p>
                                          <p><strong className='mr-2'>Status:</strong>

                                            {
                                              (invoice.status == 'paid' || invoice.status == 'externally_paid') && (
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                  Pago
                                                </span>
                                              )
                                            }
                                            {
                                              invoice.status == 'pending' && (

                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                  Pendente
                                                </span>

                                              )
                                            }
                                            {
                                              invoice.status == "expired" && (

                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-yellow-800">
                                                  Expirado
                                                </span>

                                              )
                                            }
                                            {
                                              invoice.status == 'canceled' && (

                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                  Cancelado
                                                </span>

                                              )
                                            }
                                            {
                                              invoice.status == "refunded" && (


                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-400 text-white">
                                                  Estornado
                                                </span>


                                              )
                                            }

                                          </p>
                                          <p><strong>Descrição:</strong> {invoice.description}</p>
                                          <p><strong>Desconto:</strong> {invoice.discount} %</p>
                                          <input id={invoice.id} type='hidden' className='hidden' value={invoice.path} />

                                          <div className='flex'>
                                            <p><strong className='mb-2'>Link:</strong></p>
                                            {
                                              invoice.path == '/' ? (
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                  Pagamento Local
                                                </span>
                                              ) : (
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
                                                  <button className="px-2 text-gray-400 border bg-gray-50 border-gray-200 hover:bg-gray-200 rounded-md" onClick={() => copyLinkSite(invoice.id)}>
                                                    {/* <DocumentDuplicateIcon className="h-7 w-7" /> */}
                                                    Copiar
                                                  </button>
                                                </span>
                                              )
                                            }
                                          </div>
                                          <p><strong>Multa:</strong>  {(invoice.penalty / 100).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                          })}</p>
                                          <p><strong>Expiração:</strong>   {moment(invoice.experies_at).format('DD/MM/YYYY')}</p>
                                          <p><strong>Criação:</strong>   {moment(invoice.created_at).format('DD/MM/YYYY')}</p>
                                          <p><strong>Atualização:</strong>   {moment(invoice.updated_at).format('DD/MM/YYYY')}</p>
                                        </div>
                                      ))}
                                    </div>

                                    <div className='flex items-center justify-center'>
                                      {
                                        !states.limitSearchAssociadoss &&
                                        <button className="mt-4 text-blue-600 hover:text-blue-400 font-bold py-2 px-4 rounded" onClick={loadMore} >Carregar mais faturas</button>
                                      }
                                    </div>

                                  </div>
                                </div>
                              </>
                            )
                          }

                          {
                            !arrowButton && states.dataAssociadossFatura.data == undefined &&
                            <div className="flex justify-center items-center">
                              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid mt-20 mb-20"></div>
                            </div>
                          }
                        </div>
                      )
                    }


                    {/* Área de Comentários */}
                    <div className="border p-4 rounded-md mt-6">
                      {/* Exibir Comentários */}
                      <div className="">
                        <div className="flex w-[100%] justify-between">

                          <p>Comentários</p>
                          <button
                            className="flex items-center text-gray-600 hover:text-gray-800"
                            onClick={() => setExibirComentarios(!exibirComentarios)}
                          >
                            <ChevronDoubleDownIcon
                              className={`${exibirComentarios ? 'transform rotate-180' : ''
                                } w-5 h-5 text-gray-500`}

                            />
                          </button>
                        </div>
                        {exibirComentarios && (
                          <div className="mt-4 space-y-2">
                            {states.Associadoss.comments.length > 0 ? states.Associadoss.comments.map((comentario, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="border p-4 rounded-md bg-gray-50 flex flex-row justify-between"
                                  >
                                    <div>
                                      <p className="font-semibold">{comentario.title}</p>
                                      <p>{comentario.description}</p>
                                    </div>
                                    <div>
                                      <p className="font-semibold">Criador:</p>
                                      <p>{comentario.email}</p>
                                    </div>
                                    {
                                      ((localStorage.getItem('email') === comentario.email || localStorage.getItem('permission') == 0 || localStorage.getItem('permission') == 1 || localStorage.getItem('permission') == 2)) && (
                                        <button className="mt-1 border border-gray-200 w-10 h-10 flex items-center justify-center rounded-md hover:bg-gray-50" onClick={() => openModalDeleteComment(comentario)}>
                                          <TrashIcon className="w-5 h-5 text-red-500" />
                                        </button>
                                      )
                                    }

                                  </div>
                                </>
                              )

                            }) : (
                              <p className="text-sm text-gray-600">Nenhum comentário ainda</p>
                            )
                            }
                          </div>
                        )}
                      </div>
                      {
                        (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                          <>
                            <p className="text-lg font-semibold mb-2 mt-4">Adicionar Comentário</p>
                            <input
                              className="w-full mb-2 border rounded-md border-gray-200"
                              type="text"
                              placeholder="Digite título do seu comentário"
                              value={autor}
                              onChange={(e) => setAutor(e.target.value)}
                            />
                            <textarea
                              className="w-full mb-2 border rounded-md border-gray-200 mt-2"
                              rows="4"
                              placeholder="Digite seu comentário"
                              value={novoComentario}
                              onChange={(e) => setNovoComentario(e.target.value)}
                            />
                            <button
                              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                              onClick={adicionarComentario}
                            >
                              Adicionar Comentário
                            </button>
                          </>
                        )
                      }
                    </div>

                    {/* Área de Anexar Arquivos */}
                    {
                      (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2' || localStorage.getItem('permission') == '3') && (
                        <div className="mt-6">
                          <div className="border border-gray-200 rounded-md p-4">
                            {
                              arquivoSelecionado && (
                                <input
                                  className="w-full px-3 py-2 mb-2 border rounded"
                                  type="text"
                                  placeholder="Digite titulo para o arquivo"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              )
                            }
                            <div
                              className={`border-2 border-dashed ${arquivoSelecionado ? 'bg-blue-50' : ''
                                } p-4 rounded-[10px] cursor-pointer`}
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={handleFileDrop}
                              onClick={handleInputArquivoClick}
                            >
                              {!arquivoSelecionado && (
                                <div className="flex items-center justify-center space-x-2">
                                  <ArrowUpOnSquareIcon className="w-5 h-5 text-gray-500" />
                                  <p className="text-gray-500">
                                    Arraste e solte ou clique para anexar um arquivo (jpg, jpeg, png)
                                  </p>
                                </div>
                              )}
                              {arquivoSelecionado && (
                                <>
                                  <div className="flex items-center justify-between">
                                    <p>{arquivoSelecionado.name}</p>
                                    <button
                                      className="text-red-500 hover:text-red-700"
                                      onClick={() => setArquivoSelecionado(null)}
                                    >
                                      Remover
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              accept=".jpg,.jpeg,.png,.pdf,.docx"
                              ref={inputArquivoRef}
                              className="hidden"
                              onChange={handleFileChange}
                            />
                            {arquivoSelecionado && (
                              <button
                                className="bg-blue-500 text-white px-4 py-2 mt-2 rounded hover:bg-blue-600"
                                onClick={handleFileUpload}
                              >
                                Anexar Arquivo
                              </button>
                            )}
                          </div>
                          {states.Associadoss.files.length > 0 && (
                            <div className="mt-2 space-y-2">
                              <p className="font-semibold">Arquivos Anexados:</p>
                              {states.Associadoss.files.length > 0 ? states.Associadoss.files.map((arquivo, inde) => {
                                return (
                                  <>

                                    <div className="p-2 flex ">
                                      <button className="blorder shadow w-10 h-10 flex items-center justify-center rounded-[10px]" onClick={() => viewBinary(arquivo.fileName, arquivo.datafile, inde)}>
                                        <MagnifyingGlassIcon className="w-5 h-5 text-black" />
                                      </button>
                                      <button className="ml-5 blorder shadow w-10 h-10 flex items-center justify-center rounded-[10px]" onClick={() => openModalDelete(arquivo)}>
                                        <TrashIcon className="w-5 h-5 text-red-500" />
                                      </button>
                                      {showFile && index == inde && (
                                        <button onClick={closeViewer} className="ml-5 blorder shadow w-10 h-10 flex items-center justify-center rounded-[10px]">
                                          <XCircleIcon className="w-5 h-5 text-black" />
                                        </button>)
                                      }
                                    </div>
                                    <div key={inde} className="border p-2 rounded bg-gray-50 mb-8">
                                      <p>{arquivo.title} - {arquivo.fileName}</p>
                                    </div>
                                    {showFile && index == inde && (
                                      <div>
                                        {fileType === 'application/pdf' && (
                                          <embed src={`data:application/pdf;base64,${binaryData}`} type="application/pdf" width="100%" height="500px" />
                                        )}
                                        {(fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/gif') && (
                                          <img src={`data:image/png;base64,${binaryData}`} alt="Imagem" />
                                        )}
                                      </div>
                                    )}

                                  </>
                                )
                              }) : (
                                <p className="text-sm text-gray-600">Nenhum arquivo anexado</p>
                              )
                              }



                            </div>
                          )}
                        </div>
                      )
                    }
                  </div>
                )
              }
              <ModalConfirmCard states={states} action={deleteCard} setters={setters} title={'Deletar cartão de crédito'} description={'Deseja realmente deletar esse cartão?'} />

              <ModalConfirm states={states} action={actionDelete} setters={setters} title={titleModalDelete} description={descriptionModalDelete} />
              <Footer />
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
