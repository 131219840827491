
import { useState, useEffect } from "react"
import UploadJuridico from "../../UploadJuridico"

const AdicaoSecao = ({ states, setters, adicao, isEdit, section, edit, sections }) => {

    const [file, setFile] = useState(false)
    const [motion, setMotion] = useState({
        titulo: '',
        texto: '',
        publica: null,
        file: null
    })
    const [error, setError] = useState({
        titulo: false,
        texto: false,
        publica: false,
        file: false
    })

    const saveFile = (file) => {
        setMotion({ ...motion, file: file })
    }

    const saveMotion = () => {
        let errorProcess = false;
        for (const item of Object.keys(motion)) {
            if (item === 'file') continue;
            if ((motion[item] === '' || motion[item] === ' ' || motion[item] === undefined) && (motion[item] !== false && motion[item] !== true)) {
                setError(e => {
                    return { ...e, [item]: true }
                })
                errorProcess = true
            } else {
                setError(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (errorProcess) return;
        if (isEdit) {
            edit({
                id: section.id,
                isPublic: motion.publica,
                text: motion.texto,
                title: motion.titulo,
                file: motion.file
            })
            return;
        }
        let identifier = 1;
        if (sections.length > 0) {
            const haveThis = sections.filter(e => e.id === identifier)
            if (haveThis.length > 0) {
                let haveId = true;
                while (haveId) {
                    identifier++;
                    const haveThis = sections.filter(e => e.id === identifier)
                    if (haveThis.length === 0) {
                        haveId = false;
                    }
                }
            } else {

            }
        }
        adicao({
            id: identifier,
            isPublic: motion.publica,
            text: motion.texto,
            title: motion.titulo,
            file: motion.file
        })
        setMotion({
            titulo: '',
            texto: '',
            publica: null,
            file: null
        })
    }



    useEffect(() => {
        if (isEdit) {
            setMotion({
                id: section.id,
                titulo: section.title,
                texto: section.text,
                publica: section.isPublic,
                file: section.file
            })
            section.file && setFile(true)
        } else {
            setError({ titulo: false, texto: false, publica: false, file: false })
            setMotion({ titulo: '', texto: '', publica: null, file: null })
        }

    }, [])

    return (
        <div className="flex w-full flex-col  p-3">
            {/* Adicao de arquivo e text */}
            <div className="flex flex-col w-full">
                <p className="text-sm font-bold text-gray-500">Adicionar seção</p>
            </div>

            <div className="flex flex-col w-full mt-4 rounded-[10px] border p-3">
                <p className="text-sm font-bold text-gray-500">Adicionae um título para a seção</p>
                <input
                    className="w-full border rounded-[10px] p-2 mt-1"
                    type="text"
                    placeholder="Título da seção"
                    value={motion.titulo}
                    onChange={(e) => setMotion({ ...motion, titulo: e.target.value })} />
                {
                    error.titulo && <p className="text-sm font-bold text-red-500">Adicione um titulo para a seção</p>
                }
                <p className="text-sm font-bold text-gray-500 mt-3">Adicionae um texto para a seção</p>
                <textarea
                    className="w-full border rounded-[10px] p-2 mt-1"
                    type="text"
                    placeholder="Texto da seção"
                    value={motion.texto}
                    onChange={(e) => setMotion({ ...motion, texto: e.target.value })} />
                {
                    error.texto && <p className="text-sm font-bold text-red-500">Adicione um texto para a seção</p>
                }
                {/* Checkbox para arquivos */}

                <div className="flex w-full mt-1 items-center p-3">
                    <p className="text-sm font-bold text-gray-500 mt-3">Seção publica?</p>
                    <div className="flex mt-3 items-center p-3">
                        <input
                            className="w-4 h-4 ml-2 rounded-[4px]"
                            type="checkbox"
                            checked={motion.publica === true}
                            onChange={() => setMotion({ ...motion, publica: true })} />
                        <p className="text-sm font-bold text-gray-500 ml-2">Sim</p>
                        <input
                            className="w-4 h-4 ml-2 rounded-[4px]"
                            type="checkbox"
                            checked={motion.publica === false}
                            onChange={() => setMotion({ ...motion, publica: false })} />
                        <p className="text-sm font-bold text-gray-500 ml-2">Não</p>
                    </div>
                    {
                        error.publica && <p className="text-sm font-bold text-red-500">Selecione se a seção é publica ou não</p>
                    }
                </div>
                <div className="flex w-full mt-1 items-center p-3">
                    <p className="text-sm font-bold text-gray-500 ">Deseja adicionar um arquivo?</p>
                    <input
                        className="w-4 h-4 ml-2 rounded-[4px]"
                        type="checkbox"
                        checked={file}
                        onChange={() => setFile(!file)} />
                    <p className="text-sm font-bold text-gray-500 ml-2">Sim</p>
                </div>
                {
                    file &&
                    <div className="flex flex-col w-full mt-1 items-center p-3">
                        {
                            motion.file &&
                            <div className="flex w-full flex-col items-end">
                                <button
                                    className=" bg-blue-500 text-white rounded-full p-2 text-xs mb-5"
                                    onClick={() => {
                                        setFile(false)
                                        setMotion({ ...motion, file: null })
                                    }} >X</button>
                            </div>
                        }
                        <UploadJuridico file={saveFile} setFile={setters.setFile} />
                        {
                            motion.file && <p className="text-sm font-bold text-gray-500 mt-2">{motion.file.name}</p>
                        }
                    </div>
                }
                <div className="flex w-full mt-3">
                    <button
                        className="w-full bg-blue-500 text-white rounded-[10px] p-2"
                        onClick={() => saveMotion()}

                    >
                        {
                            isEdit ? 'Editar' : 'Adicionar'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}


export default AdicaoSecao