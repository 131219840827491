import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/ferias'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'
import Table from '../../../../Components/Tabela/TabelasFerias/TabelaPainelVendaFerias'

import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'


export default function PainelVendaFerias() {
    const { dataPainelVendaFerias  } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    useEffect(() => {
        return () => {
            setters.setOffSetPainelVendaFerias(0)
            setters.setLimitSearchPainelVendaFerias(false)
            setters.setDataPainelVendaFerias([])
        }
    }, [])


    useEffect(() => {
        listarAsync()
    }, [states.offsetPainelVendaFerias])

    const listarAsync = async () => {
        await dataPainelVendaFerias()
    }

    const next = async () => {
        if (states.dataPainelVendaFerias.length === 0) {
            return
        } else {
            const data = states.dataQuery
            setters.setOffSetPainelVendaFerias(states.offsetPainelVendaFerias + states.limitPainelVendaFerias)
            const sumOff = states.offsetPainelVendaFerias + states.limitPainelVendaFerias
            const limit = states.limitPainelVendaFerias + sumOff
            const newData = data.slice(sumOff, limit)
            setters.setDataPainelVendaFerias(newData)
        }
    };

    const previous = async () => {
        if (states.dataPainelVendaFerias.length === 0 && states.offsetPainelVendaFerias <= 0) {
            return
        } else {
            const data = states.dataQuery
            states.offsetPainelVendaFerias > 0 && setters.setOffSetPainelVendaFerias(states.offsetPainelVendaFerias - states.limitPainelVendaFerias)
            states.offsetPainelVendaFerias < 0 && setters.setOffSetPainelVendaFerias(0)
            const sumOff = states.offsetPainelVendaFerias > 0 ? states.offsetPainelVendaFerias - states.limitPainelVendaFerias : 0;
            const limit = sumOff + 10 > states.limitPainelVendaFerias ? sumOff + 10 : states.limitPainelVendaFerias;
            const newData = data.slice(sumOff, limit)
            setters.setDataPainelVendaFerias(newData)
        }
    };

    const headers = ['Nome', 'Data Início', 'Data Fim', 'Valor da Venda', 'Data da Venda']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />
                            
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de Venda de Férias</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as vendas ocorridas de férias
                            </p>

                            <Table title='Painel de Venda de Férias' description='Registro das vendas ocorridas de férias' 
                                   next={next} previous={previous} states={states} headers={headers} 
                                   infoTable={{ offset: states.offsetPainelVendaFerias, limit: states.limitPainelVendaFerias }} dataTable={states.dataPainelVendaFerias} />
                        </div>
                    </div>
                </div>

                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
