import './index.css'
import moment from 'moment'

import { useEffect, useState, useRef } from 'react'
import { PlusIcon, ArrowLeftIcon, ArrowRightIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'

const TableAlertas = ({ del, states, edit, add, headers, dataTable, next, previous, infoTable, title, description }) => {

    const nextPage = () => {
        next()
    }

    const previousPage = () => {
        previous()
    }

    useEffect(() => {
        return () => {
            dataTable = []
        }
    }, [])

    const truncat = (str, value) => {
        return str?.length > value ? str.substring(0, value) + "..." : str;
    }

    const replaceDateTime = (str) => {
        const spllit_ = str.split('_')
        return spllit_[1]

    }

    const  downloadBase64File = (base64String, fileName)  => {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${base64String}`;
        link.download = fileName; // Define o nome do arquivo para download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const trGen = () => {
        if (states.isLoading) {
            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTable.length > 0 && Object.keys(dataTable[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {


            return (
                dataTable.length > 0 ? (
                    dataTable.map((logs) => {
                        //console.log(logs)
                        return (
                            <tr key={logs.id} className="hover:bg-gray-100">
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">{
                                    truncat(logs.title, 50)
                                }</td>
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                    {truncat(logs.description, 50)}
                                </td>
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                    {
                                        logs.link !== "null" ? (
                                            <a href={logs.link} target="_blank" className="text-blue-500 hover:underline">
                                                {truncat(logs.link, 50)}
                                            </a>
                                        ) : (
                                            <p className="text-gray-500">Sem link</p>
                                        )
                                    }
                                </td>
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                    {
                                        logs.pathFile !== null ? (
                                            <button className="text-blue-500 hover:underline" onClick={() => downloadBase64File(logs.pathFile, logs.fileFile)}>
                                                {truncat(replaceDateTime(logs.fileFile), 50)}
                                            </button>
                                        ) : (
                                            <p className="text-gray-500">Sem arquivo</p>
                                        )
                                    }
                                </td>
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                    {
                                        logs.pathImage !== null ? (
                                            <button className="text-blue-500 hover:underline" onClick={() => downloadBase64File(logs.pathImage, logs.fileImage)}>
                                                {truncat(replaceDateTime(logs.fileImage), 50)}
                                            </button>
                                        ) : (
                                            <p className="text-gray-500">Sem Imagem</p>
                                        )
                                    }
                                </td>
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                    {moment(logs.created_at).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                    {moment(logs.updated_at).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-8 py-6 whitespace-nowrap text-right flex items-center space-x-2">
                                    <button onClick={() => edit(logs)} className="text-blue-500 hover:underline">
                                        <PencilIcon className="h-6 w-6" />
                                    </button>
                                    <button onClick={() => del(logs)} className="text-red-500  hover:underline">
                                        <TrashIcon className="h-6 w-6" />
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500" colSpan="5">
                            Sem registro de dados
                        </td>
                    </tr>
                )
            )

        }
    }

    const scrollContainerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const scrollTable = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // A quantidade de pixels para rolar
            const currentScroll = scrollContainerRef.current.scrollLeft;
            scrollContainerRef.current.scrollLeft = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
        }
    };
    useEffect(() => {
        const checkOverflow = () => {
            const container = scrollContainerRef.current;
            if (container) {
                const isOverflowing = container.scrollWidth > container.clientWidth;
                setShowScrollButtons(isOverflowing);
            }
        };

        // Verifica o overflow quando o componente é montado
        checkOverflow();

        // Adiciona um ouvinte para verificar o overflow quando a janela é redimensionada
        window.addEventListener('resize', checkOverflow);

        // Remove o ouvinte quando o componente é desmontado
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <div className="mt-4">
            {showScrollButtons && (
                <div className="flex justify-between">
                    <button
                        onClick={() => scrollTable('left')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowLeftIcon className="h-7 w-7 text-blue-500" />
                    </button>
                    <button
                        onClick={() => scrollTable('right')}
                        className="border border-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded rounded-full transition-transform duration-300 hover:-translate-y-1"
                    >
                        <ArrowRightIcon className="h-7 w-7 text-blue-500" />
                    </button>
                </div>
            )}
            <div className="mt-4 overflow-x-auto" ref={scrollContainerRef}>
                <div className="inline-block min-w-full  rounded-md border border-gray-200">

                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 justify-between items-center">
                        <div className="flex flex-1 items-center">
                                <button
                                    onClick={() => add()}
                                    className="flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0"
                                >
                                    <PlusIcon className="h-12 w-12" />
                                </button>
                                <div className="flex flex-col ml-4">
                                    <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                    <p className="text-gray-500">{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                {
                                    headers.map((headers, index) => (
                                        <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                            {headers}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                trGen()
                            }
                        </tbody>
                    </table>
                    <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                        <div className="flex flex-1 justify-between sm:justify-start">
                            <div className="hidden sm:block mt-2">
                                <p className="text-sm text-gray-700">
                                    Mostrando registros de {infoTable.offset > 0 ? infoTable.offset : 1} a { dataTable.length === infoTable.limit ? infoTable.limit : dataTable.length} 
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-1 justify-start sm:justify-end">
                            <button
                                className="border border-gray-200 relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => previousPage()}
                            >
                                Anterior
                            </button>
                            <button
                                href="#"
                                className="border border-gray-200 relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                onClick={() => nextPage()}
                            >
                                Próximo
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>







    )

}


export default TableAlertas;