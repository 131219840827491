import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import DragDropFile from '../../UploadFile/componenteFile'

export default function ModalFaturas(props) {

  useEffect(() => {
    if (props.states.modalIsOpen == false) {
      props.setters.setTerceirizadoFatura({})
      props.setters.setAssociadoFatura({})
      props.setters.setCodeRegister('')
      limparStatesModalPlano()
    }
  }, [props.states.modalIsOpen]);

  const [preco, setPreco] = useState('')
  const [viewPreco, setViewPreco] = useState('')
  const [quantidade, setQuantidade] = useState('')
  const [descricao, setDescricao] = useState('')
  const [dataExpiracao, setDataExpiracao] = useState('')
  const [desconto, setDesconto] = useState('')
  const [multa, setMulta] = useState('')
  const [gateway, setGateway] = useState('')
  const [idAssociado, setIdAssociado] = useState('')
  const [idTerceirizado, setIdTerceirizado] = useState('')

  const [errorPreco, setErrorPreco] = useState(false)
  const [errorQuantidade, setErrorQuantidade] = useState(false)
  const [errorDescricao, setErrorDescricao] = useState(false)
  const [errorDataExpiracao, setErrorDataExpiracao] = useState(false)
  const [errorDesconto, setErrorDesconto] = useState(false)
  const [errorMulta, setErrorMulta] = useState(false)
  const [errorGateway, setErrorGateway] = useState(false)
  const [errorIdAssociado, setErrorIdAssociado] = useState(false)
  const [errorIdTerceirizado, setErrorIdTerceirizado] = useState(false)
  const [errorfile, setErrorFile] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const [entidade, setEntidade] = useState('')
  const [entidadeError, setEntidadeError] = useState(false)

  const [isAssociate, setIsAssociate] = useState(false)
  const [isTerceirizado, setIsTerceirizado] = useState(false)

  const [cobranca, setCobranca] = useState('')
  const [errorCobranca, setErrorCobranca] = useState(false)



  const handleChange = (inputValue, origin) => {
    try {
      if (inputValue.length > 0) {
        const value = inputValue.replace(/[^0-9]/g, '')
        const valueMoney = value / 100
        if (origin == 'preco') {
          const arrPrice = value.split('')
          if (arrPrice.length > 2) {

            arrPrice.splice(-2, 0, ',')
            const valueString = arrPrice.join('')
            setViewPreco(valueString)
          } else {
            setViewPreco(value)
          }
          // // 
          setPreco(value)
        }
        else if (origin == 'desconto') {
          setDesconto(valueMoney)
        }
        else if (origin == 'multa') {
          setMulta(valueMoney)
        }
      }
      else {
        if (origin == 'preco') {
          setPreco('')
        }
        else if (origin == 'desconto') {
          setDesconto('')
        }
        else if (origin == 'multa') {
          setMulta('')
        }
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'preco') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPreco(true)
      }
      else {
        setErrorPreco(false)
      }
    }
    else if (tipo === 'quantidade') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorQuantidade(true)
      }
      else {
        setErrorQuantidade(false)
      }
    }
    else if (tipo === 'cobranca') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorQuantidade(true)
      }
      else {
        setErrorQuantidade(false)
      }
    }
    else if (tipo === 'descricao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDescricao(true)
      }
      else {
        setErrorDescricao(false)
      }
    }
    else if (tipo === 'dataExpiracao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDataExpiracao(true)
      }
      else {
        setErrorDataExpiracao(false)
      }
    }
    else if (tipo === 'desconto') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDesconto(true)
      }
      else {
        setErrorDesconto(false)
      }
    }
    else if (tipo === 'multa') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorMulta(true)
      }
      else {
        setErrorMulta(false)
      }
    }
    else if (tipo === 'gateway') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorGateway(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorGateway(true)
        }
        else {
          setErrorGateway(false)
        }
      }
    }
    else if (tipo === 'entidade') {
      if (valor === undefined || valor === null || valor === '') {
        setEntidadeError(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setEntidadeError(true)
        }
        else {
          setEntidadeError(false)
        }
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()

    /*if (idAssociado === '' || idTerceirizado === '') {
      if (idAssociado === '') {
        setErrorIdAssociado(true)
      }
      if (idTerceirizado === '') {
        setErrorIdTerceirizado(true)
      }
      return

    }*/
    const validator = errorPreco === false && errorQuantidade === false && errorDescricao === false && errorDataExpiracao === false && errorDesconto === false && errorMulta === false && errorGateway === false && entidadeError === false ? true : false
    if (!validator) return
    if (props.states.filePagamento == '') {
      setErrorFile(true)
      return
    } else {
      setErrorFile(false)
    }

    // if (props.states.isEdit) {

    //   const body = {
    //     price: preco,
    //     amount: quantidade,
    //     description: descricao,
    //     experies_at: dataExpiracao,
    //     penalty: multa,
    //     payment: 0,
    //     status: gateway,
    //     idAssociate: idAssociado !== '' ? idAssociado : null,
    //     idOutSourced: idTerceirizado !== '' ? idTerceirizado : null,
    //     discount: desconto,

    //   }
    //   // 
    //   props.post(body)
    // } else {

    const body = {
      price: preco,
      amount: quantidade,
      description: descricao,
      experies_at: dataExpiracao,
      penalty: multa,
      payment: 0,
      status: gateway,
      idAssociate: idAssociado !== '' ? idAssociado : null,
      idOutSourced: idTerceirizado !== '' ? idTerceirizado : null,
      discount: desconto,
      file: props.states.filePagamento,
      expenses_id: cobranca

    }
    // 

    props.post(body)


  }

  const limparStatesModalPlano = () => {
    setPreco('')
    setQuantidade('')
    setDescricao('')
    setDataExpiracao('')
    setDesconto('')
    setMulta('')
    setGateway('')
    setIdAssociado('')
    setIdTerceirizado('')
    setViewPreco('')
    setErrorPreco(false)
    setErrorQuantidade(false)
    setErrorDescricao(false)
    setErrorDataExpiracao(false)
    setErrorDesconto(false)
    setErrorMulta(false)
    setErrorGateway(false)
    setErrorIdAssociado(false)
    setErrorIdTerceirizado(false)
  }

  const handleEntidade = (value) => {
    props.setters.setTerceirizadoFatura({})
    props.setters.setAssociadoFatura({})
    props.setters.setCodeRegister('')
    if (value === '0') {
      setIsAssociate(false)
      setIsTerceirizado(false)
    }
    if (value === '1') {
      // // 
      setIsAssociate(true)
      setIdTerceirizado(0)
      setIsTerceirizado(false)
    }
    else if (value === '2') {
      // // 
      setIdAssociado(0)
      setIsAssociate(false)
      setIsTerceirizado(true)
    }
    setEntidade(value)
    // // 
  }

  useEffect(() => {
    if (Object.keys(props.states.associadoFatura).length > 0) {
      setIdAssociado(props.states.associadoFatura.id)
    }
  }, [props.states.associadoFatura])

  useEffect(() => {
    if (Object.keys(props.states.terceirizadoFatura).length > 0) {
      setIdTerceirizado(props.states.terceirizadoFatura.id)
    }
  }, [props.states.terceirizadoFatura])

  const formatCpf = (cpf) => {
    cpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona ponto após os primeiros 3 dígitos
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona ponto após os próximos 3 dígitos
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona traço antes dos últimos 2 dígitos

    return cpf;

  }

  const clearSelect = () => {
    setEntidade('')
    setIsAssociate(false)
    setIsTerceirizado(false)
    setEntidadeError(false)
    props.setters.setTerceirizadoFatura({})
    props.setters.setAssociadoFatura({})
    props.setters.setCodeRegister('')
    props.setters.setFilePagamento(null)
  }

  const handleCheckboxChange = () => {
    props.setters.setIsEdit(!props.states.isEdit);
  };

  const setFile = (file) => {
    props.setters.setFilePagamento(file)
  }

  const changeRegisterCode = (e) => {
    const value = e.replace(/\D/g, '')
    props.setters.setCodeRegister(value)
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Fatura</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações da fatura
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1">

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Preço
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">R$</span>
                              <input
                                type="text"
                                name="preco"
                                id="preco"
                                autoComplete="preco"
                                placeholder={props.states.waitTimeout === false ? '0,00' : 'Carregando...'}
                                disabled={disabled}
                                value={viewPreco}
                                onBlur={() => leaveInput(preco, 'preco')}
                                onChange={e => handleChange(e.target.value, 'preco')}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorPreco === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do preço.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Quantidade
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="number"
                                name="quantidade"
                                id="quantidade"
                                autoComplete="quantidade"
                                placeholder={props.states.waitTimeout === false ? 'Digite a quantidade' : 'Carregando...'}
                                disabled={disabled}
                                value={quantidade}
                                onBlur={() => leaveInput(quantidade, 'quantidade')}
                                onChange={e => setQuantidade(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorQuantidade === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da quantidade.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select
                                id="gateway"
                                name="gateway"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setGateway(e.target.value)}
                                onBlur={() => leaveInput(gateway, 'gateway')}
                                value={
                                  gateway
                                }
                              >
                                <option>Selecione ..</option>
                                <option value='paid'>Fatura paga</option>
                                <option value='pending'>Fatura não paga</option>
                              </select>

                            </div>
                          </div>
                          {
                            errorGateway === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do status.
                            </p>
                          }
                        </div>
                        <div className="sm:col-span-4">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Tipo de Custo
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select
                                id="gateway"
                                name="gateway"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setCobranca(e.target.value)}
                                onBlur={() => leaveInput(cobranca, 'cobranca')}
                                value={
                                  cobranca
                                }
                              >
                                <option>Selecione ..</option>
                                {
                                  props.states.dataCustos.map((item, index) => {
                                    return (
                                      <option value={item.id}>{item.type}</option>
                                    )

                                  })
                                }
                              </select>

                            </div>
                          </div>
                          {
                            errorCobranca === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório a seleção do tipo de custo.
                            </p>
                          }
                        </div>

                        {
                          /* 
                                                <div className="col-span-full">
                                                  <div className="flex">
                                                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                      Salvar arquivo?
                                                    </label>
                                                    <input
                                                      type="checkbox"
                                                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded mt-1 ml-3"
                                                      checked={props.states.isEdit}
                                                      onChange={handleCheckboxChange}
                                                    />
                        
                        
                                                  </div>
                                                </div>
                          */
                        }
                        <div className="col-span-full">
                          <div className="flex">
                            <div className='mb-4 w-full'>
                              <p className="mt-2 ml-1 text-sm text-gray-700">
                                Arraste ou clique em cima da área para adicionar o arquivo
                              </p>
                              <div class="flex items-center justify-center w-full flex flex-col items-center justify-center mt-4">
                                <DragDropFile setters={props.setters} file={setFile} />
                              </div>
                            </div>
                          </div>
                          {
                            errorfile === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o envio do arquivo financeiro
                            </p>
                          }
                        </div>









                        <div className="col-span-full">
                          <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            Descrição
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="descricao"
                              name="descricao"
                              type="text"
                              disabled={disabled}
                              value={descricao}
                              onBlur={() => leaveInput(descricao, 'descricao')}
                              onChange={e => setDescricao(e.target.value)}
                              placeholder={props.states.waitTimeout === false ? 'Digite a descrição' : 'Carregando...'}
                              rows={3}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              defaultValue={''}
                            />
                          </div>
                          {
                            errorDescricao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da descrição.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Data de Expiração
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="date"
                                name="dataExpiracao"
                                id="dataExpiracao"
                                autoComplete="dataExpiracao"
                                placeholder={props.states.waitTimeout === false ? 'Digite a data de expiração' : 'Carregando...'}
                                disabled={disabled}
                                value={dataExpiracao}
                                onBlur={() => leaveInput(dataExpiracao, 'dataExpiracao')}
                                onChange={e => setDataExpiracao(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              //  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorDataExpiracao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da data de expiração.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Desconto
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                type="text"
                                name="desconto"
                                id="desconto"
                                autoComplete="desconto"
                                placeholder={props.states.waitTimeout === false ? '0,00' : 'Carregando...'}
                                disabled={disabled}
                                value={desconto}
                                onBlur={() => leaveInput(desconto, 'desconto')}
                                onChange={e => handleChange(e.target.value, 'desconto')}
                                //  className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                              <span className="flex select-none items-center pr-3 pl-2 text-gray-500 sm:text-sm">%</span>
                            </div>
                          </div>
                          {
                            errorDesconto === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do desconto.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Multa
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                type="text"
                                name="multa"
                                id="multa"
                                autoComplete="multa"
                                placeholder={props.states.waitTimeout === false ? '0,00' : 'Carregando...'}
                                disabled={disabled}
                                value={multa}
                                onBlur={() => leaveInput(multa, 'multa')}
                                onChange={e => handleChange(e.target.value, 'multa')}
                                //  className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                              <span className="flex select-none items-center pr-3 pl-2 text-gray-500 sm:text-sm">%</span>
                            </div>
                          </div>
                          {
                            errorMulta === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da multa.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Entidade
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select
                                id="associado"
                                name="associado"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => handleEntidade(e.target.value)}
                                onBlur={() => leaveInput(entidade, 'entidade')}
                                value={
                                  entidade
                                }
                              >
                                <option value='0'>Selecione ..</option>
                                <option value='1'>Associado</option>
                                {
                                  (localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1') && (
                                    <option value='2'>Terceirizado</option>
                                  )
                                }
                              </select>

                            </div>
                          </div>
                          {
                            entidadeError === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da entidade.
                            </p>
                          }
                        </div>
                        {

                          //Aqui comeca a busca que deve ir no back, assim ja valida e ja pode ir para faturas de pagamentoooo
                        }
                        {
                          isAssociate === true && (
                            <div className='flex  items-center mt-6 '>
                              <div class="flex">
                                <input
                                  type="text"
                                  class="w-64 py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                  placeholder="Digite sua busca..."
                                  value={props.states.codeRegister}
                                  onChange={e => changeRegisterCode(e.target.value)}
                                />
                              </div>
                              <div class="flex top-0 right-0  ml-4">
                                <MagnifyingGlassIcon class="h-8 w-8 text-gray-400" aria-hidden="true" onClick={() => props.associado()} />
                              </div>
                            </div>
                          )
                        }
                        {
                          isTerceirizado === true && (
                            <div className='flex  items-center mt-7 '>
                              <div class="flex">
                                <input
                                  type="text"
                                  class="w-64 py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                  placeholder="Digite o CPF ou CNPJ"
                                  value={props.states.codeRegister}
                                  onChange={e => changeRegisterCode(e.target.value)}
                                />
                              </div>
                              <div class="flex top-0 right-0  ml-4">
                                <MagnifyingGlassIcon class="h-8 w-8 text-gray-400" aria-hidden="true" onClick={() => props.terceirizado()} />
                              </div>
                            </div>
                          )
                        }



                      </div>
                      {
                        Object.keys(props.states.associadoFatura).length > 0 && (
                          <>
                            <div className="w-[70%] border shadow-md hover:shadow-lg rounded-[10px] flex-rw  items-center justify-start flex">
                              <div className="p-5 ">
                                Email: {props.states.associadoFatura.email || props.states.associadoFatura.emailAssociate} <br />
                                CPF: {formatCpf(props.states.associadoFatura.cpf)} <br />
                                Nome: {props.states.associadoFatura.name || props.states.associadoFatura.name}
                              </div>
                              <div className="p-5 ">
                                <XMarkIcon class="h-8 w-8 text-gray-400" aria-hidden="true" onClick={clearSelect} />

                              </div>
                            </div>
                            {
                              errorIdAssociado === true && (
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                  É obrigatório o preenchimento do associado.
                                </p>
                              )
                            }
                          </>
                        )
                      }
                      {
                        Object.keys(props.states.terceirizadoFatura).length > 0 && (
                          <>
                            <div className="w-[70%] border shadow-md hover:shadow-lg rounded-[10px] flex-rw  items-center justify-start flex">
                              <div className="p-5 ">
                                Email: {props.states.terceirizadoFatura.email} <br />
                                CPF: {formatCpf(props.states.terceirizadoFatura.cpf_cnpj)}
                                Nome: {formatCpf(props.states.terceirizadoFatura.name)}
                              </div>
                              <div className="p-5 ">
                                <XMarkIcon class="h-8 w-8 text-gray-400" aria-hidden="true" onClick={clearSelect} />
                              </div>
                            </div>
                          </>

                        )
                      }
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
