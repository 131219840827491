import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

export default function ModalFaturas(props) {


  const [valor, setValor] = useState(''); // Valor da fatura
  const [preco, setPreco] = useState(''); // Valor da fatura
  const [viewPreco, setViewPreco] = useState(''); // Valor da fatura

  useEffect(() => {
    if (props.states.modalBaixa === false) {
      clearSelect()
    }
  }, [props.states.modalBaixa]);



  const clearSelect = () => {
    setValor('')
    setPreco('')
    setViewPreco('')
    props.setters.setPagamento({})
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Lógica para lidar com o envio do formulário
    props.action()
  };


  const handleInputChange = (data) => {


    const value = data.replace(/[^0-9]/g, '')
    const valueMoney = value / 100
    const arrPrice = value.split('')
    if (arrPrice.length > 2) {

      arrPrice.splice(-2, 0, ',')
      const valueString = arrPrice.join('')
      setViewPreco('R$ ' + valueString)
    } else {
      setViewPreco('R$ ' + value)
    }
    // 
    props.setters.setPagamento({
      ...props.states.Pagamento,
      value: value
    })
    setPreco(value)
  };

  return (
    <Transition.Root show={props.states.modalBaixa} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalBaixa(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalBaixa(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Baixa em fatura</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informe o valor pago da fatura
                  </p>
                </div>

                <form className='mt-6' onSubmit={handleFormSubmit}>
                  <div className="mb-8">
                    <label htmlFor="valor" className="block font-medium mb-2">Valor:</label>
                    <input
                      type="text"
                      id="valor"
                      name="valor"
                      value={viewPreco}
                      onChange={e => handleInputChange(e.target.value)}
                      className="w-full border-gray-300 rounded-md p-2"
                      placeholder="Digite o valor"
                      required
                    />
                  </div>
                  <div className="flex justify-end">
                    <button type="submit" className="bg-blue-500 text-white rounded-md px-4 py-2">Registrar</button>
                    <button type="button" className="bg-gray-300 text-gray-700 rounded-md px-4 py-2 ml-2" >Cancelar</button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
