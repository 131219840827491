import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/pontoEletronico'
import Table from '../../../../Components/Tabela/TabelaHistoricoTrocaDePonto'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'

export default function SolicitacaoDeTrocaDePontos() {
    const { dataUsersLoadOutsourcedAndName,
        getHistoricoRequisicoesPontos,
        getHistoricoRequisicoesPontosUser, } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    const [idUser, setIdUser] = useState(0)


    const dataLoad = async () => {
        await getHistoricoRequisicoesPontos()
    }

    const listarUsuarios = async () => {
        await dataUsersLoadOutsourcedAndName()
    }



    const filterByUser = async () => {
        setters.setOffsetHistoricoAtualizacaoPonto(0)
        if (idUser === 0) {
            await getHistoricoRequisicoesPontos()
        } else {
            await getHistoricoRequisicoesPontosUser(idUser)
        }
    }

    const dataLoadTable = async () => {
        if (idUser === 0) {
            await getHistoricoRequisicoesPontos()
        } else {
            getHistoricoRequisicoesPontosUser(idUser)
        }
    }


    const add = async () => {
        setters.setModalConfiguracaoPonto(true);
    }



    const next = async () => {
        if (states.limitSearchHistoricoAtualizacaoPonto && states.offsetHistoricoAtualizacaoPonto > 0) {
            return
        } else {
            if (states.limitSearchHistoricoAtualizacaoPonto) setters.setLimitSearchHistoricoAtualizacaoPonto(false)
            setters.setOffsetHistoricoAtualizacaoPonto(states.offsetHistoricoAtualizacaoPonto + states.limitHistoricoAtualizacaoPonto)
        }

    };

    const previous = async () => {
        if (states.limitSearchHistoricoAtualizacaoPonto && states.offsetHistoricoAtualizacaoPonto <= 0) {
            states.offsetHistoricoAtualizacaoPonto < 0 && setters.setOffsetHistoricoAtualizacaoPonto(0)
            return
        } else {
            if (states.limitSearchHistoricoAtualizacaoPonto) setters.setLimitSearchHistoricoAtualizacaoPonto(false)
            setters.setOffsetHistoricoAtualizacaoPonto(states.offsetHistoricoAtualizacaoPonto - states.limitHistoricoAtualizacaoPonto)
        }
    };




    const headers = ['E-mail', 'E-mail aprovador', 'Data da solicitação', 'Data da última atualização']



    useEffect(() => {
        dataLoad()
        return () => {
            setters.setOffsetRequisicoesAtualizacaoPonto(0)
            setters.setLimitSearchRequisicoesAtualizacaoPonto(false)
            setters.setDataSystemRequisicoesAtualizacaoPonto([])
            setters.setDataSystemRequisicoesAtualizacaoPonto([])
            setters.setRequisicoesAtualizacaoPonto({})
        }
    }, [])

    useEffect(() => {
        if (states.dataHistoricoAtualizacaoPonto.length > 0 && states.dataUsers.length == 0) {
            setIdUser(0)
            listarUsuarios()
        }
    }, [states.dataHistoricoAtualizacaoPonto])



    useEffect(() => {
        dataLoadTable()

    }, [states.offsetHistoricoAtualizacaoPonto])



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Histórico de troca de ponto</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre o historico de solicitações de troca de ponto
                            </p>

                            <div className='flex flex-row justify-start mt-5'>
                                <div className='flex flex-row justify-start'>
                                    <div className="flex mr-6 my-2">
                                        <select onChange={(e) => setIdUser(e.target.value)} value={idUser} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                            <option value={0}>Selecione um usuário</option>
                                            {
                                                states.dataUsers.length > 0 && states.dataUsers.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                            {
                                                states.dataUsers.length == 0 && <option value={0}>Nenhum usuário encontrado</option>
                                            }
                                        </select>
                                        <button className="ml-5  right-0 flex items-center px-2 text-gray-700 hover:shadow rounded-[10px] border" onClick={filterByUser}>
                                            <MagnifyingGlassCircleIcon className='h-8 w-8' />
                                        </button>


                                    </div>
                                </div>
                            </div>

                            <Table add={add} title='Histórico de troca de ponto' description='Registro do histórico das solicitações de troca de ponto'
                                next={next} previous={previous} states={states} headers={headers}
                                infoTable={{ offset: states.offsetHistoricoAtualizacaoPonto, limit: states.limitHistoricoAtualizacaoPonto }} dataTable={states.dataHistoricoAtualizacaoPonto}
                            />
                        </div>
                    </div>
                </div>
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div >
        </>
    )
}
