import { AuthContext } from "../../../Context"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { AuthContextApi } from "../../../Context/api"
import { useState, useContext, useEffect } from 'react'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import TableProdutosInvestimento from '../../../Components/Tabela/TabelaProdutosInvestimentoAporte'
import ModalAport from '../../../Components/Modal/Aporte'
import ConfirmModal from '../../../Components/Modal/Confirm'

export default function ProdutosInvestimentoAporte() {
  const { id } = useParams()

  const { states, setters, } = useContext(AuthContext)
  const { dataAport, putAport, aproveAport, unproveAport, endInvest } = useContext(AuthContextApi)

  const [cardsArray, setCardsArray] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)

  const [titleConfirm, setTitleConfirm] = useState('')
  const [descriptionConfirm, setDescriptionConfirm] = useState('')

  const loadData = async () => {
    await dataAport(id)
  }

  const formatToMoney = (value) => {
    const intToFloat = parseInt(value) / 100
    return intToFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  useEffect(() => {
    if (states.limitSearchAport) return
    if (states.dataAport.length === 0) return
    loadData()
  }, [states.offsetAport])



  useEffect(() => {
    loadData()

    return () => {
      setters.setDataAport([])
      setters.setOffsetAport(0)
      setters.setLimitSearchAport(false)
      setters.setModalAport(false)
      setters.setAport({})
      setters.setIsEdit(false)
    }
  }, [])

  const next = async () => {
    if (states.limitSearchAport) {
      return
    } else {
      setters.setOffsetAport(states.offsetAport + states.limitAport)
    }
  };

  const previous = async () => {
    if (dataTable.length === 0 && states.offsetAport <= 0) {
      return
    } else {
      states.offsetAport > 0 && setters.setOffsetAport(states.offsetAport - states.limitAport)
      states.offsetAport < 0 && setters.setOffsetAport(0)
    }
  };

  const editAport = async (item) => {
    setters.setAport(item)
    setters.setIsEdit(true)
    setters.setModalAport(true)
  }

  const actionAport = async (data) => {
    setters.setModalAport(false)
    setters.setIsEdit(false)
    await putAport(data, id)
  }

  const showMOdalaprove = async (item) => {
    setters.setCloseAport(false)
    setters.setIsEdit(false)
    setters.setAport(item)
    setters.setConfirmModal(true)
    setTitleConfirm('Aprovar aporte')
    setDescriptionConfirm(`Deseja aprovar o aporte de ${formatToMoney(item.value)}?`)
  }

  const showMOdulaprove = async (item) => {
    setters.setCloseAport(false)
    setters.setIsEdit(true)
    setters.setAport(item)
    setters.setConfirmModal(true)
    setTitleConfirm('Rerprovar aporte')
    setDescriptionConfirm(`Deseja reprovar o aporte de ${formatToMoney(item.value)}?`)
  }

  const closeInvest = async (item) => {
    setters.setCloseAport(true)
    setters.setIsEdit(false)
    setters.setAport(item)
    setters.setConfirmModal(true)
    setTitleConfirm('Fechar encerrar o investimento')
    setDescriptionConfirm(`Deseja encerrar o investimento de ${formatToMoney(item.value)}?`)
  }

  const actionAprove = async () => {
    if (states.closeAport) {
      setters.setIsEdit(false)
      setters.setConfirmModal(false)
      await endInvest(id)
    } else {
      if (states.setIsEdit) {
        setters.setIsEdit(false)
        setters.setConfirmModal(false)
        await unproveAport(id)
      } else {
        setters.setIsEdit(false)
        setters.setConfirmModal(false)
        await aproveAport(id)
      }
    }
  }

  const navigate = useNavigate()

  const changePage = (item) => {
    setters.setAport(item)
    navigate(`/financeIndex/produtosinvestimento/extrato/${item.id}`)

  }



  const headers = ['Status', 'Valor', 'Rendimento', 'E-mail do requerente', 'E-mail do aprovador', 'Data de inicio', 'Data de saida', 'Data de Criação', 'Data de Atualização', '']

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <ConfirmModal states={states} setters={setters} title={titleConfirm} description={descriptionConfirm} action={actionAprove} />

        <SpinnerOverlay />

        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              {/* <NavbarPanel /> */}

              <Link to="/financeIndex/produtosinvestimento" className='text-blue-500 hover:text-blue-700'>
                Retornar
              </Link>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {cardsArray.map((item) => (
                  <div key={item.name} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                              <div className='flex flex-row'>
                                <div className="text-sm font-medium text-gray-900">{item.type}</div>
                              </div>
                            </dt>
                            <dd>
                              <dt className="truncate text-sm font-medium text-gray-500">Valor utilizado:</dt>
                              {/* <div className="text-lg font-medium text-gray-900">{formatToMoney(item.usedValue)}</div> */}
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                <p className="text-3xl font-semibold tracking-tight text-gray-900">{formatToMoney(item.usedValue)}</p>
                              </dd>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>

              <ModalAport states={states} setters={setters} action={actionAport} />
              <TableProdutosInvestimento title={`Aportes do produto de investimento`} next={next} previous={previous}
                description={
                  cardsArray.length > 0 ? `Tipo do produto de investimento: ${cardsArray[0].type}` : 'Nenhum aporte vinculado a produto de investimento'
                } setters={setters} states={states} headers={headers} infoTable={{ offset: states.offsetAport, limit: states.limitAport }} dataTable={states.dataAport}
                editAport={editAport} aprove={showMOdalaprove} reprove={showMOdulaprove} closeInvest={closeInvest} show={changePage}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
