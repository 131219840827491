import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { AuthContextApi } from '../../../Context/api';
import { toast } from 'react-toastify';

export default function ModalPlanos(props) {

  const {buscarPlanoPorId} = useContext(AuthContextApi)

  useEffect(() => {
    if (props.states.modalIsOpen === false) {
      props.setters.setPlans({})
      limparStatesModalPlano()
    }
  }, [props.states.modalIsOpen]);

  const [titulo, setTitulo] = useState('')
  const [descricao, setDescricao] = useState('')
  const [preco, setPreco] = useState('')
  const [frequencia, setFrequencia] = useState('')
  const [tolerancia, setTolerancia] = useState('')
  const [gateway, setGateway] = useState('')
  const [funcef, setFuncef] = useState('')
  const [viewPreco, setViewPreco] = useState('')

  const [errorTitulo, setErrorTitulo] = useState(false)
  const [errorDescricao, setErrorDescricao] = useState(false)
  const [errorPreco, setErrorPreco] = useState(false)
  const [errorFrequencia, setErrorFrequencia] = useState(false)
  const [errorTolerancia, setErrorTolerancia] = useState(false)
  const [errorGateway, setErrorGateway] = useState(false)
  const [errorFuncef, setErrorFuncef] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (props.states.modalIsOpen)
    {
      if (props.states.isEdit) {
        if(props.id != undefined)
        {   
          buscarPlano(props.id)
        }
      }
    }
  }, [props.states.modalIsOpen])

  const buscarPlano = async (id)=>
  {
    try{
      const data = await buscarPlanoPorId(id)
      setTitulo(data.data.title)
      setDescricao(data.data.description)
      setPreco(data.data.price)
      setViewPreco(data.data.price / 100)
      setFrequencia(data.data.frequency)
      setTolerancia(data.data.tolerance)
      setGateway(data.data.idIuguPlan)
      setFuncef(data.data.isFuncef)
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const handleChange = (inputValue) => {
    if (inputValue.length > 0) {
      const value = inputValue.replace(/[^0-9]/g, '')
      const valueMoney = value / 100
      const arrPrice = value.split('')
      if (arrPrice.length > 2) {

        arrPrice.splice(-2, 0, ',')
        const valueString = arrPrice.join('')
        setViewPreco(valueString)
      } else {
        setViewPreco(value)
      }
      // // 
      setPreco(value)
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'titulo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorTitulo(true)
      }
      else {
        setErrorTitulo(false)
      }
    }
    else if (tipo === 'descricao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDescricao(true)
      }
      else {
        setErrorDescricao(false)
      }
    }
    else if (tipo === 'preco') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPreco(true)
      }
      else {
        setErrorPreco(false)
      }
    }
    else if (tipo === 'frequencia') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorFrequencia(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorFrequencia(true)
        }
        else {
          setErrorFrequencia(false)
        }
      }
    }
    else if (tipo === 'tolerancia') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorTolerancia(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorTolerancia(true)
        }
        else {
          setErrorTolerancia(false)
        }
      }
    }
    else if (tipo === 'gateway') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorGateway(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorGateway(true)
        }
        else {
          setErrorGateway(false)
        }
      }
    }
    else if (tipo === 'funcef') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorFuncef(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorFuncef(true)
        }
        else {
          setErrorFuncef(false)
        }
      }
    }
    if (props.states.isEdit) {
      props.setters.setPlans({
        id: props.states.Plans.id,
        title: titulo,
        description: descricao,
        frequency: frequencia,
        price: preco,
        iugu: gateway == 1 ? '1' : '0',
        tolerance: tolerancia,
        isFuncef: funcef == 'Sim' ? true : false
      })

    } else {

      props.setters.setPlans({
        title: titulo,
        description: descricao,
        frequency: frequencia,
        price: preco,
        iugu: gateway == 1 ? '1' : '0',
        tolerance: tolerancia,
        isFuncef: funcef == 'Sim' ? true : false
      })
    }


  }

  const checkAllErrors = () => {
    if (titulo === undefined || titulo === null || titulo === '') {
      setErrorTitulo(true)
      setDisabled(true)
    } else {
      setErrorTitulo(false)
      setDisabled(false)
    }
    if (descricao === undefined || descricao === null || descricao === '') {
      setErrorDescricao(true)
      setDisabled(true)
    } else {
      setErrorDescricao(false)
      setDisabled(false)
    }
    if (preco === undefined || preco === null || preco === '') {
      setErrorPreco(true)
      setDisabled(true)
    } else {
      setErrorPreco(false)
      setDisabled(false)
    }
    if (frequencia === undefined || frequencia === null || frequencia === '') {
      setErrorFrequencia(true)
      setDisabled(true)
    } else {
      setErrorFrequencia(false)
      setDisabled(false)
    }
    if (tolerancia === undefined || tolerancia === null || tolerancia === '') {
      setErrorTolerancia(true)
      setDisabled(true)
    } else {
      setErrorTolerancia(false)
      setDisabled(false)
    }
    if (gateway === undefined || gateway === null || gateway === '') {
      setErrorGateway(true)
      setDisabled(true)
    } else {
      setErrorGateway(false)
      setDisabled(false)
    }
    if (funcef === undefined || funcef === null || funcef === '') {
      setErrorFuncef(true)
      setDisabled(true)
    } else {
      setErrorFuncef(false)
      setDisabled(false)
    }

  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    cadastrarPlanoOnSubmit()
  }

  const cadastrarPlanoOnSubmit = async (e) => {
    await props.create()
  }

  const editarPlanoOnSubmit = async (e) => {
    e.preventDefault()
    const plano = {
      title: titulo,
      description: descricao,
      frequency: frequencia,
      price: preco,
      iugu: gateway == 'Sim' ? '1' : '0',
      tolerance: tolerancia,
      isFuncef: funcef == 'Sim' ? true : false
    }
    props.setters.setPlans(plano)
  }

  const limparStatesModalPlano = () => {
    setTitulo('')
    setDescricao('')
    setViewPreco('')
    setPreco('')
    setFrequencia('')
    setTolerancia('')
    setGateway('')
    setFuncef('')

    setErrorTitulo(false)
    setErrorDescricao(false)
    setErrorPreco(false)
    setErrorFrequencia(false)
    setErrorTolerancia(false)
    setErrorGateway(false)
    setErrorFuncef(false)
  }

  const verificarStatesModalPlano = async (associado) => {
    try {
      let resultado = true
      for await (const key of Object.keys(associado)) {
        if (associado[key] == '' || associado[key] == ' ') {
          if (key == 'title') {
            resultado = false
            setErrorTitulo(true)
          }
          else if (key == 'description') {
            resultado = false
            setErrorDescricao(true)
          }
          else if (key == 'frequency') {
            resultado = false
            setErrorFrequencia(true)
          }
          else if (key == 'price') {
            resultado = false
            setErrorPreco(true)
          }
          else if (key == 'iugu') {
            resultado = false
            setErrorGateway(true)
          }
          else if (key == 'tolerance') {
            resultado = false
            setErrorTolerancia(true)
          }
          else if (key == 'isFuncef') {
            resultado = false
            setErrorFuncef(true)
          }
        }
        else {
          if (key == 'title') {
            setErrorTitulo(false)
          }
          else if (key == 'description') {
            setErrorDescricao(false)
          }
          else if (key == 'frequency') {
            setErrorFrequencia(false)
          }
          else if (key == 'price') {
            setErrorPreco(false)
          }
          else if (key == 'iugu') {
            setErrorGateway(false)
          }
          else if (key == 'tolerance') {
            setErrorTolerancia(false)
          }
          else if (key == 'isFuncef') {
            setErrorFuncef(false)
          }
        }
      }
      return resultado
    }
    catch (err) {
      return false
    }
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Plano</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do plano
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos ao plano.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Título
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="text"
                                name="titulo"
                                id="titulo"
                                autoComplete="titulo"
                                placeholder={props.states.waitTimeout === false ? 'Digite o título' : 'Carregando...'}

                                value={titulo}
                                onBlur={() => leaveInput(titulo, 'titulo')}
                                onChange={e => setTitulo(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorTitulo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do título.
                            </p>
                          }
                        </div>

                        <div className="col-span-full">
                          <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            Descrição
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="descricao"
                              name="descricao"
                              type="text"

                              value={descricao}
                              onBlur={() => leaveInput(descricao, 'descricao')}
                              onChange={e => setDescricao(e.target.value)}
                              placeholder={props.states.waitTimeout === false ? 'Digite a descrição' : 'Carregando...'}
                              rows={3}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              defaultValue={''}
                            />
                          </div>
                          {
                            errorDescricao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da descrição.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Preço
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">R$</span>
                              <input
                                type="text"
                                name="preco"
                                id="preco"
                                autoComplete="preco"
                                placeholder={props.states.waitTimeout === false ? '0,00' : 'Carregando...'}

                                value={viewPreco}
                                onBlur={() => leaveInput(preco, 'preco')}
                                onChange={e => handleChange(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorPreco === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do preço.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Frequência
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                id="frequencia"
                                name="frequencia"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"

                                onChange={e => setFrequencia(e.target.value)}
                                onBlur={() => leaveInput(frequencia, 'frequencia')}
                                value={
                                  frequencia
                                }
                              />
                            </div>
                          </div>
                          {
                            errorFrequencia === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da frequência.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Tolerância
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                id="tolerancia"
                                name="tolerancia"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"

                                onChange={e => setTolerancia(e.target.value)}
                                onBlur={() => leaveInput(tolerancia, 'tolerancia')}
                                value={
                                  tolerancia
                                }
                              />
                            </div>
                          </div>
                          {
                            errorTolerancia === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da tolerância.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Plano Gateway
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select
                                id="gateway"
                                name="gateway"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"

                                onChange={e => setGateway(e.target.value)}
                                onBlur={() => leaveInput(gateway, 'gateway')}
                                value={
                                  gateway
                                }
                              >
                                <option>Selecione ..</option>
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>
                              </select>

                            </div>
                          </div>
                          {
                            errorGateway === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento se o plano é vinculado ao gateway.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Funcef
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select
                                id="funcef"
                                name="funcef"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"

                                onChange={e => setFuncef(e.target.value)}
                                onBlur={() => leaveInput(funcef, 'funcef')}
                                value={
                                  funcef
                                }
                              >
                                <option>Selecione ..</option>
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>
                              </select>

                            </div>
                          </div>
                          {
                            errorFuncef === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento se o plano é vinculado a FUNCEF.
                            </p>
                          }
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      disabled={disabled}
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {props.states.isEdit ? 'Atualizar' : 'Cadastrar'}
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
