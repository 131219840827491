import moment from 'moment'
import Navbar from '../../../../Components/Navbar/navbar'
import NavbarPanel from '../../../../Components/navMenu/financeiroFolha'
import ModalInformacao from '../../../../Components/Modal/ShowRemessaAssociado'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroRemessaAssociate'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import { toast } from 'react-toastify'
import { Line } from 'react-chartjs-2';
import { useContext, useEffect } from 'react'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { InformationCircleIcon } from '@heroicons/react/24/outline'


Chart.register(...registerables);

export default function RemessaAssociadoTransacao() {

    const { states, setters, } = useContext(AuthContext)
    const { searchQueryAssociadoTransacao, dataSystemRemessaAssociadoTransaction, dataRemessaAssociadoTransacao, getDataGraphicRemessaAssociadoTransacao } = useContext(AuthContextApi)

    const loadData = async () => {
        const promises = [
            dataSystemRemessaAssociadoTransaction(),
            dataRemessaAssociadoTransacao()
            ];
        
        await Promise.all(promises);
    }

    useEffect(() => {
        setters.setOffsetRemessaAssociadoTransacao(0)
        loadData()
        return () => {
            setters.setOffsetRemessaAssociadoTransacao(0)
            setters.setLimitSearchRemessaAssociadoTransacao(false)
            setters.setDataRemessaAssociadoTransacao([])
            setters.setDataGraphicRemessaAssociadoTransacaoSolved([])
            setters.setSystemFolha([])
            setters.setDataQuery([])
        }
    }, [])

    const dataSearch = async (e) => {
        if (states.querySearchRemessaAssociadoTransacao !== '') {
            const regex = /[-\/]/;
            const isData = regex.test(states.querySearchRemessaAssociadoTransacao)
            if (isData) {
                toast.error('Não é possível pesquisar por data')
            } else {
                await searchQueryAssociadoTransacao()
            }

        }
    }

    const data = {
        labels: states.dataGraphicRemessaAssociadoTransacaoSolved.map(elements => elements.date),
        datasets: [
            {
                label: 'Total de transações resolvidas',
                data: states.dataGraphicRemessaAssociadoTransacaoSolved.map(elements => elements.total),
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                borderColor: 'rgba(63, 81, 181, 1)',
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            }
        ],
    };

    const data2 = {
        labels: states.dataGraphicRemessaAssociadoTransacaoSolved.map(elements => elements.date),
        datasets: [

            {
                label: 'Total de transações não resolvidas',
                data: states.dataGraphicRemessaAssociadoTransacaoUnsolved.map(elements => elements.total),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            }
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => {
                        return `$${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        return `${label}: $${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
    };

    const callLogsData = async () => {
        await dataRemessaAssociadoTransacao()
    }

    useEffect(() => {
        if (states.offsetRemessaAssociadoTransacao === 0) return
        if (states.dataQuery.length > 0) return
        callLogsData()
    }, [states.offsetRemessaAssociadoTransacao])

   

    const openModalInfo = (data) => {
        setters.setRemessaAssociadoTransacao(data)
        setters.setModalShowTecnico(true)
    }

    const openModalConfirm = (data) => {
        setters.setRemessaAssociadoTransacao(data)
        setters.setConfirmModal(true)
    }

    const next = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataRemessaAssociadoTransacao.length === 0) {
                return
            } else {
                const data = states.dataQuery
                setters.setOffsetRemessaAssociadoTransacao(states.offsetRemessaAssociadoTransacao + states.limitRemessaAssociadoTransacao)
                const sumOff = states.offsetRemessaAssociadoTransacao + states.limitRemessaAssociadoTransacao
                const limit = states.limitRemessaAssociadoTransacao + sumOff
                const newData = data.slice(sumOff, limit)
                setters.setDataRemessaAssociadoTransacao(newData)
            }
        } else {
            if (states.limitSearchRemessaAssociadoTransacao) {
                return
            } else {
                setters.setOffsetRemessaAssociadoTransacao(states.offsetRemessaAssociadoTransacao + states.limitRemessaAssociadoTransacao)
            }
        }

    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataRemessaAssociadoTransacao.length === 0 && states.offsetRemessaAssociadoTransacao <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetRemessaAssociadoTransacao > 0 && setters.setOffsetRemessaAssociadoTransacao(states.offsetRemessaAssociadoTransacao - states.limitRemessaAssociadoTransacao)
                states.offsetRemessaAssociadoTransacao < 0 && setters.setOffsetRemessaAssociadoTransacao(0)
                const sumOff = states.offsetRemessaAssociadoTransacao > 0 ? states.offsetRemessaAssociadoTransacao - states.limitRemessaAssociadoTransacao : 0;
                const limit = sumOff + 10 > states.limitRemessaAssociadoTransacao ? sumOff + 10 : states.limitRemessaAssociadoTransacao;
                const newData = data.slice(sumOff, limit)
                setters.setDataRemessaAssociadoTransacao(newData)
            }
        } else {
            if (states.offsetRemessaAssociadoTransacao <= 0) {
                states.offsetRemessaAssociadoTransacao < 0 && setters.setOffsetRemessaAssociadoTransacao(0)
                return
            } else {
                if (states.limitSearchRemessaAssociadoTransacao) setters.setLimitSearchRemessaAssociadoTransacao(false)
                setters.setOffsetRemessaAssociadoTransacao(states.offsetRemessaAssociadoTransacao - states.limitRemessaAssociadoTransacao)
            }
        }
    };

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setRemessaAssociadoTransacao({})
    }

    const headers = ['Valor', 'CPF', 'Email do Associado', 'Operação', 'Status', 'Data de criação', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                
                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64">
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Transações das remessas por associado</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as transações das remessas com excedente/débito.
                                    </p>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                        {/* Card */}
                                        {states.systemFolha.length > 0 && states.systemFolha.map((card, index) => (
                                            <div key={card.title} className={card.value > 0 && index === 7 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                                <div className="p-5">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                card.value > 0 && index === 7 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                            }

                                                        </div>
                                                        <div className="ml-5 w-0 flex-1">
                                                            <dl>
                                                                {
                                                                    card.value > 0 && index === 7 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                                }

                                                                <dd>
                                                                    {
                                                                        card.value > 0 && index === 7 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                                    }
                                                                    {
                                                                        card.value > 0 && index === 7 && (
                                                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                                                <div className='flex flex-row ml-6'>
                                                                                    <div className="text-sm font-medium text-red-500 hover:text-red-200"><button>Verificar agora</button></div>
                                                                                </div>
                                                                            </dt>

                                                                        )
                                                                    }
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>

                                    <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                        <h1 className='text-xl font-semibold'>Intervalo dos associados</h1>
                                        <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                                            <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                                                <label htmlFor="start-date" className="mr-2">Data de início:</label>
                                                <input id="start-date" type="date" onChange={e => setters.setInitialDateRemessaAssociadoTransacao(e.target.value)} value={states.initialDateRemessaAssociadoTransacao} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div className="flex items-center">
                                                <label htmlFor="end-date" className="mr-2">Data de término:</label>
                                                <input id="end-date" type="date" onChange={e => setters.setFinalDateRemessaAssociadoTransacao(e.target.value)} value={states.finalDateRemessaAssociadoTransacao} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div className="flex items-center md:ml-5 p-2">
                                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => loadData()}>
                                                Aplicar
                                                </button>
                                                {
                                                states.isLoading && (
                                                    <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 grid md:grid-cols-2 grid-cols-1 gap-4">
                                        <div class="flex flex-col items-center p-6 bg-white w-[100%] h-full border border-1 border-gray-200 rounded-md  hover:shadow-md">
                                            <h2 class="text-xl font-bold">Valores excedentes resolvidos</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={data} options={options} />
                                            </div>
                                        </div>

                                        <div class="flex flex-col items-center  p-6 bg-white w-[100%] h-full border border-1 border-gray-200 rounded-md  hover:shadow-md">
                                            <h2 class="text-xl font-bold">Valores excedentes não resolvidos</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={data2} options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por transação</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                <input placeholder='Digite o dado que deseja buscar' type="text"  onChange={e => setters.setQuerySearchRemessaAssociadoTransacao(e.target.value)}
                                        value={states.querySearchRemessaAssociadoTransacao} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                                        />

                                <div className="flex items-center md:ml-5 p-2">
                                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                                    Aplicar
                                    </button>
                                    {
                                    states.isLoading && (
                                        <div className="flex items-center justify-center ml-8">
                                        <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                        </div>
                                    )
                                    }
                                </div>
                                </div>
                            </div>

                            <TableFinanceiro transaction={true} title='Transações das remessas por associado' description='Registro das transações das remessas por associado' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetRemessaAssociadoTransacao, limit: states.limitRemessaAssociadoTransacao }} dataTable={states.dataRemessaAssociadoTransacao} />
                        </div>
                    </div>
                </div>

                <ModalInformacao states={states} transaction={true} setters={setters} closeModal={closeModal} data={states.RemessaAssociadoTransacao} title='Visualização ampla' />

            </div>
        </>
    )
}
