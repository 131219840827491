import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

export default function ModalProjeto(props) {

  const [autorizacao, setAutorizacao] = useState('false')
  const [etapas, setEtapas] = useState(0)
  const [titulo, setTitulo] = useState('')
  const [descricao, setDescricao] = useState('')
  const [dataEntrega, setDataEntrega] = useState('')
  const [autor, setAutor] = useState('')
  const [etapasProjeto, setEtapasProjeto] = useState(0)
  const [usuarioResponsavel, setUsuarioResponsavel] = useState('')

  const [errorTitulo, setErrorTitulo] = useState(false)
  const [errorDescricao, setErrorDescricao] = useState(false)
  const [errorDataEntrega, setErrorDataEntrega] = useState(false)
  const [errorAutor, setErrorAutor] = useState(false)
  const [errorEtapasProjeto, setErrorEtapasProjeto] = useState(false)
  const [errorUsuarioResponsavel, setErrorUsuarioResponsavel] = useState(false)

  const [id, setId] = useState('')



  useEffect(() => {
    if (props.states.modalIsOpen === false) {
      props.setters.setDataUsers([])
      props.setters.setProjetos({})
      setAutorizacao('false')
      setEtapas(0)
      setTitulo('')
      setDescricao('')
      setDataEntrega('')
      setAutor('')
      setUsuarioResponsavel('')
      setEtapasProjeto(0)
      setId('')
      setErrorTitulo(false)
      setErrorDescricao(false)
      setErrorDataEntrega(false)
      setErrorAutor(false)
      setErrorEtapasProjeto(false)
      setErrorUsuarioResponsavel(false)
    } else {
      if (props.states.isEdit) {
        setTitulo(props.states.Projetos.title)
        setDescricao(props.states.Projetos.description)
        setDataEntrega(moment(props.states.Projetos.delivery_date).format('YYYY-MM-DD'))
        setAutor(props.states.Projetos.user_author)
        setAutorizacao(props.states.Projetos.require_auth === 1 ? 'true' : 'false')
        setUsuarioResponsavel(props.states.Projetos.user_authorized)
        setEtapasProjeto(props.states.Projetos.steps)
        setId(props.states.Projetos.id)
        setEtapas('true')
      }
    }
  }, [props.states.modalIsOpen])

  const submit = async (e) => {
    let error;
    e.preventDefault()
    if (!titulo) {
      setErrorTitulo(true)
      error = true
    }
    if (!descricao) {
      setErrorDescricao(true)
      error = true
    }
    if (!dataEntrega) {
      setErrorDataEntrega(true)
      error = true
    }
    if (!autor) {
      setErrorAutor(true)
      error = true
    }
    if (autorizacao === "true" && !usuarioResponsavel) {
      setErrorUsuarioResponsavel(true)
      error = true
    }
    if (etapas === "true" && !etapasProjeto) {
      setErrorEtapasProjeto(true)
      error = true
    }
    if (etapas === "false") {
      setEtapasProjeto(undefined)
    }
    if (autorizacao === "false") {
      setUsuarioResponsavel(undefined)
    }
    if (error) {
      error = false
      return
    }


    const obj = {
      title: titulo,
      description: descricao,
      require_auth: autorizacao,
      delivery_date: dataEntrega,
      user_author: autor,
      user_authorized: usuarioResponsavel ? usuarioResponsavel : undefined,
      steps: etapasProjeto ? etapasProjeto : undefined,
      id: id !== '' ? id : undefined
    }
    await props.action(obj)

  }




  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Projeto</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    {
                      props.states.isEdit ? 'Edição de projeto' : 'Cadastro de projeto'
                    }
                  </p>
                </div>
                <form className='pt-10 pl-10 pr-10' onSubmit={submit}>
                  <div className="space-y-12">
                    {/* 1 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do projeto.</p>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                          <div className="sm:col-span-1">
                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Título</label>
                            <input
                              type="text"
                              id="title"
                              name="title"
                              value={titulo}
                              onChange={e => setTitulo(e.target.value)}
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Digite o título do projeto"
                            />
                            <span className="text-red-500 text-sm">{errorTitulo && 'Campo obrigatório'}</span>
                          </div>
                          <div className="sm:col-span-1">
                            <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                            <input
                              type="text"
                              id="descricao"
                              name="descricao"
                              value={descricao}
                              onChange={e => setDescricao(e.target.value)}
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Digite a descrição do projeto"
                            />
                            <span className="text-red-500 text-sm">{errorDescricao && 'Campo obrigatório'}</span>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-5">
                          <div className="sm:col-span-1">
                            <label htmlFor="date" className="block text-sm font-semibold leading-5 text-gray-700">Data de entrega</label>
                            <input
                              type="date"
                              id="date"
                              name="date"
                              value={dataEntrega}
                              onChange={e => setDataEntrega(e.target.value)}
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Título do projeto"
                            />
                            <span className="text-red-500 text-sm">{errorDataEntrega && 'Campo obrigatório'}</span>
                          </div>

                          <div className="sm:col-span-1">
                            <label htmlFor="autor" className="block text-sm font-semibold leading-5 text-gray-700">Autor</label>
                            <div
                              className="flex rounded-md  ring-inset ring-gray-300 sm:max-w-md">
                              <select
                                id="autor"
                                name="autor"
                                onChange={e => setAutor(e.target.value)}
                                value={autor}
                                className="mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                                <option value={''}>Selecione ..</option>
                                {
                                  props.states.dataUsers?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>{item.email}</option>
                                    )
                                  }
                                  )
                                }
                              </select>
                            </div>
                            {
                              errorAutor && <span className="text-red-500 text-sm">Campo obrigatório</span>
                            }
                          </div>


                        </div>
                      </div>
                    </div>

                    {
                      (!props.states.isEdit || autorizacao === "true") &&
                      <>
                        {/* 2 parte */}
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                          <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Autorização do projeto</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Preencha os campos, caso necessite de autorização.</p>
                          </div>
                          <div className="sm:col-span-2">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <label htmlFor="autor" className="block text-sm font-semibold leading-5 text-gray-700">Necessário autorização ?</label>
                                <div
                                  className="flex rounded-md  ring-inset ring-gray-300 sm:max-w-md">
                                  <select
                                    id="autor"
                                    name="autor"
                                    disabled={props.states.isEdit}
                                    className={props.states.isEdit ? 'bh-gray-500 mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6' : "mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"}
                                    onChange={e => setAutorizacao(e.target.value)} >
                                    <option value={''}>Selecione ..</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                  </select>
                                </div>
                              </div>
                              {
                                autorizacao === "true" &&
                                (<div className="sm:col-span-1">
                                  <label htmlFor="autorSelecao" className="block text-sm font-semibold leading-5 text-gray-700">Selecione o usuário responsável</label>
                                  <div
                                    className="flex rounded-md  ring-inset ring-gray-300 sm:max-w-md">
                                    <select
                                      id="autorSelect"
                                      name="autorSelect"
                                      onChange={e => setUsuarioResponsavel(e.target.value)}
                                      value={usuarioResponsavel}
                                      disabled={props.states.isEdit}
                                      className={props.states.isEdit ? 'bh-gray-500 mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6' : "mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"}>
                                      <option value={''}>Selecione ..</option>
                                      {
                                        props.states.dataUsers?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>{item.email}</option>
                                          )
                                        }
                                        )
                                      }
                                    </select>
                                  </div>
                                  {
                                    errorUsuarioResponsavel && <span className="text-red-500 text-sm">Campo obrigatório</span>
                                  }
                                </div>)
                              }
                            </div>
                          </div>
                        </div>

                      </>
                    }
                    {/* 3 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Adicionar quantidade de etapas</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Preencha os campos, caso necessite inserir a quantidade de etapas.</p>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                          <div className="sm:col-span-1">
                            <label htmlFor="etapas" className="block text-sm font-semibold leading-5 text-gray-700">Adicionar etapas ?</label>
                            <div
                              className="flex rounded-md  ring-inset ring-gray-300 sm:max-w-md">
                              <select
                                id="etapas"
                                name="etapas"
                                disabled={props.states.isEdit}
                                className={props.states.isEdit ? 'bh-gray-500 mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6' : "mt-1 pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"}
                                onChange={e => setEtapas(e.target.value)}>
                                <option value={''}>Selecione ..</option>
                                <option value={true}>Sim</option>
                                <option value={false}>Não</option>
                              </select>
                            </div>
                          </div>
                          {
                            etapas === "true" &&
                            (<div className="sm:col-span-1">
                              <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Digite a quantidade de etapas para o projeto</label>
                              <div
                                className="flex rounded-md  ring-inset ring-gray-300 sm:max-w-md">
                                <input
                                  type="text"
                                  id="etapas"
                                  name="etapas"
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                  value={etapasProjeto}
                                  onChange={e => {
                                    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                                    setEtapasProjeto(onlyNums)
                                  }}
                                  placeholder="Digite a quantidade de etapas"
                                />
                                {
                                  errorEtapasProjeto && <span className="text-red-500 text-sm">Campo obrigatório</span>
                                }
                              </div>
                            </div>)
                          }
                        </div>
                      </div>
                    </div>





                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
