import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import DragDropFile from '../../UploadFilePagamento/componenteFile'
export default function Example({ states, setters, send }) {
    const [open, setOpen] = useState(true)

    const inputRef = useRef(null)
    const cancelButtonRef = useRef(null)

    const setFile = (file) => {
        setters.setRemessaFile(file)
    }

    const limparArquivo = async (e) => {
        setters.setRemessaFile(null)
        inputRef.current.value = '';
    };

    const fileData = () => {
        if (states.RemessaFile) {
          return (
            <div className='mt-4'>
              <h2>Informações do arquivo:</h2>
              <p className='mt-2 mb-1'>Nome do arquivo: {states.RemessaFile.name}</p>
              <p className='mt-1 mb-1'>Tipo do arquivo: {states.RemessaFile.type}</p>
              <p className='mt-1 mb-2'>
                Última modificação:{" "}
                {states.RemessaFile.lastModifiedDate.toDateString()}
                {/* {states.RemessaFile.lastModifiedDate} */}
              </p>
              <div className="pt-4" >
                  <div className="flex justify-end gap-x-3">
                    <button
                      type="button"
                      onClick={() => limparArquivo()}
                      className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      
                    >
                      Limpar
                    </button>
                  </div>
                </div>
            </div>
          );
        } else {
          return (
            <div>
              <br />
              <h4>Nenhum arquivo selecionado</h4>
            </div>
          );
        }
    };

    const sendfile = async e => {
        e.preventDefault();
        await send(states.RemessaFile)
    }


    return (
        <Transition.Root show={states.modalRemessa} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">

                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    onClick={() => setters.setModalRemessa(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Adicione o arquivo da remessa </h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Faça upload da folha de pagamento por aqui.
                                    </p>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={sendfile}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Arquivo Selecionado
                                                </label>
                                                <div className="rounded-md">
                                                    {fileData()}
                                                </div>
                                            </div>
                                            

                                            <div className="grid w-full grid-cols-1 sm:grid-cols-6 md:col-span-2">
                                                <div className="sm:col-span-full">
                                                    <DragDropFile setters={setters} file={setFile} inputRef={inputRef}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                          onClick={() => setters.setModalRemessa(false)}
                                        >
                                        Cancelar
                                        </button>
                                        <button
                                        type="submit"
                                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        >
                                        Salvar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
