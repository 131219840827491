import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { useContext, useEffect } from "react";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import { toast } from "react-toastify";
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import Table from '../../Components/Tabela/TabelaProjetos'
import ModalShow from '../../Components/Modal/ShowProjeto'
import ModalProjeto from "../../Components/Modal/Projeto";
import ModalConfirm from "../../Components/Modal/Confirm";
import ModalAprove from '../../Components/Modal/ProjetoAprove'
import ModalInit from '../../Components/Modal/ProjetoInit'
import { useNavigate } from 'react-router-dom'
import SpinnerOverlay from "../../Components/SpinnerOverlay";

const ProjetosIndex = () => {

    const { checkLogin, dataSystemProjetos, getProjetos, getProjetosQuery, getProjetosDate, dataUsers, postProject, putProject, deleteProject, approveProject, initProject } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const hist = useNavigate()

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                setters.setSigned(false)
                return;
            }

        }
    }

    const load = async () => {
        setters.setLimitSearchProjetos(false)
        setters.setOffsetProjetos(0)
        setters.setDataProjetos([])
        setters.setDataInicialProjetos('')
        setters.setDataFinalProjetos('')
        setters.setQueryProjetos('')
        const promisses = [dataSystemProjetos(), getProjetos()]
        await Promise.all(promisses)

    }

    useEffect(() => {
        isSigned()
        load()

        return () => {
            setters.setDataSystemProjetos([])
            setters.setDataProjetos([])
            setters.setProjetos({})
            setters.setOffsetProjetos(0)
            setters.setLimitSearchProjetos(false)
            setters.setDataInicialProjetos('')
            setters.setDataFinalProjetos('')
            setters.setQueryProjetos('')
        }
    }, [])

    const headers = ['Número', 'Status', 'Título', 'Origem', 'Etapas', 'Entrega', 'Horas de entrega',
        'Autorização Necessária', 'Data prevista', 'Data Inicial', 'Data Final', 'Autor',
        'Revisor', 'Criador', 'Data de criação', 'Data de atualização', '']

    const showProjeto = (data) => {
        setters.setProjetos(data)
        setters.setModalProjetos(true)
    }




    const clearSearchPersonalizado = async () => {
        setters.setLimitSearchProjetos(false)
        setters.setOffsetProjetos(0)
        setters.setDataProjetos([])
        setters.setDataInicialProjetos('')
        setters.setDataFinalProjetos('')
        setters.setQueryProjetos('')
        await getProjetos()
    }

    const next = async () => {
        if (states.limitSearchProjetos && states.offsetProjetos > 0) {
            return;
        } else {
            setters.setLimitSearchProjetos(false)
        }
        setters.setOffsetProjetos(states.offsetProjetos + states.limitProjetos)
    }

    const previous = async () => {
        if (states.limitSearchProjetos) {
            setters.setOffsetProjetos(states.offsetProjetos - states.limitProjetos)
            if (states.offsetProjetos <= 0) setters.setOffsetProjetos(0)
            setters.setLimitSearchProjetos(false)
            return;
        }
        if (states.limitSearchProjetos && states.offsetProjetos > 0) {
            setters.setLimitSearchProjetos(false)
            setters.setOffsetProjetos(states.offsetProjetos - states.limitProjetos)
            return;
        }
        if ((states.offsetProjetos - states.limitProjetos) < 0) {
            setters.setOffsetProjetos(0)
            return;
        }
        setters.setOffsetProjetos(states.offsetProjetos - states.limitProjetos)

    }

    /*
        Realizar funcoes aqui para primeira busca
    
    */

    const getSearchQuery = async () => {
        if (states.queryProjetos === '') {
            toast.error('Digite um valor para buscar')
            return;
        }
        setters.setDataInicialProjetos('')
        setters.setDataFinalProjetos('')
        setters.setOffsetProjetos(0)
        setters.setLimitSearchProjetos(false)
        await getProjetosQuery()
    }

    const getSearchDate = async () => {
        if (states.dataInicialProjetos === '' || states.dataFinalProjetos === '') {
            toast.error('Selecione um intervalo de datas para buscar')
            return;
        }
        setters.setQueryProjetos('')
        setters.setOffsetProjetos(0)
        setters.setLimitSearchProjetos(false)
        await getProjetosDate()
    }

    useEffect(() => {
        if (states.dataProjetos.length === 0) return;
        if (states.limitSearchProjetos) {
            return;
        }
        if (states.dataInicialProjetos !== '' && states.dataFinalProjetos !== '') {
            getProjetosDate()
            return;
        }
        if (states.queryProjetos !== '') {
            getProjetosQuery()
            return;
        }
        if (states.dataInicialProjetos === '' && states.dataFinalProjetos === '' && states.queryProjetos === '') {
            getProjetos()
            return;
        }
    }, [states.offsetProjetos])


    const openModalAdicao = async () => {
        await dataUsers()
        setters.setIsEdit(false)
        setters.setModalProjetos(false)
        setters.setModalIsOpen(true)
    }

    const actionModalCreate = async data => {
        setters.setModalIsOpen(false)
        setters.setModalProjetos(false)
        if (states.isEdit) {
            setters.setIsEdit(false)
            const resp = await putProject(data, data.id)
            if (resp === true) {
                setters.setProjetos({})
                await getProjetos()
                return;
            }
            return;
        }
        const resp = await postProject(data)
        if (resp === true) {
            setters.setProjetos({})
            await getProjetos()
            return;
        }
        setters.setProjetos({})
        return;
    }


    const openModalEdit = async (data) => {
        setters.setIsEdit(true)
        setters.setModalProjetos(false)
        setters.setProjetos(data)
        await dataUsers()
        setters.setModalIsOpen(true)



    }

    const delteModal = async (data) => {
        setters.setProjetos(data)
        setters.setIsEdit(false)
        setters.setModalProjetos(false)
        setters.setModalIsOpen(false)
        setters.setConfirmModal(true)
    }

    const actionDelete = async () => {
        setters.setConfirmModal(false)
        const resp = await deleteProject(states.Projetos.id)
        if (resp === true) {
            await getProjetos()
            setters.setProjetos({})
            return;
        }
        setters.setProjetos({})
        return;
    }

    const openModalApprove = async (data) => {
        setters.setProjetos(data)
        setters.setModalProjetos(false)
        setters.setModalApprove(true)

    }


    const actionApprove = async (data) => {
        setters.setModalApprove(false)
        const resp = await approveProject(data, states.Projetos.id)
        if (resp === true) {
            await getProjetos()
            setters.setProjetos({})
            return;
        }
        setters.setProjetos({})
        return;
    }

    const openModalInitProject = async (data) => {
        setters.setProjetos(data)
        setters.setModalProjetos(false)
        setters.setModalApprove(false)
        setters.setModalInit(true)
    }

    const actionInitProject = async (data) => {
        setters.setModalInit(false)
        const resp = await initProject(data, states.Projetos.id)
        if (resp.status === true) {
            setters.setProjetos({})
            hist(`/projeto/${resp.data.data.id}`)
            return;
        }
        setters.setProjetos({})
        return;
    }

    const pageProject = data => {
        hist(`/projeto/${data.id}`)
    }


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Projetos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os projetos cadastrados no sistema
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4">
                                {states.dataSystemProjetos.map((card, index) => (
                                    <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white shadow-sm hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white shadow-sm hover:shadow-md"}>

                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    {
                                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                    }

                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        {
                                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                        }

                                                        <dd>
                                                            {
                                                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                            }
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4 shadow-sm hover:shadow-md">
                                <h1 className='text-xl font-semibold'>Intervalo de busca por projeto</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                                    <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                                        <label htmlFor="start-date" className="mr-2">Data de início do projeto:</label>
                                        <input id="start-date" type="date" onChange={e => setters.setDataInicialProjetos(e.target.value)} value={states.dataInicialProjetos} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                    </div>
                                    <div className="flex items-center">
                                        <label htmlFor="end-date" className="mr-2">Data de término do projeto:</label>
                                        <input id="end-date" type="date" onChange={e => setters.setDataFinalProjetos(e.target.value)} value={states.dataFinalProjetos} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                    </div>
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={getSearchDate}>
                                            Buscar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={clearSearchPersonalizado}>
                                    Limpar
                                </button>
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4 shadow-sm hover:shadow-md">
                                <h1 className='text-xl font-semibold'>Buscar por projetos</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                                        setters.setQueryProjetos(e.target.value);
                                    }} onKeyDown={(e) => e.key === 'Enter' && getSearchQuery()}
                                        value={states.queryProjetos} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => getSearchQuery()}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={clearSearchPersonalizado}>
                                    Limpar
                                </button>
                            </div>
                            <Table title='Projetos' page={pageProject} description='Registro de projetos do SINPREV' init={openModalInitProject} aprove={openModalApprove} del={delteModal} edit={openModalEdit} add={openModalAdicao} next={next} previous={previous} states={states} setters={setters} show={showProjeto} headers={headers} infoTable={{ offset: states.offsetProjetos, limit: states.limitProjetos }} dataTable={states.dataProjetos} />

                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <ModalShow states={states} setters={setters} />
            <ModalProjeto states={states} setters={setters} action={actionModalCreate} />
            <ModalConfirm states={states} action={actionDelete} setters={setters} title={`Deseja mesmo deletar o projeto ${states.Projetos.title}?`} description={'Ao deletar o projeto, não será possível restaura-lo'} />
            <ModalAprove states={states} setters={setters} action={actionApprove} />
            <ModalInit states={states} setters={setters} action={actionInitProject} />
        </>
    )

}

export default ProjetosIndex;
