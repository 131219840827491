import { AuthContext } from "../../../Context"
import { Link, useParams } from 'react-router-dom'
import { AuthContextApi } from "../../../Context/api"
import { useState, useContext, useEffect } from 'react'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import TableProdutosInvestimento from '../../../Components/Tabela/TabelaProdutosInvestimentoExtrato'

export default function ProdutosInvestimentoAporteExtrato() {
  const { id } = useParams()

  const { states, setters, } = useContext(AuthContext)
  const { dataExtrato } = useContext(AuthContextApi)

  const [cardsArray, setCardsArray] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)

  const loadData = async () => {
    await dataExtrato(id)
  }

  const formatToMoney = (value) => {
    const intToFloat = parseInt(value) / 100
    return intToFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  useEffect(() => {
    if (states.limitSearchExtrato) return
    if (states.dataExtrato.length === 0) return
    loadData()
  }, [states.offsetExtrato])



  useEffect(() => {
    loadData()

    return () => {
      setters.setDataExtrato([])
      setters.setOffsetExtrato(0)
      setters.setLimitSearchExtrato(false)
    }
  }, [])



  const next = async () => {
    if (states.limitSearchExtrato) {
      return
    } else {
      setters.setOffsetExtrato(states.offsetExtrato + states.limitExtrato)
    }
  };

  const previous = async () => {
    if (dataTable.length === 0 && states.offsetExtrato <= 0) {
      return
    } else {
      states.offsetExtrato > 0 && setters.setOffsetExtrato(states.offsetExtrato - states.limitExtrato)
      states.offsetExtrato < 0 && setters.setOffsetExtrato(0)
    }
  };

  const headers = ['Data Final', 'Valor do aporte', 'Juros do aporte', 'Total', 'Data de Criação', 'Data de Atualização', '']

  const voltar = () => {
    window.history.back();
    // Ou você pode usar window.history.go(-1);
  };

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              {/* <NavbarPanel /> */}
              <button onClick={voltar} className='text-blue-500 hover:text-blue-700'>
                Retornar
              </button>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {cardsArray.map((item) => (
                  <div key={item.name} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                              <div className='flex flex-row'>
                                <div className="text-sm font-medium text-gray-900">{item.type}</div>
                              </div>
                            </dt>
                            <dd>
                              <dt className="truncate text-sm font-medium text-gray-500">Valor utilizado:</dt>
                              {/* <div className="text-lg font-medium text-gray-900">{formatToMoney(item.usedValue)}</div> */}
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                <p className="text-3xl font-semibold tracking-tight text-gray-900">{formatToMoney(item.usedValue)}</p>
                              </dd>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>

              <TableProdutosInvestimento title={`Extratos do aporte`} next={next} previous={previous}
                description={
                  cardsArray.length > 0 ? `Tipo do produto de investimento: ${cardsArray[0].type}` : 'Nenhum aporte vinculado a produto de investimento'
                } setters={setters} states={states} headers={headers} infoTable={{ offset: states.offsetExtrato, limit: states.limitExtrato }} dataTable={states.dataExtrato} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
