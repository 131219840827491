import { toast } from 'react-toastify';
import { AuthContextApi } from '../../../Context/api'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useState, useEffect, useContext } from 'react'

import InputMask from "react-input-mask";

export default function ModalConfiguracaoPonto(props) {

  const { dataUsersLoadOutsourced, createConfigPonto, editConfigPonto } = useContext(AuthContextApi)

  const [horarioTotal, setHorarioTotal] = useState('')
  const [horarioAlmoco, setHorarioAlmoco] = useState('')
  const [horarioEntrada, setHorarioEntrada] = useState('')
  const [horarioSaida, setHorarioSaida] = useState('')
  const [userId, setUserId] = useState('')

  const [errorHorarioTotal, setErrorHorarioTotal] = useState(false)
  const [errorHorarioAlmoco, setErrorHorarioAlmoco] = useState(false)
  const [errorHorarioEntrada, setErrorHorarioEntrada] = useState(false)
  const [errorHorarioSaida, setErrorHorarioSaida] = useState(false)
  const [errorUserId, setErrorUserId] = useState('')

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (props.states.modalConfiguracaoPonto) 
    {
      carregaUsuarios()
      if(props.edit)
      {
        setHorarioTotal(props.data.timeTotalDay)
        setHorarioAlmoco(props.data.lunchHour)
        setHorarioEntrada(props.data.entry_time)
        setHorarioSaida(props.data.exit_time)
        setUserId(props.data.user_id)
      }
    }
    else
    {
      clearSelect()
    }
  }, [props.states.modalConfiguracaoPonto])

  const carregaUsuarios = async () => {
    await dataUsersLoadOutsourced()
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'horarioTotal') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorHorarioTotal(true)
      }
      else {
        let result = valor.replaceAll(`_`,'').replaceAll(`:`,'')
        result = result.slice(0, -2)
        if (result.length < 4) {
          setErrorHorarioTotal(true)
        }
        else {
          setErrorHorarioTotal(false)
        }
      }
    }
    else if (tipo === 'horarioAlmoco') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorHorarioAlmoco(true)
      }
      else {
        let result = valor.replaceAll(`_`,'').replaceAll(`:`,'')
        result = result.slice(0, -2)
        if (result.length < 4) {
          setErrorHorarioAlmoco(true)
        }
        else {
          setErrorHorarioAlmoco(false)
        }
      }
    }
    else if (tipo === 'horarioEntrada') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorHorarioEntrada(true)
      }
      else {
        let result = valor.replaceAll(`_`,'').replaceAll(`:`,'')
        result = result.slice(0, -2)
        if (result.length < 4) {
          setErrorHorarioEntrada(true)
        }
        else {
          setErrorHorarioEntrada(false)
        }
      }
    }
    else if (tipo === 'horarioSaida') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorHorarioSaida(true)
      }
      else {
        let result = valor.replaceAll(`_`,'').replaceAll(`:`,'')
        result = result.slice(0, -2)
        if (result.length < 4) {
          setErrorHorarioSaida(true)
        }
        else {
          setErrorHorarioSaida(false)
        }
      }
    }
    else if (tipo === 'userId') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorUserId(true)
      }
      else {
        if(valor == 'Selecione ..')
        {
          setErrorUserId(true)
        }
        else {
          setErrorUserId(false)
        }
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    try{
      const objConfig =
      {
        timeTotalDay: horarioTotal,
        lunchHour: horarioAlmoco,
        entry_time: horarioEntrada,
        exit_time: horarioSaida,
        user: userId
      }
      const retorno = await verificarStates(objConfig)
      if(retorno == true)
      {
        props.edit ? await editConfigPonto(objConfig, props.data.id) : await createConfigPonto(objConfig)
        props.setters.setModalConfiguracaoPonto(false)
      }
      else
      {
        toast.error("Verifique os campos do formulário", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const verificarStates = async (objeto) =>
  {
    try{
      let resultado = true
      for await(const key of Object.keys(objeto))    
      {                     
        if(objeto[key] == '' || objeto[key] == ' ')
        {
          if(key == 'timeTotalDay')
          { 
            resultado = false 
            setErrorHorarioTotal(true)
          }
          else if(key == 'lunchHour')
          { 
            resultado = false 
            setErrorHorarioAlmoco(true)
          }
          else if(key == 'entry_time')
          { 
            resultado = false 
            setErrorHorarioEntrada(true)
          }
          else if(key == 'exit_time')
          {
            resultado = false 
            setErrorHorarioSaida(true)
          }
          else if(key == 'user')
          { 
            resultado = false 
            setErrorUserId(true)
          }
        }
        else
        {
          if(key == 'userId')
          { 
            if(objeto[key] == 'Selecione ..')
            {
              resultado = false 
              setErrorUserId(true)
            }
            else
            {
              setErrorUserId(false)              
            }
          }
          else
          {
            let resultado = true
            if (objeto[key].replaceAll(`_`,'').replaceAll(`:00`,'').replaceAll(`:`,'').length < 4) {
              resultado = false
            }

            if(key == 'horarioTotal')
            { 
              if(!resultado)
              {
                resultado = false 
                setErrorHorarioTotal(true)
              }
              else
              {
                setErrorHorarioTotal(false)
              }
            }
            else if(key == 'horarioAlmoco')
            { 
              if(!resultado)
              {
                resultado = false 
                setErrorHorarioAlmoco(true)
              }
              else
              {
                setErrorHorarioAlmoco(false)
              }
            }
            else if(key == 'horarioEntrada')
            { 
              if(!resultado)
              {
                resultado = false 
                setErrorHorarioEntrada(true)
              }
              else
              {
                setErrorHorarioEntrada(false)
              }
            }
            else if(key == 'horarioSaida')
            {
              if(!resultado)
              {
                resultado = false 
                setErrorHorarioSaida(true)
              }
              else
              {
                setErrorHorarioSaida(false)
              }
            }
          }
        }
      }
      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  const clearSelect = () => {
    setHorarioTotal('')
    setHorarioAlmoco('')
    setHorarioEntrada('')
    setHorarioSaida('')
    setUserId('')

    setErrorHorarioTotal(false)
    setErrorHorarioAlmoco(false)
    setErrorHorarioEntrada(false)
    setErrorHorarioSaida(false)
    setErrorUserId(false)
  }

  return (
    <Transition.Root show={props.states.modalConfiguracaoPonto} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalConfiguracaoPonto(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalConfiguracaoPonto(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Configuração do Ponto</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do Ponto
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a configuração do ponto.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Horário Total
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <InputMask
                                  required
                                  id="totalTime"
                                  name="totalTime"
                                  type="text"
                                  placeholder='Digite o horário total'
                                  autoComplete="totalTime"
                                  mask="99:99:00"
                                  disabled={disabled}
                                  onChange={e => setHorarioTotal(e.target.value)}
                                  onBlur={() => leaveInput(horarioTotal, 'horarioTotal')}
                                  value={
                                    horarioTotal
                                  }
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              /> 
                            </div>
                          </div>
                          {
                            errorHorarioTotal === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do horário total.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Horário de Almoço
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <InputMask
                                  required
                                  id="lunchTime"
                                  name="lunchTime"
                                  type="text"
                                  placeholder='Digite o horário de almoço'
                                  autoComplete="lunchTime"
                                  mask="99:99:00"
                                  disabled={disabled}
                                  onChange={e => setHorarioAlmoco(e.target.value)}
                                  onBlur={() => leaveInput(horarioAlmoco, 'horarioAlmoco')}
                                  value={
                                    horarioAlmoco
                                  }
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              /> 
                            </div>
                          </div>
                          {
                            errorHorarioAlmoco === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do horário de almoço.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Horário de Entrada
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                               <InputMask
                                  required
                                  id="entryTime"
                                  name="entryTime"
                                  type="text"
                                  placeholder='Digite o horário de entrada'
                                  autoComplete="entryTime"
                                  mask="99:99:00"
                                  disabled={disabled}
                                  onChange={e => setHorarioEntrada(e.target.value)}
                                  onBlur={() => leaveInput(horarioEntrada, 'horarioEntrada')}
                                  value={
                                    horarioEntrada
                                  }
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              /> 
                            </div>
                          </div>
                          {
                            errorHorarioEntrada === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do horário de entrada.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Horário de Saída
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                               <InputMask
                                  required
                                  id="leaveTime"
                                  name="leaveTime"
                                  type="text"
                                  placeholder='Digite o horário de saída'
                                  autoComplete="leaveTime"
                                  mask="99:99:00"
                                  disabled={disabled}
                                  onChange={e => setHorarioSaida(e.target.value)}
                                  onBlur={() => leaveInput(horarioSaida, 'horarioSaida')}
                                  value={
                                    horarioSaida
                                  }
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              /> 
                            </div>
                          </div>
                          {
                            errorHorarioSaida === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do horário de saída.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-full">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Usuário
                            </label>
                            <div className="flex rounded-md shadow-sm">
                              <select
                                required
                                id="funcionario"
                                name="funcionario"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setUserId(e.target.value)}
                                onBlur={() => leaveInput(userId, 'userId')}
                                value={
                                  userId
                                }
                              >
                                <option>Selecione ..</option>
                                {
                                  props.states.dataUsers.map((user) => {
                                    return (
                                      <option value={user.id}>{user.email}</option>
                                    )
                                  })
                                }
                              </select>

                            </div>
                            {
                              errorUserId === true &&
                              <p class="mt-2 ml-2 text-pink-600 text-sm">
                                É obrigatório selecionar um usuário.
                              </p>
                            }
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalConfiguracaoPonto(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
