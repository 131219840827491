
import { XCircleIcon } from "@heroicons/react/24/outline"


const EstadoDetalhes = ({ estado, close }) => {
    if (!estado) return (
        <div className="flex border flex-col w-full mt-5 mb-5 p-4 items-center justufy-center rounded-[10px] shadow">
            Clique em um estado para ver os detalhes
        </div>
    );

    return (
        <div className="flex flex-col border w-full mt-5 mb-5 p-4 items-center justufy-center rounded-[10px] shadow">
            <div className="flex items-start justify-start w-full">
                <button className="mr-auto" onClick={() => close(null)}>
                    <XCircleIcon className="h-8 w-8 text-gray-500 hover:text-gray-700" />
                </button>
            </div>
            < h2 > {estado.nome}</h2 >
            <p>{estado.info}</p>
            <p>Total de associados: {estado.dadosAdicionais.totalGeral ? estado.dadosAdicionais.totalGeral : 0}</p>
            <div className="fle">
                {
                    estado.dadosAdicionais.totalGeral && (
                        <div className="flex flex-col items-center border mt-5 p-2 w-[90%]">
                            <h3 className="flex ml-2 text-center">Fundos  - número de associados</h3>
                            <div className="flex flex-col sm:flex-row">
                                {
                                    estado.dadosAdicionais?.fundos?.map((dado) => (
                                        <div key={dado.id} className="flex flex-col mt-5 sm:mt-0">
                                            <h3 className="flex ml-8">Fundo: {dado.fundo}</h3>
                                            <p className="flex ml-8">Total de associados: {dado.quantidade}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div >
    );
};

export default EstadoDetalhes;