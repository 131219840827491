import moment from 'moment'
import { toast } from 'react-toastify'
import { Dialog, Transition } from '@headlessui/react'
import { AuthContextApi } from '../../../../Context/api'
import { XMarkIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Fragment, useState, useEffect, useContext } from 'react'


export default function ModalFeriasFuncionarios(props) {

  const { dataEmployeeLoadOutsourced, createFeriasFuncionarios } = useContext(AuthContextApi)
  const [disabled, setDisabled] = useState(false)

  const [listFerias, setListFerias] = useState([])

  const [dataInicioContrato, setDataInicioContrato] = useState('')
  const [errorDataInicioContrato, setErrorDataInicioContrato] = useState(false)

  const [userId, setUserId] = useState('')
  const [errorUserId, setErrorUserId] = useState('')

  const [dataInicio, setDataInicio] = useState('')
  const [errorDataInicio, setErrorDataInicio] = useState(false)

  const [dataFim, setDataFim] = useState('')
  const [errorDataFim, setErrorDataFim] = useState(false)

  useEffect(() => {
    if (props.states.modalFeriasFuncionarios) 
    {
      carregaFuncionarios()
      // if(props.edit)
      // {
      // setHorarioTotal(props.data.timeTotalDay)
      // setHorarioAlmoco(props.data.lunchHour)
      // setHorarioEntrada(props.data.entry_time)
      // setHorarioSaida(props.data.exit_time)
      // setUserId(props.data.user_id)
      // }
    }
    else
    {
      clearSelect()
    }
  }, [props.states.modalFeriasFuncionarios])

  const carregaFuncionarios = async () => {
    await dataEmployeeLoadOutsourced()
  }

  const gerarIdAleatorio = () =>{
    return Math.random().toString(36).substring(2, 10);
  }

  const addFeriasOnClick = async (e) =>
  {
    try{
      let status = false
      if(dataInicio == '')
      {
        status = true
        setErrorDataInicio(true)
      }
      else
      {
        status = false
        setErrorDataInicio(false)
      }

      if(dataFim == '')
      {
        status = true
        setErrorDataFim(true)
      }
      else
      {
        status = false
        setErrorDataFim(false)
      }

      if(!status)
      {
        const newVacation = {
          "ID": gerarIdAleatorio(),
          "vacationStartDate": dataInicio,
          "vacationEndDate": dataFim
        };
    
        setListFerias([...listFerias, newVacation]);
        setDataInicio('')
        setDataFim('')
        setErrorDataInicio(false)
        setErrorDataFim(false)
      }
      else
      {
        toast.error("Verifique os campos de data", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const deleteFeriasOnClick = async (idDelete) =>
  {
    try
    {
      let arrayAux = []
      for await (const item of listFerias)
      {
        if(!(item.ID == idDelete))
        {
          arrayAux.push(item)
        }
      }
      setListFerias(arrayAux)
      setErrorDataInicio(false)
      setErrorDataFim(false)
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'dataInicioContrato') 
    {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDataInicioContrato(true)
      }
      else 
      {
        setErrorDataInicioContrato(false)
      }
    }
    else if (tipo === 'userId') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorUserId(true)
      }
      else {
        if(valor == 'Selecione ..')
        {
          setErrorUserId(true)
        }
        else {
          setErrorUserId(false)
        }
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    try{
      const objConfig =
      {
        "contractStartDate": dataInicioContrato,
        "outsourced_id": userId,
        "vacations": listFerias,
      }

      const retorno = await verificarStates(objConfig)
      if(retorno == true)
      {
        const response = await createFeriasFuncionarios(objConfig)
        if(response)
        {
          props.setters.setModalFeriasFuncionarios(false)
        }
      }
      else
      {
        toast.error("Verifique os campos do formulário", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const verificarStates = async (objeto) =>
  {
    try{
      let resultado = true
      for await(const key of Object.keys(objeto))    
      {                     
        if(objeto[key] == '' || objeto[key] == ' ')
        {
          if(key == 'contractStartDate')
          { 
            resultado = false 
            setErrorDataInicioContrato(true)
          }
          else if(key == 'outsourced_id')
          { 
            resultado = false 
            setErrorUserId(true)
          }
        }
        else
        {
          if(key == 'contractStartDate')
          { 
            setErrorDataInicioContrato(false)
          }
          else if(key == 'outsourced_id')
          { 
            setErrorUserId(false)
          }
        }
      }
      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  const clearSelect = () => {
    setUserId('')
    setErrorUserId(false)

    setListFerias([])
    setDataInicioContrato('')
    setErrorDataInicioContrato(false)
    setDataInicio('')
    setErrorDataInicio(false)
    setDataFim('')
    setErrorDataFim(false)
  }

  return (
    <Transition.Root show={props.states.modalFeriasFuncionarios} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalFeriasFuncionarios(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalFeriasFuncionarios(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Configuração de Férias</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Configuração de férias para funcionários.
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos as férias do funcionário.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Data do Início do Contrato
                            </label>
                              <div className="flex max-w-lg rounded-md shadow-sm">
                                <input
                                  type="date"
                                  name="date"
                                  id="date"
                                  value={dataInicioContrato}
                                  onChange={e => setDataInicioContrato(e.target.value)}
                                  onBlur={() => leaveInput(dataInicioContrato, 'dataInicioContrato')}
                                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {
                                errorDataInicioContrato === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    É obrigatório o preenchimento da data de início do contrato.
                                </p>
                              }
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                              Funcionário
                            </label>
                            <div className="flex rounded-md shadow-sm">
                              <select
                                required
                                id="funcionario"
                                name="funcionario"
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                disabled={disabled}
                                onChange={e => setUserId(e.target.value)}
                                onBlur={() => leaveInput(userId, 'userId')}
                                value={
                                  userId
                                }
                              >
                                <option>Selecione ..</option>
                                {
                                  props.states.dataUsers.map((user) => {
                                    return (
                                      <option value={user.id}>{user.email}</option>
                                    )
                                  })
                                }
                              </select>

                            </div>
                            {
                              errorUserId === true &&
                              <p class="mt-2 ml-2 text-pink-600 text-sm">
                                É obrigatório selecionar um usuário.
                              </p>
                            }
                        </div>

                        <div className="sm:col-span-6 border-t">
                          <h1 className="mt-4 text-base font-semibold leading-6 text-gray-900">
                            Incluir Férias Passadas
                          </h1>
                          <div className="mt-2 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7 md:col-span-2">
                            <div className="sm:col-span-3">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                  Data do Início
                                </label>
                                  <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                      type="date"
                                      name="date"
                                      id="date"
                                      value={dataInicio}
                                      onChange={e => setDataInicio(e.target.value)}
                                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                  {
                                    errorDataInicio === true && 
                                      <p class="mt-2 ml-2 text-pink-600 text-sm">
                                          É obrigatório o preenchimento da data de início.
                                      </p>
                                  }
                            </div>

                            <div className="sm:col-span-3">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                  Data do Fim
                                </label>
                                  <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                      type="date"
                                      name="date"
                                      id="date"
                                      value={dataFim}
                                      onChange={e => setDataFim(e.target.value)}
                                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                  {
                                    errorDataFim === true && 
                                      <p class="mt-2 ml-2 text-pink-600 text-sm">
                                          É obrigatório o preenchimento da data do fim.
                                      </p>
                                  }
                            </div>

                            <div className="sm:col-span-">
                                <div className="flex max-w-lg rounded-md justify-start pt-9">
                                  <button type="button" className="flex items-center justify-center h-8 w-14 bg-gray-50 text-gray-500 rounded-md border hover:bg-gray-200 focus-visible:outline-offset-0"
                                    onClick={() => addFeriasOnClick()}>
                                    {/* Adicionar */}
                                    <PlusIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-full">
                          {listFerias.length > 0 &&
                            <div>
                              <h2 className="block text-sm font-small leading-6 text-gray-700">Férias adicionadas</h2>
                                <div className="mt-6 overflow-hidden border-t border-gray-100">
                                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                                    <table className="w-full text-left">
                                      <tbody>
                                          <div >
                                            <ul role="list" className="divide-y divide-gray-100 border-b border-gray-100">
                                              {listFerias.map((ferias) => (
                                                <li key={ferias.ID} className="flex items-center justify-between gap-x-6 py-4 rounded-md p-2">
                                                  <div className="flex min-w-0 gap-x-4">
                                                    <div className="min-w-0 flex-auto">
                                                      <p className="text-sm font-small leading-6 text-gray-700">Data Início: {moment(ferias.vacationStartDate).format("DD/MM/YYYY")}</p>
                                                      <p className="text-sm font-small leading-6 text-gray-700">Data Fim: {moment(ferias.vacationEndDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                  </div>
                                                  <button
                                                    onClick={()=> deleteFeriasOnClick(ferias.ID)}
                                                    className="rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-400  hover:bg-gray-100"
                                                  >
                                                    <TrashIcon className="h-6 w-6" aria-hidden="true" />
                                                  </button>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalFeriasFuncionarios(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}




  

                                
                             