import { Link } from 'react-router-dom'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from "../../../Context/api"
import { useState, useContext, useEffect } from 'react'
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon, TrashIcon, PlusIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm'
import NavbarPanel from '../../../Components/navMenu/financeiro'
import ModalInvestimento from '../../../Components/Modal/Investimento'
import ModalAport from '../../../Components/Modal/Aporte'

export default function ProdutosInvestimento() {

  const { states, setters, } = useContext(AuthContext)
  const { postInvestimento, dataInvestimentos, postAport, putInvestimento, deleteInvestimento, createReportFinancialExpenses, createReportFinancialExpensesExtract } = useContext(AuthContextApi)

  const [isExtract, setIsExtract] = useState(false)

  const [cardsArray, setCardsArray] = useState([])



  const navigate = useNavigate()

  const add = () => {
    setters.setIsEdit(false)
    setters.setInvestimentos({})
    setters.setModalIsOpen(true)
  }

  const actionModal = async (body) => {
    if (states.isEdit) {
      setters.setModalIsOpen(false)
      await putInvestimento(body)
    } else {
      setters.setModalIsOpen(false)
      await postInvestimento(body)
    }
  }

  const loadData = async () => {
    await dataInvestimentos()
  }

  useEffect(() => {
    if (states.dataInvestimentos.length != 0) return;
    loadData()
    setCardsArray(states.dataInvestimentos)

    return () => {
      setters.setOffsetInvestimentos(0)
      setters.setLimitSearchInvestimentos(false)
      setters.setModalIsOpen(false)
      setters.setConfirmModal(false)
      setters.setInvestimentos({})
      setters.setDataInvestimentos([])


      setters.setDataQuery([])
    }
  }, [])

  useEffect(() => {
    if (states.limitSearchInvestimentos || states.dataInvestimentos.length == 0) return;
    loadData()
  }, [states.offsetInvestimentos])

  const loadMore = () => {
    setters.setOffsetInvestimentos(states.offsetInvestimentos + states.limitInvestimentos)
  }

  const modalEdit = (item) => {
    setters.setIsEdit(true)
    setters.setInvestimentos(item)
    setters.setModalIsOpen(true)
  }

  const openDeleteModal = (item) => {
    setters.setInvestimentos(item)
    setters.setConfirmModal(true)
  }

  const deleteInvestimentos = async () => {
    setters.setConfirmModal(false)
    await deleteInvestimento()
  }


  const repost = async () => {
    isExtract ? await createReportFinancialExpensesExtract() : await createReportFinancialExpenses()
  }

  const openAport = (item) => {
    setters.setIsEdit(false)
    setters.setInvestimentos(item)
    setters.setModalAport(true)
  }

  const actionAport = async (body) => {
    setters.setModalAport(false)
    await postAport(body)
  }

  const changeRoute = (item, id) => {
    setters.setAport(item)
    navigate(`/financeIndex/produtosinvestimento/aporte/${id}`)
  }

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />

        <div className="flex flex-1 flex-col md:pl-64 mb-4 mt-2">
          <div className="p-4" >
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Produtos de Investimento</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Informações sobre os produtos de investimento.
                  </p>
                </div>
              </div>

              {/* <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {cardsArray.map((item) => (
                  <div key={item.name} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="truncate text-sm font-medium text-gray-500">
                              <div className='flex flex-row'>
                                <div className="text-sm font-medium text-gray-900">{item.type}</div>
                              </div>
                            </dt>
                            <dd>
                              <div className="text-lg font-medium text-gray-900">{formatToMoney(item.usedValue)}</div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div> */}

              <div className="mt-4 overflow-x-auto">
                <div className="inline-block min-w-full rounded-md border border-gray-200">
                  <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                    <div className="flex flex-1 justify-between items-center">
                      <div className="flex flex-col">
                        <h1 className="text-xl font-bold text-gray-700">Produtos de Investimento</h1>
                        <p className="text-gray-500">Registro dos produtos de investimento do SINPREV</p>
                      </div>
                    </div>
                    <div className='flex-1 flex bg-white justify-end items-center'>
                      <button onClick={() => add()} className=" flex items-center justify-center h-10 w-10 text-gray-500 rounded-md border 
                                        hover:bg-gray-200 focus-visible:outline-offset-0">
                        <PlusIcon className="h-12 w-12" />
                      </button>
                    </div>
                  </div>
                  <table className="min-w-full">
                    {states.dataInvestimentos.map((item) => (
                      <dl className="mb-4 mr-4 ml-4 grid grid-cols-1 overflow-hidden rounded-md bg-white hover:shadow-md border border-gray-200 md:grid-cols-1">
                        <div key={item.type} className="p-4">
                          <dt className="text-base font-normal text-gray-900">Produto: {item.title}</dt>
                          <dd className="block items-baseline justify-between md:block lg:flex p-4">

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Periodo</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {
                                  item.period == 'm' ? (
                                    <span className="text-2xl font-medium text-blue-500">Mensal</span>
                                  ) : (
                                    <span className="text-2xl font-medium text-blue-500">Anual</span>
                                  )

                                }
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Rentabilidade</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {
                                  (item.roi * 100)
                                }%
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Tempo de duração</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {
                                  item.period == 'm' ? (
                                    <span className="text-2xl font-medium text-blue-500">{item.time} meses</span>
                                  ) : (
                                    <span className="text-2xl font-medium text-blue-500">{item.time} anos</span>
                                  )
                                }
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Rendimento somente no vencimento</p>
                              <div className="flex flex-col items-baseline text-xl font-semibold text-blue-600">
                                {
                                  item.inDueDate ? (
                                    <span className="text-2xl font-medium text-green-500">Sim</span>
                                  ) : (
                                    <span className="text-2xl font-medium text-blue-500">Não</span>
                                  )
                                }
                              </div>
                            </div>

                            <div className=''>
                              <p className="flex items-baseline text-sm font-medium md:mt-2 lg:mt-0">Ações</p>
                              <div className="flex items-baseline text-xl font-semibold text-blue-600">
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600' onClick={() => openAport(item)}>
                                  <BanknotesIcon className='h-7 w-7' />
                                </button>
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600' onClick={() => changeRoute(item, item.id)}>
                                  <MagnifyingGlassIcon className='h-7 w-7' />
                                </button>
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600' onClick={() => modalEdit(item)}>
                                  <AdjustmentsHorizontalIcon className='h-7 w-7' />
                                </button>
                                <button className='h-10 w-10 text-gray-400 hover:text-gray-600' onClick={() => openDeleteModal(item)}>
                                  <TrashIcon className='h-7 w-7' />
                                </button>

                              </div>
                            </div>
                          </dd>
                        </div>
                      </dl>
                    ))}
                    {
                      !states.limitSearchInvestimentos && (
                        <div className='flex justify-center pt-4 pb-8'>
                          <button className='flex' onClick={loadMore}>
                            <p className='text-blue-600 hover:text-blue-400'>Carregar mais</p>
                          </button>
                        </div>
                      )
                    }
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <ModalInvestimento states={states} setters={setters} action={actionModal} />
        <ModalAport states={states} setters={setters} action={actionAport} />
        <ModalConfirm states={states} setters={setters} action={deleteInvestimentos} title='Deseja mesmo excluir esse investimento?'
          description='Ao excluir esse custo, você não poderá mais recuperá-lo.'
        />

      </div>
    </>
  )
}
