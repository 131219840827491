import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

export default function ModalPontoEdit(props) {





//aprove={approveRequest} reprove

  const approveRequest = async (id) => {
    await props.aprove(id)
  }

  const reproveRequest = async (id) => {
    await props.reprove(id)
  }

  useEffect(() => {
    return () => {
      props.setters.setRequisicoesAtualizacaoPonto({})
    }
  }, [])



  return (
    <Transition.Root show={props.states.modalRequisicoesAtualizacaoPonto} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalRequisicoesAtualizacaoPonto(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalRequisicoesAtualizacaoPonto(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Solicitação de troca de ponto</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Solicitação de edição do ponto do dia: {moment(props.states?.RequisicoesAtualizacaoPonto?.entry).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className='pt-10 pl-10 pr-10'>
                  <div className="space-y-12">
                    {/* 1 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do projeto.</p>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 ">
                          <div className="sm:col-span-2">
                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Data</label>
                            <p className="mt-1 block w-full py-2 rounded-md  focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              {moment(props.states?.RequisicoesAtualizacaoPonto?.created_at).format('DD/MM/YYYY')}
                            </p>
                          </div>
                          <div className="sm:col-span-2">
                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                            <p className="mt-1 block w-full py-2 rounded-md  focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                              {props.states?.RequisicoesAtualizacaoPonto?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalRequisicoesAtualizacaoPonto(false)}>
                      Fechar
                    </button>
                    {
                      props.states.RequisicoesAtualizacaoPonto?.user_id_approver === null &&
                      <>
                        <button
                          onClick={() => approveRequest(props.states.RequisicoesAtualizacaoPonto?.id)}
                          type="button"
                          className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                          Aprovar
                        </button>
                        <button
                          onClick={() => reproveRequest(props.states.RequisicoesAtualizacaoPonto?.id)}
                          type="button"
                          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Reprovar
                        </button>
                      </>
                    }
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
