
import NavBar from "../../../../Components/Navbar/navbar";
import NavBarPanel from "../../../../Components/navMenu/juridicoProcesso";
import SpinnerOverlay from "../../../../Components/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { AuthContext } from "../../../../Context";
import { AuthContextJuri } from "../../../../Context/juri";
import { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import Footer from "../../../../Components/Footer/footer";
import Table from '../../../../Components/Tabela/TabelaAssociadosProcessoJuridico'
import { useParams } from "react-router-dom";
import ModalConfirm from "../../../../Components/Modal/Confirm"

const AssociadosProcessoJuridico = () => {

    const { states, setters } = useContext(AuthContext);
    const { checkToken, loadOutSourcedCompany, getProcessosAssociado, dataSystemHomeProcessAssociados, dataProcessoAssociados, getProcessosById,removeAssociateAuthorization

    } = useContext(AuthContextJuri);
    const [search, setSearch] = useState('')
    const { id } = useParams()

    const hist = useNavigate()
    const checkTokenLoad = async () => {
        const cookie = Cookies.get('tokenJuri')
        if (cookie) {
            await checkToken()
        } else {
            setters.setIsSignedJuri(false)
            setters.setInsertCode(false)
            hist('/perfil')
        }
    }




    const loadData = async () => {
        await dataSystemHomeProcessAssociados(id)
        await dataProcessoAssociados(id)
        const resp = await getProcessosById(id)
        if (resp == false) {
            hist('/juridicoIndex/processos')
        }
    }

    //NO DO USE EFFECT DEVE SER VERIFICADO CASO A DATA TENHA DADO JA PARA NAO CHAMAR DUAS VEZES


    const openModal = async () => {
        setters.setLimitSearchJuriOutSourced(false)
        setters.setOfsettJuriOutSourced(0)
        setters.setDataJuriOutSourced([])
        const check = await loadOutSourcedCompany(false)
        if (check) {
            setters.setIsEditJuri(false)
            setters.setModalJuri(true)
        }
    }

    const openModalEdit = async (data) => {
        setters.setIsEditJuri(true)
        setters.setJuriProcessoDeferimento(data)
        setters.setModalJuri(true)
    }


    const getProcessMotionSearch = async () => {
        if (search == '') {
            loadData()
        } else {
            await getProcessosAssociado(id, search)
        }
    }



    const headers = ['Nome', 'Cpf', 'Data de criação', 'Data de atualização', '']
    const [titleConfirm, setTitleConfirm] = useState('')
    const [descriptionConfirm, setDescriptionConfirm] = useState('')
    // 
    const deleteLawyer = async (data) => {
        setTitleConfirm(`Excluir associado ${data.associate_name}?`)
        setDescriptionConfirm(`Deseja remover o associado, ${data.associate_name}, do processo ${data.caseTitle}?`)
        setters.setJuriProcessoAssociate(data)
        setters.setConfirmModal(true)
    }

    const actionDelete = async () => {
        await removeAssociateAuthorization(states.JuriProcessoAssociate.id)
        setters.setConfirmModal(false)
    }



    const next = async () => {
        if (states.limitSearchJuriProcessoAssociate && states.offsetJuriProcessoAssociate > 0) {
            return;
        } else {
            setters.setOffsetJuriProcessoAssociate(states.offsetJuriProcessoAssociate + states.limitJuriProcessoAssociate)
        }
    };

    const previous = async () => {
        if (states.limitSearchJuriProcessoAssociate && states.offsetJuriProcessoAssociate <= 0) {
            states.offsetJuriProcessoAssociate < 0 && setters.setOffsetJuriProcessoAssociate(0)
            return;
        } else {
            setters.setOffsetJuriProcessoAssociate(states.offsetJuriProcessoAssociate - states.limitJuriProcessoAssociate)
        }
    };




    useEffect(() => {
        if (states.dataJuriProcessoAssociate?.length == 0) loadData()

        checkTokenLoad()
        return () => {
            setters.setDataJuriProcessoAssociate([])
            setters.setOffsetJuriProcessoAssociate(0)
            setters.setLimitSearchJuriProcessoAssociate(false)
        }
    }, [])



    useEffect(() => {
        if (states.offsetJuriProcessoAssociate >= 0 && states.dataJuriProcessoAssociate?.length > 0) {
            loadData()
        }
        setters.setConfirmModal(false)
    }, [states.offsetJuriProcessoAssociate])




    const formatCpfCnpj = data => {
        if (data?.length === 11) {
            return data?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return data?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarPanel />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Associados</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações e dados os associados incluídos no processo: {states.JuriLegalProcess?.caseTitle}
                            </p>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {states.dataSystemJuriProcessoAssociate?.statistic?.associate.map((card, index) => {
                                    return (
                                        <div key={card.title} className={card.value > 0 && index === -1 ? "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {
                                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                        }

                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            {
                                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                            }

                                                            <dd>
                                                                {
                                                                    card.value > 0 && index === -1 ?
                                                                        (<div className="text-lg font-medium text-red-500">
                                                                            {card.value}
                                                                        </div>) : (
                                                                            <div className="text-lg font-medium text-gray-900">
                                                                                {card.value}
                                                                            </div>)
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por Associado</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o cpf do associado que deseja buscar' type="text" onChange={e => {
                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                        if (e.target.value.length > 11) return
                                        setSearch(onlyNumber)
                                    }}
                                        value={formatCpfCnpj(search)} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getProcessMotionSearch()
                                            }
                                        }}
                                    />
                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={getProcessMotionSearch}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Table  add={openModal} edit={openModalEdit} deleteLaw={deleteLawyer} title={`Associados cadastrados no processo ${states.JuriLegalProcess?.caseTitle}`} description={`Registro dos associados cadastrados no procesos ${states.JuriLegalProcess?.caseTitle}`} next={next} previous={previous} states={states} headers={headers} infoTable={{ offset: states.offsetJuriProcessoAssociate, limit: states.limitJuriProcessoAssociate }} dataTable={states.dataJuriProcessoAssociate} />
                        </div>


                    </div>
                </div>
                <ModalConfirm title={titleConfirm} description={descriptionConfirm} setters={setters} states={states} action={actionDelete} />
                <Footer />
            </div>
        </>
    )
}



export default AssociadosProcessoJuridico;