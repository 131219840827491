import { useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import NavbarPanel from '../../../Components/navMenu/administrativo'
import Navbar from '../../../Components/Navbar/navbar'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import TableLogs from '../../../Components/Tabela/TabelaAssinaturaTemporaria'
import ModalShow from '../../../Components/Modal/Show'
import ModalConfirm from '../../../Components/Modal/Confirm'
import { toast } from 'react-toastify'
import SpinnerOverlay from '../../../Components/SpinnerOverlay';


export default function RegistroCron() {
  const { states, setters } = useContext(AuthContext)
  const { dataAssinaturaTemporaria, searchQueryAssinaturaTemporaria, dataSystemTemp, deleteAssinaturaTemporaria } = useContext(AuthContextApi)

  const callLogsData = async () => {
    const promises = [
      dataSystemTemp(),
      dataAssinaturaTemporaria()
    ];

    await Promise.all(promises);
  }

  useEffect(() => {
    if (states.offsetAssinaturaTemporaria == 0) return
    if (states.offsetAssinaturaTemporaria < 0) setters.setOffsetAssinaturaTemporaria(0)
    if (states.dataQuery.length > 0) return
    callLogsData()
  }, [states.offsetAssinaturaTemporaria])

  useEffect(() => {
    setters.setOffsetAssinaturaTemporaria(0)
    callLogsData()
    return () => {
      setters.setDataAssinaturaTemporaria([])
      setters.setQuerySearchAssinaturaTemporaria('')
      setters.setOffsetAssinaturaTemporaria(0)
      setters.setLimitSearchAssinaturaTemporaria(false)
      setters.setModalShowTecnico(false)
      setters.setDataSystemAssinaturaTemporaria([])
      setters.setDataQuery([])
    }
  }, [])

  const openModalInfo = (data) => {
    setters.setAssinaturaTemporaria(data)
    setters.setModalShowTecnico(true)
  }

  const openModalConfirm = (data) => {
    setters.setAssinaturaTemporaria(data)
    setters.setConfirmModal(true)
  }

  const actionDelete = async () => {
    setters.setConfirmModal(false)
    await deleteAssinaturaTemporaria()
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssinaturaTemporaria.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetAssinaturaTemporaria(states.offsetAssinaturaTemporaria + states.limitAssinaturaTemporaria)
        const sumOff = states.offsetAssinaturaTemporaria + states.limitAssinaturaTemporaria
        const limit = states.limitAssinaturaTemporaria + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataAssinaturaTemporaria(newData)
      }
    } else {
      if (states.limitSearchAssinaturaTemporaria) {
        return
      } else {
        setters.setOffsetAssinaturaTemporaria(states.offsetAssinaturaTemporaria + states.limitAssinaturaTemporaria)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssinaturaTemporaria.length === 0 && states.offsetAssinaturaTemporaria <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetAssinaturaTemporaria > 0 && setters.setOffsetAssinaturaTemporaria(states.offsetAssinaturaTemporaria - states.limitAssinaturaTemporaria)
        states.offsetAssinaturaTemporaria < 0 && setters.setOffsetAssinaturaTemporaria(0)
        const sumOff = states.offsetAssinaturaTemporaria > 0 ? states.offsetAssinaturaTemporaria - states.limitAssinaturaTemporaria : 0;
        const limit = sumOff + 10 > states.limitAssinaturaTemporaria ? sumOff + 10 : states.limitAssinaturaTemporaria;
        const newData = data.slice(sumOff, limit)
        setters.setDataAssinaturaTemporaria(newData)
      }
    } else {
      if (states.offsetAssinaturaTemporaria <= 0) {
        states.offsetAssinaturaTemporaria < 0 && setters.setOffsetAssinaturaTemporaria(0)
        return
      } else {
        if (states.limitSearchAssinaturaTemporaria) setters.setLimitSearchAssinaturaTemporaria(false)
        setters.setOffsetAssinaturaTemporaria(states.offsetAssinaturaTemporaria - states.limitAssinaturaTemporaria)
      }
    }
  };

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setAssinaturaTemporaria({})
  }

  const dataSearch = async () => {
    if (states.querySearchAssinaturaTemporaria !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchAssinaturaTemporaria)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryAssinaturaTemporaria()
      }

    }
  }

  const headers = ['Título', 'Email', 'Nome', 'Link', 'Data de Criação', 'Data de Atualização', '']
  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Assinaturas Temporárias</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre assinaturas temporárias do sistema.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.dataSystemAssinaturaTemporaria.map((card, index) => {
                  return (
                    <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por assinatura</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                    const value = e.target.value.replace(/\D/g, '')
                    setters.setQuerySearchAssinaturaTemporaria(value)
                  }}
                  onKeyDown={e => {
                      if (e.key === 'Enter') {
                        dataSearch()
                      }
                    }}
                    value={states.querySearchAssinaturaTemporaria} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-6 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <TableLogs title='Assinaturas temporárias' description='Registro das assinaturas temporárias no sistema' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetAssinaturaTemporaria, limit: states.limitAssinaturaTemporaria }} dataTable={states.dataAssinaturaTemporaria} />
            </div>
          </div>
        </div>
        <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
      </div>
      <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.AssinaturaTemporaria} title='Dados de log' />
      <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do registro da AssinaturaTemporaria.' />
    </>
  )
}
