import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { AuthContextApi } from "../../../Context/api"
import { toast } from 'react-toastify';
import { useContext } from "react"

export default function ModalUsuarios(props) {




    const [permissao, setPermissao] = useState('')
    const [usuario, setUsuario] = useState('')
    const [isUser, setIsUser] = useState('')

    const [errorPermissao, setErrorPermissao] = useState(false)
    const [errorUsuario, setErrorUsuario] = useState(false)






    const onSubmitForm = async (e) => {
        e.preventDefault()
        if (errorPermissao || errorUsuario) {
            return
        }
        if (usuario === '') {
            setErrorUsuario(true)
            return
        } else {
            setErrorUsuario(false)
        }
        if (permissao === '') {
            setErrorPermissao(true)
            return
        } else {
            setErrorPermissao(false)
        }
        let obj;
        if (isUser === '') {
            obj = {
                user: usuario,
                role: permissao
            }
        } else {
            obj = {
                id: isUser,
                user: usuario,
                role: permissao
            }
        }
        props.action(obj)
    }

    useEffect(() => {
        permissao === '' ? setErrorPermissao(true) : setErrorPermissao(false)
        usuario === '' ? setErrorUsuario(true) : setErrorUsuario(false)
    }, [permissao, usuario])

    useEffect(() => {
        if (props.states.modalJuri === false) {
            setPermissao('')
            setUsuario('')
            setIsUser('')
            setErrorPermissao(false)
            setErrorUsuario(false)
            props.setters.setJuriUser({})
        } else {
            setErrorPermissao(false)
            setErrorUsuario(false)
            if (Object.keys(props.states.JuriUser).length > 0) {
                setPermissao(props.states.JuriUser.permission)
                setUsuario(props.states.JuriUser.user_id)
                setIsUser(props.states.JuriUser.id)
            }
        }
        //Limpeza do modal ao fechar
    }, [props.states.modalJuri]);

    return (
        <Transition.Root show={props.states.modalJuri} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalJuri(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalJuri(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Usuário</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações do usuário
                                    </p>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {props.states.JuriUser?.email}
                                    </p>

                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div
                                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                    {props.states.isEditJuri === false ? 'Cadastrar' : 'Editar'} usuário
                                                </h2>
                                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                                    {props.states.isEditJuri === false ? 'Preencha os campos abaixo para cadastrar um novo usuário' : 'Preencha os campos abaixo para editar um usuário'}
                                                </p>
                                            </div>

                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">


                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Usuário
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="permissao"
                                                                name="permissao"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setUsuario(e.target.value)}
                                                                value={usuario}>
                                                                <option value={''}>Selecione ..</option>
                                                                {
                                                                    props.states.dataJuriUsers?.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.id}>{item.email}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorUsuario === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório a seleção de um usuário.
                                                    </p>
                                                    }
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Permissão
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="permissao"
                                                                name="permissao"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setPermissao(e.target.value)}
                                                                value={permissao}>
                                                                <option value={''}>Selecione ..</option>
                                                                <option value={'0'}>Administrador</option>
                                                                <option value={'1'}>Advogado</option>
                                                                <option value={'4'}>Auxiliar jurídico</option>
                                                                <option value={'2'}>Usuário</option>
                                                                <option value={'3'}>Associado</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorPermissao === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da permissão.
                                                    </p>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalJuri(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
