import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function ModalFaturas(props) {

  useEffect(() => {
    if (props.states.modalAport === false) {
      if(props.states.isEdit === true){
        props.setters.setAport({})
      }else{

        props.setters.setInvestimentos({})
      }
      limparStatesModalPlano()
    }
  }, [props.states.modalAport]);

  const [preco, setPreco] = useState('')
  const [viewPreco, setViewPreco] = useState('')
  const [idInvestimentos, setIdInvestimentos] = useState('')

  const [descricao, setDescricao] = useState('')

  const [errorPreco, setErrorPreco] = useState(false)
  const [errorQuantidade, setErrorQuantidade] = useState(false)
  const [errorDescricao, setErrorDescricao] = useState(false)

  const [disabled, setDisabled] = useState(false)


  const formatarDinheiro = (valorEmCentavos) => {
    const valorEmReais = valorEmCentavos / 100;
    return valorEmReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleChange = (inputValue, origin) => {
    try {
      if (inputValue.length > 0) {
        const value = inputValue.replace(/[^0-9]/g, '')
        const valueMoney = value / 100
        if (origin === 'preco') {
          const arrPrice = value.split('')
          if (arrPrice.length > 2) {

            arrPrice.splice(-2, 0, ',')
            const valueString = arrPrice.join('')
            const money = formatarDinheiro(value)
            setViewPreco(money)
          } else {
            setViewPreco(value)
          }
          // // 
          setPreco(value)
        }
      }
      else {
        if (origin === 'preco') {
          setPreco('')
        }
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'preco') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPreco(true)
      }
      else {
        setErrorPreco(false)
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()

    const validator = errorPreco === false ? true : false
    if (!validator) return

    if (props.states.isEdit === true) {
      const body = {
        id: props.states.Aport.id,
        value: preco
      }
      props.action(body)
    } else {
      const body = {
        value: preco,
        investment_id: props.states.Investimentos.id,

      }
      props.action(body)
    }




  }

  const limparStatesModalPlano = () => {
    setPreco('')
    setIdInvestimentos('')
    setViewPreco('')
    setErrorPreco(false)
  }



  useEffect(() => {
    if (Object.keys(props.states.Investimentos).length > 0) {
      setIdInvestimentos(props.states.Investimentos.id)
      setPreco(props.states.Investimentos.value)
      const money = formatarDinheiro(props.states.Investimentos.value)
      setViewPreco(money)
    }
  }, [props.states.Investimentos])

  useEffect(() => {
    if (Object.keys(props.states.Aport).length > 0) {
      setIdInvestimentos(props.states.Aport.id)
      setPreco(props.states.Aport.value)
      const money = formatarDinheiro(props.states.Aport.value)
      setViewPreco(money)
      
    }
  }, [props.states.Aport])

  return (
    <Transition.Root show={props.states.modalAport} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalAport(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[40%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalAport(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Aporte Financeiro</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do aporte
                  </p>
                </div>

                <form className='pt-4 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-8 md:grid-cols-1">
                      <div className="">
                        <div className="sm:col-span-4 flex flex-col justify-center items-center"> {/* Adicionado flex e estilos de alinhamento para centralizar */}
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Valor do aporte
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="text"
                                name="preco"
                                id="preco"
                                autoComplete="preco"
                                placeholder={props.states.waitTimeout === false ? 'R$ 0,00' : 'Carregando...'}
                                disabled={disabled}
                                value={viewPreco}
                                onBlur={() => leaveInput(preco, 'preco')}
                                onChange={e => handleChange(e.target.value, 'preco')}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {errorPreco === true && (
                            <p className="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do valor do aporte.
                            </p>
                          )}
                        </div>
                        {
                          props.states.isEdit === false && (

                            <div className="sm:col-span-2 flex flex-col justify-center items-center mt-4"> 
                            <div className="border border-gray-200 p-2 rounded-md"> 

                              <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                Descrição do aporte
                              </label>
                              <div className="mt-2 flex flex-col">
                                <p>Nome: {props.states.Investimentos?.title}</p>
                                <p>Período: {
                                  props.states.Investimentos?.period === 'm' ? 'Mensal' : 'Anual'
                                }</p>
                                <p>Duração: {
                                  props.states.Investimentos?.period === 'm' ? `${props.states.Investimentos?.time} ${props.states.Investimentos?.time === 1 ? 'mês' : 'meses'
                                    }` : `${props.states.Investimentos?.time} ${props.states.Investimentos?.time === 1 ? 'ano' : 'anos'
                                    }`
                                }
                                </p>
                                <p>Rendimento: {
                                  (props.states.Investimentos?.roi * 100)
                                }% {
                                  props.states.Investimentos?.period === 'm' ? 'ao mês' : 'ao ano'
                                }
                                </p>
                              </div>
                              {errorQuantidade === true && (
                                <p className="mt-2 ml-2 text-pink-600 text-sm">
                                  É obrigatório o preenchimento da descrição do investimentos
                                </p>
                              )}
                            </div>
                          </div>
                          
                          )
                        }
                      </div>

                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalAport(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {
                        props.states.isEdit === true ? 'Editar' : 'Salvar'
                      }
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
