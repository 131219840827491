import { useContext, useEffect, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import NavbarPanel from '../../../Components/navMenu/administrativo'
import Navbar from '../../../Components/Navbar/navbar'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import TableLogs from '../../../Components/Tabela/TabelaFundos'
import ModalShow from '../../../Components/Modal/Show'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalFundo from '../../../Components/Modal/Fundo'
import SpinnerOverlay from '../../../Components/SpinnerOverlay';


export default function RegistroCron() {
  const { states, setters } = useContext(AuthContext)
  const { dataFundos, dataFundosQuery, dataSystemFundos, postFundo, putFundo, deleteFundo } = useContext(AuthContextApi)
  const [query, setQuery] = useState('')

  /*
    dataSystemFundos,
    dataFundos,
    postFundo,
    putFundo,
    deleteFundo,
  */

  const callLogsData = async () => {
    const promises = [
      dataSystemFundos(),
      dataFundos()
    ];

    await Promise.all(promises);
  }

  useEffect(() => {
    if (states.offsetFundo == 0) return
    if (states.offsetFundo < 0) setters.setOffsetFundo(0)
    if (states.dataQuery.length > 0) return
    callLogsData()
  }, [states.offsetFundo])

  useEffect(() => {
    setters.setOffsetFundo(0)
    callLogsData()
    return () => {
      setters.setDataFundo([])
      // setters.setQuerySearchFundo('')
      setters.setOffsetFundo(0)
      setters.setLimitSearchFundo(false)
      setters.setModalShowTecnico(false)
      setters.setDataSystemFundo([])
      setters.setDataQuery([])
    }
  }, [])

  const openModalInfo = (data) => {
    setters.setFundo(data)
    setters.setModalShowTecnico(true)
  }

  const openModalConfirm = (data) => {
    setters.setFundo(data)
    setters.setConfirmModal(true)
  }

  const actionDelete = async () => {
    setters.setConfirmModal(false)
    await deleteFundo(states.Fundo.id)
  }

  const addFundo = () => {
    setters.setModalIsOpen(true)
    setters.setFundo({})
    setters.setIsEdit(false)

  }

  const editFundo = (data) => {
    setters.setModalIsOpen(true)
    setters.setFundo(data)
    setters.setIsEdit(true)
  }

  const actionModalPostOrPut = async (data) => {
    if (states.isEdit) {
      setters.setModalIsOpen(false)
      setters.setIsEdit(false)
      await putFundo(data, data.id)
      return;

    }
    setters.setIsEdit(false)
    setters.setModalIsOpen(false)
    await postFundo(data)
  }




  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataFundo.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetFundo(states.offsetFundo + states.limitFundo)
        const sumOff = states.offsetFundo + states.limitFundo
        const limit = states.limitFundo + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataFundo(newData)
      }
    } else {
      if (states.limitSearchFundo) {
        return
      } else {
        setters.setOffsetFundo(states.offsetFundo + states.limitFundo)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataFundo.length === 0 && states.offsetFundo <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetFundo > 0 && setters.setOffsetFundo(states.offsetFundo - states.limitFundo)
        states.offsetFundo < 0 && setters.setOffsetFundo(0)
        const sumOff = states.offsetFundo > 0 ? states.offsetFundo - states.limitFundo : 0;
        const limit = sumOff + 10 > states.limitFundo ? sumOff + 10 : states.limitFundo;
        const newData = data.slice(sumOff, limit)
        setters.setDataFundo(newData)
      }
    } else {
      if (states.offsetFundo <= 0) {
        states.offsetFundo < 0 && setters.setOffsetFundo(0)
        return
      } else {
        if (states.limitSearchFundo) setters.setLimitSearchFundo(false)
        setters.setOffsetFundo(states.offsetFundo - states.limitFundo)
      }
    }
  };

  const closeModal = () => {
    setters.setModalShowTecnico(false)
    setters.setFundo({})
  }

  const dataSearch = async () => {
    if (query === '') {
      await dataFundos()
      return
    }
    await dataFundosQuery(query)
    setQuery('')

  }

  const headers = ['Nome', 'Endereço', 'CEP', 'E-mail', 'Telefone', 'Data de Criação', 'Data de Atualização', '']
  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarPanel />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Fundos de pensão</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre os fundos de pensão cadastrados no sistema.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.dataSystemFundo.map((card, index) => {
                  return (
                    <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por Fundo</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                    setQuery(e.target.value)
                  }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        dataSearch()
                      }
                    }
                    }
                    value={query} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-6 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <TableLogs title='Fundos de Pensão' description='Registro dos fundos cadastrados no sistema' add={addFundo} edit={editFundo} next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetFundo, limit: states.limitFundo }} dataTable={states.dataFundo} />
            </div>
          </div>
        </div>
        <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
      </div>
      <ModalFundo states={states} setters={setters} action={actionModalPostOrPut} />
      <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.Fundo} title='Dados de log' />
      <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do registro do fundo.' />
    </>
  )
}
