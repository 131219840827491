import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import markerIconUrl from '../../ico/favicon.ico'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './index.css'

const MapaEstados = ({ dados, onEstadoSelect, dataBeckEnd }) => {
    // Coordenadas aproximadas do centro do Brasil
    const zoomLevel = 4; // Ajuste este nível de zoom conforme necessário

    const centerOfBrazil = [-14.2350, -51.9253]; // Coordenadas aproximadas do centro do Brasil

    // Componente para redefinir o mapa para o centro do Brasil se o usuário sair dos limites
    function ResetMapView({ center }) {
        const map = useMap();
        map.setView(center, map.getZoom());
        return null;
    }
    const [dadosMapa, setDadosMapa] = useState([])




    const stateMap = {
        'AC': 'Acre', 'AL': 'Alagoas', 'AP': 'Amapá', 'AM': 'Amazonas',
        'BA': 'Bahia', 'CE': 'Ceará', 'DF': 'Distrito Federal',
        'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão',
        'MT': 'Mato Grosso', 'MS': 'Mato Grosso do Sul', 'MG': 'Minas Gerais',
        'PA': 'Pará', 'PB': 'Paraíba', 'PR': 'Paraná', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte',
        'RS': 'Rio Grande do Sul', 'RO': 'Rondônia', 'RR': 'Roraima',
        'SC': 'Santa Catarina', 'SP': 'São Paulo', 'SE': 'Sergipe', 'TO': 'Tocantins'
    };

    const combinarDados = (estadosData, dadosBackend) => {
        return estadosData.map(estado => {
            const sigla = Object.keys(stateMap).find(key => stateMap[key] === estado.nome);
            return {
                ...estado,
                dadosAdicionais: dadosBackend[sigla] || [] // Adiciona os dados do backend se disponíveis
            };
        });
    };


    useEffect(() => {
        if (dataBeckEnd) {
            const estadosComDadosCombinados = combinarDados(dados, dataBeckEnd);
            setDadosMapa(estadosComDadosCombinados)

        }
    }, [dataBeckEnd])


    const markerIcon = new L.Icon({
        iconUrl: markerIconUrl,
        iconSize: [25, 41], // Tamanho do ícone em pixels
        iconAnchor: [12, 41], // Ponto do ícone que corresponderá à localização do marcador
        popupAnchor: [1, -34], // Ponto onde o popup será ancorado no ícone
        shadowUrl: 'path-to-your-marker-shadow.png', // Opcional: Caminho para a imagem da sombra do ícone
        shadowSize: [41, 41] // Opcional: Tamanho da sombra do ícone em pixels
    });

    return (
        <MapContainer center={centerOfBrazil} zoom={zoomLevel} style={{ height: '600px', width: '100%', zIndex: 0, position: 'relative' }}
            minZoom={4} // Impede o usuário de dar zoom out demais
            maxBounds={[
                [-33.751748, -73.982817], // Sudoeste do Brasil
                [5.271841, -29.345927]  // Nordeste do Brasil
            ]}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <ResetMapView center={centerOfBrazil} />

            {dadosMapa.length > 0 && dadosMapa.map((estado) => {
                if (estado.dadosAdicionais.totalGeral > 0) {
                    return (
                        <Marker
                            key={estado.id}
                            position={[estado.latitude, estado.longitude]}
                            eventHandlers={{
                                click: () => {
                                    onEstadoSelect(estado);
                                },
                            }}
                            icon={markerIcon}
                        >
                            <Popup>{estado.nome}</Popup>
                        </Marker>
                    )
                }
            })
            }
        </MapContainer >
    );
};


export default MapaEstados