import { Line } from 'react-chartjs-2';
import { useContext, useEffect } from 'react'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import moment from 'moment'
import Navbar from '../../../../Components/Navbar/navbar'
import ModalRemessa from '../../../../Components/Modal/Remessa/'
import ModalInformacao from '../../../../Components/Modal/ShowRemessa'
import NavbarPanel from '../../../../Components/navMenu/financeiroFolha'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroRemessa'

Chart.register(...registerables);

export default function Remessas() {
    const { states, setters, } = useContext(AuthContext)
    const { dataSystemRemessa, getDataGraphicRemessa, dataRemessa, postRemessa, putRemessa } = useContext(AuthContextApi)

    const loadData = async () => {
        const promises = [
            dataSystemRemessa(),
            dataRemessa()
            ];
        
        await Promise.all(promises);
    }

    useEffect(() => {
        loadData()
        setters.setOffsetRemessa(0)
        return () => {
            setters.setLimitSearchRemessa(false)
            setters.setOffsetRemessa(0)
            setters.setDataRemessa([])
            setters.setDataGraphicRemessa([])
            setters.setSystemFolha([])
        }
    }, [])

    const data = {
        labels: states.dataGraphicRemessa.map(elements => elements.date),
        datasets: [
            {
                label: 'Valor de entrada',
                data: states.dataGraphicRemessa.map(elements => elements.total),
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                borderColor: 'rgba(63, 81, 181, 1)',
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => {
                        return `$${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        return `${label}: $${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
    };

    const callLogsData = async () => {
        await dataRemessa()
    }

    useEffect(() => {
        if (states.offsetRemessa == 0) return
        if (states.dataRemessa.length == 0) return
        callLogsData()
    }, [states.offsetRemessa])

    
    const openModalInfo = (data) => {
        setters.setRemessa(data)
        setters.setModalShowTecnico(true)
    }

    const openModalConfirm = (data) => {
        setters.setRemessa(data)
        setters.setConfirmModal(true)
    }

    const next = async () => {
        if (states.limitSearchRemessa) {
            return
        } else {
            setters.setOffsetRemessa(states.offsetRemessa + states.limitRemessa)
        }
    }

    const previous = async () => {
        if (states.offsetRemessa <= 0) {
            states.offsetRemessa < 0 && setters.setOffsetRemessa(0)
            return
        } else {
            if (states.limitSearchRemessa) setters.setLimitSearchRemessa(false)
            setters.setOffsetRemessa(states.offsetRemessa - states.limitRemessa)
        }

    }

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setRemessa({})
    }

    const openModalSendRemessa = () => {
        setters.setPostRemessa(true)
        setters.setModalRemessa(true)

    }

    const openModalSendRemessaUpdate = (data) => {
        setters.setPostRemessa(false)
        setters.setRemessa(data)
        setters.setModalRemessa(true)
    }

    const sendFile = async () => {
        states.postRemessa ? await postRemessa() : await putRemessa()
    }

    const headers = ['Mês', 'Ano', 'Status', 'Status do processo', 'Valor', 'Usuário', 'Data de atualização','Data de criação', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />

                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Remessas</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as remessas.
                                    </p>

                                    <div className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                        {/* Card */}
                                        {states.systemFolha.length > 0 && states.systemFolha.map((card, index) => (
                                            <div key={card.title} className={card.value > 0 && index === 0 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                                <div className="p-5">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0">
                                                            {
                                                                card.value > 0 && index === 0 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                            }

                                                        </div>
                                                        <div className="ml-5 w-0 flex-1">
                                                            <dl>
                                                                {
                                                                    card.value > 0 && index === 0 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                                }

                                                                <dd>
                                                                    {
                                                                        card.value > 0 && index === 0 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                                    }

                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>

                                    <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                        <h1 className='text-xl font-semibold'>Intervalo</h1>
                                        <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                                        <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                                            <label htmlFor="start-date" className="mr-2">Data de início:</label>
                                            <input id="start-date" type="date" onChange={e => setters.setInitialDateRemessa(e.target.value)} value={states.initialDateRemessa} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                        </div>
                                        <div className="flex items-center">
                                            <label htmlFor="end-date" className="mr-2">Data de término:</label>
                                            <input id="end-date" type="date"  onChange={e => setters.setFinalDateRemessa(e.target.value)} value={states.finalDateRemessa} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                        </div>
                                        <div className="flex items-center md:ml-5 p-2">
                                            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => loadData()}>
                                            Aplicar
                                            </button>
                                            {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                            }
                                        </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 grid md:grid-cols-1 grid-cols-1">
                                        <div class="flex flex-col items-center p-6 bg-white rounded-md hover:shadow-md w-full h-full border border-1 border-gray-200">
                                            <h2 class="text-xl font-bold">Evolução dos valores de remessa</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={data} options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <TableFinanceiro update={openModalSendRemessaUpdate} add={openModalSendRemessa} title='Remessas' description='Registro das remessas de folha de pagamento' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetRemessa, limit: states.limitRemessa }} dataTable={states.dataRemessa} />

                        </div>
                    </div>
                </div>

                <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.Remessa} title='Visualização ampla' />
                <ModalRemessa states={states} setters={setters} send={sendFile} />

            </div>
        </>
    )
}
