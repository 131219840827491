import { Line } from 'react-chartjs-2';
import { useContext, useEffect } from 'react'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import moment from 'moment'
import Navbar from '../../../../Components/Navbar/navbar'
import ModalConfirma from '../../../../Components/Modal/Confirm'
import ModalInformacao from '../../../../Components/Modal/ShowCobranca'
import NavbarPanel from '../../../../Components/navMenu/financeiroFaturas'
import ModalFaturas from '../../../../Components/Modal/FaturaPagamento/modalFaturas'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroPagamento'
import ModalFaturasBaixa from '../../../../Components/Modal/FaturaBaixaPagamento/modalFaturas'
import { BuildingLibraryIcon, InformationCircleIcon } from '@heroicons/react/24/outline'

Chart.register(...registerables);

export default function Cobranca() {
    const { states, setters, } = useContext(AuthContext)
    const { searchQueryPagamento, reembolso, dataSystemPagamento, dataPagamento, 
    cancelarPagamento, buscarAssociadoCpf, buscarTerceirizadoCodigo, createPagamento, createPagamentoFile, baixaFaturaPagamento, getAllExpenses } = useContext(AuthContextApi)

    const loadData = async () => {
        const promises = [
            dataSystemPagamento(),
            dataPagamento()
            ];
        
        await Promise.all(promises);
    }

    useEffect(() => {
        states.offsetPagamento > 0 && setters.setOffsetPagamento(0)
        loadData()

        return () => {
            setters.setDataPagamento([])
            setters.setDataGraphicPagamento([])
            setters.setDataGraphicPagamentoNotPaid([])
            setters.setDataSystemPagamento([])
            setters.setQueryPagamento('')
            setters.setInitialDatePagamento(moment().format('YYYY-MM-DD'))
            setters.setOffsetPagamento(0)
            setters.setDataQuery([])
        }
    }, [])

    const callLogsData = async () => {
        await dataPagamento()
    }

    useEffect(() => {
        if (states.dataQuery.length > 0) return
        states.offsetPagamento > 0 && callLogsData()
    }, [states.offsetPagamento])

   
    const data = {
        labels: states.dataGraphicPagamento.map(elements => elements.date),
        datasets: [
            {
                label: 'Valor faturas pagas',
                data: states.dataGraphicPagamento.map(elements => elements.total),
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                borderColor: 'rgba(63, 81, 181, 1)',
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => {
                        return `$${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        return `${label}: $${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
    };

    const dataFlowCash = {
        labels: states.dataGraphicPagamentoNotPaid.map(elements => elements.date),
        datasets: [
            {
                label: 'Entradas',
                data: states.dataGraphicPagamentoNotPaid.map(elements => elements.total),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const openModalInfo = (data) => {
        setters.setPagamento(data)
        setters.setModalShowTecnico(true)
    }

    const next = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataPagamento.length === 0) {
                return
            } else {
                const data = states.dataQuery
                setters.setOffsetPagamento(states.offsetPagamento + states.limitPagamento)
                const sumOff = states.offsetPagamento + states.limitPagamento
                const limit = states.limitPagamento + sumOff
                const newData = data.slice(sumOff, limit)
                setters.setDataPagamento(newData)
            }
        } else {
            if (states.limitSearchPagamento) {
                return
            } else {
                setters.setOffsetPagamento(states.offsetPagamento + states.limitPagamento)
            }
        }

    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataPagamento.length === 0 && states.offsetPagamento <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetPagamento > 0 && setters.setOffsetPagamento(states.offsetPagamento - states.limitPagamento)
                states.offsetPagamento < 0 && setters.setOffsetPagamento(0)
                const sumOff = states.offsetPagamento > 0 ? states.offsetPagamento - states.limitPagamento : 0;
                const limit = sumOff + 10 > states.limitPagamento ? sumOff + 10 : states.limitPagamento;
                // 
                const newData = data.slice(sumOff, limit)
                setters.setDataPagamento(newData)
            }
        } else {
            if (states.offsetPagamento <= 0) {
                states.offsetPagamento < 0 && setters.setOffsetPagamento(0)
                return
            } else {
                if (states.limitSearchPagamento) setters.setLimitSearchPagamento(false)
                setters.setOffsetPagamento(states.offsetPagamento - states.limitPagamento)
            }
        }
    };

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setPagamento({})
    }

    const add = async () => {
        await getAllExpenses()
        setters.setModalIsOpen(true)
    }

    const cancelFatura = async (data) => {
        setters.setPagamento(data)
        setters.setConfirmModal(true)
        setters.setIsCanceled(true)
    }

    const reembolsoAction = async (data) => {       
        setters.setPagamento(data)
        setters.setConfirmModal(true)
        setters.setIsCanceled(false)
    }

    const actionDelete = async () => {
        setters.setConfirmModal(false)
        if (states.isCanceled) {
            await cancelarPagamento()
        } else {
            await reembolso()

        }
        setters.setPagamento({})
        setters.setConfirmModal(false)
    }

    const baixaFaturaValue = async (data) => {
        setters.setModalBaixa(true)
        setters.setPagamento({
            idInvoice: data.id,
        })
    }

    const baixaFaturaAction = async () => {
        setters.setModalBaixa(false)
        await baixaFaturaPagamento()
    }

    const postPagamento = async (data) => {
        if (!states.isEdit) {
            await createPagamentoFile(data)
        } else {
            await createPagamento(data)
        }
    }

    const dataSearch = async () => {
        await searchQueryPagamento()
    }

    const headers = ['Valor', 'Descrição', 'Desconto', 'Status', 'Link da Fatura', 'Multa', 'Criador', 'Data de Expiração', 'Data de Criação', 'Data de Atualização', '']

    const titleModal = states.isCanceled ? 'Confirmação de cancelamento' : 'Confirmação de reembolso'
    const descModal = states.isCanceled ? 'Deseja realmente cancelar a fatura?' : 'Deseja realmente reembolsar a fatura?'

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                
                <div className="flex flex-1 flex-col md:pl-64 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                        <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Faturas de Pagamento</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as faturas de pagamento.
                                    </p>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                            {/* Card */}
                                            {states.dataSystemPagamento.map((card, index) => {
                                                if (index == 0 || index == 1) {
                                                    if ((localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2')) {
                                                        return (
                                                            <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                                <div className="p-5">
                                                                    <div className="flex items-center">
                                                                        <div className="flex-shrink-0">
                                                                            {
                                                                                (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                            }
                                                                        </div>
                                                                        <div className="ml-5 w-0 flex-1">
                                                                            <dl>
                                                                                <dt className="truncate text-sm font-medium text-gray-500">
                                                                                    <div className='flex flex-row'>
                                                                                        <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                                    </div>
                                                                                </dt>
                                                                                <dd>
                                                                                    {
                                                                                        index === 0 && (<div className="text-lg font-medium text-green-500">{card.value}</div>)
                                                                                    }
                                                                                    {
                                                                                        index === 1 && (<div className="text-lg font-medium text-red-500">{card.value}</div>)
                                                                                    }
                                                                                    {
                                                                                        index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                                    }
                                                                                </dd>
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                            <div className="p-5">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0">
                                                                        {
                                                                            (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                        }
                                                                    </div>
                                                                    <div className="ml-5 w-0 flex-1">
                                                                        <dl>
                                                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                                                <div className='flex flex-row'>
                                                                                    <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                                </div>
                                                                            </dt>
                                                                            <dd>
                                                                                {
                                                                                    index === 0 && (<div className="text-lg font-medium text-green-500">{card.value}</div>)
                                                                                }
                                                                                {
                                                                                    index === 1 && (<div className="text-lg font-medium text-red-500">{card.value}</div>)
                                                                                }
                                                                                {
                                                                                    index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                                }
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }


                                            }

                                            )}
                                        </div>


                                    <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                        <h1 className='text-xl font-semibold'>Intervalo das faturas</h1>
                                        <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                                            <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                                                <label htmlFor="start-date" className="mr-2">Data de início:</label>
                                                <input id="start-date" type="date" onChange={e => setters.setInitialDatePagamento(e.target.value)} value={states.initialDatePagamento} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div className="flex items-center">
                                                <label htmlFor="end-date" className="mr-2">Data de término:</label>
                                                <input id="end-date" type="date" onChange={e => setters.setFinalDatePagamento(e.target.value)} value={states.finalDatePagamento} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div className="flex items-center md:ml-5 p-2">
                                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataPagamento()}>
                                                Aplicar
                                                </button>
                                                {
                                                states.isLoading && (
                                                    <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                        <div class="flex flex-col items-center p-6 bg-white mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <h2 class="text-xl font-bold">Faturas Pagas</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment(states.initialDatePagamento).format('DD/MM/YYYY')} - {moment(states.finalDatePagamento).format('DD/MM/YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={data} options={options} />
                                            </div>
                                        </div>

                                        <div class="flex flex-col items-center p-6 bg-white mt-4 w-[100%] h-full border border-1 border-gray-200 rounded-md bg-white hover:shadow-md">
                                            <h2 class="text-xl font-bold">Faturas canceladas e expiradas</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment(states.initialDatePagamento).format('DD/MM/YYYY')} - {moment(states.finalDatePagamento).format('DD/MM/YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={dataFlowCash} options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-8">
                                <h1 className='text-xl font-semibold'>Busca por associado</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => setters.setQueryPagamento(e.target.value)}
                                        value={states.queryPagamento} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                                <div className="flex items-center md:ml-5 p-2">
                                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                                    Aplicar
                                    </button>
                                    {
                                    states.isLoading && (
                                        <div className="flex items-center justify-center ml-8">
                                        <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                        </div>
                                    )
                                    }
                                </div>
                                </div>
                            </div>

                            <TableFinanceiro baixa={baixaFaturaValue} reembolso={reembolsoAction} cancel={cancelFatura} add={add} title='Faturas de pagamento' description='Registro das faturas de pagamento' next={next} previous={previous} setters={setters} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetPagamento, limit: states.limitPagamento }} dataTable={states.dataPagamento} />

                        </div>
                    </div>
                </div>

                <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.Pagamento} title='Visualização ampla' />
                <ModalConfirma states={states} setters={setters} closeModal={closeModal} data={states.Pagamento} title={titleModal} description={descModal} action={actionDelete} />
                <ModalFaturas post={postPagamento} associado={buscarAssociadoCpf} terceirizado={buscarTerceirizadoCodigo} states={states} setters={setters} />
                <ModalFaturasBaixa action={baixaFaturaAction}  states={states} setters={setters} />
                

            </div>
        </>
    )
}
