import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// import { AuthContext } from "../../../Context"
import { AuthContextApi } from "../../Context/api"
import moment from 'moment';
import { AuthContext } from '../../Context';
import { jsonConfig } from '../../Config';

const Cartao = props => {
  const { cadastrarCartaoSignature } = useContext(AuthContextApi)
  const { states, setters } = useContext(AuthContext)

  const [number, setNumber] = useState('')
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [cvv, setCvv] = useState('')

  const [errorNumber, setErrorNumber] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [errorDate, setErrorDate] = useState(false)
  const [errorCvv, setErrorCvv] = useState(false)

  const { token, id } = useParams();
  const hist = useNavigate()




  useEffect(() => {
    if ((token == null || token == '' || token == undefined) || (id == null || id == '' || id == undefined)) {
      hist('/')
    }
  }, [])

  useEffect(() => {
    let numeroCartao = number

    numeroCartao = numeroCartao.replace(/\D/g, '') // Permite apenas dígitos
    numeroCartao = numeroCartao.replace(/(\d{4})/g, '$1 ') // Coloca um ponto a cada 4 caracteres
    numeroCartao = numeroCartao.replace(/\.$/, '') // Remove o ponto se estiver sobrando
    numeroCartao = numeroCartao.substring(0, 19) // Limita o tamanho

    setNumber(numeroCartao)

  }, [number])

  useEffect(() => {

    let dataExpiracao = date

    dataExpiracao = dataExpiracao.replace(/\D/g, '') // Permite apenas dígitos
    dataExpiracao = dataExpiracao.replace(/(\d{2})/g, '$1/') // Coloca um ponto a cada 4 caracteres
    dataExpiracao = dataExpiracao.substring(0, 5) // Limita o tamanho

    setDate(dataExpiracao)

  }, [date])

  const enviarFormulario = async (e) => {
    e.preventDefault()
    const cartao = {
      number: number.replaceAll(' ', ''),
      verification_value: cvv,
      first_name: name.split(' ')[0],
      last_name: name.replace(name.split(' ')[0], '').replace(' ', ''),
      month: date.split('/')[0],
      year: date.split('/')[1],
      token: token
    }
    const verificar = await verificaStates(cartao)
    if (verificar) {
      const response = await cadastrarCartaoSignature(cartao, id)
      if (response.status == true) {
        setNumber('')
        setName('')
        setCvv('')
        setDate('')
      }
    }

  }

  const verificaStates = async (cartao) => {
    try {
      let resultado = true
      for await (const key of Object.keys(cartao)) {
        if (cartao[key] == '' || cartao[key] == ' ') {
          if (key == 'number') {
            resultado = false
            setErrorNumber(true)
          }
          else if (key == 'first_name' || key == 'last_name') {
            resultado = false
            setErrorName(true)
          }
          else if (key == 'date') {
            resultado = false
            setErrorDate(true)
          }
          else if (key == 'verification_value') {
            resultado = false
            setErrorCvv(true)
          }
        }
        else {
          if (key == 'number') {
            if (number.replaceAll(' ', '').length < 16 || number.replaceAll(' ', '').length > 16) {
              resultado = false
              setErrorNumber(true)
            }
            else {
              setErrorNumber(false)
            }
          }
          else if (key == 'first_name' || key == 'last_name') {
            setErrorName(false)
          }
          else if (key == 'date') {
            if (date == '00/00') {
              resultado = false
              setErrorDate(true)
            }
            else {
              // 
              setErrorDate(false)
            }
          }
          else if (key == 'verification_value') {
            if (cvv.length > 4) {
              resultado = false
              setErrorCvv(true)
            }
            else {
              setErrorCvv(false)
            }
          }
        }
      }
      return resultado
    }
    catch (err) {
      return false
    }
  }

  const changeCvv = (e) => {
    //isNumber
    const check = /^[0-9]+$/.test(e);
    if (check) {
      setCvv(e)
    }

  }

  if (states.isLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // A cor do overlay (50% de transparência)
          zIndex: 9999, // Coloque o overlay acima dos outros elementos da página
        }}
      >
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-500 border-solid"></div>
        </div>

      </div>
    )

  } else {


    return (
      <>
        <div>
          <header class="flex justify-center w-full bg-gradient-to-r from-blue-700 via-blue-400 to-blue-700 fixed top-[0px] h-20" >
            <div class="mx-auto max-w-7xl overflow-hidden p-5">
              <img class="block h-8 w-auto" src={`${jsonConfig.urlAplication}/logo-sinprev-resp.png`} alt="Sinprev" />
            </div>
          </header>

          <body class='sm:h-[100vh] flex flex-col justify-center p-8 mb-[10%]'>
            <div class="mr-4 ml-4 mt-[15%]">
              <div class="rounded-lg overflow-hidden shadow-lg " >

                <div class="rounded-lg overflow-hidden bg-gray-100 h-14">
                  <div className='flex-col'>
                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mt-4">Adicione o seu cartão para concluir a assinatura</h3>
                  </div>
                </div>

                <div
                  class="flex justify-center p-10 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"
                >
                  <div
                    class="w-64 h-40 bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 rounded-lg shadow-lg"
                  >
                    <div class="flex justify-between m-2">

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="3" y="5" width="18" height="14" rx="3" />
                        <line x1="3" y1="10" x2="21" y2="10" />
                        <line x1="7" y1="15" x2="7.01" y2="15" />
                        <line x1="11" y1="15" x2="13" y2="15" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="9.5" cy="9.5" r="5.5" fill="#fff" />
                        <circle cx="14.5" cy="14.5" r="5.5" />
                      </svg>
                    </div>
                    <div class="flex justify-center mt-4">
                      <h1 class="text-gray-400 font-thin font-os">
                        {number == '' ? '**** **** **** ****' : number}
                      </h1>
                    </div>
                    <div
                      class="flex flex-col justfiy-end mt-1 p-4 text-gray-400 font-quick"
                    >
                      <p class="font-bold text-xs">{cvv == 0 ? '000' : cvv}</p>
                      <p class="font-bold text-xs">{date}</p>
                      <h4 class="uppercase tracking-wider font-semibold text-xs">
                        {name == '' ? 'Nome impresso' : name}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='flex-col'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mt-4">Cartão</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center">
                    Informe os dados do seu cartão de crédito
                  </p>
                </div>

                <div className="space-y-6 sm:space-y-5 p-5">
                  <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <div className="mt-2 sm:col-span-1  sm:mt-0">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                        Número
                      </label>
                      <div className="flex max-w-lg rounded-md shadow-sm">
                        <input
                          type="text"
                          name="nome"
                          id="username"
                          placeholder='Digite o número ...'
                          value={number}
                          onChange={e => setNumber(e.target.value)}
                          maxLength={19}
                          // onBlur={e => leaveInput(props.states.emailAssociado,'email')}
                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {
                        errorNumber === true &&
                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                          {/* Forneça um endereço de e-mail válido. */}
                          É obrigatório o preenchimento de um número válido. Verifique se o número está correto.
                        </p>
                      }
                    </div>

                    <div className="mt-2 sm:col-span-1 sm:mt-0">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                        Data de Expiração
                      </label>
                      <div className="flex max-w-lg rounded-md shadow-sm">
                        <input
                          type="text"
                          name="sobrenome"
                          id="username"
                          placeholder='Digite a data de expiração ...'
                          value={date}
                          onChange={e => setDate(e.target.value)}
                          maxLength={5}
                          // onBlur={e => leaveInput(props.states.celular,'celular')}
                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {
                        errorNumber === true &&
                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                          {/* Forneça um endereço de e-mail válido. */}
                          É obrigatório o preenchimento de uma data válida.
                        </p>
                      }
                    </div>

                    <div className="mt-2 sm:col-span-1 sm:mt-0">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                        Nome Impresso no Cartão
                      </label>
                      <div className="flex max-w-lg rounded-md shadow-sm">
                        <input
                          type="text"
                          name="cpf"
                          id="username"
                          autoComplete="cep"
                          placeholder='Digite o nome ...'
                          value={name}
                          onChange={e => setName(e.target.value)}
                          maxLength={120}
                          // onBlur={e => leaveInput(props.states.cep,'cep')}
                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {
                        errorName === true &&
                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                          {/* Forneça um endereço de e-mail válido. */}
                          É obrigatório o preenchimento de um nome e sobrenome.
                        </p>
                      }
                    </div>

                    <div className="mt-2 sm:col-span-1 sm:mt-0">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                        CVV
                      </label>
                      <div className="flex max-w-lg rounded-md shadow-sm">
                        <input
                          type="text"
                          name="bairro"
                          id="bairro"
                          autoComplete="bairro"
                          placeholder='Digite o CVV ...'
                          value={cvv}
                          onChange={e => changeCvv(e.target.value)}
                          maxLength={4}
                          // onBlur={e => leaveInput(props.states.bairro,'bairro')}
                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />

                      </div>
                      {
                        errorCvv === true &&
                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                          {/* Forneça um endereço de e-mail válido. */}
                          É obrigatório o preenchimento do CVV.
                        </p>
                      }
                    </div>
                  </div>
                </div>

                <div class="p-4 flex justify-center">
                  <button type="button"
                    disabled={states.isLoading}
                    class="text-white tracking-wide text-sm border rounded-md text-sm shadow-sm px-4 py-1 bg-blue-600" onClick={e => enviarFormulario(e)}>

                    {
                      states.isLoading ? (
                        <p>Carregando</p>
                      ) : (
                        <p>Cadastrar Cartão</p>
                      )
                    }
                  </button>
                </div>
              </div>
            </div>
          </body>






          <footer class="flex justify-center w-full bg-gradient-to-r from-blue-700 via-blue-400 to-blue-700 bottom-[0px] h-20">
            <div class="mx-auto max-w-7xl overflow-hidden">
              <p class="text-center text-xs leading-5 text-black py-8">&copy; {moment().format('YYYY')} SINPREV. Todos os direitos reservados</p>
            </div>
          </footer>
        </div>
      </>
    )
  }
}

export default Cartao;





















