import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'

import InputMask from "react-input-mask";
import moment from 'moment';

export default function ModalProduct(props) {

  const { buscarCep, validarCpf, acaoFormularioAssociado } = useContext(AuthContext)
  const { buscarAssociadoPorId } = useContext(AuthContextApi)
  const [disabled, setDisabled] = useState(false)


  const [associado, setAssociado] = useState({})

  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [sexo, setSexo] = useState('')
  const [dataNascimento, setDataNascimento] = useState('')
  const [cidadeNascimento, setCidadeNascimento] = useState('')
  const [estadoNascimento, setEstadoNascimento] = useState('')
  const [estadoCivil, setEstadoCivil] = useState('')
  const [rg, setRg] = useState('')
  const [rgExpedicao, setRgExpedicao] = useState('')
  const [rgOrgao, setRgOrgao] = useState('')
  const [nomePai, setNomePai] = useState('')
  const [nomeMae, setNomeMae] = useState('')
  const [empresa, setEmpresa] = useState('')
  const [matriculaEmpresa, setMatriculaEmpresa] = useState('')
  const [aposentado, setAposentado] = useState('')
  const [fundo, setFundo] = useState('')
  const [escolaridade, setEscolaridade] = useState('')
  const [dataAssociacao, setDataAssociacao] = useState('')
  const [planoSaude, setPlanoSaude] = useState('');
  const [nomePlanoSaude, setNomePlanoSaude] = useState('')
  const [email, setEmail] = useState('')
  const [celular, setCelular] = useState('')
  const [cep, setCep] = useState('')
  const [bairro, setBairro] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [numeroResidencia, setNumeroResidencia] = useState('')
  const [complemento, setComplemento] = useState('')
  const [cidade, setCidade] = useState('')
  const [estado, setEstado] = useState('')
  const [metodoPagamento, setMetodoPagamento] = useState('')

  const [errorNome, setErrorNome] = useState(false)
  const [errorCpf, setErrorCpf] = useState(false)
  const [errorDataNascimento, setErrorDataNascimento] = useState(false)
  const [errorCidadeNascimento, setErrorCidadeNascimento] = useState(false)
  const [errorEstadoNascimento, setErrorEstadoNascimento] = useState(false)
  const [errorEstadoCivil, setErrorEstadoCivil] = useState(false)
  const [errorRg, setErrorRg] = useState(false)
  const [errorRgExpedicao, setErrorRgExpedicao] = useState(false)
  const [errorRgOrgao, setErrorRgOrgao] = useState(false)
  const [errorNomePai, setErrorNomePai] = useState(false)
  const [errorNomeMae, setErrorNomeMae] = useState(false)
  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [errorMatriculaEmpresa, setErrorMatriculaEmpresa] = useState(false)
  const [errorFundo, setErrorFundo] = useState(false)
  const [errorEscolaridade, setErrorEscolaridade] = useState(false)
  const [errorDataAssociacao, setErrorDataAssociacao] = useState(false)
  const [errorNomePlanoSaude, setErrorNomePlanoSaude] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorCelular, setErrorCelular] = useState(false)
  const [errorCep, setErrorCep] = useState(false)
  const [errorBairro, setErrorBairro] = useState(false)
  const [errorLogradouro, setErrorLogradouro] = useState(false)
  const [errorNumeroResidencia, setErrorNumeroResidencia] = useState(false)
  const [errorComplemento, setErrorComplemento] = useState(false)
  const [errorCidade, setErrorCidade] = useState(false)
  const [errorEstado, setErrorEstado] = useState(false)
  const [errorMetodoPagamento, setErrorMetodoPagamento] = useState(false)
  const [errorSexo, setErrorSexo] = useState(false)
  const [errorPlanoSaude, setErrorPlanoSaude] = useState(false)
  const [errorAposentado, setErrorAposentado] = useState(false)

  useEffect(() => {
    try {
      if (props.states.modalIsOpen === false) {
        limparStatesModalAssociado()
        setAssociado({})
        props.setid(0)

      }
      else {
        if (props.id != undefined && props.id != '0') {
          buscarAssociado(props.id)
        }
      }
    }
    catch (err) {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [props.states.modalIsOpen]);

  const buscarAssociado = async (id) => {
    try {
      const data = await buscarAssociadoPorId(id)
      const objeto = { ...data.associate }
      setAssociado(objeto)
      carregarStatesModalAssociado(objeto)
    }
    catch (err) {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'nome') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorNome(true)
      }
      else {
        setErrorNome(false)
      }
    }
    else if (tipo === 'cpf') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCpf(true)
      }
      else {
        const resultado = validarCpf(cpf)
        setErrorCpf(!resultado.status)
      }
    }
    else if (tipo === 'dataNascimento') {
      if (valor === undefined || valor === null || valor === '') {

        setErrorDataNascimento(true)
      }
      else {
        const myArray = valor.split("-");
        let year = myArray[0];
        if (year.length != 4) {
          setErrorDataNascimento(true)
        }
        else {
          setErrorDataNascimento(false)
        }
      }
    }
    else if (tipo === 'cidadeNascimento') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCidadeNascimento(true)
      }
      else {
        setErrorCidadeNascimento(false)
      }
    }
    else if (tipo === 'estadoNascimento') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEstadoNascimento(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorEstadoNascimento(true)
        }
        else {
          setErrorEstadoNascimento(false)
        }
      }
    }
    else if (tipo === 'estadoCivil') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEstadoCivil(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorEstadoCivil(true)
        }
        else {
          setErrorEstadoCivil(false)
        }
      }
    }
    else if (tipo === 'rg') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorRg(true)
      }
      else {
        setErrorRg(false)
      }
    }
    else if (tipo === 'rgExpedicao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorRgExpedicao(true)
      }
      else {
        const myArray = valor.split("-");
        let year = myArray[0];
        if (year.length != 4) {
          setErrorRgExpedicao(true)
        }
        else {
          setErrorRgExpedicao(false)
        }
      }
    }
    else if (tipo === 'rgOrgao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorRgOrgao(true)
      }
      else {
        setErrorRgOrgao(false)
      }
    }
    else if (tipo === 'nomePai') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorNomePai(true)
      }
      else {
        setErrorNomePai(false)
      }
    }
    else if (tipo === 'nomeMae') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorNomeMae(true)
      }
      else {
        setErrorNomeMae(false)
      }
    }
    else if (tipo === 'empresa') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEmpresa(true)
      }
      else {
        setErrorEmpresa(false)
      }
    }
    else if (tipo === 'matriculaEmpresa') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorMatriculaEmpresa(true)
      }
      else {
        setErrorMatriculaEmpresa(false)
      }
    }
    else if (tipo === 'fundo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorFundo(true)
      }
      else {
        setErrorFundo(false)
      }
    }
    else if (tipo === 'escolaridade') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEscolaridade(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorEscolaridade(true)
        }
        else {
          setErrorEscolaridade(false)
        }
      }
    }
    else if (tipo === 'dataAssociacao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDataAssociacao(true)
      }
      else {
        const myArray = valor.split("-");
        let year = myArray[0];
        if (year.length != 4) {
          setErrorDataAssociacao(true)
        }
        else {
          setErrorDataAssociacao(false)
        }
      }
    }
    else if (tipo === 'nomePlanoSaude') {
      if (valor === undefined || valor === null || valor === '') {
        if (planoSaude == 'S') setErrorNomePlanoSaude(true)
        setNomePlanoSaude('NA')
      }
      else {
        setErrorNomePlanoSaude(false)
      }
    }
    else if (tipo === 'email') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEmail(true)
      }
      else {
        setErrorEmail(false)
      }
    }
    else if (tipo === 'celular') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCelular(true)
      }
      else {
        const resultado = (celular.replaceAll(`_`, ``).replaceAll(`+`, ``).replaceAll(`-`, ``).replaceAll(`(`, ``).replaceAll(`)`, ``).replaceAll(` `, ``).length == 13) ? true : false
        setErrorCelular(!resultado)
      }
    }
    else if (tipo === 'cep') {
      handleCep()
      if (valor === undefined || valor === null || valor === '') {
        setErrorCep(true)
      }
      else {
        setErrorCep(false)
      }
    }
    else if (tipo === 'bairro') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorBairro(true)
      }
      else {
        setErrorBairro(false)
      }
    }
    else if (tipo === 'logradouro') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorLogradouro(true)
      }
      else {
        setErrorLogradouro(false)
      }
    }
    else if (tipo === 'numeroResidencia') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorNumeroResidencia(true)
      }
      else {
        setErrorNumeroResidencia(false)
      }
    }
    else if (tipo === 'complemento') {
      if (valor === undefined || valor === null || valor === '') {
        setComplemento('NA')
      }
      else {
        setErrorComplemento(false)
      }
    }
    else if (tipo === 'cidade') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCidade(true)
      }
      else {
        setErrorCidade(false)
      }
    }
    else if (tipo === 'estado') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEstado(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorEstado(true)
        }
        else {
          setErrorEstado(false)
        }
      }
    }
    else if (tipo === 'metodoPagamento') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorMetodoPagamento(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorMetodoPagamento(true)
        }
        else {
          setErrorMetodoPagamento(false)
        }
      }
    }
    else if (tipo === 'sexo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorSexo(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorSexo(true)
        }
        else {
          setErrorSexo(false)
        }
      }
    }
    else if (tipo === 'aposentado') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorAposentado(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorAposentado(true)
        }
        else {
          setErrorAposentado(false)
        }
      }
    }
    else if (tipo === 'planoSaude') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPlanoSaude(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorPlanoSaude(true)
        }
        else {
          setErrorPlanoSaude(false)
        }
      }
    }

  }

  const handleCep = async () => {
    try {
      if (cep != '') {
        const cepCorrigido = cep
          .replace('-', '')
          .replace('_', '')
        if (!(cepCorrigido == '' || cepCorrigido.length != 8)) {
          setErrorCep(false)
          const result = await buscarCep(cep)
          if (result.status == true) {
            setLogradouro(result.content.logradouro.replace('Rua ', ''))
            setBairro(result.content.bairro)
            setCidade(result.content.localidade)
            setEstado(buscaAbreviacao(result.content.uf))

            setErrorLogradouro(false)
            setErrorBairro(false)
            setErrorCidade(false)
            setErrorEstado(false)
            toast.success('CEP encontrado!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          } else {
            setErrorCep(true)
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          }
        } else {
          setErrorCep(true)
          toast.error('CEP inválido!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        }
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
  }

  const buscaAbreviacao = (sigla) => {
    if (sigla == 'AC') {
      return 'Acre'
    } else if (sigla == 'AL') {
      return 'Alagoas'
    } else if (sigla == 'AP') {
      return 'Amapá'
    } else if (sigla == 'AM') {
      return 'Amazonas'
    } else if (sigla == 'BA') {
      return 'Bahia'
    } else if (sigla == 'CE') {
      return 'Ceará'
    } else if (sigla == 'DF') {
      return 'Distrito Federal'
    } else if (sigla == 'ES') {
      return 'Espírito Santo'
    } else if (sigla == 'GO') {
      return 'Goiás'
    } else if (sigla == 'MA') {
      return 'Maranhão'
    } else if (sigla == 'MT') {
      return 'Mato Grosso'
    } else if (sigla == 'MS') {
      return 'Mato Grosso do Sul'
    } else if (sigla == 'MG') {
      return 'Minas Gerais'
    } else if (sigla == 'PA') {
      return 'Pará'
    } else if (sigla == 'PB') {
      return 'Paraíba'
    } else if (sigla == 'PR') {
      return 'Paraná'
    } else if (sigla == 'PE') {
      return 'Pernambuco'
    } else if (sigla == 'PI') {
      return 'Piauí'
    } else if (sigla == 'RJ') {
      return 'Rio de Janeiro'
    } else if (sigla == 'RN') {
      return 'Rio Grande do Norte'
    } else if (sigla == 'RS') {
      return 'Rio Grande do Sul'
    } else if (sigla == 'RO') {
      return 'Rondônia'
    } else if (sigla == 'RR') {
      return 'Roraima'
    } else if (sigla == 'SC') {
      return 'Santa Catarina'
    } else if (sigla == 'SP') {
      return 'São Paulo'
    } else if (sigla == 'SE') {
      return 'Sergipe'
    } else if (sigla == 'TO') {
      return 'Tocantins'
    } else {
      return sigla
    }
  }

  const buscaExtenso = (estado) => {
    if (estado == 'Acre') { return 'AC' }
    else if (estado == 'Alagoas') { return 'AL' }
    else if (estado == 'Amapá') { return 'AP' }
    else if (estado == 'Amazonas') { return 'AM' }
    else if (estado == 'Bahia') { return 'BA' }
    else if (estado == 'Ceará') { return 'CE' }
    else if (estado == 'Distrito Federal') { return 'DF' }
    else if (estado == 'Espírito Santo') { return 'ES' }
    else if (estado == 'Goiás') { return 'GO' }
    else if (estado == 'Maranhão') { return 'MA' }
    else if (estado == 'Mato Grosso') { return 'MT' }
    else if (estado == 'Mato Grosso do Sul') { return 'MS' }
    else if (estado == 'Minas Gerais') { return 'MG' }
    else if (estado == 'Pará') { return 'PA' }
    else if (estado == 'Paraíba') { return 'PB' }
    else if (estado == 'Paraná') { return 'PR' }
    else if (estado == 'Pernambuco') { return 'PE' }
    else if (estado == 'Piauí') { return 'PI' }
    else if (estado == 'Rio de Janeiro') { return 'RJ' }
    else if (estado == 'Rio Grande do Norte') { return 'RN' }
    else if (estado == 'Rio Grande do Sul') { return 'RS' }
    else if (estado == 'Rondônia') { return 'RO' }
    else if (estado == 'Roraima') { return 'RR' }
    else if (estado == 'Santa Catarina') { return 'SC' }
    else if (estado == 'São Paulo') { return 'SP' }
    else if (estado == 'Sergipe') { return 'SE' }
    else if (estado == 'Tocantins') { return 'TO' }
    else { return estado }
  }

  const buscaMetodoPagamento = (metodo) => {
    if (estado == 'Cartão de Crédito') { return 'credit_card' }
    else if (estado == 'Boleto') { return 'bank_sleep' }
    else { return metodo }
  }

  const onSubmitForm = async (e) => {
    try {
      if (Object.keys(associado).length > 0) {
        editarAssociadoOnSubmit(e)
      }
      else {
        cadastrarAssociadoOnSubmit(e)
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const cadastrarAssociadoOnSubmit = async (e) => {
    try {
      e.preventDefault()
      const associado = {
        cpf: cpf.replaceAll('.', '').replaceAll('-', '').replaceAll('_', ''),
        email: email,
        dateFiliate: dataAssociacao,
        filiate: true,
        name: nome,
        phone: celular.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', ''),
        fundo: fundo,
        sexo: sexo,
        civilState: estadoCivil,
        escolarchip: escolaridade,
        hometown: cidadeNascimento,
        homeState: buscaExtenso(estadoNascimento),
        rg: rg,
        emissorEntityRg: rgOrgao,
        dateRg: rgExpedicao,
        fathername: nomePai,
        mothername: nomeMae,
        company: empresa,
        companyCode: matriculaEmpresa,
        retired: aposentado,
        houseNumber: numeroResidencia,
        city: cidade,
        neighborhood: bairro,
        state: buscaExtenso(estado),
        healthPlan: planoSaude,
        nameHealthPlan: nomePlanoSaude,
        paymentMethod: buscaMetodoPagamento(metodoPagamento),
        cep: cep,
        address: logradouro,
        complement: complemento == '' ? 'NA' : complemento,
        birthDate: dataNascimento,
        stateAcronym: buscaExtenso(estado),
        // user_id=>token
      }
      const verificar = await verificarStatesModalAssociado(associado)
      if (verificar == true) {
        const result = await acaoFormularioAssociado('', false, associado)
        if (result.status == true) {
          toast.success('Associado cadastrado com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setters.setModalIsOpen(false)
        }
        else {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
    catch (err) {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const editarAssociadoOnSubmit = async (e) => {
    e.preventDefault()
    try {
      const associadoData = {
        cpf: cpf.replaceAll('.', '').replaceAll('-', '').replaceAll('_', ''),
        email: email,
        dateFiliate: dataAssociacao,
        filiate: true,
        name: nome,
        phone: celular.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', ''),
        fundo: fundo,
        sexo: sexo,
        civilState: estadoCivil,
        escolarchip: escolaridade,
        hometown: cidadeNascimento,
        homeState: buscaExtenso(estadoNascimento),
        rg: rg,
        emissorEntityRg: rgOrgao,
        dateRg: rgExpedicao,
        fathername: nomePai,
        mothername: nomeMae,
        company: empresa,
        companyCode: matriculaEmpresa,
        retired: aposentado,
        houseNumber: numeroResidencia,
        city: cidade,
        neighborhood: bairro,
        state: buscaExtenso(estado),
        healthPlan: planoSaude,
        nameHealthPlan: nomePlanoSaude,
        paymentMethod: buscaMetodoPagamento(metodoPagamento),
        cep: cep,
        address: logradouro,
        complement: complemento,
        birthDate: dataNascimento,
        stateAcronym: buscaExtenso(estado),
        // user_id=>token
      }
      const verificar = await verificarStatesModalAssociado(associadoData)
      if (verificar == true) {
        const result = await acaoFormularioAssociado(associado.id, true, associadoData)
        if (result.status == true) {
          toast.success('Associado editado com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.setters.setModalIsOpen(false)
        }
        else {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
      else {
        toast.error('Associado não pôde ser editado!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    catch (err) {
      toast.error('Associado não pôde ser editado!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const carregarStatesModalAssociado = (associado) => {
    setNome(associado.name)
    setCpf(associado.cpf)
    setSexo(associado.sexo)
    setDataNascimento(moment(associado.birthDate).format('YYYY-MM-DD'))
    setCidadeNascimento(associado.hometown)
    setEstadoNascimento(buscaAbreviacao(associado.homeState))
    setEstadoCivil(associado.civilState)
    setRgExpedicao(moment(associado.dateRg).format('YYYY-MM-DD'))
    setRg(associado.rg)
    setRgOrgao(associado.rg)
    setNomePai(associado.fathername)
    setNomeMae(associado.mothername)
    setEmpresa(associado.company)
    setMatriculaEmpresa(associado.companyCode)
    setAposentado(associado.retired == 'S' ? 'S' : associado.retired == 'N' && 'N')
    setFundo(associado.fundo_id)
    setEscolaridade(associado.escolarchip)
    setDataAssociacao(associado.dateFiliate.replace('T03:00:00.000Z', ''))
    setPlanoSaude(associado.healthPlan == 'N' ? 'N' : 'S');
    setNomePlanoSaude(associado.nameHealthPlan)
    setEmail(associado.email)
    setCelular(associado.phone.replace(/^(\+55)/, ''))
    setCep(associado.cep)
    setBairro(associado.neighborhood)
    setLogradouro(associado.address)
    setNumeroResidencia(associado.houseNumber)
    setComplemento(associado.complement)
    setCidade(associado.city)
    setEstado(buscaAbreviacao(associado.state))
    setMetodoPagamento(associado.paymentMethod)

    setErrorNome(false)
    setErrorCpf(false)
    setErrorDataNascimento(false)
    setErrorCidadeNascimento(false)
    setErrorEstadoNascimento(false)
    setErrorEstadoCivil(false)
    setErrorRg(false)
    setErrorRgExpedicao(false)
    setErrorRgOrgao(false)
    setErrorNomePai(false)
    setErrorNomeMae(false)
    setErrorEmpresa(false)
    setErrorMatriculaEmpresa(false)
    setErrorFundo(false)
    setErrorEscolaridade(false)
    setErrorDataAssociacao(false)
    setErrorNomePlanoSaude(false)
    setErrorEmail(false)
    setErrorCelular(false)
    setErrorCep(false)
    setErrorBairro(false)
    setErrorLogradouro(false)
    setErrorNumeroResidencia(false)
    setErrorComplemento(false)
    setErrorCidade(false)
    setErrorEstado(false)
    setErrorMetodoPagamento(false)
    setErrorSexo(false)
    setErrorPlanoSaude(false)
    setErrorAposentado(false)
  }

  const limparStatesModalAssociado = () => {
    setNome('')
    setCpf('')
    setSexo('')
    setDataNascimento('')
    setCidadeNascimento('')
    setEstadoNascimento('')
    setEstadoCivil('')
    setRg('')
    setRgExpedicao('')
    setRgOrgao('')
    setNomePai('')
    setNomeMae('')
    setEmpresa('')
    setMatriculaEmpresa('')
    setAposentado('')
    setFundo('')
    setEscolaridade('')
    setDataAssociacao('')
    setPlanoSaude('');
    setNomePlanoSaude('')
    setEmail('')
    setCelular('')
    setCep('')
    setBairro('')
    setLogradouro('')
    setNumeroResidencia('')
    setComplemento('')
    setCidade('')
    setEstado('')
    setMetodoPagamento('')

    setErrorNome(false)
    setErrorCpf(false)
    setErrorDataNascimento(false)
    setErrorCidadeNascimento(false)
    setErrorEstadoNascimento(false)
    setErrorEstadoCivil(false)
    setErrorRg(false)
    setErrorRgExpedicao(false)
    setErrorRgOrgao(false)
    setErrorNomePai(false)
    setErrorNomeMae(false)
    setErrorEmpresa(false)
    setErrorMatriculaEmpresa(false)
    setErrorFundo(false)
    setErrorEscolaridade(false)
    setErrorDataAssociacao(false)
    setErrorNomePlanoSaude(false)
    setErrorEmail(false)
    setErrorCelular(false)
    setErrorCep(false)
    setErrorBairro(false)
    setErrorLogradouro(false)
    setErrorNumeroResidencia(false)
    setErrorComplemento(false)
    setErrorCidade(false)
    setErrorEstado(false)
    setErrorMetodoPagamento(false)
    setErrorSexo(false)
    setErrorPlanoSaude(false)
    setErrorAposentado(false)
  }

  const verificarStatesModalAssociado = async (associado) => {
    try {
      let resultado = true
      for await (const key of Object.keys(associado)) {
        if (associado[key] == '' || associado[key] == ' ') {
          if (key == 'cpf') {
            resultado = false
            setErrorCpf(true)
          }
          else if (key == 'email') {
            resultado = false
            setErrorEmail(true)
          }
          else if (key == 'dateFiliate') {
            resultado = false
            setErrorDataAssociacao(true)
          }
          else if (key == 'name') {
            resultado = false
            setErrorNome(true)
          }
          else if (key == 'phone') {
            resultado = false
            setErrorCelular(true)
          }
          else if (key == 'fundo') {
            resultado = false
            setErrorFundo(true)
          }
          else if (key == 'sexo') {
            resultado = false
            setErrorSexo(true)
          }
          else if (key == 'civilState') {
            resultado = false
            setErrorEstadoCivil(true)
          }
          else if (key == 'escolarchip') {
            resultado = false
            setErrorEscolaridade(true)
          }
          else if (key == 'hometown') {
            resultado = false
            setErrorCidadeNascimento(true)
          }
          else if (key == 'homeState') {
            resultado = false
            setErrorEstadoNascimento(true)
          }
          else if (key == 'rg') {
            resultado = false
            setErrorRg(true)
          }
          else if (key == 'emissorEntityRg') {
            resultado = false
            setErrorRgOrgao(true)
          }
          else if (key == 'dateRg') {
            resultado = false
            setErrorRgExpedicao(true)
          }
          else if (key == 'fathername') {
            resultado = false
            setErrorNomePai(true)
          }
          else if (key == 'mothername') {
            resultado = false
            setErrorNomeMae(true)
          }
          else if (key == 'company') {
            resultado = false
            setErrorEmpresa(true)
          }
          else if (key == 'companyCode') {
            resultado = false
            setErrorMatriculaEmpresa(true)
          }
          else if (key == 'retired') {
            resultado = false
            setErrorAposentado(true)
          }
          else if (key == 'houseNumber') {
            resultado = false
            setErrorNumeroResidencia(true)
          }
          else if (key == 'city') {
            resultado = false
            setErrorCidade(true)
          }
          else if (key == 'neighborhood') {
            resultado = false
            setErrorBairro(true)
          }
          else if (key == 'state') {
            resultado = false
            setErrorEstado(true)
          }
          else if (key == 'healthPlan') {
            resultado = false
            setErrorPlanoSaude(true)
          }
          else if (key == 'nameHealthPlan') {
            resultado = false
            setErrorNomePlanoSaude(true)
          }
          else if (key == 'paymentMethod') {
            resultado = false
            setErrorMetodoPagamento(true)
          }
          else if (key == 'cep') {
            resultado = false
            setErrorCep(true)
          }
          else if (key == 'address') {
            resultado = false
            setErrorLogradouro(true)
          }
          else if (key == 'complement') {
            resultado = false
            setErrorComplemento(true)
          }
          else if (key == 'birthDate') {
            resultado = false
            setErrorDataNascimento(true)
          }
          else if (key == 'stateAcronym') {
            resultado = false
            setErrorEstado(true)
          }
        }
        else {
          if (key == 'cpf') {
            setErrorCpf(false)
          }
          else if (key == 'email') {
            setErrorEmail(false)
          }
          else if (key == 'dateFiliate') {
            setErrorDataAssociacao(false)
          }
          else if (key == 'name') {
            setErrorNome(false)
          }
          else if (key == 'phone') {
            setErrorCelular(false)
          }
          else if (key == 'fundo') {
            setErrorFundo(false)
          }
          else if (key == 'sexo') {
            setErrorSexo(false)
          }
          else if (key == 'civilState') {
            setErrorEstadoCivil(false)
          }
          else if (key == 'escolarchip') {
            setErrorEscolaridade(false)
          }
          else if (key == 'hometown') {
            setErrorCidadeNascimento(false)
          }
          else if (key == 'homeState') {
            setErrorEstadoNascimento(false)
          }
          else if (key == 'rg') {
            setErrorRg(false)
          }
          else if (key == 'emissorEntityRg') {
            setErrorRgOrgao(false)
          }
          else if (key == 'dateRg') {
            setErrorRgExpedicao(false)
          }
          else if (key == 'fathername') {
            setErrorNomePai(false)
          }
          else if (key == 'mothername') {
            setErrorNomeMae(false)
          }
          else if (key == 'company') {
            setErrorEmpresa(false)
          }
          else if (key == 'companyCode') {
            setErrorMatriculaEmpresa(false)
          }
          else if (key == 'retired') {
            setErrorAposentado(false)
          }
          else if (key == 'houseNumber') {
            setErrorNumeroResidencia(false)
          }
          else if (key == 'city') {
            setErrorCidade(false)
          }
          else if (key == 'neighborhood') {
            setErrorBairro(false)
          }
          else if (key == 'state') {
            setErrorEstado(false)
          }
          else if (key == 'healthPlan') {
            setErrorPlanoSaude(false)
          }
          else if (key == 'nameHealthPlan') {
            setErrorNomePlanoSaude(false)
          }
          else if (key == 'paymentMethod') {
            setErrorMetodoPagamento(false)
          }
          else if (key == 'cep') {
            setErrorCep(false)
          }
          else if (key == 'address') {
            setErrorLogradouro(false)
          }
          else if (key == 'complement') {
            setErrorComplemento(false)
          }
          else if (key == 'birthDate') {
            setErrorDataNascimento(false)
          }
          else if (key == 'stateAcronym') {
            setErrorEstado(false)
          }
        }
      }
      return resultado
    }
    catch (err) {
      return false
    }
  }

  const formatarData = (data) => {
    var dateParts = data.split("/");
    const dia = parseInt(dateParts[0])
    const mes = parseInt(dateParts[1])
    const ano = parseInt(dateParts[2])
    const dataFinal = (dia < 10 ? '0' + dia : '' + dia) + '/' + (mes < 10 ? '0' + mes : '' + mes) + '/' + ano
    return dataFinal
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Associado</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do associado
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    {/* 1 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações pessoais</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados privados do associado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nome
                          </label>
                          <div className="rounded-md shadow-sm">
                            <input
                              type="text"
                              name="nome"
                              id="nome"
                              autoComplete="nome"

                              placeholder={props.states.waitTimeout === false ? 'Digite seu nome' : 'Carregando...'}
                              disabled={disabled}
                              value={nome}
                              onBlur={() => leaveInput(nome, 'nome')}
                              onChange={e => setNome(e.target.value)}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorNome === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do nome.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            CPF
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <InputMask

                              type="text"
                              name="cpf"
                              id="cpf"
                              autoComplete="cpf"
                              placeholder='Digite o CPF'
                              mask="999.999.999-99"
                              disabled={disabled}
                              value={cpf}
                              onBlur={() => leaveInput(cpf, 'cpf')}
                              onChange={e => setCpf(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCpf === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento de um CPF válido.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Sexo
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              id="sexo"
                              name="sexo"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              disabled={disabled}
                              onChange={e => setSexo(e.target.value)}
                              onBlur={() => leaveInput(sexo, 'sexo')}
                              value={
                                sexo
                              }
                            >
                              <option>Selecione ..</option>
                              <option value="M">Masculino</option>
                              <option calue="F">Feminino</option>
                            </select>

                          </div>
                          {
                            errorSexo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do sexo.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Data de nascimento
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="date"
                              name="datanascimento"
                              id="datanascimento"
                              autoComplete="datanascimento"
                              disabled={disabled}
                              value={dataNascimento}
                              onBlur={() => leaveInput(dataNascimento, 'dataNascimento')}
                              onChange={e => setDataNascimento(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorDataNascimento === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da data de nascimento.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Cidade de nascimento
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="cidadenascimento"
                              id="cidadenascimento"
                              autoComplete="cidadenascimento"

                              placeholder={props.states.waitTimeout === false ? 'Digite sua cidade de nascimento' : 'Carregando...'}
                              disabled={disabled}
                              value={cidadeNascimento}
                              onBlur={() => leaveInput(cidadeNascimento, 'cidadeNascimento')}
                              onChange={e => setCidadeNascimento(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCidadeNascimento === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da cidade de nascimento.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Estado de nascimento
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select

                              type="text"
                              name="postal-code"
                              id="postal-code"
                              autoComplete="postal-code"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              value={estadoNascimento}
                              onBlur={() => leaveInput(estadoNascimento, 'estadoNascimento')}
                              onChange={e => setEstadoNascimento(e.target.value)}>
                              <option>Selecione ..</option>
                              <option>Acre</option>
                              <option>Alagoas</option>
                              <option>Amapá</option>
                              <option>Amazonas</option>
                              <option>Bahia</option>
                              <option>Ceará</option>
                              <option>Distrito Federal</option>
                              <option>Espírito Santo</option>
                              <option>Goiás</option>
                              <option>Maranhão</option>
                              <option>Mato Grosso</option>
                              <option>Mato Grosso do Sul</option>
                              <option>Minas Gerais</option>
                              <option>Pará</option>
                              <option>Paraíba</option>
                              <option>Paraná</option>
                              <option>Pernambuco</option>
                              <option>Piauí</option>
                              <option>Rio de Janeiro</option>
                              <option>Rio Grande do Norte</option>
                              <option>Rio Grande do Sul</option>
                              <option>Rondônia</option>
                              <option>Roraima</option>
                              <option>Santa Catarina</option>
                              <option>São Paulo</option>
                              <option>Sergipe</option>
                              <option>Tocantins</option>
                            </select>
                          </div>
                          {
                            errorEstadoNascimento === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do estado de nascimento.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Estado civil
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select

                              type="text"
                              name="estadocivil"
                              id="estadocivil"
                              autoComplete="estadocivil"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder={props.states.waitTimeout === false ? 'Digite seu estado civil' : 'Carregando...'}
                              disabled={disabled}
                              value={estadoCivil}
                              onBlur={() => leaveInput(estadoCivil, 'estadoCivil')}
                              onChange={e => setEstadoCivil(e.target.value)}>
                              <option>Selecione ..</option>
                              <option value='Solteiro(a)'>Solteiro(a)</option>
                              <option value='Casado(a)'>Casado(a)</option>
                              <option value='Divorciado(a)'>Divorciado(a)</option>
                              <option value='Viúvo(a)'>Viúvo(a)</option>
                            </select>
                          </div>
                          {
                            errorEstadoCivil === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do estado civil.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Escolaridade
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              type="text"
                              name="escolaridade"
                              id="escolaridade"
                              autoComplete="escolaridade"



                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder={props.states.waitTimeout === false ? 'Digite sua escolaridade' : 'Carregando...'}
                              disabled={disabled}
                              value={escolaridade}
                              onBlur={() => leaveInput(escolaridade, 'escolaridade')}
                              onChange={e => setEscolaridade(e.target.value)}>
                              <option>Selecione ..</option>
                              <option value='fundamental'>Ensino fundamental</option>
                              <option value='medio'>Ensino médio</option>
                              <option value='tec'>Técnico</option>
                              <option value='superior'>Superior</option>
                              <option value='pos'>Pós graduação</option>
                              <option value='mestrado'>Mestrado</option>
                              <option value='doutorado'>Doutorado</option>
                            </select>
                          </div>
                          {
                            errorEscolaridade === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da escolaridade.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            RG
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="rg"
                              id="rg"
                              autoComplete="rg"
                              placeholder={props.states.waitTimeout === false ? 'Digite seu RG' : 'Carregando...'}
                              disabled={disabled}
                              value={rg}
                              onBlur={() => leaveInput(rg, 'rg')}
                              onChange={e => setRg(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorRg === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do RG.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Órgão expedidor do RG
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="rgOrgao"
                              id="rgOrgao"
                              autoComplete="rgOrgao"
                              placeholder={props.states.waitTimeout === false ? 'Digite o órgão expedidor' : 'Carregando...'}
                              disabled={disabled}
                              value={rgOrgao}
                              onBlur={() => leaveInput(rgOrgao, 'rgOrgao')}
                              onChange={e => setRgOrgao(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorRgOrgao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do órgão expedidor do RG.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Data expedição do RG
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="date"
                              name="dataexpedicaorg"
                              id="dataexpedicaorg"
                              autoComplete="dataexpedicaorg"
                              disabled={disabled}
                              value={rgExpedicao}
                              onBlur={() => leaveInput(rgExpedicao, 'rgExpedicao')}
                              onChange={e => setRgExpedicao(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorRgExpedicao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da data de expedição do RG.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nome do pai
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="nomepai"
                              id="nomepai"
                              autoComplete="nomepai"

                              placeholder={props.states.waitTimeout === false ? 'Digite o nome do seu pai' : 'Carregando...'}
                              disabled={disabled}
                              value={nomePai}
                              onBlur={() => leaveInput(nomePai, 'nomePai')}
                              onChange={e => setNomePai(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorNomePai === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do nome do pai.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nome da mãe
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="nomemae"
                              id="nomemae"
                              autoComplete="nomemae"

                              placeholder={props.states.waitTimeout === false ? 'Digite o nome da sua mãe' : 'Carregando...'}
                              disabled={disabled}
                              value={nomeMae}
                              onBlur={() => leaveInput(nomeMae, 'nomeMae')}
                              onChange={e => setNomeMae(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorNomeMae === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do nome da mãe.
                            </p>
                          }
                        </div>

                      </div>
                    </div>

                    {/* 2 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações profissionais</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados profissionais do associado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Empresa
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="empresa"
                              id="empresa"
                              autoComplete="empresa"

                              placeholder={props.states.waitTimeout === false ? 'Digite sua empresa' : 'Carregando...'}
                              disabled={disabled}
                              value={empresa}
                              onBlur={() => leaveInput(empresa, 'empresa')}
                              onChange={e => setEmpresa(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorEmpresa === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da empresa.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Matrícula da empresa
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="matriculaempresa"
                              id="matriculaempresa"
                              autoComplete="matriculaempresa"

                              placeholder={props.states.waitTimeout === false ? 'Digite a matrícula da empresa' : 'Carregando...'}
                              disabled={disabled}
                              value={matriculaEmpresa}
                              onBlur={() => leaveInput(matriculaEmpresa, 'matriculaEmpresa')}
                              onChange={e => setMatriculaEmpresa(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorMatriculaEmpresa === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da matrícula da empresa.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Data de associação
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="date"
                              name="dataAssociacao"
                              id="dataAssociacao"
                              autoComplete="dataAssociacao"

                              placeholder={props.states.waitTimeout === false ? 'Digite a data de associação' : 'Carregando...'}
                              disabled={disabled}
                              value={dataAssociacao}
                              onBlur={() => leaveInput(dataAssociacao, 'dataAssociacao')}
                              onChange={e => {
                                setDataAssociacao(e.target.value)
                              }}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorDataAssociacao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da data de associação.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Método de pagamento
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              id="metodoPagamento"
                              name="metodoPagamento"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              disabled={disabled}
                              value={
                                metodoPagamento
                              }
                              onChange={e => setMetodoPagamento(e.target.value)}
                              onBlur={() => leaveInput(metodoPagamento, 'metodoPagamento')}
                            >
                              <option>Selecione ..</option>
                              <option value='bank_slip'>Boleto</option>
                              <option value='credit_card'>Cartão de Crédito</option>
                            </select>
                          </div>
                          {
                            errorMetodoPagamento === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do método de pagamento.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-1">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Aposentado
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              id="aposentado"
                              name="aposentado"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              disabled={disabled}
                              value={
                                aposentado
                              }
                              onChange={e => setAposentado(e.target.value)}
                              onBlur={() => leaveInput(aposentado, 'aposentado')}
                            >
                              <option>Selecione ..</option>
                              <option value='S'>Sim</option>
                              <option value='N'>Não</option>
                            </select>

                          </div>
                          {
                            errorAposentado === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento se é aposentado.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Fundo
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            {/* <input
                              type="text" //states.dataFundo
                              name="fundo"
                              id="fundo"
                              autoComplete="fundo"

                              placeholder={props.states.waitTimeout === false ? 'Digite seu fundo' : 'Carregando...'}
                              disabled={disabled}
                              value={fundo}
                              onBlur={() => leaveInput(fundo, 'fundo')}
                              onChange={e => setFundo(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            /> */}
                            <select
                              id="fundo"
                              name="fundo"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              disabled={disabled}
                              value={
                                fundo
                              }
                              onChange={e => setFundo(e.target.value)}
                              onBlur={() => leaveInput(fundo, 'fundo')}
                            >
                              <option value={null}>Selecione</option>
                              {
                                props.states.dataFundo.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                          {
                            errorFundo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do fundo.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Plano de saúde
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              id="planoSaude"
                              name="planoSaude"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              disabled={disabled}
                              value={
                                planoSaude
                              }
                              onChange={e => setPlanoSaude(e.target.value)}
                              onBlur={() => leaveInput(planoSaude, 'planoSaude')}
                            >
                              <option>Selecione ..</option>
                              <option value="S">Sim</option>
                              <option value="N">Não</option>
                            </select>
                          </div>
                          {
                            errorPlanoSaude === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento se possui plano de saúde.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nome do plano de saúde
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="planoSaudeName"
                              id="planoSaudeName"

                              placeholder={props.states.waitTimeout === false ? 'Digite o nome do plano' : 'Carregando...'}
                              disabled={disabled}
                              value={nomePlanoSaude}
                              onBlur={() => leaveInput(nomePlanoSaude, 'nomePlanoSaude')}
                              onChange={e => setNomePlanoSaude(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorNomePlanoSaude === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do nome do plano de saúde.
                            </p>
                          }
                        </div>

                      </div>
                    </div>

                    {/* 3 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de contato</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados de contato do associado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            E-Mail
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="email"
                              name="email"
                              id="email"

                              placeholder={props.states.waitTimeout === false ? 'Digite seu E-Mail' : 'Carregando...'}
                              disabled={disabled}
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              onBlur={() => leaveInput(email, 'email')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorEmail === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do email.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Celular
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <InputMask

                              type="text"
                              name="telefone"
                              id="telefone"
                              autoComplete="telefone"
                              placeholder='Digite o telefone'
                              mask="+55 (99) 99999-9999"
                              disabled={disabled}
                              value={celular}
                              onChange={e => setCelular(e.target.value)}
                              onBlur={() => leaveInput(celular, 'celular')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCelular === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento de um telefone válido.
                            </p>
                          }
                        </div>

                      </div>
                    </div>

                    {/* 4 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de endereço</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados de endereço do associado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            CEP
                          </label>
                          <div className="rounded-md shadow-sm">
                            <input
                              type="text"
                              name="cep"
                              id="cep"
                              autoComplete="cep"

                              placeholder={props.states.waitTimeout === false ? 'Digite seu CEP' : 'Carregando...'}
                              disabled={disabled}
                              value={cep}
                              onChange={e => setCep(e.target.value)}
                              onBlur={() => leaveInput(cep, 'cep')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCep === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento de um CEP válido.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Logradouro
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="logradouro"
                              id="logradouro"
                              autoComplete="logradouro"

                              placeholder={props.states.waitTimeout === false ? 'Digite seu logradouro' : 'Carregando...'}
                              disabled={disabled}
                              value={logradouro}
                              onChange={e => setLogradouro(e.target.value)}
                              onBlur={() => leaveInput(logradouro, 'logradouro')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorLogradouro === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do logradouro.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nº da residência
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="numeroresidencia"
                              id="numeroresidencia"
                              autoComplete="numeroresidencia"

                              placeholder={props.states.waitTimeout === false ? 'Digite o Nº da sua residência' : 'Carregando...'}
                              disabled={disabled}
                              value={numeroResidencia}
                              onChange={e => {
                                const isNumber = /^[0-9]*$/.test(e.target.value)
                                if (isNumber) {
                                  setNumeroResidencia(e.target.value)
                                }
                              }}
                              onBlur={() => leaveInput(numeroResidencia, 'numeroResidencia')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorNumeroResidencia === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do Nº da residência.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Complemento
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="complemento"
                              id="complemento"
                              autoComplete="complemento"

                              placeholder={props.states.waitTimeout === false ? 'Digite o complemento' : 'Carregando...'}
                              disabled={disabled}
                              value={complemento}
                              onChange={e => setComplemento(e.target.value)}
                              onBlur={() => leaveInput(complemento, 'complemento')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {/* {
                              errorComplemento === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    
                                    É obrigatório o preenchimento do complemento.
                                </p>
                            } */}
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Bairro
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="bairro"
                              id="bairro"
                              autoComplete="bairro"

                              placeholder={props.states.waitTimeout === false ? 'Digite seu bairro' : 'Carregando...'}
                              disabled={disabled}
                              value={bairro}
                              onChange={e => setBairro(e.target.value)}
                              onBlur={() => leaveInput(bairro, 'bairro')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorBairro === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do bairro.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Cidade
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="cidade"
                              id="cidade"
                              autoComplete="cidade"

                              placeholder={props.states.waitTimeout === false ? 'Digite sua cidade' : 'Carregando...'}
                              disabled={disabled}
                              value={cidade}
                              onChange={e => setCidade(e.target.value)}
                              onBlur={() => leaveInput(cidade, 'cidade')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCidade === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da cidade.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Estado
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select

                              type="text"
                              name="postal-code"
                              id="postal-code"
                              autoComplete="postal-code"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              value={estado}
                              onChange={e => setEstado(e.target.value)}
                              onBlur={() => leaveInput(estado, 'estado')}>
                              <option>Selecione ..</option>
                              <option>Acre</option>
                              <option>Alagoas</option>
                              <option>Amapá</option>
                              <option>Amazonas</option>
                              <option>Bahia</option>
                              <option>Ceará</option>
                              <option>Distrito Federal</option>
                              <option>Espírito Santo</option>
                              <option>Goiás</option>
                              <option>Maranhão</option>
                              <option>Mato Grosso</option>
                              <option>Mato Grosso do Sul</option>
                              <option>Minas Gerais</option>
                              <option>Pará</option>
                              <option>Paraíba</option>
                              <option>Paraná</option>
                              <option>Pernambuco</option>
                              <option>Piauí</option>
                              <option>Rio de Janeiro</option>
                              <option>Rio Grande do Norte</option>
                              <option>Rio Grande do Sul</option>
                              <option>Rondônia</option>
                              <option>Roraima</option>
                              <option>Santa Catarina</option>
                              <option>São Paulo</option>
                              <option>Sergipe</option>
                              <option>Tocantins</option>
                            </select>
                          </div>
                          {
                            errorEstado === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do estado.
                            </p>
                          }
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
