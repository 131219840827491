import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/ferias'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'
import Table from '../../../../Components/Tabela/TabelasFerias/TabelaFeriasFuncionarios'
import Modal from '../../../../Components/Modal/Ferias/FeriasFuncionarios'

import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'


export default function FeriasFuncionarios() {
    const { dataFeriasFuncionarios, deleteFeriasFuncionarios } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    useEffect(() => {
        return () => {
            setters.setOffSetFeriasFuncionarios(0)
            setters.setLimitSearchFeriasFuncionarios(false)
            setters.setDataFeriasFuncionarios([])
        }
    }, [])

    useEffect(() => {
    if (states.dataQuery.length == 0){
        listarAsync()
    }
    }, [states.offsetFeriasFuncionarios])

    const listarAsync = async () => {
        await dataFeriasFuncionarios()
    }

    const add = async () => {
        setters.setModalFeriasFuncionarios(true);
    }

    const deleted = async (id) => {
        await deleteFeriasFuncionarios(id)
    }

    const next = async () => {
        if (states.dataFeriasFuncionarios.length === 0) {
            return false
        } else {
            const data = states.dataQuery
            setters.setOffSetFeriasFuncionarios(states.offsetFeriasFuncionarios + states.limitFeriasFuncionarios)
            const sumOff = states.offsetFeriasFuncionarios + states.limitFeriasFuncionarios
            const limit = states.limitFeriasFuncionarios + sumOff
            const newData = data.slice(sumOff, limit)
            setters.setDataFeriasFuncionarios(newData)
        }
    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataFeriasFuncionarios.length === 0 && states.offsetFeriasFuncionarios <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetFeriasFuncionarios > 0 && setters.setOffSetFeriasFuncionarios(states.offsetFeriasFuncionarios - states.limitFeriasFuncionarios)
                states.offsetFeriasFuncionarios < 0 && setters.setOffSetFeriasFuncionarios(0)
                const sumOff = states.offsetFeriasFuncionarios > 0 ? states.offsetFeriasFuncionarios - states.limitFeriasFuncionarios : 0;
                const limit = sumOff + 10 > states.limitFeriasFuncionarios ? sumOff + 10 : states.limitFeriasFuncionarios;
                const newData = data.slice(sumOff, limit)
                setters.setDataFeriasFuncionarios(newData)
            }
        } else {
            if (states.offsetFeriasFuncionarios <= 0) {
                states.offsetFeriasFuncionarios < 0 && setters.setOffsetFeriasFuncionarios(0)
                return
            } else {
                if (states.limitSearchFeriasFuncionarios) setters.setLimitSearchFeriasFuncionarios(false)
                setters.setOffSetFeriasFuncionarios(states.offsetFeriasFuncionarios - states.limitFeriasFuncionarios)
            }
        }
    };

    const headers = ['Email do Criador', 'Nome Terceirizado',  'Data Início do Contrato', 'Dias de Férias Restantes', 'Dias de Férias Acumulados', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />
                            
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Férias dos Funcionários</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as configurações das férias dos funcionários
                            </p>

                            <Table title='Férias dos Funcionários' description='Registro dos funcionários que estão de férias e o cadastro das férias de cada funcionário' 
                                   add={add} deleted={deleted} next={next} previous={previous} states={states} headers={headers}
                                   infoTable={{ offset: states.offsetFeriasFuncionarios, limit: states.limitFeriasFuncionarios }} dataTable={states.dataFeriasFuncionarios} />
                        </div>
                    </div>
                </div>

                <Modal states={states} setters={setters}/>
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
