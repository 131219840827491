import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { useContext, useEffect, useState } from "react";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { InformationCircleIcon, ArrowLeftCircleIcon } from '@heroicons/react/24/outline'
import moment from "moment";
import ModalConfirm from "../../Components/Modal/Confirm";
import ModalStep from "../../Components/Modal/ProjetoStep";
import ModalAprove from "../../Components/Modal/ProjetoAproveConclude";
import SpinnerOverlay from "../../Components/SpinnerOverlay";

const ProjetoIndex = () => {

    const { checkLogin, getProjeto, endStep, addStep, editStep, endProject, approveConcludeProject } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const { id } = useParams()
    const hist = useNavigate()
    const [open, setOpen] = useState(false)
    const [step, setStep] = useState('')
    const [titleModal, setTitleModal] = useState('')
    const [descriptionModal, setDescriptionModal] = useState('')
    const [endProjectState, setEndProjectState] = useState(false)

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.removeItem('token')
                setters.setSigned(false)
                return;
            }

        }
    }


    const checkId = async () => {
        if (id === undefined || id === null || id === '' || id === ' ') {
            hist('/projetos')
            toast.error('Projeto não encontrado')
        }
        const response = await getProjeto(id)
        if (response === false) {
            // hist('/projetos')
            toast.error('Projeto não encontrado')
        }

    }


    useEffect(() => {
        isSigned()
        checkId()

        return () => {
        }
    }, [])

    const endStepModal = async (data) => {
        setTitleModal(`Deseja encerrar a etapa ${data.title}?`)
        setDescriptionModal(`Ao encerrar a etapa ${data.title} você não poderá mais adicionar horas a mesma. Deseja continuar?`)
        setEndProjectState(false)
        setStep(data)
        setters.setConfirmModal(true)
    }

    const endProjectModal = async () => {
        setTitleModal(`Deseja encerrar o projeto ${states.Projetos.title}?`)
        setDescriptionModal(`Ao encerrar o projeto ${states.Projetos.title} você não poderá mais adicionar horas a mesma. Deseja continuar?`)
        setEndProjectState(true)
        setters.setConfirmModal(true)

    }

    const endStepAction = async () => {
        if (!endProjectState) {
            await endStep(step.id, id)
        } else {
            await endProject(states.Projetos.id)
        }
        setters.setConfirmModal(false)
        setEndProjectState(false)
        setStep('')
        setTitleModal('')
        setDescriptionModal('')
    }

    const modalAddStep = () => {
        setStep('')
        setters.setIsEdit(false)
        setters.setModalAddStep(true)
    }

    const modalEditStep = (data) => {
        setStep(data)
        setters.setIsEdit(true)
        setters.setModalAddStep(true)
    }

    const actionModalStep = async (data, id) => {
        setters.setModalAddStep(false)
        if (states.isEdit) {
            setStep('')
            await editStep(data, id, states.Projetos.id)
        } else {
            setStep('')
            await addStep(data, states.Projetos.id)
        }
    }

    const openModalApproveProject = () => {
        setters.setModalApproveProject(true)
    }

    const actionModalApproveProject = async (data) => {
        setters.setModalApproveProject(false)
        await approveConcludeProject(data, states.Projetos.id)
    }


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">

                        <button onClick={() => hist('/projetos')} className="flex items-center text-sm text-gray-500 hover:text-blue-500 mt-2">
                            <ArrowLeftCircleIcon className="h-16 w-8 text-blue-500 hover:text-blue-200" />
                        </button>

                        <div className="px-4 sm:px-6 lg:px-8">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Projeto {states.isLoading === false && states.Projetos.title}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre o projeto {states.isLoading === false && states.Projetos.title} e andamento do mesmo.
                            </p>
                            {/* Botao para encerrar projeto */}
                            <div className="flex row">
                                {
                                    (states.isLoading === false && states.Projetos.imAuthor === true && states.Projetos.acceptSteps === true && states.Projetos.status !== 8) && (
                                        <div className="flex items-between justify-between mt-4 p-3">
                                            <button onClick={endProjectModal}>
                                                <p className="text-md text-gray-500 ml-1 hover:text-blue-500">Encerrar projeto</p>
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    (states.isLoading === false && states.Projetos.imAuthorized === true && states.Projetos.acceptSteps === false && states.Projetos.status !== 8) && (
                                        <div className="flex items-between justify-between mt-4 p-3">
                                            <button onClick={openModalApproveProject}>
                                                <p className="text-md text-gray-500 ml-1 hover:text-blue-500">Aprovar projeto</p>
                                            </button>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="border mt-2 rounded-[5px] p-4 mt-5">
                                {/* HEADER PARA DROP DOWN */}
                                <div className="flex justify-between items-center">
                                    <h2 className="text-md font-semibold text-gray-900">Informações sobre o projeto</h2>
                                    <div className="flex items-center">
                                        <button onClick={() => setOpen(!open)} className="flex items-center text-sm text-gray-500 hover:text-blue-500">
                                            <InformationCircleIcon className="h-5 w-5 text-gray-500 hover:text-blue-500" />
                                            <p className="text-md text-gray-500 ml-1 hover:text-blue-500">{
                                                open === true ? 'Clique para ocultar as informações do projeto' : 'Clique para visualizar as informações do projeto'
                                            }</p>
                                        </button>
                                    </div>
                                </div>
                                {
                                    (states.isLoading === false && Object.keys(states.Projetos).length > 0 && open === true) ? (
                                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-4 mt-4">

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] '>
                                                    <p className="text-sm text-gray-500">Número de série:</p>
                                                    <p className="text-sm text-gray-900">{states.Projetos.serialNumber}</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Origem do projeto:</p>
                                                    <p className="text-sm text-gray-900 ml-2">  {
                                                        states.Projetos.origin === 'dev' ? 'Equipe de desenvolvimento' : 'Equipe interna'
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-4">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px] mt-2'>
                                                    <p className="text-sm text-gray-500">Descrição:</p>
                                                    <p className="text-sm text-gray-900 ml-2">{states.Projetos.description}</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-1">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Etapas:</p>
                                                    <p className="text-sm text-gray-900 ml-2">  {
                                                        `${states.Projetos.steps}`
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-1">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Entrega:</p>
                                                    <p className="text-sm text-gray-900 ml-2">  {
                                                        states.Projetos.percent_completed
                                                    }%</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-1">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Status:</p>
                                                    <p className="text-sm text-gray-900 ml-2"> {
                                                        states.Projetos.status === 0 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                                Solicitado
                                                            </span>
                                                        ) : states.Projetos.status === 1 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                                Aprovado
                                                            </span>
                                                        ) : states.Projetos.status === 2 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                                Em desenvolvimento
                                                            </span>
                                                        ) : states.Projetos.status === 3 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                                Em teste
                                                            </span>
                                                        ) : states.Projetos.status === 4 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                Em produção
                                                            </span>
                                                        ) : states.Projetos.status === 5 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                Cancelado
                                                            </span>
                                                        ) : states.Projetos.status === 6 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                Reprovado
                                                            </span>
                                                        ) : states.Projetos.status === 7 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                                Finalizado aguardando aprovação
                                                            </span>
                                                        ) : states.Projetos.status === 8 ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                Finalizado
                                                            </span>
                                                        ) : (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                Sem status
                                                            </span>
                                                        )
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-1">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Horas totais:</p>
                                                    <p className="text-sm text-gray-900 ml-2">   {
                                                        states.Projetos.total_hours !== null ? states.Projetos.total_hours : '0'
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-1">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Necessário autorização:</p>
                                                    <p className="text-sm text-gray-900 ml-2">    {
                                                        states.Projetos.requireAuth == true ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                Sim
                                                            </span>
                                                        ) : (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                Não
                                                            </span>
                                                        )
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-1">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Data de entrega prevista:</p>
                                                    <p className="text-sm text-gray-900 ml-2">    {
                                                        moment(states.Projetos.delivery_date).format('DD/MM/YYYY')
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Data de início:</p>
                                                    <p className="text-sm text-gray-900 ml-2">    {
                                                        states.Projetos.initial_date !== null ? moment(states.Projetos.initial_date).format('DD/MM/YYYY HH:mm:ss') : 'Projeto não iniciado'
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Data de entrega:</p>
                                                    <p className="text-sm text-gray-900 ml-2">   {
                                                        states.Projetos.final_date !== null ? moment(states.Projetos.final_date).format('DD/MM/YYYY HH:mm:ss') : 'Projeto não iniciado'
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Autor:
                                                        {
                                                            states.Projetos.user_author_email
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Criador:
                                                        {
                                                            states.Projetos.user_created_email
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-2">
                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Responsável pela autorização:
                                                        {
                                                            states.Projetos.require_auth == 1 ? states.Projetos.user_authorized_email : 'Não requer autorização'
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-span-1 md:col-span-4">

                                                <div className='flex w-full justify-between border p-4 hover:bg-gray-100 rounded-[10px]'>
                                                    <p className="text-sm text-gray-500">Notas:</p>
                                                    <p className="text-sm text-gray-900 ml-2">{states.Projetos?.notes?.replace('.', '\n')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="border mt-2 rounded-[5px] p-4 mt-5">
                                {/* HEADER PARA DROP DOWN */}
                                <div className="flex justify-between items-center">
                                    <h2 className="text-md font-semibold text-gray-900">Andamentos do projeto</h2>
                                </div>
                                {/* Painel de botoes para adicao e aprovacao */}
                                <div className="flex items-between justify-between mt-4 p-3">
                                    {
                                        (states.Projetos.imAuthor === true && states.Projetos.acceptSteps === true) &&
                                        <button onClick={modalAddStep}>
                                            <p className="text-md text-gray-500 ml-1 hover:text-blue-500">Adicionar andamento</p>
                                        </button>
                                    }
                                </div>

                                {/* Tabela de andamentos */}
                                {states.Projetos?.stepsProject?.length > 0 ? (
                                    <div className="space-y-4">
                                        {states.Projetos.stepsProject.map((step, index) => (
                                            <div key={index} className="p-4 bg-white shadow-sm rounded-lg border mt-3">
                                                <div className="flex justify-between items-center">
                                                    <h2 className="text-lg font-semibold text-gray-800">Título</h2>
                                                    <h2 className="text-lg font-semibold text-gray-800">{step.title}</h2>
                                                </div>
                                                <hr className="my-2" />
                                                <div className="flex flex-col justify-between items-start mt-4">
                                                    <h2 className="text-lg font-semibold text-gray-800">Descrição:</h2>
                                                    <h2 className="text-lg font-semibold text-gray-800 mt-2">{step.description}</h2>
                                                </div>
                                                <hr className="my-2" />
                                                <div className="flex flex-col justify-between items-start mt-4">
                                                    <h2 className="text-lg font-semibold text-gray-800">Andamentos:</h2>
                                                    <div className="flex w-full flex-row justify-between items-between mt-2 ">
                                                        <div className="flex justify-between items-center">
                                                            <h2 className="text-lg  text-gray-800">Data de início:</h2>
                                                            <h2 className="text-lg  text-gray-800 ml-2">{moment(step.created_at).format('DD/MM/YYYY HH:mm:ss')}</h2>
                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            <h2 className="text-lg  text-gray-800">Horas totais:</h2>
                                                            <h2 className="text-lg  text-gray-800 ml-2">{step.total_hours !== null ? step.total_hours : "Etapa não finalizada"}</h2>
                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            <h2 className="text-lg  text-gray-800">Data de finalização:</h2>
                                                            <h2 className="text-lg  text-gray-800 ml-2">{step.final_date !== null ? moment(step.created_at).format('DD/MM/YYYY HH:mm:ss') : "Etapa não finalizada"}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Painel aonde terá botão para encerrar step */}
                                                <div className="flex items-between justify-between mt-4 p-3">
                                                    {
                                                        (states.Projetos.imAuthor === true && step.final_date === null) &&
                                                        <>
                                                            <div className="flex items-between justify-between mt-4 p-3">
                                                                <button onClick={() => endStepModal(step)}>
                                                                    <p className="text-md text-gray-500 ml-1 hover:text-blue-500">Encerrar etapa</p>
                                                                </button>
                                                            </div>
                                                            <div className="flex items-between justify-between mt-4 p-3">
                                                                <button onClick={() => modalEditStep(step)}>
                                                                    <p className="text-md text-gray-500 ml-1 hover:text-blue-500">Editar etapa</p>
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="py-5 px-4 bg-white shadow-sm rounded-lg text-center">
                                        <h1 className="text-gray-800 font-semibold">Nenhum andamento encontrado</h1>
                                    </div>
                                )}
                            </div>
                            {
                                states.Projetos.authProjectComplete && states.Projetos.authProjectComplete.length > 0 && (

                                    <div className="border mt-2 rounded-[5px] p-4 mt-5">
                                        {/* HEADER PARA DROP DOWN */}
                                        <div className="flex justify-between items-center">
                                            <h2 className="text-md font-semibold text-gray-900">Status de aprovação</h2>
                                        </div>
                                        {states.Projetos?.authProjectComplete?.length > 0 ? (
                                            <div className="space-y-4">
                                                {states.Projetos.authProjectComplete.map((step, index) => (
                                                    <div key={index} className="p-4 bg-white shadow-sm rounded-lg border mt-3">
                                                        <div className="flex justify-between items-center">
                                                            <h2 className="text-lg font-semibold text-gray-800">Título</h2>
                                                            <h2 className="text-lg font-semibold text-gray-800">{
                                                                step.status === 1 ? (
                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                                        Pendente
                                                                    </span>
                                                                ) : step.status === 2 ? (
                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                        Aprovado
                                                                    </span>
                                                                ) : step.status === 3 ? (
                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                                        Revisão necessária
                                                                    </span>
                                                                ) : step.status === 4 ? (
                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                        Reprovado
                                                                    </span>
                                                                ) : (
                                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                        Sem status
                                                                    </span>
                                                                )
                                                            }</h2>
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="flex flex-col justify-between items-start mt-4">
                                                            <h2 className="text-lg font-semibold text-gray-800">Notas:</h2>
                                                            <h2 className="text-lg font-semibold text-gray-800 mt-2">{step.notes}</h2>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="py-5 px-4 bg-white shadow-sm rounded-lg text-center">
                                                <h1 className="text-gray-800 font-semibold">Nenhum andamento encontrado</h1>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div >
            <ModalConfirm action={endStepAction} states={states} setters={setters} title={titleModal} description={descriptionModal} />
            <ModalStep states={states} setters={setters} action={actionModalStep} step={step} />
            <ModalAprove states={states} setters={setters} action={actionModalApproveProject} />
        </>
    )

}

export default ProjetoIndex;
