import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm'
import NavbarPanel from '../../../Components/navMenu/comunicacao'
import TabelaNoticiasComunicacao from '../../../Components/Tabela/TabelaNoticiasComunicacao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import ModalNoticia from '../../../Components/Modal/NoticiaComunicacao'
import Footer from '../../../Components/Footer/footer'

export default function RegistroLogs() {
    const { states, setters } = useContext(AuthContext)
    const { putNoticia, postNoticia, dataNoticias, deleteNoticia } = useContext(AuthContextApi)

    let dataArrSys = []

    const callLogsData = async () => {
        const promises = [
            dataNoticias()
        ];

        await Promise.all(promises);
    }

    // useEffect(() => {
    //     callLogsData()
    // }, [states.offsetHooks])

    useEffect(() => {
        setters.setOffsetNoticiasComunicacao(0)
        if(states.dataNoticiasComunicacao.length === 0){
            callLogsData()
        }
        return () => {
            setters.setLimitSearchNoticiasComunicacao(false)
            setters.setDataNoticiasComunicacao([])
            setters.setTotalNoticiasComunicacao(0)
            setters.setNoticiasComunicacao({})
        }
    }, [])

 

  

    const next = async () => {
        if (states.limitSearchHooks) {
            return
        } else {
            setters.setOffsetHooks(states.offsetHooks + states.limitLog)
        }
    }

    const previous = async () => {
        if (states.offsetHooks <= 0) {
            states.offsetHooks < 0 && setters.setOffsetHooks(0)
            return
        } else {
            if (states.limitSearchHooks) setters.setLimitSearchHooks(false)
            setters.setOffsetHooks(states.offsetHooks - states.limitLog)
        }
    }

    const closeModal = () => {
        setters.setModalNoticiasComunicacao(false)
        setters.setNoticiasComunicacao({})
    }

    const openModal = () => {
        setters.setIsEdit(false)
        setters.setModalNoticiasComunicacao(true)
    }

    const actionModal = async (data) => {
        if(states.isEdit){
            setters.setIsEdit(false)
            setters.setModalNoticiasComunicacao(false)
            await putNoticia(data.form, data.id)
            return 
        }
        setters.setIsEdit(false)
        setters.setModalNoticiasComunicacao(false)
        await postNoticia(data)
    }

    const openModalEdit = (data) => {
        setters.setIsEdit(true)
        setters.setNoticiasComunicacao(data)
        setters.setModalNoticiasComunicacao(true)
    }

    const openModalDelete = (data) => {
        setters.setNoticiasComunicacao(data)
        setters.setConfirmModal(true)
    }

    const actionDelete = async () => {
        await deleteNoticia(states.NoticiasComunicacao.id)
        setters.setConfirmModal(false)
        setters.setNoticiasComunicacao({})
    }


    const headers = ['Título', 'Descrição', 'Link', 'Arquivo', 'Imagem', 'Data de criação', 'Data de atualização', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <SpinnerOverlay />
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Registro das notícias</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as notícias cadastradas no sistema
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                <div className="verflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total de notícias</dt>
                                                    <dd>
                                                        <div className="text-lg font-medium text-gray-900">{states.totalNoticiasComunicacao}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <TabelaNoticiasComunicacao del={openModalDelete} add={openModal} title='Notícias' description='Registro das notícias cadastradas' next={next} previous={previous} states={states} edit={openModalEdit} headers={headers} infoTable={{ offset: states.offsetNoticiasComunicacao, limit: states.limitNoticiasComunicacao }} dataTable={states.dataNoticiasComunicacao} />

                        </div>
                    </div>
                    <ModalConfirm states={states} setters={setters} action={actionDelete} title={`Deseja realmente excluir a notícia ${states.NoticiasComunicacao.title}?`} description={`A notícia ${states.NoticiasComunicacao.title} será excluída permanentemente e não será mais possível vizualizar na área do associado. Deseja realmente excluir?`} />
                    <ModalNoticia states={states} setters={setters} closeModal={closeModal} action={actionModal} />
                </div>
                <Footer />
            </div >
        </>
    )
}
