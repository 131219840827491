import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/pontoEletronico'
import Table from '../../../../Components/Tabela/TabelaSolicitacaoTrocaDePonto'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'
import { MagnifyingGlassCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import ModalPonto from '../../../../Components/Modal/PontoShow'
import ModalConfirm from '../../../../Components/Modal/Confirm'

export default function SolicitacaoDeTrocaDePontos() {
    const { dataSystemRequisicoesPontos, getRequisicoesPontos, dataUsersLoadOutsourcedAndName, deleteRequesicaoPonto, approveRequesicaoPonto,
        reproveRequesicaoPonto, editPonto, getRequisicoesPontosUser } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    const [idUser, setIdUser] = useState(0)


    const dataLoad = async () => {
        Promise.all([dataSystemRequisicoesPontos(), getRequisicoesPontos()])
    }

    const listarUsuarios = async () => {
        await dataUsersLoadOutsourcedAndName()
    }


    const filterByUser = async () => {
        setters.setOffsetRequisicoesAtualizacaoPonto(0)
        if (idUser === 0) {
            await getRequisicoesPontos()
        } else {
            await getRequisicoesPontosUser(idUser)
        }
    }

    const dataLoadTable = async () => {
        if (idUser === 0) {
            await getRequisicoesPontos()
        } else {
            getRequisicoesPontosUser(idUser)
        }
    }

    const actionEditPontos = async (ponto, id) => {
        const resp = await editPonto(ponto, id)
        if (resp) {
            setters.setModalEditPontos(false)
            setters.setPonto({})
            setters.setModalPonto(false)
        }
    }

    const add = async () => {
        setters.setModalConfiguracaoPonto(true);
    }

    const approveRequest = async (id) => {
        const resp = await approveRequesicaoPonto(id)
        if (resp) {
            setters.setModalRequisicoesAtualizacaoPonto(false)
            setters.setRequisicoesAtualizacaoPonto({})
        }
    }

    const reproveRequest = async (id) => {
        const resp = await reproveRequesicaoPonto(id)
        if (resp) {
            setters.setModalRequisicoesAtualizacaoPonto(false)
            setters.setRequisicoesAtualizacaoPonto({})
        }
    }

    /*
    
    const [dataSystemRequisicoesAtualizacaoPonto, setDataSystemRequisicoesAtualizacaoPonto] = useState([])
    const [dataRequisicoesAtualizacaoPonto, setDataRequisicoesAtualizacaoPonto] = useState([])
    const [RequisicoesAtualizacaoPonto, setRequisicoesAtualizacaoPonto] = useState({})
    const [offsetRequisicoesAtualizacaoPonto, setOffsetRequisicoesAtualizacaoPonto] = useState(0)
    const [limitRequisicoesAtualizacaoPonto, setLimitRequisicoesAtualizacaoPonto] = useState(2)
    const [limitSearchRequisicoesAtualizacaoPonto, setLimitSearchRequisicoesAtualizacaoPonto] = useState(false)
    const [modalRequisicoesAtualizacaoPonto, setModalRequisicoesAtualizacaoPonto] = useState(false)
    */

    const next = async () => {
        if (states.limitSearchRequisicoesAtualizacaoPonto && states.offsetRequisicoesAtualizacaoPonto > 0) {
            return
        } else {
            if (states.limitSearchRequisicoesAtualizacaoPonto) setters.setLimitSearchRequisicoesAtualizacaoPonto(false)
            setters.setOffsetRequisicoesAtualizacaoPonto(states.offsetRequisicoesAtualizacaoPonto + states.limitRequisicoesAtualizacaoPonto)
        }

    };

    const previous = async () => {
        if (states.limitSearchRequisicoesAtualizacaoPonto && states.offsetRequisicoesAtualizacaoPonto <= 0) {
            states.offsetRequisicoesAtualizacaoPonto < 0 && setters.setOffsetRequisicoesAtualizacaoPonto(0)
            return
        } else {
            if (states.limitSearchRequisicoesAtualizacaoPonto) setters.setLimitSearchRequisicoesAtualizacaoPonto(false)
            setters.setOffsetRequisicoesAtualizacaoPonto(states.offsetRequisicoesAtualizacaoPonto - states.limitRequisicoesAtualizacaoPonto)
        }
    };




    const openModalPontos = async (data) => {
        setters.setRequisicoesAtualizacaoPonto(data)
        setters.setModalRequisicoesAtualizacaoPonto(true)
    }

    const deleteRequest = async (data) => {
        setters.setConfirmModal(true)
        setters.setRequisicoesAtualizacaoPonto(data)
    }

    const actionModal = async () => {
        setters.setConfirmModal(false)
        await deleteRequesicaoPonto(states.RequisicoesAtualizacaoPonto.id)
    }

    const headers = ['E-mail', 'Status', 'E-mail aprovador', 'Data da solicitação', 'Data da última atualização', 'Ações']


    // const [dataSystemRequisicoesAtualizacaoPonto, setDataSystemRequisicoesAtualizacaoPonto] = useState([])
    // const [dataRequisicoesAtualizacaoPonto, setDataRequisicoesAtualizacaoPonto] = useState([])
    // const [RequisicoesAtualizacaoPonto, setRequisicoesAtualizacaoPonto] = useState({})
    // const [offsetRequisicoesAtualizacaoPonto, setOffsetRequisicoesAtualizacaoPonto] = useState(0)
    // const [limitRequisicoesAtualizacaoPonto, setLimitRequisicoesAtualizacaoPonto] = useState(30)
    // const [limitSearchRequisicoesAtualizacaoPonto, setLimitSearchRequisicoesAtualizacaoPonto] = useState(false)
    // const [modalRequisicoesAtualizacaoPonto, setModalRequisicoesAtualizacaoPonto] = useState(false)



    useEffect(() => {
        dataLoad()
        return () => {
            setters.setOffsetRequisicoesAtualizacaoPonto(0)
            setters.setLimitSearchRequisicoesAtualizacaoPonto(false)
            setters.setDataSystemRequisicoesAtualizacaoPonto([])
            setters.setDataSystemRequisicoesAtualizacaoPonto([])
            setters.setRequisicoesAtualizacaoPonto({})
        }
    }, [])


    useEffect(() => {
        if (states.dataRequisicoesAtualizacaoPonto.length > 0 && states.dataUsers.length == 0) {
            setIdUser(0)
            listarUsuarios()
        }
    }, [states.dataRequisicoesAtualizacaoPonto])



    useEffect(() => {
        dataLoadTable()

    }, [states.offsetRequisicoesAtualizacaoPonto])



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Requisições de troca de ponto</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as solicitações de troca de ponto
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                {states.dataSystemRequisicoesAtualizacaoPonto.map((card, index) => (
                                    <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    {
                                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                                    }

                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        {
                                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                                        }

                                                        <dd>
                                                            {
                                                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                            }
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}

                            </div>



                            <div className='flex flex-row justify-start mt-5'>
                                <div className='flex flex-row justify-start'>
                                    <div className="flex mr-6 my-2">
                                        <select onChange={(e) => setIdUser(e.target.value)} value={idUser} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                            <option value={0}>Selecione um usuário</option>
                                            {
                                                states.dataUsers.length > 0 && states.dataUsers.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                            {
                                                states.dataUsers.length == 0 && <option value={0}>Nenhum usuário encontrado</option>
                                            }
                                        </select>
                                        <button className="ml-5  right-0 flex items-center px-2 text-gray-700 hover:shadow rounded-[10px] border" onClick={filterByUser}>
                                            <MagnifyingGlassCircleIcon className='h-8 w-8' />
                                        </button>


                                    </div>
                                </div>
                            </div>

                            <Table add={add} title='Solicitações de troca de ponto' description='Registro das solicitações de troca de ponto'
                                next={next} previous={previous} states={states} headers={headers} del={deleteRequest}
                                infoTable={{ offset: states.offsetRequisicoesAtualizacaoPonto, limit: states.limitRequisicoesAtualizacaoPonto }} dataTable={states.dataRequisicoesAtualizacaoPonto}
                                pontos={openModalPontos} />



                        </div>
                    </div>
                </div>

                <ModalPonto states={states} setters={setters} action={actionEditPontos} aprove={approveRequest} reprove={reproveRequest} />
                <ModalConfirm states={states} setters={setters} action={actionModal} title={'Deseja realmente excluir a solicitação de troca de ponto?'} description={'Essa ação não poderá ser desfeita'} />
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div >
        </>
    )
}
