import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Table from '../../Tabela/TabelaPontosUsuarioDia'
import './index.css'



export default function ModalShow({ states, setters, title, description, data, headers, next, previous, editPonto }) {
    const [open, setOpen] = useState(true)

    const close = () => {
        setters.setModalPonto(false)
    }



    return (
        //modalPonto
        <Transition.Root show={states.modalPonto} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-0"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-[80%]">
                            {/* <div className="bg-blue-100 px-4 py-5 sm:px-6  items-center justify-center">
                                <h2 className="text-lg font-medium text-gray-900 text-center">{title}</h2>
                            </div>
                            <div className="px-4 py-5 sm:p-4">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 className="text-lg font-medium leading-6 text-gray-900">Descrição:</h2>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{description}</h3>
                                    </div>
                                </div>
                            </div> */}

                            <div className="px-4 py-5 sm:p-4">
                                {
                                    data.length > 0 && (
                                        <Table states={states} headers={headers} dataTable={data} title={title} description={description}
                                            next={next} previous={previous} edit={editPonto}
                                        />
                                    )
                                }
                            </div>
                            <div className="bg-blue-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

