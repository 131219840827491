import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify';




export default function ModalAcaoJuri(props) {



    const [name, setName] = useState('')
    const [acaoColetiva, setAcaoColetiva] = useState('')
    const [acaoRestrita, setAcaoRestrita] = useState('')
    const [fundos, setFundos] = useState([])
    const [fundoSelect, setFundoSelect] = useState({})

    const [id, setId] = useState('')

    const [erroName, setErroName] = useState(false)
    const [erroAcaoRestrita, setErroAcaoRestrita] = useState(false)
    const [erroAcaoColetiva, setErroAcaoColetiva] = useState(false)
    const [erroFundo, setErroFundo] = useState(false)







    const submit = async (e) => {
        e.preventDefault()
        let error = false
        if (name === '' || name === ' ' || name === undefined || name === null) {
            setErroName(true)
            error = true
        } else {
            setErroName(false)
        }
        if (acaoRestrita === '' || acaoRestrita === ' ' || acaoRestrita === undefined || acaoRestrita === null) {
            setErroAcaoRestrita(true)
            error = true
        } else {
            setErroAcaoRestrita(false)
        }
        if (acaoColetiva === '' || acaoColetiva === ' ' || acaoColetiva === undefined || acaoColetiva === null) {
            setErroAcaoColetiva(true)
            error = true
        } else {
            setErroAcaoColetiva(false)
        }
        if (acaoRestrita === 'true' && fundos.length === 0) {
            setErroFundo(true)
            error = true
        } else {
            setErroFundo(false)
        }
        if (error === true) {
            error = false
            return
        }


        const obj = {
            name: name,
            type: parseInt(acaoColetiva),
            isRestricted: acaoRestrita === 'true' ? true : false,
            fundos: fundos
        }
        if (props.states.isEditJuri === true) {
            obj.id = id
        }
        props.action(obj)
    }


    useEffect(() => {
        if (props.states.modalJuri === false) {
            setName('')
            setAcaoColetiva('')
            setAcaoRestrita('')
            setFundos([])
            setFundoSelect('')
            setErroName(false)
            setErroAcaoRestrita(false)
            setErroAcaoColetiva(false)
            setErroFundo(false)
        } else {
            //JuriTribunais
            if (props.states.isEditJuri === true) {
                ////console.log(props.states.JuriAcoes.fundos)
                setName(props.states.JuriAcoes.name)
                setAcaoColetiva(props.states.JuriAcoes.isColetive === 1 ? 'true' : 'false')
                setAcaoRestrita(props.states.JuriAcoes.isRestricted === 1 ? 'true' : 'false')
                setFundoSelect('')
                setFundos(props.states.JuriAcoes.fundos?.map(item => {
                    return { id: item.fundo_id, name: item.name }
                }))
                setId(props.states.JuriAcoes.id)
            }

        }
        //Limpeza do modal ao fechar
    }, [props.states.modalJuri]);


    const addFundo = () => {
        const findFoundo = props.states.dataFundo.find(item => item.id === fundoSelect)
        if (!findFoundo) {
            toast.error('Fundo não encontrado')
            return
        } else {
            const checkHave = fundos.find(item => item.id === fundoSelect)
            if (checkHave) {
                toast.error('Fundo já adicionado')
                return
            }
            setFundos([...fundos, findFoundo])
        }
    }
    const formatName = name => {
        const arrName = name.split(' ')
        const firstName = arrName[0]
        return firstName
    }


    useEffect(() => {
        if (acaoRestrita === 'false') {
            setFundoSelect('')
            setFundos([])
        }
    }, [acaoRestrita])

    return (
        <Transition.Root show={props.states.modalJuri} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalJuri(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalJuri(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Ação</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações da ação
                                    </p>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={submit}>
                                    <div className="space-y-12">
                                        <div
                                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                    {props.states.isEditJuri === false ? 'Cadastrar' : 'Editar'} ação
                                                </h2>
                                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                                    {props.states.isEditJuri === false ? 'Preencha os campos abaixo para cadastrar uma nova ação' : 'Preencha os campos abaixo para edita nova ação'}
                                                </p>
                                            </div>

                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Nome
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroName === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tipo da Ação
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={acaoColetiva}
                                                                onChange={e => setAcaoColetiva(e.target.value)}
                                                            >
                                                                <option value="">Selecione</option>
                                                                <option value={1}>Ação Coletiva</option>
                                                                <option value={2}>Ação Pública</option>
                                                                <option value={3}>Ação</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {erroAcaoColetiva === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Ação restrita por fundo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={acaoRestrita}
                                                                onChange={e => setAcaoRestrita(e.target.value)}
                                                            >
                                                                <option value="">Selecione</option>
                                                                <option value="true">Sim</option>
                                                                <option value="false">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {erroAcaoRestrita === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                {
                                                    acaoRestrita === 'true' &&
                                                    <div className="sm:col-span-6">
                                                        <label
                                                            htmlFor="title"
                                                            className="block text-sm font-medium leading-6 text-gray-900">
                                                            Selecione o fundo
                                                        </label>
                                                        <div className='flex'>
                                                            <div className="mt-2">
                                                                <div
                                                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                                    <select className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                        value={fundoSelect}
                                                                        onChange={e => setFundoSelect(e.target.value)}
                                                                    >
                                                                        <option value="">Selecione</option>
                                                                        {
                                                                            props.states.dataFundo.map((item, index) => {
                                                                                return (
                                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='flex items-between justify-between ml-5 p-2 '>
                                                                <button className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                    onClick={() => addFundo()}
                                                                    type="button" >
                                                                    <PlusIcon className="h-6 w-6" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {erroFundo === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            É obrigatório o preenchimento do terceirizado.
                                                        </p>
                                                        }
                                                        {
                                                            fundos.length > 0 &&
                                                            <div
                                                                className="grid grid-cols-1 gap-x-8 gap-y-5 border-b border-gray-900/10 pb-6 md:grid-cols-3 sm:grid-cols-1 mt-3">
                                                                {
                                                                    fundos.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className="flex items-center justify-center bg-gray-100 rounded-md shadow-sm hover:bg-gray-200">
                                                                                    <span key={index}
                                                                                        className=" p-2  mt-2 "
                                                                                    >{formatName(item.name)}

                                                                                    </span>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="ml-2 border mt-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                                                        onClick={() => {
                                                                                            const newFundo = fundos.filter((itemFilter, indexFilter) => indexFilter !== index)
                                                                                            setFundos(newFundo)
                                                                                            if (newFundo.length === 0) {
                                                                                                setFundoSelect('')
                                                                                            }

                                                                                        }}
                                                                                    >
                                                                                        <XMarkIcon className="h-5 w-5" />
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalJuri(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
