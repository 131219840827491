import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { AuthContextApi } from "../../../Context/api"
import { toast } from 'react-toastify';
import { useContext } from "react"

export default function ModalTribunalJuri(props) {



    const [name, setName] = useState('')
    const [zip_code, setZip_code] = useState('')
    const [number, setNumber] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [complement, setComplement] = useState('')
    const [id, setId] = useState('')

    const [erroName, setErroName] = useState(false)
    const [erroZip_code, setErroZip_code] = useState(false)
    const [erroNumber, setErroNumber] = useState(false)
    const [erroPhone, setErroPhone] = useState(false)
    const [erroEmail, setErroEmail] = useState(false)
    const [erroComplement, setErroComplement] = useState(false)





    const formatCep = (cep) => {
        if (cep.length === 8) {
            return `${cep.substring(0, 5)}-${cep.substring(5, 8)}`
        }
        return cep
    }

    const formatPhoneDDDOnly = (phone) => {
        if (phone.length === 11) {
            return `(${phone.substring(0, 2)}) ${phone.substring(2, 11)}`
        }
        return phone
    }



    const submit = async (e) => {
        e.preventDefault()
        let error = false
        if (name === '') {
            setErroName(true)
            error = true
        } else {
            setErroName(false)
        }
        if (zip_code === '') {
            setErroZip_code(true)
            error = true
        } else {
            setErroZip_code(false)
        }
        if (number === '') {
            setErroNumber(true)
            error = true
        } else {
            setErroNumber(false)
        }
        if (phone === '') {
            setErroPhone(true)
            error = true
        } else {
            setErroPhone(false)
        }
        if (email === '') {
            setErroEmail(true)
            error = true
        } else {
            setErroEmail(false)
        }
        if (error === true) {
            error = false
            return
        }
        const obj = {
            name: name,
            zip_code: zip_code,
            number: number,
            phone: phone,
            email: email,
            complement: complement
        }
        if (props.states.isEditJuri === true) {
            obj.id = id
        }
        props.action(obj)
    }


    useEffect(() => {
        if (props.states.modalJuri === false) {
            setName('')
            setZip_code('')
            setNumber('')
            setPhone('')
            setEmail('')
            setComplement('')
            setErroName(false)
            setErroZip_code(false)
            setErroNumber(false)
            setErroPhone(false)
            setErroEmail(false)
            setErroComplement(false)
            setId('')
        } else {
            //JuriTribunais
            if (props.states.isEditJuri === true) {
                setName(props.states.JuriTribunais?.name)
                setZip_code(props.states.JuriTribunais?.zip_code)
                setNumber(props.states.JuriTribunais?.number)
                setPhone(props.states.JuriTribunais?.phone)
                setEmail(props.states.JuriTribunais?.email)
                setComplement(props.states.JuriTribunais?.complement)
                setId(props.states.JuriTribunais?.id)
            }

        }
        //Limpeza do modal ao fechar
    }, [props.states.modalJuri]);



    return (
        <Transition.Root show={props.states.modalJuri} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalJuri(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalJuri(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Tribunal</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Informações do tribunal
                                    </p>
                                    <p className="flex flex-col items-center mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {props.states.JuriOutSourced?.emailOutsourced} <br />
                                    </p>

                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={submit}>
                                    <div className="space-y-12">
                                        <div
                                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                                    {props.states.isEditJuri === false ? 'Cadastrar' : 'Editar'} tribunal
                                                </h2>
                                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                                    {props.states.isEditJuri === false ? 'Preencha os campos abaixo para cadastrar um novo tribunal' : 'Preencha os campos abaixo para editar um tribunal'}
                                                </p>
                                            </div>

                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Cep
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={formatCep(zip_code)}
                                                                onChange={e => {
                                                                    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
                                                                    if (onlyNumbers.length <= 8) {
                                                                        setZip_code(onlyNumbers)
                                                                    }
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroZip_code === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Telefone
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={formatPhoneDDDOnly(phone)}
                                                                onChange={e => {
                                                                    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
                                                                    if (onlyNumbers.length <= 11) {
                                                                        setPhone(onlyNumbers)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroPhone === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        numero
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={number}
                                                                onChange={e => {
                                                                    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
                                                                    if (onlyNumbers.length <= 5) {
                                                                        setNumber(onlyNumbers)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroNumber === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Nome
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={name}
                                                                onChange={e => setName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroName === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Complemento
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={complement}
                                                                onChange={e => setComplement(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroComplement === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-6">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Email
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                className="block w-full rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm"
                                                                value={email}
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {erroEmail === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do terceirizado.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalJuri(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
