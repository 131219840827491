import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import Moment from 'moment';
import UploadCSV from '../../UploadCSV';

export default function Modal({ states, setters, action }) {

    const [errorCSV, setErrorCSV] = useState(false)
    const [arquivo, setArquivo] = useState([])

    const closeModal = () => {
        setters.setModalImport(false)
    }

    const setFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setArquivo(formData)
    }

    const downloadCSV = () => {
        const fileName = 'exemploImportacao.csv';

        const downloadUrl = window.location.origin + '/' + fileName;

        const link = document.createElement('a');
        link.href = downloadUrl;

        // Especifique o nome do arquivo para download
        link.download = fileName;

        // Adicione o link ao corpo do documento
        document.body.appendChild(link);

        // Simule um clique no link para iniciar o download
        link.click();
    };

    useEffect(() => {
        setErrorCSV(false)
    }, [])
    useEffect(() => {
        if (states.modalImport === false) {
            setArquivo([])
        }
    }, [states.modalImport])

    const cancelButtonRef = useRef(null)

    const submitForm = e => {
        e.preventDefault()
        if (arquivo.length === 0) {
            setErrorCSV(true)
            return
        } else {
            action(arquivo)
        }
    }

    return (
        <Transition.Root show={states.modalImport} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => closeModal()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Upload de Arquivo</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        Aqui você poderá fazer uma importação utilizando um arquivo .CSV
                                    </p>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={submitForm}>
                                    <div className="space-y-12">
                                        {/* 3 parte */}
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div className='rounded-md'>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do arquivo</h2>
                                                {arquivo.length != 0 ?
                                                    <div className='mt-4'>
                                                        <p className='mt-2'>Nome do arquivo: {arquivo.name}</p>
                                                        <p className='mt-2'>Tipo do arquivo: {arquivo.type}</p>
                                                        <p className='mt-2'>
                                                            Última modificação:{" "}
                                                            {Moment(arquivo.lastModifiedDate).format('DD/MM/YYYY')}
                                                            {/* {arquivo.lastModifiedDate.toDateString()} */}
                                                        </p>
                                                        <div className="mt-1 flex items-center justify-end">
                                                            <button type="button" className="border border-gray-200 text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                                onClick={() => setArquivo([])}
                                                            >
                                                                Descartar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <br />
                                                        <h4>Nenhum arquivo selecionado</h4>
                                                    </div>
                                                }
                                            </div>

                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                                <div className="sm:col-span-6">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 pb-1">
                                                        Arquivo
                                                    </label>
                                                    <div className="flex rounded-md shadow-sm">
                                                        <UploadCSV file={setFile} />
                                                    </div>
                                                    {
                                                        errorCSV === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            É obrigatório o preenchimento do CSV.
                                                        </p>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 flex items-center justify-end gap-x-4">
                                        <button
                                            type="button"
                                            className="border border-gray-200 bg-gray-100 hover:bg-gray-300  text-sm font-semibold leading-5 text-gray-900 rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => downloadCSV()}
                                        >
                                            Baixar CSV de Exemplo
                                        </button>
                                        <button
                                            type="button"
                                            className="border border-gray-200 bg-gray-100 hover:bg-gray-300  text-sm font-semibold leading-5 text-gray-900 rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => closeModal()}
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            type="submit"
                                            className="border border-blue-200 bg-blue-600 hover:bg-blue-500 px-3 py-2 text-sm font-semibold text-white rounded-md  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        >
                                            Salvar
                                        </button>
                                    </div>

                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}



// const fileData = () => {
//     if (props.selectedFile) {
//       return (
//         <div className='mt-4'>
//           <h2>Informações do arquivo:</h2>
//           <p className='mt-2 mb-1'>Nome do arquivo: {props.selectedFile.name}</p>
//           <p className='mt-1 mb-1'>Tipo do arquivo: {props.selectedFile.type}</p>
//           <p className='mt-1 mb-2'>
//             Última modificação:{" "}
//             {props.selectedFile.lastModifiedDate.toDateString()}
//             {/* {props.selectedFile.lastModifiedDate} */}
//           </p>
//         </div>
//       );
//     } else {
//       return (
//         <div>
//           <br />
//           <h4>Nenhum arquivo selecionado</h4>
//         </div>
//       );
//     }
//   };

//   return (
//     <div className='flex flex-col'>
//       <input className='' type="file" onChange={e=> onFileChange(e)} />
//         {fileData()}
//     </div>
//   );

