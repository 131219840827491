import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/Show'
import ModalConfirm from '../../../Components/Modal/Confirm'
import NavbarPanel from '../../../Components/navMenu/tecnico'
import TableLogs from '../../../Components/Tabela/TabelaTecnicoAlerta'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export default function RegistroLogs() {
    const { states, setters } = useContext(AuthContext)
    const { dataLogs, deleteLogs, dataSystemTecAlert } = useContext(AuthContextApi)

    const callLogsData = async () => {
        const promises = [
            dataSystemTecAlert(),
            dataLogs()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        callLogsData()
    }, [states.offsetLog])

    useEffect(() => {
        setters.setOffsetLog(0)
        return () => {
            setters.setOffsetLog(0)
            setters.setLimitSearchLog(false)
            setters.setLogData([])
        }
    }, [])

    useEffect(() => {
        dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))
    }, [states.systemTecAlert])

    const openModalInfo = (data) => {
        setters.setLog(data)
        setters.setModalShowTecnico(true)
    }

    const openModalConfirm = (data) => {
        setters.setLog(data)
        setters.setConfirmModal(true)
    }

    const actionDelete = async () => {
        setters.setConfirmModal(false)
        await deleteLogs()
    }

    const next = async () => {
        if (states.limitSearchLog) {
            return
        } else {
            setters.setOffsetLog(states.offsetLog + states.limitLog)
        }
    }

    const previous = async () => {
        if (states.offsetLog <= 0) {
            states.offsetLog < 0 && setters.setOffsetLog(0)
            return
        } else {
            if (states.limitSearchLog) setters.setLimitSearchLog(false)
            setters.setOffsetLog(states.offsetLog - states.limitLog)
        }
    }

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setLog({})
    }

    let dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))
    const headers = ['Descrição', 'Linha', 'Nome do arquivo', 'Data de Criação', 'Data de Atualização', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />

                            <SpinnerOverlay/>
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Registro de Logs</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os registros de logs do sistema.
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                {dataArrSys?.logs.map((card, index) => {
                                    return (
                                        <div key={card.value} className="verflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                                            <dd>
                                                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <TableLogs title='Logs' description='Registro de logs' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetLog, limit: states.limitLog }} dataTable={states.logData} />

                        </div>
                    </div>
                    <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
                    <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.log} title='Dados de log' />
                    <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do log' />
                </div>
            </div>
        </>
    )
}
