import { useContext,useEffect } from 'react'
import { AuthContext } from "../../Context"
import { AuthContextApi } from '../../Context/api'
import { useParams,useNavigate } from 'react-router-dom';


export default function MudarEmail() {
    const { states, setters } = useContext(AuthContext)
    const { confirmChangeEmail }= useContext(AuthContextApi)
    const { token } = useParams();
    const hist = useNavigate()

    useEffect(() => {
        if(token == null || token == '' || token == undefined)
        {
          hist('/')
        }
      }, [])

    const confirmarEmail = async() =>
    {
        try{
            const result = await confirmChangeEmail(token)
            hist('/')
        }
        catch(err)
        {

        }
    }

    return(
        <div class="bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl">
            <img
                className="h-12 w-auto"
                src="http://localhost:3000/logo-sinprev-resp.png"
                alt="Sinprev"
                />
            <p class="text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-gray-300">Bem vindo</p>
            <p class="text-2xl md:text-3xl lg:text-4xl font-bold tracking-wider text-gray-500 mt-4">Clique no botão abaixo para confirmar a troca de email</p>
            {/* <p class="text-gray-500 mt-4 pb-4 border-b-2 text-center">A página que você procura não pôde ser encontrada</p> */}
            <button class="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 mt-6 rounded transition duration-150" onClick={e=> confirmarEmail(e)}>
                <span>Confirmar a troca</span>
            </button>
        </div>
    </div>
    )

}
