import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'



export default function ModalProjeto(props) {
  const [status, setStatus] = useState('')
  const [haveNotes, setHaveNotes] = useState('')
  const [notes, setNotes] = useState('')
  const [errorStatus, setErrorStatus] = useState(false)
  const [errorNotes, setErrorNotes] = useState(false)


  useEffect(() => {
    if (props.states.modalApproveProject === false) {
      setNotes('')
      setStatus('')
      setHaveNotes('')
      setErrorStatus(false)
      setErrorNotes(false)
    }
  }, [props.states.modalApproveProject])

  const submit = async (e) => {
    let error;
    e.preventDefault()
    if (status === '') {
      setErrorStatus(true)
      error = true
    } else {
      setErrorStatus(false)
    }
    if (haveNotes === 'true' && notes === '') {
      setErrorNotes(true)
      error = true
    } else {
      setErrorNotes(false)
    }
    if (haveNotes === 'false') {
      setNotes('')
    }
    if (error) {
      error = false;
      return
    }

    const data = {
      statusAprove: status,
      notes: haveNotes === 'true' ? notes : undefined
    }
    await props.action(data)
  }





  return (
    <Transition.Root show={props.states.modalApproveProject} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalApproveProject(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalApproveProject(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Projeto</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Aprovação da conclusão do projeto
                  </p>
                </div>
                <form className='pt-10 pl-10 pr-10' onSubmit={submit}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de aprovação</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Cadastre as informações de aprovação ou negação do projeto</p>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-1">
                          <div className="sm:col-span-1">
                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Status</label>
                            <select
                              id="autorizacao"
                              name="autorizacao"
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">Selecione o status</option>
                              <option value="2">Projeto aprovado</option>
                              <option value="3">Revisão necessária</option>
                              <option value="4">Projeto não aprovado</option>
                            </select>
                          </div>
                          <span className="text-red-500 text-sm">{errorStatus && 'Campo obrigatório'}</span>
                        </div>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-5">
                          <div className="sm:col-span-1">
                            <label htmlFor="date" className="block text-sm font-semibold leading-5 text-gray-700">Deseja adicionar notas?</label>
                            <select
                              id="etapas"
                              name="etapas"
                              value={haveNotes}
                              onChange={(e) => setHaveNotes(e.target.value)}
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                              <option value="">Selecione...</option>
                              <option value="true">Sim</option>
                              <option value="false">Não</option>
                            </select>
                          </div>
                          {
                            haveNotes === 'true' &&
                            <div className="sm:col-span-1">
                              <label htmlFor="autor" className="block text-sm font-semibold leading-5 text-gray-700">Notas</label>
                              <div
                                className="flex rounded-md  ring-inset ring-gray-300 sm:max-w-md">
                                <textarea
                                  id="autor"
                                  name="autor"
                                  value={notes}
                                  onChange={(e) => setNotes(e.target.value)}
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                {
                                  errorNotes && <span className="text-red-500 text-sm">Campo obrigatório</span>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
