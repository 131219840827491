import { Link } from "react-router-dom"
import { AuthContext } from "../../Context"
import { useNavigate } from 'react-router-dom'
import { AuthContextApi } from "../../Context/api"
import { EyeIcon } from '@heroicons/react/24/outline'
import { useState, useContext, useEffect } from "react"
import Footer from "../../Components/Footer/footer"
import { jsonConfig } from "../../Config"


const Login = () => {

    const { states, setters } = useContext(AuthContext)
    const { login } = useContext(AuthContextApi)

    const hist = useNavigate()

    const [inputEmail, setInputEmail] = useState(true)
    const [remainSigned, setRemainSigned] = useState(false)

    useEffect(() => {
        const email = localStorage.getItem('email')
        if (email != null) {
            setters.setEmail(email)
            setRemainSigned(true)
        }
        else {
            setters.setEmail('')
        }

        const password = localStorage.getItem('password')
        if (password != null) {
            setters.setPassword(password)
            setRemainSigned(true)
        }
        else {
            setters.setPassword('')
        }
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
        localStorage.removeItem('dataSystemAlertTec');
        localStorage.removeItem('permission');
        localStorage.removeItem('diaAberto');
        localStorage.removeItem('funcionario');
        localStorage.removeItem('panelJuri');

    }, [])

    const action = async (e) => {
        e.preventDefault()
        const resLogin = await login()
        if (resLogin) {
            if (resLogin.permission == '0') {
                hist('/home')
            }
            else if (resLogin.permission == '1') {
                hist('/home/')
            }
            else if (resLogin.permission == '2') {
                hist('/home')
            }
            else if (resLogin.permission == '3') {
                hist('/home')
            }
            else if (resLogin.permission == '4') {
                hist('/tecnicoIndex')
            } else if (resLogin.permission == '5') {
                hist('/home')
            }

            if (remainSigned) {
                localStorage.setItem('email', states.email);
                localStorage.setItem('password', states.password);
            }
            else {
                localStorage.removeItem('password');
            }
        }
    }

    const leaveInput = async () => {
        if (states.email === '') {
            setInputEmail(false)
        } else {
            setInputEmail(true)
        }
    }

    const remainSignedOnClick = () => {
        if (!remainSigned) {
            setRemainSigned(true)
        }
        else {
            setRemainSigned(false)
        }
    }

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div className="justify-center">
                                <img
                                    className="h-[5em] w-auto"
                                    src={`${jsonConfig.urlAplication}/logo-sinprev-resp.png`}
                                    alt="Sinprev logo"
                                />
                                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Seja bem-vindo ao FinancePrev</h2>
                            </div>

                            <div className="mt-8">
                                <div>
                                    <div className="relative mt-6">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center text-sm">
                                            <span className="bg-white px-2 text-gray-500">Faça seu login</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <form action="#" method="POST" className="space-y-6" onSubmit={action}>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                E-mail
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    onChange={e => setters.setEmail(e.target.value)}
                                                    onBlur={leaveInput}
                                                    value={states.email}
                                                    required
                                                    className={inputEmail === true
                                                        ? "block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gr" +
                                                        "ay-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue" +
                                                        "-500 sm:text-sm sm:leading-6"
                                                        : "block w-full rounded-md border-1 py-1.5 border border-red-500 shadow-sm ring-1 r" +
                                                        "ing-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
                                                        "focus:ring-blue-500 sm:text-sm sm:leading-6"} />
                                            </div>
                                            {
                                                inputEmail === false &&
                                                <p class="mt-2 text-pink-600 text-sm">
                                                    {/* Forneça um endereço de e-mail válido. */}
                                                    É obrigatório o preenchimento de e-mail.
                                                </p>
                                            }

                                        </div>
                                        <div className="space-y-1">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                Senha
                                            </label>
                                            <div className="relative mt-2 rounded-md shadow-sm">
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    autoComplete="password"
                                                    value={states.password}
                                                    onChange={e => setters.setPassword(e.target.value)}
                                                    required
                                                    className="block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                                />
                                                <div className="absolute inset-y-0 right-0 flex items-center">
                                                    <button type="button" className="h-10 w-10 text-gray-400 hover:text-gray-600" onMouseEnter={togglePasswordVisibility} onMouseLeave={togglePasswordVisibility}>  {/*  onClick={()=> changePage(logs)} */}
                                                        <EyeIcon className="ml-2 h-5 w-5" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <input
                                                    id="remember-me"
                                                    name="remember-me"
                                                    type="checkbox"
                                                    checked={remainSigned}
                                                    onChange={() => remainSignedOnClick()}
                                                    className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue-600" />
                                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                    Lembrar-me
                                                </label>
                                            </div>

                                            <div className="text-sm">
                                                <Link to='/forgot' className="font-medium text-blue-600 hover:text-blue-600">
                                                    Esqueceu a senha?
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-blue-600">
                                                {
                                                    states.isLoading === true ? (
                                                        <p>Carregando...</p>
                                                    ) : (
                                                        <p>Login</p>
                                                    )
                                                }
                                            </button>
                                        </div>

                                        <Footer />

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                            alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login