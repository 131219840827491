import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'

import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/Show'
import NavbarPanel from '../../../Components/navMenu/alerta'
import ModalConfirm from '../../../Components/Modal/Confirm'
import TableLogs from '../../../Components/Tabela/TabelaTecnicoAlerta'


export default function AlertasRemessa() {
    const { states, setters } = useContext(AuthContext)
    const { dataAlertRemittence, deleteAlertRemittence } = useContext(AuthContextApi)

    const callLogsData = async () => {
        await dataAlertRemittence()
    }

    useEffect(() => {
        callLogsData()
    }, [states.offsetAlertRemittence])

    useEffect(() => {
        setters.setOffsetAlertRemittence(0)
        return () => {
            setters.setAlertRemittenceData([])
            setters.setOffsetAlertRemittence(0)
            setters.setLimitSearchAlertRemittence(false)
            setters.setModalShowTecnico(false)
        }
    }, [])

    const openModalInfo = (data) => {
        setters.setAlertRemittence(data)
        setters.setModalShowTecnico(true)
    }

    const openModalConfirm = (data) => {
        setters.setAlertRemittence(data)
        setters.setConfirmModal(true)
    }

    const actionDelete = async () => {
        setters.setConfirmModal(false)
        await deleteAlertRemittence()
    }

    const next = async () => {
        if (states.limitSearchAlertRemittence) {
            return
        } else {
            setters.setOffsetAlertRemittence(states.offsetAlertRemittence + states.limitAlertRemittence)
        }
    }

    const previous = async () => {
        if (states.offsetAlertRemittence <= 0) {
            states.offsetAlertRemittence < 0 && setters.setOffsetAlertRemittence(0)
            return
        } else {
            if (states.limitSearchAlertRemittence) setters.setLimitSearchAlertRemittence(false)
            setters.setOffsetAlertRemittence(states.offsetAlertRemittence - states.limitAlertRemittence)
        }
    }

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setAlertRemittence({})
    }

    const headers = ['Descrição', 'Linha', 'Nome do arquivo', 'ID Remessa', 'Data de Criação', 'Data de Atualização', '']
    const dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Alertas de Remessa</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os alertas de remessa do sistema.
                            </p>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {/* Card */}
                                {dataArrSys?.alertRemittence?.map((card, index) => {
                                    return (
                                        <div key={card.value} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>
                                                            <dd>
                                                                <div className="text-lg font-medium text-gray-900">{card.value} {index === 1 ? '%' : ''}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <TableLogs title='Alertas de remessa' description='Registro dos alertas de remessa do sistema' next={next} previous={previous} states={states} show={openModalInfo} dell={openModalConfirm} headers={headers} infoTable={{ offset: states.offsetAlertRemittence, limit: states.limitAlertRemittence }} dataTable={states.alertRemittenceData} />

                        </div>
                    </div>
                    <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
                    <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.alertRemittence} title='Dados do alerta' />
                    <ModalConfirm states={states} action={actionDelete} setters={setters} title='Confirmação de deleção' description='Ao realizar essa ação, o registro será apagado do sistema. Confirma a deleção do alerta' />
                </div>

            </div>
        </>
    )
}
