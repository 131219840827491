import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import DragDropFile from '../../UploadFile/componenteFile'

export default function ModalFaturas(props) {

  useEffect(() => {
    if (props.states.modalIsOpen == false) {
      props.setters.setInvestimentos({})
      limparStatesModalPlano()
    }
  }, [props.states.modalIsOpen]);




  const [disabled, setDisabled] = useState(false)

  //////////////////////////////////////////////////
  const [titulo, setTitulo] = useState('')
  const [rendimento, setRendimento] = useState('')
  const [periodo, setPeriodo] = useState('')
  const [tempo, setTempo] = useState('')
  const [rentabilidade, setRentabilidade] = useState('')
  const [idInvestimento, setIdInvestimento] = useState('')

  const [errorTitulo, setErrorTitulo] = useState(false)
  const [errorRendimento, setErrorRendimento] = useState(false)
  const [errorPeriodo, setErrorPeriodo] = useState(false)
  const [errorTempo, setErrorTempo] = useState(false)
  const [errorRentabilidade, setErrorRentabilidade] = useState(false)
  const [rentabilidadeAlert, setRentabilidadeAlert] = useState(false)



  const leaveInput = async (valor, tipo) => {
    if (tipo === 'titulo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorTitulo(true)
      }
      else {
        setErrorTitulo(false)
      }
    }
    else if (tipo === 'rendimento') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorRendimento(true)
      }
      else {
        setErrorRendimento(false)
      }
    }
    else if (tipo === 'periodo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPeriodo(true)
      }
      else {
        setErrorPeriodo(false)
      }
    }
    else if (tipo === 'tempo') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorTempo(true)
      }
      else {
        setErrorTempo(false)
      }
    }
    else if (tipo === 'rentabilidadeVencimento') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorRentabilidade(true)
      }
      else {
        setErrorRentabilidade(false)
      }
    }
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    errorTitulo === true || titulo == '' ? setErrorTitulo(true) : setErrorTitulo(false)
    errorRendimento === true || rendimento == '' ? setErrorRendimento(true) : setErrorRendimento(false)
    errorPeriodo === true || periodo == '' ? setErrorPeriodo(true) : setErrorPeriodo(false)
    errorTempo === true || tempo == '' ? setErrorTempo(true) : setErrorTempo(false)
    errorRentabilidade === true || rentabilidade == '' ? setErrorRentabilidade(true) : setErrorRentabilidade(false)

    const validator = errorTitulo === false && errorRendimento === false && errorPeriodo === false && errorTempo === false && errorRentabilidade === false ? true : false
    if (!validator) {
      return
    }

    if (props.states.isEdit == true) {
      const body = {
        id: idInvestimento,
        title: titulo,
        roi: rendimento,
        period: periodo,
        time: tempo,
        inDueDate: rentabilidade,
        inEarn: false

      }
      props.action(body)
    } else {
      const body = {
        title: titulo,
        roi: rendimento,
        period: periodo,
        time: tempo,
        inDueDate: rentabilidade,
        inEarn: false

      }
      props.action(body)
    }




  }



  const limparStatesModalPlano = () => {
    setTitulo('')
    setRendimento('')
    setPeriodo('')
    setTempo('')
    setRentabilidade('')
    setErrorTitulo(false)
    setErrorRendimento(false)
    setErrorPeriodo(false)
    setErrorTempo(false)
    setErrorRentabilidade(false)

  }



  useEffect(() => {
    if (Object.keys(props.states.Investimentos).length > 0) {
      setTitulo(props.states.Investimentos.title)
      setRendimento(props.states.Investimentos.roi * 100)
      setPeriodo(props.states.Investimentos.period)
      setTempo(props.states.Investimentos.time)
      setRentabilidade(props.states.Investimentos.inDueDate)
      setIdInvestimento(props.states.Investimentos.id)

    }
  }, [props.states.Investimentos])

  const changeRendimento = (value) => {
    if (/^\d+$/.test(value) || value === '') {
      setRendimento(value);
    }
  }
  const changeRentabilidade = (value) => {
    value === '1' ? setRentabilidadeAlert(true) : setRentabilidadeAlert(false)
    setRentabilidade(value);
  }


  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className="w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Investimento</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Cadastro de produto de investimento
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1">

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Título do produto
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="text"
                                name="quantidade"
                                id="quantidade"
                                autoComplete="quantidade"
                                placeholder={props.states.waitTimeout === false ? 'Digite o título do investimento' : 'Carregando...'}
                                disabled={disabled}
                                value={titulo}
                                onBlur={() => leaveInput(titulo, 'titulo')}
                                onChange={e => setTitulo(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorTitulo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do título
                            </p>
                          }
                        </div>
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Rendimento
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="text"
                                name="quantidade"
                                id="quantidade"
                                autoComplete="quantidade"
                                placeholder={props.states.waitTimeout === false ? 'Digite o rendimento em %' : 'Carregando...'}
                                disabled={disabled}
                                value={rendimento}
                                onBlur={() => leaveInput(rendimento, 'rendimento')}
                                onChange={e => changeRendimento(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorRendimento === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do rendimento
                            </p>
                          }
                        </div>
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Período
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select id="country" name="country" autoComplete="country"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                onChange={e => setPeriodo(e.target.value)}
                                onBlur={() => leaveInput(periodo, 'periodo')}
                              >
                                <option>Selecione o período do rendimento</option>
                                <option value='m'>Mensal</option>
                                <option value='y'>Anual</option>
                              </select>
                            </div>
                          </div>
                          {
                            errorPeriodo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do período do investimento
                            </p>
                          }
                        </div>
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Tempo do investimento
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="number"
                                name="quantidade"
                                id="quantidade"
                                autoComplete="quantidade"
                                placeholder={props.states.waitTimeout === false ? 'Digite o tempo do investimento' : 'Carregando...'}
                                disabled={disabled}
                                value={tempo}
                                onBlur={() => leaveInput(tempo, 'tempo')}
                                onChange={e => setTempo(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorTempo === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do tempo do investimento
                            </p>
                          }
                        </div>
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Rentabilidade no vencimento?
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <select id="country" name="country" autoComplete="country"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                onChange={e => changeRentabilidade(e.target.value)}
                                onBlur={() => leaveInput(rentabilidade, 'rentabilidadeVencimento')}
                              >
                                <option>Selecione a rentabilidade no vencimento</option>
                                <option value='0'>Não</option>
                                <option value='1'>Sim</option>
                              </select>
                            </div>
                          </div>
                          {
                            errorRentabilidade === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da rentabilidade no vencimento
                            </p>
                          }
                          {
                            rentabilidadeAlert === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                             Ao selecionar sim, o valor do rendimento só será contabilizado no final do mes ou no final do periodo. Caso tenha resgate, o valor do mes em aberto não será contabilizado.
                            </p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {
                        props.states.isEdit == true ? 'Editar' : 'Salvar'
                      }
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
