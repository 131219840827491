import { Line } from 'react-chartjs-2';
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { BuildingLibraryIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import Navbar from '../../../../Components/Navbar/navbar'
import NavbarPanel from '../../../../Components/navMenu/Importacao'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroImportacaoFalhas'
import ModalImport from '../../../../Components/Modal/ImportFalhas'
import moment from 'moment'

const ImportacoesFaturas = () => {
    const { states, setters, } = useContext(AuthContext)
    const { dataImportFalhas } = useContext(AuthContextApi)
    const [dataModal, setDataModal] = useState({})


    const loadDataImport = async () => {
        await dataImportFalhas();

    }

    const loadData = async () => {
        const promises = [
            dataImportFalhas()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        loadData()

        return () => {
            setters.setDataImportFalhas([])
            setters.setOffsetImportFalhas(0)
            setters.setLimitSearchImportFalhas(false)
            setters.setModalImportFalhas(false)
        }
    }, [])




    const next = async () => {
        if (states.dataImportFalhas.length === 0) {
            return
        } else {
            setters.setOffsetImportFalhas(states.offsetImportFalhas + states.limitImportFalhas)
            loadDataImport()
        }

    };

    const previous = async () => {
        if (states.dataImportFalhas.length === 0 && states.offsetImportFalhas <= 0) {
            return
        } else {
            states.offsetImportFalhas > 0 && setters.setOffsetImportFalhas(states.offsetImportFalhas - states.limitImportFalhas)
            states.offsetImportFalhas < 0 && setters.setOffsetImportFalhas(0)
            loadDataImport()
        }

    };


    const showImportInfo = async (data) => {
        setDataModal(data)
        setters.setModalImportFalhas(true)
    }


    const headers = ['Descrição', 'Criador da importação', 'Data de criação', 'Data de atualização', 'Ação']
    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Falhas de importação</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Falhas das importações realizadas
                                    </p>

                                </div>
                            </div>



                            <TableFinanceiro title='Faturas vinculadas a importação'
                                description='Registro das faturas de importação' next={next} previous={previous} setters={setters} states={states} show={showImportInfo} headers={headers} infoTable={{ offset: states.offsetImportFalhas, limit: states.limitImportFalhas }} dataTable={states.dataImportFalhas} />

                            <ModalImport states={states} setters={setters} data={dataModal}
                                title={`Informações de falhas de importação`}
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export default ImportacoesFaturas;