import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Table from '../../Tabela/TabelaAssinaturasExtrato'
import TableFuncef from '../../Tabela/TabelaAssinaturasExtratoFuncef'


export default function ModalAssinaturas(props) {


  const close = () => {
    props.setters.setDataAssinaturaExtrato([])
    props.setters.setAssinaturaExtrato({})
    props.setters.setModalAssinaturaExtrato(false)
    props.setters.setLimitSearchAssinaturaExtrato(false)
    props.setters.setOffsetAssinaturaExtrato(0)
  }

  const next = async () => {
    if (props.states.limitSearchAssinaturaExtrato) {
      return
    } else {
      props.setters.setOffsetAssinaturaExtrato(props.states.offsetAssinaturaExtrato + props.states.limitAssinaturaExtrato)
      if (props.states.dataAssinaturaExtrato.length > 0) {
        await props.data(props.states.dataAssinaturaExtrato[0].idSignature)
      }
    }

  };

  const previous = async () => {
    if (props.states.offsetAssinaturaExtrato <= 0) {
      props.states.offsetAssinaturaExtrato < 0 && props.setters.setOffsetAssinaturaExtrato(0)
      return
    } else {
      if (props.states.limitSearchAssinaturaExtrato) props.setters.setLimitSearchAssinaturaExtrato(false)
      props.setters.setOffsetAssinaturaExtrato(props.states.offsetAssinaturaExtrato - props.states.limitAssinaturaExtrato)
      if (props.states.dataAssinaturaExtrato.length > 0) {
        await props.data(props.states.dataAssinaturaExtrato[0].idSignature)
      }
    }
  };


  const formatCPF = (cpf) => {
    if (!cpf) {
      return '';
    }
    const cleanedCPF = cpf.replace(/\D/g, '');
    if (cleanedCPF.length !== 11) {
      return cpf;
    }
    const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');
    return formattedCPF;
  }

  const formatarTelefone = (input) => {
    const digit = input.replace(/\D/g, '');
    let formattedNumber = digit;
    if (digit.length === 11 && !digit.startsWith('55')) {
      formattedNumber = '55' + digit;
    }
    const formatted = formattedNumber.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
    return formatted;
  };

  const headers = ['Data de início', 'Data de fim', 'Método', 'Valor', 'Dia da cobrança', 'Status da Assinatura', 'Status de pagamento', 'Data de criação', '']
  const headersFuncef = ['Data da remessa', 'Valor',  'Dia da cobrança', 'Status da Assinatura', 'Status de pagamento', 'Data de criação', '']
  return (
    <Transition.Root show={props.states.modalAssinaturaExtrato} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalAssinaturaExtrato(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[90%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Assinatura</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Extrato da assinatura
                  </p>
                </div>

                <div className='pt-10 pl-10 pr-10'>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a assinatura.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 md:col-span-2">
                        {
                          props.states.dataAssinaturaExtrato.length > 0 && (
                            <>
                              <div className="sm:col-span-1">
                                <div className="flex items-center justify-start">
                                  <dt className="text-sm font-medium text-gray-500">Nome: </dt>
                                  <dd className="mt-1 text-sm text-gray-900 ml-2">{props.states.dataAssinaturaExtrato[0]?.name}</dd>
                                </div>
                                <div className="flex items-center justify-start">
                                  <dt className="text-sm font-medium text-gray-500">CPF: </dt>
                                  <dd className="mt-1 text-sm text-gray-900 ml-2">{formatCPF(props.states.dataAssinaturaExtrato[0]?.cpf)}</dd>
                                </div>
                                {
                                  props.states.dataAssinaturaExtrato.find(e => e.debit === true) ? (
                                    <div className="flex items-center justify-start mt-2">
                                      <dt className="text-sm font-medium text-gray-500">Status: </dt>
                                      <dd className="mt-1 text-sm text-gray-900 ml-2">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                          Débito presente
                                        </span>
                                      </dd>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-start mt-2">
                                      <dt className="text-sm font-medium text-gray-500">Status: </dt>
                                      <dd className="mt-1 text-sm text-gray-900 ml-2">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                          Sem débitos
                                        </span>
                                      </dd>
                                    </div>

                                  )
                                }
                              </div>
                              <div className="sm:col-span-1">
                                <div className="flex items-center justify-start">
                                  <dt className="text-sm font-medium text-gray-500">E-mail: </dt>
                                  <dd className="mt-1 text-sm text-gray-900 ml-2">{props.states.dataAssinaturaExtrato[0]?.email}</dd>
                                </div>
                                <div className="flex items-center justify-start">
                                  <dt className="text-sm font-medium text-gray-500">Telefone: </dt>
                                  <dd className="mt-1 text-sm text-gray-900 ml-2">{formatarTelefone(props.states.dataAssinaturaExtrato[0]?.phone)}</dd>
                                </div>
                              </div>
                            </>
                          )
                        }

                      </div>
                    </div>
                  </div>

                  <div>
                    {
                      props.signature?.isFuncef === 1 ? (
                        <TableFuncef
                          title='Assinatura Funcef' description='Registro dos pagamentos e dos débitos' next={next} previous={previous} states={props.states} headers={headersFuncef} infoTable={{ offset: props.states.offsetAssinaturaExtrato, limit: props.states.limitAssinaturaExtrato }} dataTable={props.states.dataAssinaturaExtrato} />
                      ) : (
                        <Table
                          title='Períodos de assinatura' description='Registro dos periodos por assinatura' next={next} previous={previous} states={props.states} headers={headers} infoTable={{ offset: props.states.offsetAssinaturaExtrato, limit: props.states.limitAssinaturaExtrato }} dataTable={props.states.dataAssinaturaExtrato} />
                      )
                    }
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalAssinaturaExtrato(false)}>
                      Fechar
                    </button>
                  </div>
                </div>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
