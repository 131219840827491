import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

export default function ModalShow({ states, setters, title, data, closeModal }) {
    const [open, setOpen] = useState(true)

    const close = () => {
        closeModal()
    }

    return (
        <Transition.Root show={states.modalHooksIsOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-0"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-blue-100 px-4 py-5 sm:px-6  items-center justify-center">
                                <h2 className="text-lg font-medium text-gray-900 text-center">{title}</h2>
                            </div>
                            <div className="px-4 py-5 sm:p-6">
                                <dl className="space-y-2">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Tipo:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.type}</dd>
                                    </div>
                                    {/* Status */}

                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Status:</dt>
                                        <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word', color: data.status === 1 ? '#0076ff' : '#ff0000' }}>{data.status === 1 ? 'Sucesso' : 'Erro'}</dd>
                                    </div>
                                    {/* Descriçã */}
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Descrição:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.description}</dd>
                                    </div>
                                    {/* datas */}
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Data de criação:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}</dd>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                        <dt className="text-sm font-medium text-gray-500">Data de atualização:</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{moment(data.updated_at).format('DD/MM/YYYY HH:mm:ss')}</dd>
                                    </div>
                                    {
                                        data.isSignature == false && (
                                            <>
                                                {/** Tipo */}
                                                {/* IdDoGateway */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Id do gateway:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.dataHook.idVindi}</dd>
                                                </div>
                                                {/** Codigo do cliente no gateway */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Código do cliente no gateway:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.dataHook.customer_id}</dd>
                                                </div>
                                                {/**Url de acesso */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Url de acesso:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.dataHook.url}</dd>
                                                </div>
                                                {/* Status do gateway - paid, canceled, pending or another status default */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Status do gateway:</dt>
                                                    <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word', color: data.dataHook.status === 'paid' ? '#0076ff' : '#ff0000' }}>{data.dataHook.status === 'paid' ? 'Pago' : 'Não pago'}</dd>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        data.isSignature == true && (
                                            <>
                                                {/** Id da vindi */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Id do gateway:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.dataHook.idVindi}</dd>
                                                </div>
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Id do plano no gateway:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.dataHook.idPlan}</dd>
                                                </div>
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Id do associado no gateway:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{data.dataHook.idPlan}</dd>
                                                </div>
                                                {/*** Method = credit_card or bank_slip or another default */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Método de pagamento:</dt>
                                                    <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{
                                                        overflow: 'auto', wordWrap: 'break-word', color: data.dataHook.payment_method === 'credit_card' ? '#0076ff' :
                                                            data.dataHook.payment_method === 'bank_slip' ? '#GfH101' : '#000000'
                                                    }}>{
                                                            data.dataHook.payment_method === 'credit_card' ? 'Cartão de crédito' :
                                                                data.dataHook.payment_method === 'bank_slip' ? 'Boleto' : 'Não identificado'
                                                        }</dd>
                                                </div>
                                                {/** Stastuso da Assinatura no gatewauy */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Status da assinatura no gateway:</dt>
                                                    <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word', color: data.dataHook.status === 'active' ? '#0076ff' : '#ff0000' }}>{data.dataHook.status === 'active' ? 'Ativa' : 'Inativa'}</dd>
                                                </div>
                                                {/** Data de criaçao no gateway */}
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 rounded-md border border-gray-200">
                                                    <dt className="text-sm font-medium text-gray-500">Data de criação no gateway:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" style={{ overflow: 'auto', wordWrap: 'break-word' }}>{moment(data.dataHook.created_at).format('DD/MM/YYYY HH:mm:ss')}</dd>
                                                </div>

                                            </>
                                        )
                                    }
                                </dl>
                            </div>
                            <div className="bg-blue-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => close()}
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

