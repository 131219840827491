

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import './index.css'





const TableLogs = ({ states, headers, dataTable, title, description }) => {

    const [dataTableState, setDataTableState] = useState([])
    const [offSet, setOffSet] = useState(0)
    const limitTable = 10

    const nextPage = () => {
        if (dataTableState.length === 0 || dataTable.length <= limitTable ) {
            return
        } else {
            const data = dataTable
            const sumOff = offSet + limitTable
            const limit = limitTable + sumOff
            const newData = data.slice(sumOff, limit)
            setOffSet(limitTable + offSet)
            setDataTableState(newData)
        }
    }

    const previousPage = () => {
        if (offSet === 0) {
            return
        } else {
            const data =dataTable
            offSet > 0 && setOffSet(offSet - limitTable)
            offSet < 0 && setOffSet(0)
            const sumOff = offSet > 0 ? offSet - limitTable : 0;
            const limit = sumOff + 10 > limitTable ? sumOff + 10 : states.limitAssociadoss;
            const newData = data.slice(sumOff, limit)
            setDataTableState(newData)
        }

    }

    useEffect(() => {
        setDataTableState(dataTable.slice(0, limitTable))
    }, [])





    const trGen = () => {
        if (states.isLoading) {


            return (
                <tr className="hover:bg-gray-100">
                    {
                        dataTableState.length > 0 && Object.keys(dataTableState[0]).map((key, index) => {
                            return (

                                <td className="px-6 py-4 bg-white px-8 py-6 whitespace-pre-wrap text-sm text-gray-500" >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                    </div>
                                </td>
                            )
                        })
                    }
                </tr>
            )

        } else {


            return (
                dataTableState.length > 0 ? (
                    dataTableState.map((logs) => {

                        return (
                            <tr key={logs.id} className="hover:bg-gray-100">
                                {
                                    Object.keys(logs).map((key, index) => {
                                        if (key !== 'id' && key !== 'isDeleted' && key !== 'created_at' && key !== 'updated_at' && key !== 'createdAt'
                                            && key !== 'updatedAt' && key !== 'isDenger' && key !== 'status' && key !== 'user_id' && key !== 'isDeleted'
                                            && key !== 'filiate') {
                                            return (
                                                <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500 parag">{logs[key]}</td>
                                            )
                                        }
                                        if ((key === 'created_at' || key === 'updated_at' || key === 'createdAt' || key === 'updatedAt' || key === 'dateFiliate') && (moment(logs[key]).format('DD/MM/YYYY') !== 'Invalid date')) {
                                            return (
                                                <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">{moment(logs[key]).format('DD/MM/YYYY')}</td>
                                            )
                                        }
                                        if ((key === 'created_at' || key === 'updated_at' || key === 'createdAt' || key === 'updatedAt' || key === 'dateFiliate') && (moment(logs[key]).format('DD/MM/YYYY') === 'Invalid date')) {
                                            return (
                                                <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">Sem Atualização</td>
                                            )
                                        }
                                        if (key === 'isDenger') {
                                            if (logs[key] === 1) {
                                                return (
                                                    <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                                        <div className="flex items-center space-x-2">
                                                            <InformationCircleIcon className="h-6 w-6 text-red-500" />
                                                            <p className="text-red-500">Perigo</p>
                                                        </div>
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                                        <div className="flex items-center space-x-2">
                                                            <InformationCircleIcon className="h-6 w-6 text-green-500" />
                                                            <p className="text-green-500">Normal</p>
                                                        </div>
                                                    </td>
                                                )
                                            }
                                        }
                                        if (key === 'status') {
                                            if (logs[key] === 1) {
                                                return (
                                                    <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                                        <div className="flex items-center space-x-2">
                                                            <InformationCircleIcon className="h-6 w-6 text-green-500" />
                                                            <p className="text-green-500">Sucesso!</p>
                                                        </div>
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={index} className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500">
                                                        <div className="flex items-center space-x-2">
                                                            <InformationCircleIcon className="h-6 w-6 text-red-500" />
                                                            <p className="text-red-500">Erro!</p>
                                                        </div>
                                                    </td>
                                                )
                                            }
                                        }
                                    })
                                }

                            </tr>
                        )
                    }
                    )
                ) : (
                    <tr>
                        <td className="px-8 py-6 whitespace-nowrap text-sm text-center text-gray-500" colSpan="5">
                            Sem registro de dados
                        </td>
                    </tr>
                )
            )

        }
    }



    return (
        <div className="py-2 mt-6 overflow-x-auto">
            <div className="inline-block min-w-full  rounded-md border border-gray-200">

                <div>
                    <div className="rounded-md flex justify-between items-center px-8 py-6 bg-white">
                        <div className="flex flex-1 justify-between items-center">
                            <div className="flex flex-col">
                                <h1 className="text-xl font-bold text-gray-700">{title}</h1>
                                <p className="text-gray-500">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="min-w-full divide-y divide-gray-300">


                    <thead className="bg-gray-50">
                        <tr>
                            {
                                headers.map((headers, index) => (
                                    <th key={index} scope="col" className="px-8 py-4 text-left text-sm text-center font-semibold text-gray-900">
                                        {headers}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            trGen()
                        }
                    </tbody>
                </table>
                <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                    <div className="flex flex-1 justify-between sm:justify-start">
                        <button
                            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 bg-gray-100 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                            onClick={() => previousPage()}
                        >
                            Anterior
                        </button>
                        <button
                            href="#"
                            className="relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 bg-gray-100 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                            onClick={() => nextPage()}
                        >
                            Próximo
                        </button>


                    </div>
                </nav>
            </div>
        </div>







    )



}


export default TableLogs;