import { useRef, useState } from 'react'
import { toast } from 'react-toastify'

export default function DragDropFile({ file }) {
    const [dragActiveImg, setdragActiveImgImg] = useState(false);
    const inputRef = useRef(null);




    function handleFile(files) {
        // Check if the file is an image
        if (files[0].type.startsWith("image/")) {
            const put = files[0];
            inputRef.current.value = '';
            file(put);
        } else {
            // Display an error message for non-image files
            toast.error('Apenas arquivos de imagem são aceitos (JPEG, PNG, GIF, etc.)');
        }
    }

    const handleDragImg = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setdragActiveImgImg(true);
        } else if (e.type === "dragleave") {
            setdragActiveImgImg(false);
        }
    };

    const handleDropImg = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setdragActiveImgImg(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    const handleChangeImg = function (e) {
        e.preventDefault();
        const file = e.target.files[0];
        if (file.type.startsWith("image/")) {
            handleFile(e.target.files);

        } else {
            // Display an error message for non-image files
            toast.error('Apenas arquivos de imagem são aceitos (JPEG, PNG, GIF, etc.)');
        }
    };

    return (
        <form id="form-file-upload" onDragEnter={handleDragImg} onSubmit={(e) => e.preventDefault()} className='border '>
            <input ref={inputRef} type="file" id="input-file-upload-image" multiple={false} onChange={handleChangeImg} className='hidden' />

            <label id="input-file-upload-image" htmlFor="input-file-upload-image" className={dragActiveImg ? "drag-active" : ""} accept="image/*">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                            Arraste e solte o arquivo aqui
                        </span>
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Somente arquivos de imagem são aceitos</p>
                </div>
            </label>
            {dragActiveImg && <div id="drag-file-element" onDragEnter={handleDragImg} onDragLeave={handleDragImg} onDragOver={handleDragImg} onDrop={handleDropImg}></div>}
        </form>
    );
};
