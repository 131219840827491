import { Line } from 'react-chartjs-2';
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { BuildingLibraryIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import Navbar from '../../../../Components/Navbar/navbar'
import NavbarPanel from '../../../../Components/navMenu/Importacao'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroImportacaoFaturas'
import ModalImport from '../../../../Components/Modal/ImportFaturas'
import moment from 'moment'

const ImportacoesFaturas = () => {
    const { states, setters, } = useContext(AuthContext)
    const { dataSystemImportFaturas, dataImportFaturas, dataImportFaturasId } = useContext(AuthContextApi)


    const loadDataImport = async () => {
        await dataImportFaturas();

    }
    // useEffect(() => {
    //     if (states.dataImportFaturas.length > 0) {
    //         loadDataImport();
    //     }
    // }, [states.offsetImportFaturas])

    const loadData = async () => {
        const promises = [
            dataSystemImportFaturas(),
            dataImportFaturas()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        loadData()

        return () => {
            setters.setDataImportFaturas([])
            setters.setOffsetImportFaturas(0)
            setters.setLimitSearchImportFaturas(false)
            setters.setModalImportFaturas(false)
        }
    }, [])




    const next = async () => {
        if (states.dataImportFaturas.length === 0) {
            return
        } else {
            setters.setOffsetImportFaturas(states.offsetImportFaturas + states.limitImportFaturas)
            loadDataImport()
        }

    };

    const previous = async () => {
        if (states.dataImportFaturas.length === 0 && states.offsetImportFaturas <= 0) {
            return
        } else {
            states.offsetImportFaturas > 0 && setters.setOffsetImportFaturas(states.offsetImportFaturas - states.limitImportFaturas)
            states.offsetImportFaturas < 0 && setters.setOffsetImportFaturas(0)
            loadDataImport()
        }

    };


    const showImportInfo = async (data) => {
        await dataImportFaturasId(data.id)
        setters.setModalImportFaturas(true)
    }


    const headers = ['Status', 'Tipo', 'Valor', 'Data de criação', 'Data de atualizações', 'Ação']
    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Faturas de importação</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as faturas importadas.
                                    </p>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                        {/* Card */}
                                        {states.dataSystemImportFaturas?.map((card, index) => {
                                            if (index == 0 || index == 1) {
                                                if ((localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2')) {
                                                    return (
                                                        <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                            <div className="p-5">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0">
                                                                        <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                                    </div>
                                                                    <div className="ml-5 w-0 flex-1">
                                                                        <dl>
                                                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                                                <div className='flex flex-row'>
                                                                                    <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                                </div>
                                                                            </dt>
                                                                            <dd><div className="text-lg font-medium text-gray-500">{card.value}</div>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <div className="p-5">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    {
                                                                        (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                    }
                                                                </div>
                                                                <div className="ml-5 w-0 flex-1">
                                                                    <dl>
                                                                        <dt className="truncate text-sm font-medium text-gray-500">
                                                                            <div className='flex flex-row'>
                                                                                <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                            </div>
                                                                        </dt>
                                                                        <dd>
                                                                            {
                                                                                index === 0 && (<div className="text-lg font-medium text-green-500">{card.value}</div>)
                                                                            }
                                                                            {
                                                                                index === 1 && (<div className="text-lg font-medium text-red-500">{card.value}</div>)
                                                                            }
                                                                            {
                                                                                index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                            }
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }


                                        }

                                        )}
                                    </div>

                                </div>
                            </div>


                            <TableFinanceiro title='Faturas vinculadas a importação'
                                description='Registro das faturas de importação' next={next} previous={previous} setters={setters} states={states} show={showImportInfo} headers={headers} infoTable={{ offset: states.offsetImportFaturas, limit: states.limitImportFaturas }} dataTable={states.dataImportFaturas} />

                            <ModalImport states={states} setters={setters}
                                title={`Informações de fatura de importação`}
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export default ImportacoesFaturas;