import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/pontoEletronico'
import Table from '../../../../Components/Tabela/TabelaDiasTrabalhados'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import ModalPontoEdit from '../../../../Components/Modal/PontoEdit'
import ModalShowTable from '../../../../Components/Modal/ShowTableUsuarioPonto'


export default function DiasTrabalhados() {
    const { dataDiasTrabalhados, /*dataUsersLoadOutsourced,*/ dataUsersLoadOutsourcedAndName, dataDiasTrabalhadosPerUser, dataPontosUsuarioAdmin, editPonto } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const [titleModal, setTitleModal] = useState('')
    const [descriptionModal, setDescriptionModal] = useState('')
    const [idUser, setIdUser] = useState(0)


    const listarConfiguracaoAsync = async (id) => {
        await dataDiasTrabalhados()
    }

    const listarUsuarios = async () => {
        await dataUsersLoadOutsourcedAndName()
    }

    const listarUsuarioFiltro = async () => {
        await dataDiasTrabalhadosPerUser(idUser)
    }

    const listarUsuarioFiltroPontos = async () => {
        await dataPontosUsuarioAdmin(states.idDiaTrabalho)
    }

    const openModalEditPontos = async (ponto) => {
        setters.setPonto(ponto)
        setters.setModalEditPontos(true)
    }

    const actionEditPontos = async (ponto, id) => {
        const resp = await editPonto(ponto, id)
        if (resp) {
            setters.setModalEditPontos(false)
            setters.setPonto({})
            setters.setModalPonto(false)
        }
    }

    useEffect(() => {
        return () => {
            setters.setOffSetDiasTrabalhados(0)
            setters.setLimitSearchDiasTrabalhados(false)
            setters.setDataDiasTrabalhados([])
        }
    }, [])


    useEffect(() => {
        if (states.dataDiasTrabalhados.length > 0 && states.dataUsers.length == 0) {
            setIdUser(0)
            listarUsuarios()
        }
    }, [states.dataDiasTrabalhados])

    useEffect(() => {
        if (idUser == 0) {
            listarConfiguracaoAsync()
        } else {
            listarUsuarioFiltro(idUser)
        }
    }, [states.offsetDiasTrabalhados])

    useEffect(() => {
        listarUsuarioFiltroPontos()

    }, [states.offsetPontosUsuario])


    const add = async () => {
        setters.setModalConfiguracaoPonto(true);
    }

    const next = async () => {
        if (states.limitSearchDiasTrabalhados && states.offsetDiasTrabalhados > 0) {
            return
        } else {
            if (states.limitSearchDiasTrabalhados) setters.setLimitSearchDiasTrabalhados(false)
            setters.setOffSetDiasTrabalhados(states.offsetDiasTrabalhados + states.limitDiasTrabalhados)
        }

    };

    const previous = async () => {
        if (states.limitSearchDiasTrabalhados && states.offsetDiasTrabalhados <= 0) {
            states.offsetDiasTrabalhados < 0 && setters.setOffSetDiasTrabalhados(0)
            return
        } else {
            if (states.limitSearchDiasTrabalhados) setters.setLimitSearchDiasTrabalhados(false)
            setters.setOffSetDiasTrabalhados(states.offsetDiasTrabalhados - states.limitDiasTrabalhados)
        }
    };

    const filterByUser = async () => {
        if (idUser == 0) {
            await dataDiasTrabalhados()
        } else {
            setters.setOffSetDiasTrabalhados(0)
            await dataDiasTrabalhadosPerUser(idUser)
        }
    }

    const nextModal = async () => {
        if (states.dataPontosUsuario.length === 0) {
            return;
        }
        else {
            if (!states.limitSearchPontosUsuario) {
                setters.setOffSetPontosUsuario(states.offsetPontosUsuario + states.limitPontosUsuario)
            } else {
                states.offsetPontosUsuario < 0 && setters.setOffSetPontosUsuario(0) && setters.setLimitSearchPontosUsuario(false)
                return;
            }
        };
    }

    const previousModal = async () => {
        if (states.dataPontosUsuario.length === 0 && states.offsetPontosUsuario <= 0) {
            states.offsetPontosUsuario < 0 && setters.setOffSetPontosUsuario(0)
            return
        } else {
            states.offsetPontosUsuario > 0 && setters.setOffSetPontosUsuario(states.offsetPontosUsuario - states.limitPontosUsuario)
            states.limitSearchUsuarioDiasTrabalho && setters.limitSearchPontosUsuario(false)
        }
    }

    const openModalPontos = async (day) => {
        //console.log(day)
        setters.setDataPontosUsuario([])
        setters.setOffSetPontosUsuario(0)
        setters.setLimitSearchPontosUsuario(false)
        setters.setIdDiaTrabalho(day.id)
        setTitleModal(`Pontos do dia: ${moment(day.date).format("DD/MM/YYYY")} - Funcionário: ${day.name}`)
        setDescriptionModal(day.description ? day.description : 'Sem descrição') 
        setters.setModalPonto(true)
        await dataPontosUsuarioAdmin(day.id)
    }

    const headers = ['Nome', 'Data', 'Horário de Entrada', 'Horário de Saída', 'Horas Totais', '']
    const headersPontos = ['Solicitar troca', 'Data', 'Tipo do ponto', 'Horário de Entrada', 'Horário de Saída', 'Horário Total']



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Dias Trabalhados</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os dias trabalhados dos usuários
                            </p>
                            {
                                //Filtro de busca
                            }
                            <div className='flex flex-row justify-start mt-5'>
                                <div className='flex flex-row justify-start'>
                                    <div className="flex mr-6 my-2">
                                        <select onChange={(e) => setIdUser(e.target.value)} value={idUser} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                            <option value={0}>Selecione um usuário</option>
                                            {
                                                states.dataUsers.length > 0 && states.dataUsers.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                            {
                                                states.dataUsers.length == 0 && <option value={0}>Nenhum usuário encontrado</option>
                                            }
                                        </select>
                                        <button className="ml-5  right-0 flex items-center px-2 text-gray-700 hover:shadow rounded-[10px] border" onClick={filterByUser}>
                                            <MagnifyingGlassCircleIcon className='h-8 w-8' />
                                        </button>


                                    </div>
                                </div>
                            </div>

                            <Table add={add} title='Dias Trabalhados' description='Registro dos dias trabalhados'
                                next={next} previous={previous} states={states} headers={headers}
                                infoTable={{ offset: states.offsetDiasTrabalhados, limit: states.limitDiasTrabalhados }} dataTable={states.dataDiasTrabalhados} pontos={openModalPontos} />
                            <ModalShowTable states={states} setters={setters} title={titleModal} data={states.dataPontosUsuario}
                                headers={headersPontos} descriptio={titleModal} dataFunction={dataPontosUsuarioAdmin}
                                next={nextModal} previous={previousModal} description={descriptionModal} editPonto={openModalEditPontos}

                            />
                        </div>
                    </div>
                </div>

                <ModalPontoEdit states={states} setters={setters} action={actionEditPontos} />
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div >
        </>
    )
}
