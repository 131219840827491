import { Fragment, useState,useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function ModalFaturas(props) {
  const [disabled , setDisabled] = useState(true)

  const [status,setStatus] = useState('')
  const [email,setEmail] = useState('')
  const [dataCriacao,setDataCriacao] = useState('')

  const [objDataValidade, setObjDataValidade]= useState('')
  const [objDescricao, setObjDescricao]= useState('')
  const [objMulta, setObjMulta]= useState('')
  const [objPreco, setObjPreco]= useState('')
  const [objStatus, setObjStatus]= useState('')
  const [objQuantidade, setObjQuantidade]= useState('')
  const [objDesconto, setObjDesconto]= useState('')
  const [objDataCriacao, setObjDataCriacao]= useState('')
  const [objDataAlteracao, setObjDataAlteracao]= useState('')

  const [nome, setNome] = useState('')

  // 

  useEffect(() => {
    try{
      if(props.states.modalIsOpen === false)
      {
        limparStatesModal()
      }
      else
      {
        carregarStatesModal()
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.modalIsOpen]);

  const carregarStatesModal = () =>
  {
    setStatus(props.fatura.status)
    setEmail(props.fatura.userEmail)
    setDataCriacao(props.fatura.created_at.split('T')[0])
    // setObjStatus(props.assinatura.objInsert.status)
    // setObjDataVencimento(props.assinatura.objInsert.dateTolerance.split('T')[0])
    // setObjStatusPago(props.assinatura.objInsert.statusPaid.split(' ')[0].replaceAll('/','-'))
    // setObjDataAlteracao(props.assinatura.objInsert.updated_at.split(' ')[0].replaceAll('/','-'))
    setObjDataValidade(props.fatura.objInsert.experies_at)
    setObjDescricao(props.fatura.objInsert.description)
    setObjMulta(props.fatura.objInsert.penalty)
    setObjPreco(props.fatura.objInsert.price)
    setObjStatus(props.fatura.objInsert.status)
    setObjQuantidade(props.fatura.objInsert.amount)
    setNome(props.fatura.objInsert.name)
    setObjDesconto(props.fatura.objInsert.discount)
    setObjDataCriacao(props.fatura.objInsert.created_at.split(' ')[0].replaceAll('/','-'))
    setObjDataAlteracao(props.fatura.objInsert.updated_at.split(' ')[0].replaceAll('/','-'))
  }

  const limparStatesModal = () =>
  {
    setStatus('')
    setEmail('')
    setDataCriacao('')

    setObjDataValidade('')
    setObjDescricao('')
    setObjMulta('')
    setObjPreco('')
    setObjStatus('')
    setObjQuantidade('')
    setObjDesconto('')
    setObjDataCriacao('')
    setObjDataAlteracao('')
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={()=>props.setters.setModalIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Fatura</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações da fatura pendente
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10'>
                  <div className="space-y-12">

                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a pendência.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="status"
                                  name="status"
                                  placeholder='Digite o status'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    status === 1 ? 'Aprovado' : status === 0 ? 'Pendente' : 'Cancelado' 
                                    }
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Email
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="email"
                                  name="email"
                                  placeholder='Digite o email'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    email
                                    }
                                />
                            </div>
                          </div>
                          
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Data de Criação
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  type='date'
                                  id="dataCriacao"
                                  name="dataCriacao"
                                  placeholder='Digite a data de criação'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    dataCriacao
                                    }
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Nome
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  type='text'
                                  id="dataCriacao"
                                  name="dataCriacao"
                                  placeholder='Digite a data de criação'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    nome
                                    }
                                />
                            </div>
                          </div>
                        </div>

                      </div> 
                    </div>

                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a fatura pendente.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        
                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Status
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="statusFatura"
                                  name="statusFatura"
                                  placeholder='Digite o status'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    objStatus
                                    }
                                />
                            </div>
                          </div>
                          
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Valor
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="preco"
                                  name="preco"
                                  placeholder='Digite o valor'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value= {'R$'+ parseFloat(objPreco/100).toFixed(2).toString().replace('.',',')} 
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Quantidade
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="quantidade"
                                  name="quantidade"
                                  placeholder='Digite a quantidade'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    objQuantidade
                                    }
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Descrição
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="descricao"
                                  name="descricao"
                                  placeholder='Digite a descrição'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    objDescricao
                                    }
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Desconto
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="desconto"
                                  name="desconto"
                                  placeholder='Digite o desconto'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value= {'R$'+ parseFloat(objDesconto/100).toFixed(2).toString().replace('.',',')} 
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Multa
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  id="multa"
                                  name="multa"
                                  placeholder='Digite a multa'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value= {'R$'+ parseFloat(objMulta/100).toFixed(2).toString().replace('.',',')} 
                                />
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Data de Vencimento
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  type='date'
                                  id="dataVencimento"
                                  name="dataVencimento"
                                  placeholder='Digite a data de vencimento'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    objDataValidade
                                    }
                                />
                            </div>
                          </div>
                          
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Data de criação
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  type='date'
                                  id="dataModificacao"
                                  name="dataModificacao"
                                  placeholder='Digite a data de criação'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    objDataCriacao
                                  }
                                />
                            </div>
                          </div>
                          
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Data da última alteração
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                              <input
                                  type='date'
                                  id="dataModificacao"
                                  name="dataModificacao"
                                  placeholder='Digite a data de modificação'
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  value={
                                    objDataAlteracao
                                  }
                                />
                            </div>
                          </div>
                          
                        </div>

                      </div> 
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                    onClick={()=> props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
