import { toast } from 'react-toastify';
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SelectBoxPesquisa from '../../../Components/SelectBoxPesquisa/index'

export default function ModalNegociacoes(props) {
  useEffect(() => {
    if (props.states.modalIsOpen === false) {
      limparStatesModalNegociacao()
    }
    else {
      if (props.negociacao != undefined) {
        carregarStatesModalNegociacao(props.negociacao)
      }
    }

  }, [props.states.modalIsOpen]);

  const [dataExpiracao, setDataExpiracao] = useState('')
  const [errorDataExpiracao, setErrorDataExpiracao] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'dataExpiracao') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorDataExpiracao(true)
      }
      else {
        setErrorDataExpiracao(false)
        props.setters.setNegociacao({
          emailAssociate: props.states.Negociacao.emailAssociate,
          cpf: props.states.Negociacao.cpf,
          associate_id: props.states.Negociacao.associate_id,
          experies_at: valor,
          totalDebit: props.states.Negociacao.totalDebit,
        })
      }
    }
  }

  const onSubmitForm = async (e) => {
    cadastrarNegociacaoOnSubmit(e)
  }

  const cadastrarNegociacaoOnSubmit = async (e) => {
    e.preventDefault()
    if (dataExpiracao === '') {
      setErrorDataExpiracao(true)
    } else {
      props.setters.setNegociacao({
        ...props.states.Negociacao,
        totalDebit: props.states.Negociacao.totalDebit.replace('.', '').replace(',', '.').replace('R$', '') * 100
      })
      setDataExpiracao('')
      props.action()
    }
  }

  const carregarStatesModalNegociacao = (negociacao) => {
    setDataExpiracao(formatarData(negociacao.experies_at))

    setErrorDataExpiracao(false)
  }

  const limparStatesModalNegociacao = () => {
    setDataExpiracao('')

    setErrorDataExpiracao(false)
  }


  const formatarData = (data) => {
    var date = data.split("T");
    var dateParts = date[0].split("-");
    const dia = parseInt(dateParts[2])
    const mes = parseInt(dateParts[1])
    const ano = parseInt(dateParts[0])
    const dataFinal = ano + '-' + (mes < 10 ? '0' + mes : '' + mes) + '-' + (dia < 10 ? '0' + dia : '' + dia)
    return dataFinal
  }

  const formatCPF = (cpf) => {
    if (!cpf) {
      return '';
    }

    const cleanedCPF = cpf.replace(/\D/g, '');

    if (cleanedCPF.length !== 11) {
      return cpf; // Retorna o valor original se não for possível formatar
    }

    const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');

    return formattedCPF;
  }

  function money(numero) {
    if (numero === undefined || numero === null || numero === '') return
    var numeroFormatado = numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return numeroFormatado;
  }



  return (
    <Transition.Root show={props.states.modalNegociacao} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalNegociacao(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" sm:w-[60%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => props.closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Negociação</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações da negociação
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados relativos a negociação.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Data expiração
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                              <input
                                type="date"
                                name="dataExpiracao"
                                id="dataExpiracao"
                                autoComplete="dataExpiracao"
                                placeholder={props.states.waitTimeout === false ? 'Digite a data de expiração' : 'Carregando...'}
                                disabled={disabled}
                                value={dataExpiracao}
                                onBlur={() => leaveInput(dataExpiracao, 'dataExpiracao')}
                                onChange={e => setDataExpiracao(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          {
                            errorDataExpiracao === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento da data de expiração.
                            </p>
                          }
                        </div>

                        <div className="col-span-full">
                          <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            Associado
                          </label>
                          <div className="mt-2">
                            { /* card de exibicao do associado */}
                            <div className="flex flex-col items-start p-4 shadow border border-[1px] rounded-[15px] hover:shadow-md " style={{ overflow: 'auto', wordWrap: 'break-word' }}>
                              <p className="text-sm font-medium leading-5 text-gray-500 truncate"  >
                                <span className='text-gray-900'>E-mail:</span> {props.states.Negociacao.emailAssociate}
                              </p>
                              <p className="text-sm leading-5 text-gray-500 truncate">
                                <span className='text-gray-900'>CPF:</span> {formatCPF(props.states.Negociacao.cpf)}
                              </p>
                              <p className="text-sm leading-5 text-gray-500 truncate">
                                <span className='text-gray-900'>Valor:</span> {props.states.Negociacao.totalDebit}
                              </p>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.closeModal()}>
                      Cancelar
                    </button>
                    {props.negociacao == undefined &&
                      <button
                        type="submit"
                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Enviar
                      </button>
                    }
                  </div>
                </form>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}