import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AuthContext } from "../../../Context"
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { AuthContextApi } from '../../../Context/api';

import InputMask from "react-input-mask";

export default function ModalProduct(props) {

  const { buscarTerceirizadoPorId } = useContext(AuthContextApi)
  const { buscarCep, validarCpf, validarCnpj } = useContext(AuthContext)
  const [disabled, setDisabled] = useState(false)
  const [isCpf, setIsCpf] = useState('')

  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [funcionario, setFuncionario] = useState('')
  const [email, setEmail] = useState('')
  const [celular, setCelular] = useState('')
  const [cep, setCep] = useState('')
  const [bairro, setBairro] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [numeroResidencia, setNumeroResidencia] = useState('')
  const [complemento, setComplemento] = useState('')
  const [cidade, setCidade] = useState('')
  const [estado, setEstado] = useState('')
  const [pais, setPais] = useState('')

  const [errorNome, setErrorNome] = useState(false)
  const [errorCpf, setErrorCpf] = useState(false)
  const [errorCnpj, setErrorCnpj] = useState(false)
  const [errorFuncionario, setErrorFuncionario] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorCelular, setErrorCelular] = useState(false)
  const [errorCep, setErrorCep] = useState(false)
  const [errorBairro, setErrorBairro] = useState(false)
  const [errorLogradouro, setErrorLogradouro] = useState(false)
  const [errorNumeroResidencia, setErrorNumeroResidencia] = useState(false)
  const [errorComplemento, setErrorComplemento] = useState(false)
  const [errorCidade, setErrorCidade] = useState(false)
  const [errorEstado, setErrorEstado] = useState(false)
  const [errorPais, setErrorPais] = useState(false)
  const [selectUser, setSelectUser] = useState(false)
  const [user_id, setUserId] = useState('')
  const [user_id_error, setUserIdError] = useState(false)

  useEffect(() => {
    if (props.states.modalIsOpen === false) {
      limparStatesModalTerceirizado()
    }
    else
    {
      if (props.states.isEdit) 
      {
        if(props.id != undefined)
        {   
          buscarTerceirizado(props.id)
        }
      }
      else {
        setNome('')
        setCpf('')
        setCelular('')
        setFuncionario('')
        setEmail('')
        setLogradouro('')
        setBairro('')
        setNumeroResidencia('')
        setCidade('')
        setEstado('')
        setPais('')
        setComplemento('')
        setCep('')
        setIsCpf(false)
        setCnpj('')
      }
    }
  }, [props.states.modalIsOpen]);

  const buscarTerceirizado = async (id)=>
  {
    try{
      const data = await buscarTerceirizadoPorId(id)
      setNome(data.data.name)
      setCpf(data.data.cpf_cnpj)
      setCelular(`(${data.data.prefix_phone})` + data.data.phone)
      setFuncionario(data.data.employee)
      setEmail(data.data.email)
      setLogradouro(data.data.street)
      setBairro(data.data.district)
      setNumeroResidencia(data.data.locationNumber)
      setCidade(data.data.city)
      setEstado(data.data.state)
      setPais(data.data.country)
      setComplemento(data.data.complement)
      setCep(data.data.zip_code)
      setIsCpf(data.data.isCompany)
      setCnpj(data.data.cpf_cnpj)
      setUserId(data.data.user)
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const handleCep = async () => {
    try {
      if (cep != '') {
        const cepCorrigido = cep
          .replace('-', '')
          .replace('_', '')
        if (!(cepCorrigido == '' || cepCorrigido.length != 8)) {
          setErrorCep(false)
          const result = await buscarCep(cep)
          if (result.status == true) {
            setLogradouro(result.content.logradouro.replace('Rua ', ''))
            setBairro(result.content.bairro)
            setCidade(result.content.localidade)
            setEstado(buscaAbreviacao(result.content.uf))
            setPais('Brasil')

            setErrorLogradouro(false)
            setErrorBairro(false)
            setErrorCidade(false)
            setErrorEstado(false)
            setErrorPais(false)
            toast.success('CEP encontrado!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          } else {
            setErrorCep(true)
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          }
        } else {
          setErrorCep(true)
          toast.error('CEP inválido!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        }
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    }
  }

  const buscaAbreviacao = (sigla) => {
    if (sigla == 'AC') {
      return 'Acre'
    } else if (sigla == 'AL') {
      return 'Alagoas'
    } else if (sigla == 'AP') {
      return 'Amapá'
    } else if (sigla == 'AM') {
      return 'Amazonas'
    } else if (sigla == 'BA') {
      return 'Bahia'
    } else if (sigla == 'CE') {
      return 'Ceará'
    } else if (sigla == 'DF') {
      return 'Distrito Federal'
    } else if (sigla == 'ES') {
      return 'Espírito Santo'
    } else if (sigla == 'GO') {
      return 'Goiás'
    } else if (sigla == 'MA') {
      return 'Maranhão'
    } else if (sigla == 'MT') {
      return 'Mato Grosso'
    } else if (sigla == 'MS') {
      return 'Mato Grosso do Sul'
    } else if (sigla == 'MG') {
      return 'Minas Gerais'
    } else if (sigla == 'PA') {
      return 'Pará'
    } else if (sigla == 'PB') {
      return 'Paraíba'
    } else if (sigla == 'PR') {
      return 'Paraná'
    } else if (sigla == 'PE') {
      return 'Pernambuco'
    } else if (sigla == 'PI') {
      return 'Piauí'
    } else if (sigla == 'RJ') {
      return 'Rio de Janeiro'
    } else if (sigla == 'RN') {
      return 'Rio Grande do Norte'
    } else if (sigla == 'RS') {
      return 'Rio Grande do Sul'
    } else if (sigla == 'RO') {
      return 'Rondônia'
    } else if (sigla == 'RR') {
      return 'Roraima'
    } else if (sigla == 'SC') {
      return 'Santa Catarina'
    } else if (sigla == 'SP') {
      return 'São Paulo'
    } else if (sigla == 'SE') {
      return 'Sergipe'
    } else if (sigla == 'TO') {
      return 'Tocantins'
    } else {
      return sigla
    }
  }

  const onSubmitForm = async (e) => {
    cadastrarAssociadoOnSubmit(e)
  }

  const cadastrarAssociadoOnSubmit = async (e) => {
    e.preventDefault()
    const terceirizado = {
      zip_code: cep,
      email: email,
      street: logradouro,
      district: bairro,
      locationNumber: numeroResidencia,
      city: cidade,
      state: estado,
      country: pais,
      complement: complemento,
      cpf_cnpj: isCpf ? cpf : cnpj,
      isCompany: isCpf ? false : true,
      name: nome,
      prefix_phone: celular,
      phone: celular,
      employee: funcionario,
      // user_id
    }
    props.action()
    // 
    // 

  }

  const dddFunc = (telefone) => {
    telefone = telefone.replace(/[^\d]/g, '');
    var ddd = telefone.substring(0, 2);

    return ddd
  }

  const numeroFunc = (telefone) => {
    telefone = telefone.replace(/[^\d]/g, '');
    var numero = telefone.substring(2);

    return numero
  }

  const leaveInput = async (valor, tipo) => {
    if (tipo === 'nome') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorNome(true)
      }
      else {
        setErrorNome(false)
      }
    }
    if (tipo === 'userId') {
      if (valor === undefined || valor === null || valor === '') {
        setUserIdError(true)
      }
      else {
        setUserIdError(false)
      }
    }
    else if (tipo === 'cpf') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCpf(true)
      }
      else {
        const resultado = validarCpf(cpf)
        setErrorCpf(!resultado.status)
      }
    }
    else if (tipo === 'cnpj') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCnpj(true)
      }
      else {
        setErrorCnpj(false)
        const resultado = validarCnpj(cnpj)
        setErrorCnpj(!resultado.status)
      }
    }
    else if (tipo === 'funcionario') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorFuncionario(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorFuncionario(true)
        }
        else {
          setErrorFuncionario(false)
        }
      }
    }
    else if (tipo === 'email') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEmail(true)
      }
      else {
        setErrorEmail(false)
      }
    }
    else if (tipo === 'celular') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCelular(true)
      }
      else {
        const resultado = (celular.replaceAll(`_`, ``).replaceAll(`-`, ``).replaceAll(`(`, ``).replaceAll(`)`, ``).replaceAll(` `, ``).length == 11) ? true : false
        setErrorCelular(!resultado)
      }
    }
    else if (tipo === 'cep') {
      handleCep()
      if (valor === undefined || valor === null || valor === '') {
        setErrorCep(true)
      }
      else {
        setErrorCep(false)
      }
    }
    else if (tipo === 'bairro') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorBairro(true)
      }
      else {
        setErrorBairro(false)
      }
    }
    else if (tipo === 'logradouro') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorLogradouro(true)
      }
      else {
        setErrorLogradouro(false)
      }
    }
    else if (tipo === 'numeroResidencia') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorNumeroResidencia(true)
      }
      else {
        setErrorNumeroResidencia(false)
      }
    }
    else if (tipo === 'cidade') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorCidade(true)
      }
      else {
        setErrorCidade(false)
      }
    }
    else if (tipo === 'estado') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorEstado(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorEstado(true)
        }
        else {
          setErrorEstado(false)
        }
      }
    }
    else if (tipo === 'pais') {
      if (valor === undefined || valor === null || valor === '') {
        setErrorPais(true)
      }
      else {
        if (valor == 'Selecione ..') {
          setErrorPais(true)
        }
        else {
          setErrorPais(false)
        }
      }
    }
    props.setters.setTerceirizado({
      id: props.id ? props.id : null,
      zip_code: cep,
      email: email,
      street: logradouro,
      district: bairro,
      locationNumber: numeroResidencia,
      city: cidade,
      state: estado,
      country: pais,
      complement: complemento,
      cpf_cnpj: isCpf ? cpf : cnpj,
      isCompany: isCpf ? false : true,
      name: nome,
      prefix_phone: dddFunc(celular),
      phone: numeroFunc(celular),
      employee: funcionario,
      user: user_id
    })
  }

  const limparStatesModalTerceirizado = () => {
    setNome('')
    setCpf('')
    setCnpj('')
    setFuncionario('')
    setEmail('')
    setCelular('')
    setCep('')
    setBairro('')
    setLogradouro('')
    setNumeroResidencia('')
    setComplemento('')
    setCidade('')
    setEstado('')
    setPais('')

    setErrorNome(false)
    setErrorCpf(false)
    setErrorCnpj(false)
    setErrorFuncionario(false)
    setErrorEmail(false)
    setErrorCelular(false)
    setErrorCep(false)
    setErrorBairro(false)
    setErrorLogradouro(false)
    setErrorNumeroResidencia(false)
    setErrorComplemento(false)
    setErrorCidade(false)
    setErrorEstado(false)
    setErrorPais(false)
  }

  const handleChange = (input, origin) => {
    if (origin == 'cpf') {
      if (input == '___.___.___-__') {
        setIsCpf('')
      }
      else {
        setErrorCnpj(false)
        setIsCpf(true)
      }
      setCpf(input)
    }
    else {
      if (input == '__.___.___/000_-__') {
        setIsCpf('')
      }
      else {
        setErrorCpf(false)
        setIsCpf(false)
      }
      setCnpj(input)
    }
  }

  useEffect(() => {
    if (funcionario == 1) {
      setSelectUser(true)
    } else {
      setUserId(null)
      setSelectUser(false)
    }
  }, [funcionario])

  const closeModal = () => {
    props.setters.setModalIsOpen(false)
    props.setters.setIsEdit(false)
    limparStatesModalTerceirizado()
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.setters.setModalIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Mexer na altura, só colocar o height na classname abaixo */}
              <Dialog.Panel className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className='flex-col border-b border-gray-900/10'>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Terceirizado</h3>
                  <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                    Informações do terceirizado
                  </p>
                </div>

                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                  <div className="space-y-12">
                    {/* 1 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações pessoais</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados privados do terceirizado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nome
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="nome"
                              id="nome"
                              autoComplete="nome"
                              required
                              placeholder={props.states.waitTimeout === false ? 'Digite seu nome' : 'Carregando...'}
                              disabled={disabled}
                              value={nome}
                              onBlur={() => leaveInput(nome, 'nome')}
                              onChange={e => setNome(e.target.value)}
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorNome === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento do nome.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Funcionário
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              id="funcionario"
                              name="funcionario"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              disabled={disabled}
                              onChange={e => setFuncionario(e.target.value)}
                              onBlur={() => leaveInput(funcionario, 'funcionario')}
                              value={
                                funcionario
                              }
                              required
                            >
                              <option>Selecione ..</option>
                              <option value={1}>Sim</option>
                              <option value={0}>Não</option>
                            </select>

                          </div>
                          {
                            errorFuncionario === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento se é funcionário.
                            </p>
                          }
                        </div>

                        {
                          funcionario == '1' && (

                            <div className="sm:col-span-4">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                Usuário
                              </label>
                              <div className="flex max-w-lg rounded-md shadow-sm">
                                <select
                                  id="funcionario"
                                  name="funcionario"
                                  className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  disabled={disabled}
                                  onChange={e => setUserId(e.target.value)}
                                  onBlur={() => leaveInput(user_id, 'userId')}
                                  value={
                                    user_id
                                  }
                                  required
                                >
                                  <option>Selecione ..</option>
                                  {
                                    props.states.dataUsers.map((user) => {
                                      return (
                                        <option value={user.id}>{user.email}</option>
                                      )
                                    })
                                  }
                                </select>

                              </div>
                              {
                                user_id_error === true &&
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                  É obrigatório selecionar um usuário para um funcionário.
                                </p>
                              }
                            </div>
                          )
                        }

                        <div className="sm:col-span-3" >
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 p-1">
                            CPF
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <InputMask
                              disabled={(!isCpf && cnpj !== '') ? true : false}
                              type="text"
                              name="cpf_cnpj"
                              id="cpf_cnpj"
                              autoComplete="cpf_cnpj"
                              placeholder='Digite o CPF/CNPJ'
                              mask="999.999.999-99"
                              required
                              value={cpf}
                              onBlur={() => leaveInput(cpf, 'cpf')}
                              // onChange={e => setCpf(e.target.value)}
                              onChange={e => handleChange(e.target.value, 'cpf')}
                              className={(!isCpf && cnpj !== '') ?
                                "pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-200 placeholder:text-gray-200 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                : "pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              } />
                          </div>
                          {
                            (!(!isCpf && cnpj !== '') && errorCpf === true) &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento de um CPF válido.
                            </p>
                          }
                        </div>
                        {
                          (funcionario == 0) && (
                            <div className="sm:col-span-3">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 p-1">
                                CNPJ
                              </label>
                              <div className="flex max-w-lg rounded-md shadow-sm">
                                <InputMask
                                  disabled={(isCpf && cpf !== '') ? true : false}
                                  type="text"
                                  name="cpf_cnpj"
                                  id="cpf_cnpj"
                                  autoComplete="cpf_cnpj"
                                  placeholder='Digite o CPF/CNPJ'
                                  mask="99.999.999/0009-99"
                                  value={cnpj}
                                  onBlur={() => leaveInput(cnpj, 'cnpj')}
                                  // onChange={e => setCnpj(e.target.value)}
                                  onChange={e => handleChange(e.target.value, 'cnpj')}
                                  required
                                  className={(isCpf && cpf !== '') ?
                                    "pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-200 placeholder:text-gray-200 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                    : "pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  }
                                />
                              </div>
                              {
                                (!(isCpf && cpf !== '') && errorCnpj === true) &&
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                  É obrigatório o preenchimento de um CNPJ válido.
                                </p>
                              }
                            </div>
                          )
                        }

                      </div>
                    </div>

                    {/* 2 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de contato</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados de contato do terceirizado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            E-Mail
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              required

                              placeholder={props.states.waitTimeout === false ? 'Digite seu E-Mail' : 'Carregando...'}
                              disabled={disabled}
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              onBlur={() => leaveInput(email, 'email')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorEmail === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do email.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Celular
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <InputMask

                              type="text"
                              name="telefone"
                              id="telefone"
                              autoComplete="telefone"
                              placeholder='Digite o telefone'
                              mask="(99) 99999-9999"
                              disabled={disabled}
                              value={celular}
                              required
                              onChange={e => setCelular(e.target.value)}
                              onBlur={() => leaveInput(celular, 'celular')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCelular === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento de um telefone válido.
                            </p>
                          }
                        </div>

                      </div>
                    </div>

                    {/* 3 parte */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de endereço</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados de endereço do terceirizado.</p>
                      </div>

                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            CEP
                          </label>
                          <div className="rounded-md shadow-sm">
                            <input
                              type="text"
                              name="cep"
                              id="cep"
                              required
                              autoComplete="cep"
                              placeholder={props.states.waitTimeout === false ? 'Digite seu CEP' : 'Carregando...'}
                              disabled={disabled}
                              value={cep}
                              onChange={e => setCep(e.target.value)}
                              onBlur={() => leaveInput(cep, 'cep')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCep === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                              É obrigatório o preenchimento de um CEP válido.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Bairro
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="bairro"
                              id="bairro"
                              autoComplete="bairro"
                              required

                              placeholder={props.states.waitTimeout === false ? 'Digite seu bairro' : 'Carregando...'}
                              disabled={disabled}
                              value={bairro}
                              onChange={e => setBairro(e.target.value)}
                              onBlur={() => leaveInput(bairro, 'bairro')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorBairro === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do bairro.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Logradouro
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="logradouro"
                              id="logradouro"
                              autoComplete="logradouro"
                              required

                              placeholder={props.states.waitTimeout === false ? 'Digite seu logradouro' : 'Carregando...'}
                              disabled={disabled}
                              value={logradouro}
                              onChange={e => setLogradouro(e.target.value)}
                              onBlur={() => leaveInput(logradouro, 'logradouro')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorLogradouro === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do logradouro.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Nº da residência
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="numeroresidencia"
                              id="numeroresidencia"
                              autoComplete="numeroresidencia"
                              required

                              placeholder={props.states.waitTimeout === false ? 'Digite o Nº da sua residência' : 'Carregando...'}
                              disabled={disabled}
                              value={numeroResidencia}
                              onChange={e => setNumeroResidencia(e.target.value)}
                              onBlur={() => leaveInput(numeroResidencia, 'numeroResidencia')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorNumeroResidencia === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do Nº da residência.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Complemento
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="complemento"
                              id="complemento"
                              autoComplete="complemento"
                              required

                              placeholder={props.states.waitTimeout === false ? 'Digite o complemento' : 'Carregando...'}
                              disabled={disabled}
                              value={complemento}
                              onChange={e => setComplemento(e.target.value)}
                              onBlur={() => leaveInput(complemento, 'complemento')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {/* {
                              errorComplemento === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    
                                    É obrigatório o preenchimento do complemento.
                                </p>
                            } */}
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Cidade
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              type="text"
                              name="cidade"
                              id="cidade"
                              autoComplete="cidade"
                              required

                              placeholder={props.states.waitTimeout === false ? 'Digite sua cidade' : 'Carregando...'}
                              disabled={disabled}
                              value={cidade}
                              onChange={e => setCidade(e.target.value)}
                              onBlur={() => leaveInput(cidade, 'cidade')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {
                            errorCidade === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento da cidade.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Estado
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <select
                              required

                              type="text"
                              name="postal-code"
                              id="postal-code"
                              autoComplete="postal-code"
                              className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              value={estado}
                              onChange={e => setEstado(e.target.value)}
                              onBlur={() => leaveInput(estado, 'estado')}>
                              <option>Selecione ..</option>
                              <option>Acre</option>
                              <option>Alagoas</option>
                              <option>Amapá</option>
                              <option>Amazonas</option>
                              <option>Bahia</option>
                              <option>Ceará</option>
                              <option>Distrito Federal</option>
                              <option>Espírito Santo</option>
                              <option>Goiás</option>
                              <option>Maranhão</option>
                              <option>Mato Grosso</option>
                              <option>Mato Grosso do Sul</option>
                              <option>Minas Gerais</option>
                              <option>Pará</option>
                              <option>Paraíba</option>
                              <option>Paraná</option>
                              <option>Pernambuco</option>
                              <option>Piauí</option>
                              <option>Rio de Janeiro</option>
                              <option>Rio Grande do Norte</option>
                              <option>Rio Grande do Sul</option>
                              <option>Rondônia</option>
                              <option>Roraima</option>
                              <option>Santa Catarina</option>
                              <option>São Paulo</option>
                              <option>Sergipe</option>
                              <option>Tocantins</option>
                            </select>
                          </div>
                          {
                            errorEstado === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do estado.
                            </p>
                          }
                        </div>

                        <div className="sm:col-span-2">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            País
                          </label>
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            <input
                              required
                              type="text"
                              name="pais"
                              id="pais"
                              autoComplete="pais"
                              placeholder={props.states.waitTimeout === false ? 'Digite seu país' : 'Carregando...'}
                              disabled={disabled}
                              value={bairro}
                              onChange={e => setPais(e.target.value)}
                              onBlur={() => leaveInput(pais, 'pais')}
                              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />

                          </div>
                          {
                            errorPais === true &&
                            <p class="mt-2 ml-2 text-pink-600 text-sm">

                              É obrigatório o preenchimento do país.
                            </p>
                          }
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                      onClick={() => props.setters.setModalIsOpen(false)}>
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {
                        props.states.isEdit === true ? 'Salvar' : 'Cadastrar'
                      }
                    </button>
                  </div>
                </form>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
