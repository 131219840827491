import { Pie } from 'react-chartjs-2';
import { toast } from 'react-toastify'
import { Chart, registerables } from 'chart.js'
import { AuthContext } from "../../../Context/index"
import { AuthContextApi } from '../../../Context/api'
import { useState, useContext, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import SpinnerOverlay from '../../../Components/SpinnerOverlay';

import moment from 'moment'
import Navbar from '../../../Components/Navbar/navbar'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalShow from '../../../Components/Modal/ShowAssinatura'
import Table from '../../../Components/Tabela/TabelaAssinaturas'
import TableFilter from '../../../Components/Tabela/TabelaAssinaturasFiltros'
import ModalNegociacao from '../../../Components/Modal/Negociacao'
import NavbarMenu from '../../../Components/navMenu/administrativo'
import ModalConfirmFuncef from '../../../Components/Modal/ConfirmFuncef'
import ModalShowTable from '../../../Components/Modal/ShowTableAssociado'
import Modal from '../../../Components/Modal/Assinaturas/modalAssinaturas'
import ModalFiltro from '../../../Components/Modal/ModalFiltrosAssinaturas'
import ModalExtrato from '../../../Components/Modal/AssinaturasExtrato'

Chart.register(...registerables);

export default function Assinaturas() {

  const { states, setters } = useContext(AuthContext)
  const { dataAssinaturaExtrato, dataAssinaturaCard, createNegociacao, dataAssinaturaQuery, changeFuncefAssinatura, activeAssinatura, suspendAssinatura, updateAssinatura, createAssinatura, dataAssinatura, dataSystemAssinatura, buscarAssociadoCpf, searchQueryAssinatura, dataPlans } = useContext(AuthContextApi)

  const [headersShow, setHeadersShow] = useState([])
  const [signature, setSignature] = useState({});
  const [idSelecionado, setIdSelecionado] = useState(0)
  const [dataFiltro, setDataFiltro] = useState({})
  const [param, setParam] = useState('')

  const callData = async () => {
    await dataAssinatura()
  }

  useEffect(() => {
    load()
    return () => {
      setters.setDataAssinatura([])
      setters.setQuerySearchAssinatura('')
      setters.setOffsetAssinatura(0)
      setters.setLimitSearchAssinatura(false)
      setters.setModalShowTecnico(false)
      setters.setSystemAssinatura([])
      setters.setDataQuery([])
    }
  }, []);

  useEffect(() => {
    if (states.dataTableFiltroAssinaturas.length > 0 || states.offsetAssinatura == 0) return
    //Isso aqui atrapalha a paginacao
    callData()
  }, [states.offsetAssinatura])

  const load = async () => {
    const promises = [
      dataSystemAssinatura(),
      dataAssinatura()
    ];

    await Promise.all(promises);
  }

  const next = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssinatura.length === 0) {
        return
      } else {
        const data = states.dataQuery
        setters.setOffsetAssinatura(states.offsetAssinatura + states.limitAssinatura)
        const sumOff = states.offsetAssinatura + states.limitAssinatura
        const limit = states.limitAssinatura + sumOff
        const newData = data.slice(sumOff, limit)
        setters.setDataAssinatura(newData)
      }
    } else {
      if (states.limitSearchAssinatura) {
        return
      } else {
        setters.setOffsetAssinatura(states.offsetAssinatura + states.limitAssinatura)
      }
    }

  };

  const previous = async () => {
    if (states.dataQuery.length > 0) {
      if (states.dataAssinatura.length === 0 && states.offsetAssinatura <= 0) {
        return
      } else {
        const data = states.dataQuery
        states.offsetAssinatura > 0 && setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
        states.offsetAssinatura < 0 && setters.setOffsetAssinatura(0)
        const sumOff = states.offsetAssinatura > 0 ? states.offsetAssinatura - states.limitAssinatura : 0;
        const limit = sumOff + 10 > states.limitAssinatura ? sumOff + 10 : states.limitAssinatura;
        const newData = data.slice(sumOff, limit)
        setters.setDataAssinatura(newData)
      }
    } else {
      if (states.offsetAssinatura <= 0) {
        states.offsetAssinatura < 0 && setters.setOffsetAssinatura(0)
        return
      } else {
        if (states.limitSearchAssinatura) setters.setLimitSearchAssinatura(false)
        setters.setOffsetAssinatura(states.offsetAssinatura - states.limitAssinatura)
      }
    }
  };

  const data = {
    labels: states.dataGraficAssinaturaMethod.map((item) => item.method),
    datasets: [
      {
        data: states.dataGraficAssinaturaMethod.map((item) => item.items),
        backgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        hoverBackgroundColor: [
          '#8E44AD', '#F1C40F', '#3498DB', '#1ABC9C', '#E74C3C', '#9B59B6', '#F39C12', '#16A085', '#C0392B', '#2980B9',
          '#27AE60', '#D35400', '#2C3E50', '#FDE3A7', '#6C5B7B', '#F8EFBA', '#FF7F50', '#2ECC71', '#E67E22', '#8A2BE2',
          '#FF00FF', '#00FFFF', '#FF1493', '#00CED1', '#FFD700', '#FF4500', '#008080', '#FF69B4', '#808000', '#800080',
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
        hoverBorderColor: '#ffffff',
      },
    ]
  };

  const data1 = {
    labels: states.dataAssinaturaStatus.map(item => item.status),
    datasets: [
      {
        data: states.dataAssinaturaStatus.map(item => item.items),
        backgroundColor: ['#66BB6A', '#FF5252', '#42A5F5'],
        hoverBackgroundColor: ['#66BB6A', '#FF5252', '#42A5F5'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255, 255, 255, 0.8)',
        fontSize: 12,
        padding: 20,
      },
    },
  };

  

  const add = async () => {
    await dataPlans()
    setters.setIsEdit(false)
    setters.setAssociadoFatura({})
    setters.setAssinatura({})
    setters.setModalIsOpen(true);
  }

  const dataFilter = async () => {
    await dataAssinatura()
  }

  const openModalInfo = (associado) => {
    const { ...newJson } = associado;
    const updatedJson = { ...newJson };
    setters.setAssinatura(updatedJson);
    setters.setModalShowTecnico(true);

  }

  const closeModalShow = () => {
    setters.setAssinatura({});
    setters.setModalShowTecnico(false);
  }

  const abrirModal = async (id) => {
    await dataPlans()
    setters.setIsEdit(true)
    setIdSelecionado(id)
    setters.setAssinatura({})
    setters.setAssociadoFatura({})
    setters.setModalIsOpen(true);
  };

  const dataSearch = async () => {
    if (states.querySearchAssinatura !== '') {
      const regex = /[-\/]/;
      const isData = regex.test(states.querySearchAssociados)
      if (isData) {
        toast.error('Não é possível pesquisar por data')
      } else {
        await searchQueryAssinatura()
      }

    }
  }

  const actionConfirm = async () => {
    states.isSuspend ? await suspendAssinatura() : await activeAssinatura()
  }

  const showTables = async (data) => {
    setters.setDataAssinaturaCard([])
    setters.setOffsetAssinaturaCard(0)
    setters.setLimitSearchAssinaturaCard(false)
    setParam(data.param)
    if (data.debit) {
      setHeadersShow(['Cpf Associado', 'Email do associado', 'Metodo', 'Titulo Plano', 'Status', 'Status de pagamento', 'Tipo de assinatura', 'Data de criação', 'Total de débitos', ''])
    } else {
      setHeadersShow(['Cpf Associado', 'Email do associado', 'Metodo', 'Titulo Plano', 'Status', 'Status de pagamento', 'Tipo de assinatura', 'Data de criação'])
    }
    await dataAssinaturaCard(data.param)
    setters.setModalTable(true)
  }

  const submit = async () => {
    setters.setModalIsOpen(false)
    states.isEdit ? await updateAssinatura() : await createAssinatura()
  }

  const openSuspendSignature = (data) => {
    setters.setIsSuspend(true)
    setters.setAssinatura(data)
    setters.setConfirmModal(true)
  }

  const activeSignature = (data) => {
    setters.setIsSuspend(false)
    setters.setAssinatura(data)
    setters.setConfirmModal(true)
  }

  const changeFuncef = async (data) => {
    setters.setAssinatura(data)
    setters.setModalFuncef(true)
  }

  const changeFuncefAction = async (data) => {
    setters.setModalFuncef(false)
    await changeFuncefAssinatura()
  }

  const openModalNegociacao = (data) => {
    setters.setNegociacao(data)
    setters.setModalTable(false)
    setters.setModalNegociacao(true)
  }

  const closeModalNegociacao = () => {
    setters.setNegociacao({})
    setters.setModalNegociacao(false)
  }

  const createNegociacaoAction = async () => {
    setters.setModalNegociacao(false)
    await createNegociacao()
  }

  const titleConfirm = states.isSuspend ? 'Suspensão de assinatura' : 'Ativação de assinatura'
  const descriptionConfirm = states.isSuspend ? 'Deseja realmente suspender a assinatura?' : 'Deseja realmente ativar a assinatura?'

  const headers = ['Email', 'CPF', 'Nome', 'Dia de cobrança', 'Método', 'Título do plano', 'Status', 'Status de pagamento', 'Tipo de assinatura', 'Data de criação', '']

  const abrirFiltros = () => {
    setDataFiltro({})
    setters.setOffsetFiltroAssinaturas(0)
    setters.setModalFiltro(true)
  }

  const acaoFiltros = async (data) => {
    setters.setModalFiltro(false)
    await dataAssinaturaQuery(data)
    setDataFiltro(data)
  }

  const nextFilters = async () => {
    if (states.limitSearchFiltroAssinaturas) {
      return
    } else {
      setters.setOffsetFiltroAssinaturas(states.offsetFiltroAssinaturas + states.limitFiltroAssinaturas)
    }
  };

  const previousFilters = async () => {
    if (states.offsetFiltroAssinaturas <= 0) {
      states.offsetFiltroAssinaturas < 0 && setters.setOffsetFiltroAssinaturas(0)
      return
    } else {
      if (states.limitSearchFiltroAssinaturas) setters.setLimitSearchFiltroAssinaturas(false)
      setters.setOffsetFiltroAssinaturas(states.offsetFiltroAssinaturas - states.limitFiltroAssinaturas)
    }

  };

  useEffect(() => {
    if (Object.keys(dataFiltro).length == 0) {
      return
    } else {
      acaoFiltros(dataFiltro)
    }
  }, [states.offsetFiltroAssinaturas])


  useEffect(() => {
    if(!states.modalTable){
      setters.setDataAssinaturaCard([])
      setters.setOffsetAssinaturaCard(0)
      setters.setLimitSearchAssinaturaCard(false)
      setParam('')
    }
  }, [states.modalTable])


  const getExtrato = async (data) => {
    setSignature(data)
    const response = await dataAssinaturaExtrato(data.id)
    if (response == true) {
      setters.setModalAssinaturaExtrato(true)
    } else {
      setSignature({})
    }
  }

  useEffect(() => {
    if (states.modalAssinaturaExtrato == false) {
      setSignature({})
      setters.setDataAssinaturaExtrato([])
      setters.setAssinaturaExtrato({})
      setters.setLimitSearchAssinaturaExtrato(false)
      setters.setOffsetAssinaturaExtrato(0)
    }
  }, [states.modalAssinaturaExtrato])

  return (
    <>
      <div className="flex flex-1 flex-col pb-16 bg-white">
        <Navbar />
        <SpinnerOverlay />
        <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
          <div className="p-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <NavbarMenu />
              <h1 className="text-base font-semibold leading-6 text-gray-900">Assinatura</h1>
              <p className="mt-2 text-sm text-gray-700">
                Informações sobre as assinaturas dos associados.
              </p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                {/* Card */}
                {states.systemAssinatura.map((card, index) => (
                  <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {
                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                          }

                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            {
                              card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                            }

                            <dd>
                              {
                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                              }
                              {
                                card.value > 0 && (index === 4) && (
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row '>
                                      <div className="text-sm font-medium text-red-500 hover:text-red-200"><button onClick={() => showTables({ data: card, debit: true, param: 'debit' })}>Filtrar dados</button></div>
                                    </div>
                                  </dt>

                                )
                              }
                              {
                                card.value > 0 && (index === 3) && (
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row '>
                                      <div className="text-sm font-medium text-blue-500 hover:text-blue-700"><button onClick={() => showTables({ data: card, debit: false, param: 'suspended' })}>Verificar</button></div>
                                    </div>
                                  </dt>

                                )
                              }
                              {
                                card.value > 0 && (index === 1) && (
                                  <dt className="truncate text-sm font-medium text-gray-500">
                                    <div className='flex flex-row '>
                                      <div className="text-sm font-medium text-blue-500 hover:text-blue-700"><button onClick={() => showTables({ data: card, debit: false, param: 'new' })}>Verificar</button></div>
                                    </div>
                                  </dt>

                                )
                              }
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}

              </div>


              <div className='lg:flex'>
                <div className="lg:mr-2 flex lg:w-[80%] flex-col items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                  <h2 className="text-xl font-bold">Assinaturas por método de pagamento</h2>
                  <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Pie data={data} options={options} />
                  </div>
                </div>

                <div className="lg:ml-2 flex flex-col lg:w-[80%] items-center p-6 bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md mt-4 w-full h-full">
                  <h2 className="text-xl font-bold">Assinaturas por status</h2>
                  <span className="text-sm font-semibold text-gray-500">{moment().format('YYYY')}</span>
                  <div style={{ height: '100%', width: '100%' }}>
                    <Pie data={data1} options={options} />
                  </div>
                </div>
              </div>


              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Intervalo das assinaturas</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                  <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                    <label htmlFor="start-date" className="mr-2">Data de início:</label>
                    <input id="start-date" type="date" onChange={e => setters.setInitialDataAssinatura(e.target.value)} value={states.initialDataAssinatura} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center">
                    <label htmlFor="end-date" className="mr-2">Data de término:</label>
                    <input id="end-date" type="date" onChange={e => setters.setFinalDataAssinatura(e.target.value)} value={states.finalDataAssinatura} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                  </div>
                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataFilter()}>
                      Aplicar
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                <h1 className='text-xl font-semibold'>Busca por assinatura</h1>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                  <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                    const numericValue = e.target.value.replace(/\D/g, '');
                    setters.setQuerySearchAssinatura(numericValue)
                  }} onKeyDown={(e) => e.key === 'Enter' && dataSearch()}
                    value={states.querySearchAssinatura} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                  <div className="flex items-center md:ml-5 p-2">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataSearch()}>
                      Aplicar
                    </button>
                    <button className="ml-5 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-8 rounded" onClick={() => abrirFiltros()}>
                      Filtros
                    </button>
                    {
                      states.isLoading && (
                        <div className="flex items-center justify-center ml-8">
                          <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {
                states.dataTableFiltroAssinaturas.length > 0 &&
                <TableFilter setters={setters}
                  extrato={getExtrato}
                  add={add} funcef={changeFuncef} edit={abrirModal} active={activeSignature} form={openSuspendSignature} title='Assinaturas - Filtros ativos' description='Registro das assinaturas do SINPREV' next={nextFilters} previous={previousFilters} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetFiltroAssinaturas, limit: states.limitFiltroAssinaturas }} dataTable={states.dataTableFiltroAssinaturas} />
              }
              {
                states.dataTableFiltroAssinaturas.length == 0 &&
                <Table gerarNegociacao={() => console.log()} add={add}
                  extrato={getExtrato}
                  funcef={changeFuncef} edit={abrirModal} active={activeSignature} form={openSuspendSignature} title='Assinaturas' description='Registro das assinaturas do SINPREV' next={next} previous={previous} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetAssinatura, limit: states.limitAssinatura }} dataTable={states.dataAssinatura} />
              }

            </div>
          </div>
        </div>
        <ModalExtrato states={states} setters={setters} closeModal={closeModalNegociacao} data={dataAssinaturaExtrato} title='Extrato' signature={signature} />
        <ModalFiltro states={states} setters={setters} acao={acaoFiltros} />
        <Modal submit={submit} getAssociate={buscarAssociadoCpf} states={states} setters={setters} id={idSelecionado} />
        <ModalShow states={states} setters={setters} closeModal={closeModalShow} data={states.Assinatura} title='Visualização' />
        <ModalShowTable states={states} setters={setters} signature={true} closeModal={closeModalShow} headers={headersShow}
          negociacao={openModalNegociacao} title='Visualiazação de assinatura' description='Exibição de assinatura' get={dataAssinaturaCard } param={param} />
        <ModalConfirm states={states} action={actionConfirm} setters={setters} title={titleConfirm} description={descriptionConfirm} />
        <ModalConfirmFuncef states={states} action={changeFuncefAction} setters={setters} title='Migrar para FUNCEF' description='Deseja migrar essa assinatura para FUNCEF?' />
        <ModalNegociacao action={createNegociacaoAction} states={states} setters={setters} closeModal={closeModalNegociacao} data={states.Negociacao} title='Negociação' />
      </div>
    </>
  )
}
