import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'

export default function ModalFaqComunicacao(props) {



    const [id, setId] = useState('')
    const [form, setForm] = useState({
        question: '',
        answer: '',
    })
    const [errorForm, setErrorForm] = useState({
        question: false,
        answer: false,
    })







    const onSubmitForm = async (e) => {
        e.preventDefault()
        //Caso tenha os arquivos marcados, deve verificar assim como o link 

        let error = false;
        for (const item of Object.keys(form)) {
            if (form[item] == '' || form[item] == ' ' || form[item] == undefined) {
                if (item == 'file' && checkFile == false) continue;
                if (item == 'link' && checkLink == false) continue;
                if (item == 'image' && checkImage == false) continue;
                error = true
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (error) {
            error = false
            return;
        }
        if(props.states.isEdit){
            form.id = id
            props.action(form)
            return;
        }
        props.action({faqs: [form]})
    }



    useEffect(() => {
        if (props.states.modalFaqComunicacao == false) {
            setId('')
            Object.keys(form).map(item => {
                if (item == 'file' || item == 'image') {
                    setForm(e => {
                        return { ...e, [item]: null }
                    })
                }
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            setCheckFile(false)
            setCheckLink(false)
            setCheckImage(false)
            props.setters.setIsEdit(false)
        } else {
            if(Object.keys(props.states.FaqsComunicacao).length > 0){
                setId(props.states.FaqsComunicacao.id)
                setForm(() => {
                    return {...props.states.FaqsComunicacao}
                })
            }
        }
        //Limpeza do modal ao fechar
    }, [props.states.modalFaqComunicacao]);




    const [checkFile, setCheckFile] = useState(false)
    const [checkLink, setCheckLink] = useState(false)
    const [checkImage, setCheckImage] = useState(false)

    

    // useEffect(() => {
    //     if (checkFile == false) {
    //         setForm(() => {
    //             return { ...form, file: null }
    //         })
    //     }
    // }, [checkFile])

    /* aqui deve fazer um useEffect para limpar os arquivos, caso mude a opção */

    return (
        <Transition.Root show={props.states.modalFaqComunicacao} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalFaqComunicacao(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalFaqComunicacao(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            {props.states.isEdit === false ? 'Cadastrar' : 'Editar'} Pergunta e Resposta
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            Preencha os campos abaixo para cadastrar uma nova seção de perguntas e respostas
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Digite a pergunta
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o título da seção'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, question: e.target.value }
                                                                })}
                                                                value={form?.question}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.question === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório a pergunta.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 4 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Digite a resposta
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, answer: e.target.value }
                                                                })}
                                                                value={form?.answer}
                                                                placeholder='Digite a descrição do seção'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    {errorForm.answer === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da resposta.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                    </div>
                                </div>

                                <div className="mt-12 flex items-center justify-end gap-x-6">
                                    <button
                                        type="button"
                                        className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                        onClick={() => props.setters.setModalFaqComunicacao(false)}>
                                        Cancelar
                                    </button>
                                    <button
                                        // hidden ={disabled}
                                        type="submit"
                                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                        Enviar
                                    </button>
                                </div>
                            </form>

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
        </Transition.Root >
    )
}
