import { Line } from 'react-chartjs-2';
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from "../../../../Context/api"
import { BuildingLibraryIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { Chart, registerables } from 'chart.js'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay';

import moment from 'moment'
import Navbar from '../../../../Components/Navbar/navbar'
import ModalConfirma from '../../../../Components/Modal/Confirm'
import ModalInformacao from '../../../../Components/Modal/ShowCobranca'
import NavbarPanel from '../../../../Components/navMenu/financeiroFaturas'
import ModalFaturas from '../../../../Components/Modal/Fatura/modalFaturas'
import TableFinanceiro from '../../../../Components/Tabela/TabelaFinanceiroCobranca'
import TableFinanceirofiltro from '../../../../Components/Tabela/TabelaFinanceiroCobrancaFiltro'
import ModalFaturasBaixa from '../../../../Components/Modal/FaturaBaixa/modalFaturas'
import ModalFaturasChangeData from '../../../../Components/Modal/FaturaData/modalFaturas'
import ModalFiltros from '../../../../Components/Modal/ModalFiltrosCobrancas'

Chart.register(...registerables);

export default function Cobranca() {
    const { states, setters, } = useContext(AuthContext)
    const { dataCobrancasQuery, buscarFaturaPorCpf, reembolso, 
        checkFaturaVindi, dataSystemCobranca, mudarDataFatura, dataCobranca, cancelarFatura,
         buscarAssociadoCpf, buscarTerceirizadoCodigo, createCobranca, baixaFatura } = useContext(AuthContextApi)

    const [dataFiltro, setDataFiltro] = useState({});

    const loadData = async () => {
        const promises = [
            dataSystemCobranca()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        states.offsetCobranca > 0 && setters.setOffsetCobranca(0)
        setters.setQueryCobranca('')
        loadData()

        return () => {
            setters.setQueryCobranca('')
            setters.setDataCobranca([])
            setters.setDataTableFiltroCobrancas([])
            setters.setDataGraphicCobranca([])
            setters.setDataGraphicCobrancaNotPaid([])
            setters.setOffsetCobranca(0)
            setters.setLimitSearchCobranca(false)
            setters.setOffsetFiltroCobrancas(0)
            setters.setLimitSearchFiltroCobrancas(false)
            setters.setDataQuery([])
        }
    }, [])

    const callLogsData = async () => {
        if (states.queryCobranca != '') {
            await buscarFaturaPorCpf()
        }
        if (states.queryCobranca == '') {
            await dataCobranca()
        }
    }

    useEffect(() => {
        if (states.dataQuery.length > 0) return
        (states.offsetCobranca > 0 || states.offsetCobranca == 0) && callLogsData()
        states.offsetCobranca < 0 && setters.setOffsetCobranca(0)
    }, [states.offsetCobranca])



    const data = {
        labels: states.dataGraphicCobranca.map(elements => elements.date),
        datasets: [
            {
                label: 'Valor faturas pagas',
                data: states.dataGraphicCobranca.map(elements => elements.total),
                backgroundColor: 'rgba(63, 81, 181, 0.5)',
                borderColor: 'rgba(63, 81, 181, 1)',
                pointBackgroundColor: 'rgba(63, 81, 181, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(63, 81, 181, 1)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value) => {
                        return `$${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y || 0;
                        return `${label}: $${value.toLocaleString('en-US')}`;
                    },
                },
            },
        },
    };

    const dataFlowCash = {
        labels: states.dataGraphicCobrancaNotPaid.map(elements => elements.date),
        datasets: [
            {
                label: 'Entradas',
                data: states.dataGraphicCobrancaNotPaid.map(elements => elements.total),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const openModalInfo = (data) => {
        setters.setCobranca(data)
        setters.setModalShowTecnico(true)
    }

    const next = async () => {
        if (states.dataCobranca.length > 0) {
            if (states.dataCobranca.length === 0) {
                return
            } else {
                setters.setOffsetCobranca(states.offsetCobranca + states.limitCobranca)
            }
        } else {
            if (states.limitSearchCobranca) {
                return
            } else {
                setters.setOffsetCobranca(states.offsetCobranca + states.limitCobranca)
            }
        }

    };

    const previous = async () => {
        if (states.dataCobranca.length > 0) {
            if (states.dataCobranca.length === 0 && states.offsetCobranca <= 0) {
                return
            } else {
                states.offsetCobranca > 0 && setters.setOffsetCobranca(states.offsetCobranca - states.limitCobranca)
                states.offsetCobranca < 0 && setters.setOffsetCobranca(0)
            }
        } else {
            if (states.offsetCobranca <= 0) {
                states.offsetCobranca < 0 && setters.setOffsetCobranca(0)
                return
            } else {
                if (states.limitSearchCobranca) setters.setLimitSearchCobranca(false)
                setters.setOffsetCobranca(states.offsetCobranca - states.limitCobranca)
            }
        }
    };

    const closeModal = () => {
        setters.setModalShowTecnico(false)
        setters.setCobranca({})
    }

    const add = () => {
        setters.setModalIsOpen(true)
    }

    const cancelFatura = async (data) => {
        setters.setCobranca(data)
        setters.setConfirmModal(true)
        setters.setIsCanceled(true)
    }

    const reembolsoAction = async (data) => {
        setters.setCobranca(data)
        setters.setConfirmModal(true)
        setters.setIsCanceled(false)
    }

    const actionDelete = async () => {
        setters.setConfirmModal(false)
        if (states.isCanceled) {
            await cancelarFatura()
        } else {
            await reembolso()

        }
        setters.setCobranca({})
        setters.setConfirmModal(false)
    }

    const baixaFaturaValue = async (data) => {
        setters.setModalBaixa(true)
        setters.setCobranca({
            idInvoice: data.id,
        })
    }

    const baixaFaturaAction = async () => {
        setters.setModalBaixa(false)
        await baixaFatura()
    }

    const postCobranca = async (data) => {
        await createCobranca(data)
    }

    const dataSearch = async () => {
        // await searchQueryCobranca()
        if (states.offsetCobranca > 0) {
            setters.setOffsetCobranca(0)
        }
        await buscarFaturaPorCpf()
    }

    const openChangeDate = (data) => {
        setters.setCobranca({
            id: data.id
        })
        setters.setModalData(true)
    }

    const actionChangeDate = async () => {
        setters.setModalData(false)
        await mudarDataFatura()
    }

    const checkFatura = async (data) => {
        await checkFaturaVindi(data)
    }
    const headers = ['Valor', 'Descrição', 'Tipo', 'Nome', 'Data de Criação', 'Data de Vencimento', 'Status', 'Método de pagamento', 'Criador', '']

    const titleModal = states.isCanceled ? 'Confirmação de cancelamento' : 'Confirmação de reembolso'
    const descModal = states.isCanceled ? 'Deseja realmente cancelar a fatura?' : 'Deseja realmente reembolsar a fatura?'

    const abrirFiltros = () => {
        setDataFiltro({})
        setters.setOffsetFiltroCobrancas(0)
        setters.setModalFiltro(true)
    }
    const acaoFiltros = async (data) => {
        setters.setModalFiltro(false)

        await dataCobrancasQuery(data)
        setDataFiltro(data)

    }

    const nextFilters = async () => {
        if (states.limitSearchFiltroCobrancas) {
            return
        } else {
            setters.setOffsetFiltroCobrancas(states.offsetFiltroCobrancas + states.limitFiltroCobrancas)
        }


    };


    const previousFilters = async () => {
        if (states.offsetFiltroCobrancas <= 0) {
            states.offsetFiltroCobrancas < 0 && setters.setOffsetFiltroCobrancas(0)
            return
        } else {
            if (states.limitSearchFiltroCobrancas) setters.setLimitSearchFiltroCobrancas(false)
            setters.setOffsetFiltroCobrancas(states.offsetFiltroCobrancas - states.limitFiltroCobrancas)
        }

    };

    useEffect(() => {
        if (Object.keys(dataFiltro).length == 0) {
            return
        } else {
            acaoFiltros(dataFiltro)
        }
    }, [states.offsetFiltroCobrancas])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className="flex flex-1 flex-col md:pl-64 mt-2">
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">

                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Faturas de Cobrança</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre as faturas de cobrança.
                                    </p>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                        {/* Card */}
                                        {states.dataSystemCobranca.map((card, index) => {
                                            if (index == 0 || index == 1) {
                                                if ((localStorage.getItem('permission') == '0' || localStorage.getItem('permission') == '1' || localStorage.getItem('permission') == '2')) {
                                                    return (
                                                        <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                            <div className="p-5">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0">
                                                                        {
                                                                            (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                        }
                                                                    </div>
                                                                    <div className="ml-5 w-0 flex-1">
                                                                        <dl>
                                                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                                                <div className='flex flex-row'>
                                                                                    <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                                </div>
                                                                            </dt>
                                                                            <dd>
                                                                                {
                                                                                    index === 0 && (<div className="text-lg font-medium text-green-500">{card.value}</div>)
                                                                                }
                                                                                {
                                                                                    index === 1 && (<div className="text-lg font-medium text-red-500">{card.value}</div>)
                                                                                }
                                                                                {
                                                                                    index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                                }
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <div key={index} className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                        <div className="p-5">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    {
                                                                        (index === 0 || index === 1) ? (<BuildingLibraryIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />) : (<InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />)
                                                                    }
                                                                </div>
                                                                <div className="ml-5 w-0 flex-1">
                                                                    <dl>
                                                                        <dt className="truncate text-sm font-medium text-gray-500">
                                                                            <div className='flex flex-row'>
                                                                                <div className="text-sm font-sma text-gray-900">{card.title}</div>
                                                                            </div>
                                                                        </dt>
                                                                        <dd>
                                                                            {
                                                                                index === 0 && (<div className="text-lg font-medium text-green-500">{card.value}</div>)
                                                                            }
                                                                            {
                                                                                index === 1 && (<div className="text-lg font-medium text-red-500">{card.value}</div>)
                                                                            }
                                                                            {
                                                                                index !== 0 && index !== 1 && (<div className="text-lg font-medium text-gray-500">{card.value}</div>)
                                                                            }
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }


                                        }

                                        )}
                                    </div>


                                    <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                        <h1 className='text-xl font-semibold'>Intervalo das faturas</h1>
                                        <div className='flex lg:flex-row flex-col items-center justify-center mt-2'>
                                            <div className="flex items-center md:mr-8 ml-4 mb-4 md:mb-0">
                                                <label htmlFor="start-date" className="mr-2">Data de início:</label>
                                                <input id="start-date" type="date" onChange={e => setters.setInitialDateCobranca(e.target.value)} value={states.initialDateCobranca} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div className="flex items-center">
                                                <label htmlFor="end-date" className="mr-2">Data de término:</label>
                                                <input id="end-date" type="date" onChange={e => setters.setFinalDateCobranca(e.target.value)} value={states.finalDateCobranca} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div className="flex items-center md:ml-5 p-2">
                                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-8 rounded" onClick={() => dataCobranca()}>
                                                    Aplicar
                                                </button>
                                                {
                                                    states.isLoading && (
                                                        <div className="flex items-center justify-center ml-8">
                                                            <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
                                        <div class="flex flex-col items-center p-6 bg-white rounded-md hover:shadow-md w-[100%] h-full border border-1 border-gray-200">
                                            <h2 class="text-xl font-bold">Faturas pagas</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment(states.initialDateCobranca).format('DD/MM/YYYY')} - {moment(states.finalDateCobranca).format('DD/MM/YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={data} options={options} />
                                            </div>
                                        </div>

                                        <div class="flex flex-col items-center p-6 bg-white rounded-md hover:shadow-md w-[100%] h-full border border-1 border-gray-200">
                                            <h2 class="text-xl font-bold">Faturas canceladas e expiradas</h2>
                                            <span class="text-sm font-semibold text-gray-500">{moment(states.initialDateCobranca).format('DD/MM/YYYY')} - {moment(states.finalDateCobranca).format('DD/MM/YYYY')}</span>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <Line data={dataFlowCash} options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <h1 className='text-xl font-semibold'>Busca por fatura</h1>
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <input placeholder='Digite o dado que deseja buscar' type="text" onChange={e => {
                                        // Utiliza uma expressão regular para verificar se o valor é numérico
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        setters.setQueryCobranca(numericValue);
                                    }} onKeyDown={e => { e.key === 'Enter' && dataSearch() }}
                                        value={states.queryCobranca} className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]" />

                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => dataSearch()}>
                                            Aplicar
                                        </button>
                                        <button className="ml-5 bg-green-500 hover:bg-green-600 text-white py-1 font-bold px-8 rounded" onClick={() => abrirFiltros()}>
                                            Filtros
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="bg-white flex flex-col items-center justify-center bg-white border border-1 border-gray-200 rounded-md overflow-hidden bg-white p-4 mt-4">
                                <div className='flex lg:flex-row flex-col items-center justify-center mt-2 w-full'>
                                    <select
                                        onChange={e => setters.setStatusCobranca(e.target.value)}
                                        value={states.statusCobranca}
                                        className="border border-gray-300 px-2 py-1 rounded focus:outline-none focus:border-blue-500  w-[35%]"
                                    >
                                        <option value='all'>Todas as faturas</option>
                                        <option value='paid'>Faturas Pagas</option>
                                        <option value='pending'>Faturas Pendentes</option>
                                        <option value='expired'>Faturas Expiradas</option>
                                        <option value='canceled'>Faturas Canceladas</option>
                                        <option value='refounded'>Faturas Reembolsadas</option>
                                    </select>


                                    <div className="flex items-center md:ml-5 p-2">
                                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 font-bold px-8 rounded" onClick={() => callLogsData()}>
                                            Aplicar
                                        </button>
                                        {
                                            states.isLoading && (
                                                <div className="flex items-center justify-center ml-8">
                                                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-gray-500"></div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                states.dataTableFiltroCobrancas.length > 0 &&
                                <TableFinanceirofiltro checkFatura={checkFatura} changeDate={openChangeDate} baixa={baixaFaturaValue} reembolso={reembolsoAction} cancel={cancelFatura} add={add} title='Faturas de cobrança - Filtros' description='Registro das faturas de cobrança' next={nextFilters} previous={previousFilters} setters={setters} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetFiltroCobrancas, limit: states.limitFiltroCobrancas }} dataTable={states.dataTableFiltroCobrancas} />
                            }
                            {
                                states.dataTableFiltroCobrancas.length == 0 &&
                                <TableFinanceiro checkFatura={checkFatura} changeDate={openChangeDate} baixa={baixaFaturaValue} reembolso={reembolsoAction} cancel={cancelFatura} add={add} title='Faturas de cobrança' description='Registro das faturas de cobrança' next={next} previous={previous} setters={setters} states={states} show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetCobranca, limit: states.limitCobranca }} dataTable={states.dataCobranca} />
                            }


                        </div>
                    </div>
                </div>
                <ModalFiltros states={states} setters={setters} acao={acaoFiltros} />
                <ModalFaturasChangeData states={states} setters={setters} action={actionChangeDate} data={states.Cobranca} />
                <ModalInformacao states={states} setters={setters} closeModal={closeModal} data={states.Cobranca} title='Visualização ampla' />
                <ModalConfirma states={states} setters={setters} closeModal={closeModal} data={states.Cobranca} title={titleModal} description={descModal} action={actionDelete} />
                <ModalFaturas post={postCobranca} associado={buscarAssociadoCpf} terceirizado={buscarTerceirizadoCodigo} states={states} setters={setters} />
                <ModalFaturasBaixa action={baixaFaturaAction} post={postCobranca} associado={buscarAssociadoCpf} terceirizado={buscarTerceirizadoCodigo} states={states} setters={setters} />

            </div>
        </>
    )
}
