import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import moment from 'moment'

export default function ModalProcessos(props) {




    const [isUser, setIsUser] = useState('')
    const [form, setForm] = useState({
        escritorio: '',
        advogado: '',
        isPublic: '',
        acao: '',
        numero: '',
        titulo: '',
        status: '',
        tipo: '',
        reu: '',
        acusado: '',
        vara: '',
        juiz: '',
        dataAbertura: '',
        dataProximaAudiencia: '',
        descricao: '',
        notas: '',
    })
    const [errorForm, setErrorForm] = useState({
        escritorio: false,
        advogado: false,
        numero: false,
        acao: false,
        isPublic: false,
        titulo: false,
        status: false,
        tipo: false,
        reu: false,
        acusado: false,
        juiz: false,
        vara: false,
        dataAbertura: false,
        dataProximaAudiencia: false,
        descricao: false,
        notas: false,
    })



    const [dataSelect, setDataSelect] = useState([])
    const [dataSelectLawyer, setDataSelectLawyer] = useState([])
    const [dataSelectCourt, setDataSelectCourt] = useState([])
    const [dataSelectAcao, setDataSelectAcao] = useState([])





    const onSubmitForm = async (e) => {
        e.preventDefault()
        let error = false;
        for (const item of Object.keys(form)) {
            if (form[item] == '' || form[item] == ' ' || form[item] == undefined) {
                error = true
                setErrorForm(e => {
                    return { ...e, [item]: true }
                })
            } else {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            }
        }
        if (error) {
            error = false
            return;
        }

        let obj = {
            attorney: form.advogado,
            caseTitle: form.titulo,
            caseDescription: form.descricao,
            action_id: form.acao,
            caseType: form.tipo,
            status: form.status,
            court: form.vara,
            judge: form.juiz,
            isPublic: form.isPublic,
            lawFirm: form.escritorio,
            notes: form.notas,
            filingDate: form.dataAbertura,
            nextFilingDate: form.dataProximaAudiencia,
            number: form.numero,
            defendant: form.reu,
            prosecutor: form.acusado,
            id: form.id || null

        }
        props.action(obj)
    }



    useEffect(() => {
        if (props.states.modalJuri === false) {
            Object.keys(form).map(item => {
                setForm(e => {
                    return { ...e, [item]: '' }
                })
            })
            Object.keys(errorForm).map(item => {
                setErrorForm(e => {
                    return { ...e, [item]: false }
                })
            })
            props.setters.setIsEditJuri(false)
            props.setters.setJuriLegalProcess({})
            props.setters.setDataJuriLawyer([])
            props.setters.setDataJuriEscritorio([])
            props.setters.setDataJuriTribunais([])
            props.setters.setDataJuriAcoes([])
        } else {
            if (Object.keys(props.states.JuriOutSourced).length > 0) {
                setIsUser(props.states.JuriOutSourced.id)

            }
            if (Object.keys(props.states.JuriLegalProcess).length > 0) {
                const checkLaw = dataSelectLawyer.find(item => item.id === props.states.JuriLegalProcess.law_id)
                if (!checkLaw) {
                    dataSelectLawyer.push({ id: props.states.JuriLegalProcess.lawyer_id, email: props.states.JuriLegalProcess.lawyer_email })
                }
                const checkEscritorio = dataSelect.find(item => item.id === props.states.JuriLegalProcess.lawFirm_id)
                if (!checkEscritorio) {
                    dataSelect.push({ id: props.states.JuriLegalProcess.lawFirm_id, email: props.states.JuriLegalProcess.lawfirm_email })
                }
                const checkTribunal = dataSelectCourt.find(item => item.id === props.states.JuriLegalProcess.court)
                if (!checkTribunal) {
                    dataSelectCourt.push({ id: props.states.JuriLegalProcess.court, email: props.states.JuriLegalProcess.court_email, name: props.states.JuriLegalProcess.court_name })
                }
                const checkAcao = dataSelectAcao.find(item => item.id === props.states.JuriLegalProcess.action_id)
                if (!checkAcao) {
                    dataSelectAcao.push({ id: props.states.JuriLegalProcess.action_id, name: props.states.JuriLegalProcess.action_name, isColetive: props.states.JuriLegalProcess.isColetive, isRestricted: props.states.JuriLegalProcess.isRestricted })
                }
                setForm(() => {
                    return {
                        ...form,
                        escritorio: props.states.JuriLegalProcess.lawFirm_id,
                        advogado: props.states.JuriLegalProcess.lawyer_id,
                        acao: props.states.JuriLegalProcess.action_id,
                        numero: props.states.JuriLegalProcess.number,
                        titulo: props.states.JuriLegalProcess.caseTitle,
                        status: props.states.JuriLegalProcess.status,
                        tipo: props.states.JuriLegalProcess.caseType,
                        reu: props.states.JuriLegalProcess.defendant,
                        acusado: props.states.JuriLegalProcess.prosecutor,
                        vara: props.states.JuriLegalProcess.court,
                        juiz: props.states.JuriLegalProcess.judge,
                        dataAbertura: moment(props.states.JuriLegalProcess.filingDate).format('YYYY-MM-DD'),
                        dataProximaAudiencia: moment(props.states.JuriLegalProcess.nextFilingDate).format('YYYY-MM-DD'),
                        descricao: props.states.JuriLegalProcess.caseDescription,
                        notas: props.states.JuriLegalProcess.notes,
                        isPublic: props.states.JuriLegalProcess.isPublic == 1 ? true : false,
                        id: props.states.JuriLegalProcess.id
                    }
                })
            }
        }
        //Limpeza do modal ao fechar
    }, [props.states.modalJuri]);


    const setTerAdvogadoFunction = async e => {
        // /more
        if (e.target.value == 'search') {
            await props.moreAdvogado()
        } else {
            setForm(() => {
                return { ...form, advogado: e.target.value }
            })
        }
    }
    const setEscritorioFunction = async e => {
        // /more
        if (e.target.value == 'search') {
            await props.moreEscritorio()
        } else {
            setForm(() => {
                return { ...form, escritorio: e.target.value }
            })
        }
    }




    const setTribunalFunction = async e => {
        // /more
        if (e.target.value == 'search') {
            await props.moreTribunal()
        } else {
            setForm(() => {
                return { ...form, vara: e.target.value }
            })
        }
    }

    
    const setAcaoFunction = async e => {
        // /more
        if (e.target.value == 'search') {
            await props.moreAcao()
        } else {
            setForm(() => {
                return { ...form, acao: e.target.value }
            })
        }
    }


    useEffect(() => {
        if (props.states.dataJuriEscritorio.length > 0 && props.states.dataJuriLawyer.length > 0 && props.states.dataJuriTribunais.length > 0 && props.states.dataJuriAcoes.length > 0) {
            setDataSelect(props.states.dataJuriEscritorio)
            setDataSelectLawyer(props.states.dataJuriLawyer)
            setDataSelectCourt(props.states.dataJuriTribunais)
            setDataSelectAcao(props.states.dataJuriAcoes)
        }
    }, [props.states.dataJuriEscritorio, props.states.dataJuriLawyer, props.states.dataJuriTribunais, props.states.dataJuriAcoes])

    return (
        <Transition.Root show={props.states.modalJuri} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => props.setters.setModalJuri(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                        className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel
                                className=" w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => props.setters.setModalJuri(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <div className='p-3'>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            {props.states.isEditJuri === false ? 'Cadastrar' : 'Editar'} processo
                                        </h2>
                                        <p className="mt-2 text-sm leading-6 text-gray-600">
                                            {props.states.isEditJuri === false ? 'Preencha os campos abaixo para iniciar o cadastro de um novo processo' : 'Preencha os campos abaixo para editar um processo'}
                                        </p>
                                    </div>
                                </div>

                                <form className='pt-10 pl-10 pr-10' onSubmit={e => onSubmitForm(e)}>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            {/* LINHA 1 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-12">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Ação
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setAcaoFunction(e)}
                                                                value={form?.acao}>
                                                                <option value={''}>Selecione ..</option>
                                                                {
                                                                    dataSelectAcao?.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.id}>{item.name} - {item.isColetive === 1 ? 'Coletiva' : 'Individual'} - {item.isRestricted === 1 ? 'Restrita a fundo' : 'Todos os fundos'}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                                {
                                                                    !props.states.limitSearchJuriAcoes &&
                                                                    <option value={'search'}>Pesquisar ..</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.acao === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da ação.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Escritório
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setEscritorioFunction(e)}
                                                                value={form?.escritorio}>
                                                                <option value={''}>Selecione ..</option>
                                                                {
                                                                    dataSelect?.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.id}>{item.name} - {item.email}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                                {
                                                                    !props.states.limitSearchJuriEscritorio &&
                                                                    <option value={'search'}>Pesquisar ..</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.escritorio === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do escritório.
                                                    </p>
                                                    }
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Advogado responsável
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setTerAdvogadoFunction(e)}
                                                                value={form?.advogado}>
                                                                <option value={''}>Selecione ..</option>
                                                                {
                                                                    dataSelectLawyer?.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.id}>{item.name} - {item.emailOutsourced}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                                {
                                                                    !props.states.limitSearchJuriLawyer &&
                                                                    <option value={'search'}>Pesquisar ..</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.advogado === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do advogado.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Número do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o número do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, numero: e.target.value }
                                                                })}
                                                                value={form?.numero}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.numero === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do número do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Título do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o número do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, titulo: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.titulo}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.titulo === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do título do processo.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 2 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Status do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, status: e.target.value }
                                                                })}
                                                                value={form?.status}>
                                                                <option value={''}>Selecione ..</option>
                                                                <option value={'1'}>Aberto</option>
                                                                <option value={'2'}>Fechado</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.status === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do status do processo.
                                                    </p>
                                                    }
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tipo do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o tipo do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, tipo: e.target.value }
                                                                })}
                                                                value={form?.tipo}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.tipo === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do tipo do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Sujeito passivo do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o réu do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, reu: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.reu}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.reu === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do sujeito passivo do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Sujeito ativo do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o acusado do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, acusado: e.target.value }
                                                                })}
                                                                value={form?.acusado}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.acusado === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do sujeito ativo do processo.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 3 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Juiz do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='text'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                placeholder='Digite o juiz do processo'
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, juiz: e.target.value }
                                                                })}
                                                                value={form?.juiz}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.juiz === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do juiz do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Tribunal
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <select
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setTribunalFunction(e)}
                                                                value={form?.vara}>
                                                                <option value={''}>Selecione ..</option>
                                                                {
                                                                    dataSelectCourt?.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.id}>{item.name} - {item.email}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                                {
                                                                    !props.states.limitSearchJuriTribunais &&
                                                                    <option value={'search'}>Pesquisar ..</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.vara === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento do tribunal do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Data de abertura
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='date'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, dataAbertura: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.dataAbertura}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errorForm.dataAbertura === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da data de abertura do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Data próxima audiência
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md">
                                                            <input
                                                                type='date'
                                                                className="pl-2 block w-full  min-w-0 flex-1 rounded-none rounded-l-md rounded-r-md border-0 ring-1 ring-inset ring-gray-300 py-1.5 text-gray-900 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, dataProximaAudiencia: e.target.value }
                                                                }
                                                                )}
                                                                value={form?.dataProximaAudiencia}
                                                            />

                                                        </div>
                                                    </div>
                                                    {errorForm.dataProximaAudiencia === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da data da próxima audiência.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                            {/* LINHA 4 */}
                                            <div className="grid max-w-6xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-12 md:col-span-8">
                                                <div className="sm:col-span-4">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Descrição do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, descricao: e.target.value }
                                                                })}
                                                                value={form?.descricao}
                                                                placeholder='Digite a descrição do processo'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {errorForm.descricao === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento da descrição do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-4">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Notas do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <textarea
                                                                id="oab"
                                                                name="oab"
                                                                className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, notas: e.target.value }
                                                                })}
                                                                value={form?.notas}
                                                                placeholder='Digite as notas do processo'
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    {errorForm.notas === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento das notas do processo.
                                                    </p>
                                                    }
                                                </div>
                                                <div className="sm:col-span-4">
                                                    <label
                                                        htmlFor="title"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Visibilidade do processo
                                                    </label>
                                                    <div className="mt-2">
                                                        <div
                                                            className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 ">
                                                            <select className="pl-2 block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                                onChange={e => setForm(() => {
                                                                    return { ...form, isPublic: e.target.value }
                                                                })}
                                                                value={form?.isPublic}>
                                                                <option value={''}>Selecione ..</option>
                                                                <option value={true}>Público</option>
                                                                <option value={false}>Privado</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {errorForm.isPublic === true && <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                        É obrigatório o preenchimento das notas do processo.
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => props.setters.setModalJuri(false)}>
                                            Cancelar
                                        </button>
                                        <button
                                            // hidden ={disabled}
                                            type="submit"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                            Enviar
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
