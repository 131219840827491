import Navbar from '../../../Components/Navbar/navbar'
import ModalShow from '../../../Components/Modal/ShowHealthRegister'
import NavbarPanel from '../../../Components/navMenu/tecnico'
import TabelaHooks from '../../../Components/Tabela/TabelaHealth'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalConfirmHealth from '../../../Components/Modal/ConfirmHealthRegister'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../Context"
import { AuthContextApi } from '../../../Context/api'
import { InformationCircleIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'

export default function RegistroLogs() {
    const { states, setters } = useContext(AuthContext)
    const {
        deleteHealth,
        postHealth,
        dataHealthRegister,
        dataHealth,
        dataSystemHealth, } = useContext(AuthContextApi)

    let dataArrSys = []

    const callLogsData = async () => {
        const promises = [
            dataSystemHealth(),
            dataHealth()
        ];

        await Promise.all(promises);
    }

    useEffect(() => {
        setters.setLimitSearchHealthRegister(false)
        setters.setOffsetHealthRegister(0)
    }, [states.modalHealth])


    useEffect(() => {
        callLogsData()
    }, [states.offsetHealth])

    useEffect(() => {
        setters.setOffsetHealth(0)
        return () => {
            setters.setLimitSearchHealth(false)
            setters.setDataHealth([])
        }
    }, [])

    useEffect(() => {
        dataArrSys = JSON.parse(localStorage.getItem('dataSystemAlertTec'))
    }, [states.dataSystemHealth])



    const next = async () => {
        if (states.limitSearchHealth) {
            return
        } else {
            setters.setOffsetHealth(states.offsetHealth + states.limitLog)
        }
    }

    const previous = async () => {
        if (states.offsetHealth <= 0) {
            states.offsetHealth < 0 && setters.setOffsetHealth(0)
            return
        } else {
            if (states.limitSearchHealth) setters.setLimitSearchHealth(false)
            setters.setOffsetHealth(states.offsetHealth - states.limitLog)
        }
    }

    const openModalInfo = async (data) => {
        const resp = await dataHealthRegister(data.id)
        if (resp) {
            setters.setHealth(data)
            setters.setModalHealth(true)
        }
    }

    const closeModal = () => {
        setters.setOffsetHealthRegister(0)
        setters.setModalHealth(false)
        setters.setHealth({})
    }





    const headers = ['Título', 'Descrição', 'Status', 'Agendado', 'Criador', 'Data de criação', 'Data de atualização', '']

    const translateNameObject = name => {
        switch (name) {
            case 'healthInMonth':
                return 'Revisão mensal'
            case 'healthSchedule':
                return 'Agendamento'
            case 'filesSave':
                return 'Arquivos gerados'
            case 'healthProcess':
                return 'Revisões processadas'
            default:
                return ''
        }
    }

    const actionScheduled = async () => {
        await postHealth()
        setters.setConfirmModal(false)
    }

    const deleteScheduled = (data) => {
        setters.setHealth(data)
        setters.setModalHealthConfirm(true)
    }

    const deleteActionScheduled = async () => {
        await deleteHealth(states.Health.id)
        setters.setModalHealthConfirm(false)
    }


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavbarPanel />
                            <SpinnerOverlay/>
                            <div className='flex w-full flex-row items-between justify-between'>
                                <div className='flex flex-col'>
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Saúde do sistema</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Informações sobre o sistema de monitoramento.
                                    </p>
                                </div>
                                <button className='mr-2 border rounded-[15px] shadow' onClick={() => setters.setConfirmModal(true)}>
                                    <ClipboardDocumentCheckIcon className='h-9 w-10 text-gray-400' />
                                </button>
                            </div>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                                {Object.keys(states.dataSystemHealth).map((card, index) => {
                                    return (
                                        <div key={card} className="verflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                            <div className="p-5">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0">
                                                        {index === 0 ?
                                                            <InformationCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                                            : <InformationCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                                        }
                                                    </div>
                                                    <div className="ml-5 w-0 flex-1">
                                                        <dl>
                                                            <dt className="truncate text-sm font-medium text-gray-500">{translateNameObject(card)}</dt>
                                                            <dd>
                                                                {
                                                                    index === 0 ? (
                                                                        <div className="text-lg font-medium text-gray-900">{
                                                                            parseInt(states.dataSystemHealth[card]) > 0 ? (
                                                                                <span className='text-green-500'>{'Realizada'}</span>
                                                                            ) : (
                                                                                <span className='text-red-500'>{'Pendente'}</span>
                                                                            )
                                                                        }</div>
                                                                    ) : (
                                                                        <div className="text-lg font-medium text-gray-900">{states.dataSystemHealth[card]}</div>
                                                                    )
                                                                }
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                            <TabelaHooks title='Revisões' description='Registro das revisões de sistema' next={next} previous={previous} states={states} dell={deleteScheduled}
                                show={openModalInfo} headers={headers} infoTable={{ offset: states.offsetHealth, limit: states.limitHealth }} dataTable={states.dataHealth} />
                        </div>
                    </div>
                    <ModalConfirm states={states} setters={setters} title='Agendamento de revisão' description='Deseja confirmar a solicitação de agendamento?' action={actionScheduled} />
                    <ModalConfirmHealth states={states} setters={setters} title='Deleção de revisão' description='Deseja confirmar a deleção do agendamento?' action={deleteActionScheduled} />
                    <ModalShow states={states} setters={setters} closeModal={closeModal} data={states.dataHealthRegister} title='Dados de revisão' get={dataHealthRegister} />
                </div>
            </div>
        </>
    )
}
