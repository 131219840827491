import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ModalFiltro = ({ states, setters, acao }) => {
    const [selectedFilters, setSelectedFilters] = useState({
        nome: false,
        email: false,
        cpf: false,
        preço: false,
        descrição: false,
        statusDePagamento: false,
        dataDeVencimento: false,
        dataDeCriação: false,

    });

    // 'viewsignatgureassociate.id',
    // 'viewsignatgureassociate.name',
    // 'viewsignatgureassociate.email',
    // 'viewsignatgureassociate.cpf',
    // 'viewsignatgureassociate.isFuncef',
    // 'viewsignatgureassociate.dayCharge',
    // 'viewsignatgureassociate.statusPaid',
    // 'viewsignatgureassociate.dateTolerance',
    // 'viewsignatgureassociate.method',
    // 'viewsignatgureassociate.planTitle',
    // 'viewsignatgureassociate.createdAt'
    const [dataFilter, setDataFilter] = useState([]);


    //Apos conseguir a troca do objeto, faca a validacao e envie para o backend
    const changeKey = (key, value) => {
        switch (key) {
            case 'nome':
                setDataFilter(prev => ({ ...prev, name: value }));
                break;
            case 'email':
                setDataFilter(prev => ({ ...prev, email: value }));
                break;
            case 'cpf':
                setDataFilter(prev => ({ ...prev, cpf: value }));
                break;
            case 'preço':
                setDataFilter(prev => ({ ...prev, price: value }));
                break;
            case 'descrição':
                setDataFilter(prev => ({ ...prev, description: value }));
                break;
            case 'statusDePagamento':
                setDataFilter(prev => ({ ...prev, status: value }));
                break;    
            case 'dataDeVencimento':
                setDataFilter(prev => ({ ...prev, experies_at: value }));   
                break;
            case 'dataDeCriação':
                setDataFilter(prev => ({ ...prev, created_at: value }));
                break;
            default:
        }
    }

    const action = () => {
        setters.setModalFiltro(false);
        acao(dataFilter);
        Object.keys(selectedFilters).map((key) => {
            setSelectedFilters(prev => ({ ...prev, [key]: false }));
        });
    }

    const close = () => {
        setters.setModalFiltro(false);
        Object.keys(selectedFilters).map((key) => {
            setSelectedFilters(prev => ({ ...prev, [key]: false }));
        });
    }

    return (
        <Transition.Root show={states.modalFiltro} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setters.setModalFiltro}>
                <div className="flex min-h-screen items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <Dialog.Panel className="w-full max-w-6xl p-4 rounded-lg bg-white shadow-xl border-2">
                            <div className='flex flex-col items-center p-5'>
                                <h1 className="text-2xl font-bold">Filtros</h1>
                                <p className="ml-2 text-gray-500">Selecione os filtros desejados e realize sua busca!</p>
                            </div>
                            <div className='flex flex-col border p-6 rounded-[15px] shadow-sm hover:shadow-md'>
                                <div className="grid sm:grid-cols-4 sm:gap-4  grid-cols-2 gap-4">
                                    {Object.entries(selectedFilters).map(([key, value]) => (
                                        <div key={key} className="p-2 border rounded bg-gray-100">
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={value}
                                                    onChange={() => setSelectedFilters(prev => ({ ...prev, [key]: !value }))}
                                                />
                                                <span className="ml-2 capitalize">{key.replace(/([A-Z])/g, ' $1').toLowerCase()}</span>
                                            </label>
                                            {value && key !== 'statusDePagamento' && 
                                            (
                                                <input
                                                    type={
                                                        ['dataDeVencimento', 'dataDeCriação'].includes(key)
                                                            ? 'date'
                                                            : ['diaDebito'].includes(key) ? 'number' : 'text'
                                                    }
                                                    onChange={(e) => changeKey(key, e.target.value)}
                                                    placeholder={`Digite ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                                                    className="mt-2 p-2 w-full border rounded"
                                                />
                                            )}
                                            {value && key === 'statusDePagamento' && (
                                                <select
                                                    onChange={(e) => changeKey(key, e.target.value)}
                                                    className="mt-2 p-2 w-full border rounded"
                                                >
                                                    <option value="">Selecione</option>
                                                    <option value="paid">Pago</option>
                                                    <option value="pending">Pendendte</option>
                                                    <option value="expired">Expirado</option>
                                                    <option value="canceled">Cancelado</option>
                                                </select>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-4 flex justify-end">
                                    <button
                                        className={Object.values(selectedFilters).every((value) => !value) ? "ml-2 bg-gray-400 text-white px-4 py-2 rounded" : "ml-2 bg-blue-600 text-white px-4 py-2 rounded"}
                                        onClick={() => action()}
                                        disabled={Object.values(selectedFilters).every((value) => !value)}
                                    >
                                        Aplicar Filtros
                                    </button>
                                    <button
                                        className=" ml-2 bg-red-600 text-white px-4 py-2 rounded"
                                        onClick={() => close()}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default ModalFiltro;
