import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/pontoEletronico'
import Table from '../../../../Components/Tabela/TabelaDiasAtrasados'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'

import { useContext, useEffect } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'


export default function DiasAtrasados() {
    const { dataDiasAtrasados } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)

    useEffect(() => {
        return () => {
            setters.setOffSetDiasAtrasados(0)
            setters.setLimitSearchDiasAtrasados(false)
            setters.setDataDiasAtrasados([])
        }
    }, [])

    useEffect(() => {
        if (states.dataQuery.length == 0) {
            listarConfiguracaoAsync()
        }
    }, [states.offsetDiasAtrasados])

    const listarConfiguracaoAsync = async (id) => {
        await dataDiasAtrasados()
    }

    const add = async () => {
        setters.setModalConfiguracaoPonto(true);
    }

    const next = async () => {
        if (states.dataDiasAtrasados.length === 0) {
            return
        } else {
            const data = states.dataQuery
            setters.setOffSetDiasAtrasados(states.offsetDiasAtrasados + states.limitDiasAtrasados)
            const sumOff = states.offsetDiasAtrasados + states.limitDiasAtrasados
            const limit = states.limitDiasAtrasados + sumOff
            const newData = data.slice(sumOff, limit)
            setters.setDataDiasAtrasados(newData)
        }
    };

    const previous = async () => {
        if (states.dataDiasAtrasados.length === 0 && states.offsetDiasAtrasados <= 0) {
            return
        } else {
            const data = states.dataQuery
            states.offsetDiasAtrasados > 0 && setters.setOffSetDiasAtrasados(states.offsetDiasAtrasados - states.limitDiasAtrasados)
            states.offsetDiasAtrasados < 0 && setters.setOffSetDiasAtrasados(0)
            const sumOff = states.offsetDiasAtrasados > 0 ? states.offsetDiasAtrasados - states.limitDiasAtrasados : 0;
            const limit = sumOff + 10 > states.limitDiasAtrasados ? sumOff + 10 : states.limitDiasAtrasados;
            const newData = data.slice(sumOff, limit)
            setters.setDataDiasAtrasados(newData)
        }
    };

    const headers = ['Email', 'Atrasado', 'Data', 'Devendo Horas', 'Tempo Total de Atraso',]

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />

                            <h1 className="text-base font-semibold leading-6 text-gray-900">Dias Atrasados</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre os dias atrasados dos usuários
                            </p>
                            <Table add={add} title='Dias Atrasados' description='Registro dos dias atrasados'
                                next={next} previous={previous} states={states} headers={headers}
                                infoTable={{ offset: states.offsetDiasAtrasados, limit: states.limitDiasAtrasados }} dataTable={states.dataDiasAtrasados} />
                        </div>
                    </div>
                </div>

                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
