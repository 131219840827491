import Navbar from '../../../../Components/Navbar/navbar'
import NavPanel from '../../../../Components/navMenu/pontoEletronico'
import Modal from '../../../../Components/Modal/ConfiguracaoPonto/modalConfiguracaoPonto'
import Table from '../../../../Components/Tabela/TabelaConfiguracaoPonto'
import SpinnerOverlay from '../../../../Components/SpinnerOverlay'

import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../../../Context"
import { AuthContextApi } from '../../../../Context/api'


export default function ConfiguracaoPonto() {
    const { dataConfiguracaoPonto, searchQueryHistorico } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const [isEdit,setIsEdit] = useState(false)
    const [data, setData] = useState([])


    useEffect(() => {
        return () => {
            setters.setOffSetConfiguracaoPonto(0)
            setters.setLimitSearchConfiguracaoPonto(false)
            setters.setDataConfiguracaoPonto([])
        }
    }, [])


    useEffect(() => {
    if (states.dataQuery.length == 0){
        listarConfiguracaoAsync()
    }
    }, [states.offsetConfiguracaoPonto])

    const listarConfiguracaoAsync = async (id) => {
        await dataConfiguracaoPonto()
    }

    const openModalInfo = async (data) => {
        setters.setModalConfiguracaoPonto(true);
        setIsEdit(true)
    }

    const add = async () => {
        setters.setModalConfiguracaoPonto(true);
        setIsEdit(false)
        setData([])
    }

    const edit = async (data) => {
        setters.setModalConfiguracaoPonto(true);
        setIsEdit(true)
        setData(data)
    }

    const next = async () => {
        if (states.dataConfiguracaoPonto.length === 0) {
            return false
        } else {
            const data = states.dataQuery
            setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto + states.limitConfiguracaoPonto)
            const sumOff = states.offsetConfiguracaoPonto + states.limitConfiguracaoPonto
            const limit = states.limitConfiguracaoPonto + sumOff
            const newData = data.slice(sumOff, limit)
            setters.setDataConfiguracaoPonto(newData)
        }
    };

    const previous = async () => {
        if (states.dataQuery.length > 0) {
            if (states.dataConfiguracaoPonto.length === 0 && states.offsetConfiguracaoPonto <= 0) {
                return
            } else {
                const data = states.dataQuery
                states.offsetConfiguracaoPonto > 0 && setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
                states.offsetConfiguracaoPonto < 0 && setters.setOffSetConfiguracaoPonto(0)
                const sumOff = states.offsetConfiguracaoPonto > 0 ? states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto : 0;
                const limit = sumOff + 10 > states.limitConfiguracaoPonto ? sumOff + 10 : states.limitConfiguracaoPonto;
                const newData = data.slice(sumOff, limit)
                setters.setDataConfiguracaoPonto(newData)
            }
        } else {
            if (states.offsetConfiguracaoPonto <= 0) {
                states.offsetConfiguracaoPonto < 0 && setters.setOffsetConfiguracaoPonto(0)
                return
            } else {
                if (states.limitSearchConfiguracaoPonto) setters.setLimitSearchConfiguracaoPonto(false)
                setters.setOffSetConfiguracaoPonto(states.offsetConfiguracaoPonto - states.limitConfiguracaoPonto)
            }
        }
    };

    const headers = ['Email', 'Horário Total (Dia)',  'Horário de Almoço', 'Horário de Entrada', 'Horário de Saída', '']

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <SpinnerOverlay />

                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavPanel />
                            
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Configuração dos Pontos Eletrônicos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Informações sobre as configurações dos pontos eletrônicos dos usuários
                            </p>

                            <Table add={add} title='Configuração dos Pontos' description='Registro das configurações dos pontos' 
                                   next={next} previous={previous} states={states} show={openModalInfo} headers={headers} edit={edit}
                                   infoTable={{ offset: states.offsetConfiguracaoPonto, limit: states.limitConfiguracaoPonto }} dataTable={states.dataConfiguracaoPonto} />
                        </div>
                    </div>
                </div>

                <Modal states={states} setters={setters} edit={isEdit} data={data}/>
                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}
